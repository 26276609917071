import {Component, DestroyRef, inject, ViewEncapsulation, Input, OnInit, input} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {IconsAccordionMenuComponent} from "@app/common/components/viewers/configurator/config-menu/icons-accordion-menu/icons-accordion-menu.component"
import {DefaultMenuComponent} from "@app/common/components/viewers/configurator/config-menu/default-menu/default-menu.component"
import {ConfigMenuService} from "@app/common/components/viewers/configurator/config-menu/services/config-menu.service"
import {InterfaceDescriptor, isConfigInput} from "@cm/lib/templates/interface-descriptors"
import {UiStyle} from "@cm/lib/templates/nodes/scene-properties"
import {Breakpoints} from "@app/common/models/constants"

@Component({
    standalone: true,
    selector: "cm-config-menu",
    templateUrl: "./config-menu.component.html",
    styleUrls: ["./config-menu.component.scss"],
    encapsulation: ViewEncapsulation.ShadowDom,
    imports: [IconsAccordionMenuComponent, DefaultMenuComponent],
})
export class ConfigMenuComponent implements OnInit {
    $hideInputsSetByTemplate = input<boolean>(true)

    interfaceDescriptors: InterfaceDescriptor<unknown, object>[] = []
    uiStyle: UiStyle | undefined

    //https://github.com/angular/angular/issues/53981
    //input/output signals currently do not work properly on web components:
    //Do not rename any of the inputs, they are used on the web component and exposed to the customers
    @Input() uiStyleOverride?: UiStyle
    @Input() externalMenu: boolean = false
    @Input() useCaptions: boolean = false

    private configMenuService = inject(ConfigMenuService)
    private destroyRef = inject(DestroyRef)

    minimized = false

    constructor() {
        this.configMenuService.interfaceNew$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((interfaceDescriptors) => {
            this.interfaceDescriptors = interfaceDescriptors.filter((desc) => this.showInput(desc))
        })

        this.configMenuService.uiStyle$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((uiStyle) => {
            if (this.uiStyleOverride != null) this.uiStyle = this.uiStyleOverride
            else this.uiStyle = uiStyle
        })
    }

    ngOnInit(): void {
        if (window.innerWidth < Breakpoints.mobileScreen) {
            this.minimized = true
        }
    }

    showInput(descriptor: InterfaceDescriptor<unknown, object>): boolean {
        if (isConfigInput(descriptor)) return true
        return this.$hideInputsSetByTemplate() ? !descriptor.props.isSetByTemplate : true
    }
}
