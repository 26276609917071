import {MeshBuffers, createMeshBuffersForMaterialGroups} from "@cm/lib/geometry-processing/mesh-data"
import {IMaterialData, keyForMeshMaterialData} from "@cm/lib/templates/interfaces/material-data"
import {MeshRenderSettings, SceneNodes} from "@cm/lib/templates/interfaces/scene-object"
import {anyDifference, mapDifferent, objectFieldsDifferent} from "@template-editor/helpers/change-detection"
import {ThreeObject, getThreeObjectPart, mathIsEqual, setThreeObjectPart, updateTransform} from "@template-editor/helpers/three-object"
import {ThreeSceneManagerService} from "@template-editor/services/three-scene-manager.service"
import {Observable, of, tap} from "rxjs"
import * as THREE from "three"
import {SAH, acceleratedRaycast, computeBoundsTree, disposeBoundsTree} from "three-mesh-bvh"

THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree
THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree
THREE.Mesh.prototype.raycast = acceleratedRaycast

export function createBufferGeometry(meshBuffers: MeshBuffers): THREE.BufferGeometry {
    const {vertices, normals, uvs, indices} = meshBuffers

    const geometry = new THREE.BufferGeometry()
    geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3))
    geometry.setAttribute("normal", new THREE.BufferAttribute(normals, 3))
    geometry.setAttribute("uv", new THREE.BufferAttribute(uvs[0], 2))
    for (let uvIdx = 1; uvIdx < uvs.length; uvIdx++) geometry.setAttribute(`uv${uvIdx}`, new THREE.BufferAttribute(uvs[uvIdx], 2))
    geometry.setIndex(new THREE.BufferAttribute(indices, 1))

    return geometry
}

export class ThreeMesh extends ThreeObject<SceneNodes.Mesh> {
    protected override renderObject: THREE.Group = new THREE.Group()
    wasDisposed = false

    constructor(protected override threeSceneManagerService: ThreeSceneManagerService) {
        super(threeSceneManagerService)
        setThreeObjectPart(this.renderObject, this)
    }

    override setup(sceneNode: SceneNodes.Mesh) {
        const {sceneManagerService, materialManagerService} = this.threeSceneManagerService

        const setupDeferredMaterialMaterial = (
            mesh: THREE.Mesh<THREE.BufferGeometry, THREE.Material>,
            deferredMaterial: [Observable<THREE.Material>, IMaterialData],
            callback?: (material: THREE.Material) => void,
        ) => {
            const [material, materialData] = deferredMaterial
            sceneManagerService.addTask(
                `setupMaterial(${keyForMeshMaterialData(materialData, sceneNode.meshRenderSettings)})`,
                material.pipe(
                    tap((material) => {
                        if (this.wasDisposed) {
                            materialManagerService.releaseMaterial(material)
                            return
                        }

                        materialManagerService.releaseMaterial(mesh.material)
                        mesh.material = material

                        if (callback) callback(material)

                        this.threeSceneManagerService.exchangedShadowMapMeshTexture(mesh)

                        this.threeSceneManagerService.requestRedraw()
                    }),
                ),
            )
        }

        const getMaterial = (materialIndex: number): [THREE.Material, [Observable<THREE.Material>, IMaterialData] | null] => {
            const materialData = sceneNode.materialMap.get(materialIndex)
            if (materialData) {
                const [defaultMaterial, deferredMaterial] = materialManagerService.acquireMaterial(materialData, sceneNode.meshRenderSettings, materialIndex)
                if (!deferredMaterial) return [defaultMaterial, null]
                return [defaultMaterial, [deferredMaterial, materialData]]
            } else return [materialManagerService.getDefaultMaterial(undefined, materialIndex), null]
        }

        const notifyCachedMaterial = (materialIndex: number) => {
            const materialData = sceneNode.materialMap.get(materialIndex)
            if (materialData)
                sceneManagerService.addTask(`setupCachedMaterial(${keyForMeshMaterialData(materialData, sceneNode.meshRenderSettings)})`, of(null))
        }

        const key = (materialData: IMaterialData | null | undefined, meshRenderSettings: MeshRenderSettings) => {
            if (materialData === null) return null
            if (materialData === undefined) return undefined
            return keyForMeshMaterialData(materialData, meshRenderSettings)
        }

        let materialsAlreadyLoaded = false
        return anyDifference([
            objectFieldsDifferent(
                sceneNode.meshData,
                this.parameters?.meshData,
                ["uvs", "materialGroups", "vertices", "normals", "faceIDs"],
                undefined,
                ({uvs, materialGroups, vertices, normals, faceIDs}) => {
                    this.dispose(false)

                    if (uvs.length === 0) throw new Error("MeshData has no UVs")
                    const setupMesh = (bufferGeometry: THREE.BufferGeometry<THREE.NormalBufferAttributes>, materialIndex: number) => {
                        bufferGeometry.computeBoundsTree({strategy: SAH})

                        const [material, deferredMaterial] = getMaterial(materialIndex)

                        const mesh = new THREE.Mesh(bufferGeometry, material)
                        mesh.castShadow = true
                        mesh.receiveShadow = true
                        mesh.renderOrder = sceneNode.isDecal ? 1 : 0
                        setThreeObjectPart(mesh, this, `group${materialIndex}`)

                        this.threeSceneManagerService.addShadowMapMesh(mesh)
                        this.threeSceneManagerService.exchangedShadowMapMeshTexture(mesh)

                        if (deferredMaterial) {
                            if (this.threeSceneManagerService.$displayMode() === "configurator") {
                                mesh.visible = false
                                setupDeferredMaterialMaterial(mesh, deferredMaterial, () => {
                                    mesh.visible = true
                                    this.threeSceneManagerService.requestShadowMapUpdate()
                                })
                            } else setupDeferredMaterialMaterial(mesh, deferredMaterial)
                        } else notifyCachedMaterial(materialIndex)

                        return mesh
                    }

                    const meshBuffersByGroup = createMeshBuffersForMaterialGroups(vertices, normals, uvs, faceIDs, materialGroups)
                    for (const meshBuffer of meshBuffersByGroup) {
                        if (meshBuffer.indices.length === 0) continue
                        this.renderObject.add(setupMesh(createBufferGeometry(meshBuffer), meshBuffer.materialIndex))
                    }

                    materialsAlreadyLoaded = true
                },
            ),
            objectFieldsDifferent(
                sceneNode,
                this.parameters,
                ["transform"],
                (valueA, valueB) => mathIsEqual(valueA, valueB),
                ({transform}) => {
                    updateTransform(transform, this.renderObject)
                },
            ),
            mapDifferent(
                sceneNode.materialMap,
                this.parameters?.materialMap,
                (valueA, valueB) => {
                    return key(valueA, sceneNode.meshRenderSettings) === key(valueB, this.parameters?.meshRenderSettings ?? {})
                },
                (materialMap) => {
                    if (materialsAlreadyLoaded) return false
                    materialsAlreadyLoaded = true

                    const {materialManagerService} = this.threeSceneManagerService

                    let allMaterialsDeferred = true
                    for (const [materialIndex, materialData] of materialMap) {
                        const oldMaterialData = this.parameters?.materialMap.get(materialIndex)

                        if (key(materialData, sceneNode.meshRenderSettings) === key(oldMaterialData, this.parameters?.meshRenderSettings ?? {})) continue

                        const mesh = this.getSubMesh(materialIndex)

                        const [material, deferredMaterial] = getMaterial(materialIndex)

                        if (!deferredMaterial) {
                            materialManagerService.releaseMaterial(mesh.material)
                            mesh.material = material
                            this.threeSceneManagerService.exchangedShadowMapMeshTexture(mesh)
                            allMaterialsDeferred = false
                            notifyCachedMaterial(materialIndex)
                        } else {
                            materialManagerService.releaseMaterial(mesh.material)
                            setupDeferredMaterialMaterial(mesh, deferredMaterial)
                        }
                    }

                    return !allMaterialsDeferred
                },
            ),
            objectFieldsDifferent(sceneNode, this.parameters, ["castRealtimeShadows"], undefined, ({castRealtimeShadows}) => {
                this.renderObject.children.forEach((child) => {
                    if (child instanceof THREE.Mesh) {
                        const mesh = child as THREE.Mesh<THREE.BufferGeometry, THREE.Material>
                        mesh.castShadow = castRealtimeShadows
                    }
                })
                this.threeSceneManagerService.requestShadowMapUpdate()
            }),
            objectFieldsDifferent(sceneNode, this.parameters, ["receiveRealtimeShadows"], undefined, ({receiveRealtimeShadows}) => {
                this.renderObject.children.forEach((child) => {
                    if (child instanceof THREE.Mesh) {
                        const mesh = child as THREE.Mesh<THREE.BufferGeometry, THREE.Material>
                        mesh.receiveShadow = receiveRealtimeShadows
                        if (receiveRealtimeShadows) this.threeSceneManagerService.addShadowMapMesh(mesh)
                        else this.threeSceneManagerService.removeShadowMapMesh(mesh)
                    }
                })
            }),
            objectFieldsDifferent(sceneNode, this.parameters, ["isDecal"], undefined, ({isDecal}) => {
                this.renderObject.children.forEach((child) => {
                    if (child instanceof THREE.Mesh) {
                        const mesh = child as THREE.Mesh<THREE.BufferGeometry, THREE.Material>
                        mesh.renderOrder = isDecal ? 1 : 0
                    }
                })
            }),
        ])
    }

    getSubMesh(materialIndex: number) {
        for (const child of this.renderObject.children) {
            if (child instanceof THREE.Mesh) {
                const mesh = child as THREE.Mesh<THREE.BufferGeometry, THREE.Material>
                const threeObjectPart = getThreeObjectPart(mesh)

                if (threeObjectPart && threeObjectPart.part === `group${materialIndex}`) return mesh
            }
        }

        throw new Error(`SubMesh with materialIndex ${materialIndex} not found`)
    }

    override dispose(final: boolean) {
        const {materialManagerService} = this.threeSceneManagerService

        this.renderObject.children.forEach((child) => {
            if (child instanceof THREE.Mesh) {
                const mesh = child as THREE.Mesh<THREE.BufferGeometry, THREE.Material>
                mesh.geometry.disposeBoundsTree()
                mesh.geometry.dispose()
                materialManagerService.releaseMaterial(mesh.material)
                this.threeSceneManagerService.removeShadowMapMesh(mesh)
            }
        })
        this.renderObject.clear()

        if (final) this.wasDisposed = true
    }
}
