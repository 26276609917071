import {Component, inject, OnInit} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {FormsModule} from "@angular/forms"
import {MatButtonModule} from "@angular/material/button"
import {MatDialog} from "@angular/material/dialog"
import {MatIconModule} from "@angular/material/icon"
import {MatInputModule} from "@angular/material/input"
import {MatMenuModule} from "@angular/material/menu"
import {MatSelectModule} from "@angular/material/select"
import {MatTooltipModule} from "@angular/material/tooltip"
import {ContentTypeModel, MutationUpdateTemplateInput, TemplateDetailsFragment} from "@api"
import {ButtonComponent} from "@common/components/buttons/button/button.component"
import {CopyLegacyIdButtonComponent} from "@common/components/buttons/copy-legacy-id-button/copy-legacy-id-button.component"
import {CopyToClipboardButtonComponent} from "@common/components/buttons/copy-to-clipboard-button/copy-to-clipboard-button.component"
import {DropdownButtonComponent} from "@common/components/buttons/dropdown-button/dropdown-button.component"
import {DialogComponent} from "@common/components/dialogs/dialog/dialog.component"
import {RoutedDialogComponent} from "@common/components/dialogs/routed-dialog/routed-dialog.component"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NativeInputTextAreaComponent} from "@common/components/inputs/native/native-input-text-area/native-input-text-area.component"
import {BooleanLabelSelectComponent} from "@common/components/inputs/select/boolean-label-select/boolean-label-select.component"
import {OrganizationSelectComponent} from "@common/components/inputs/select/organization-select/organization-select.component"
import {TagSelectComponent} from "@common/components/inputs/select/tag-select/tag-select.component"
import {SectionComponent, SectionContentComponent} from "@common/components/item"
import {DialogSize} from "@common/models/dialogs"
import {Enums} from "@enums"
import {UtilsService} from "@legacy/helpers/utils"
import {BaseDetailsComponent} from "@platform/components/base/base-details/base-details.component"
import {CommentSectionComponent} from "@platform/components/details/comment-section/comment-section.component"
import {FilesSectionComponent} from "@platform/components/details/files-section/files-section.component"
import {GalleryImageComponent} from "@platform/components/details/gallery-image/gallery-image.component"
import {TitleSectionComponent} from "@platform/components/details/title-section/title-section.component"
import {CornersLayoutComponent} from "@platform/components/layouts/corners-layout/corners-layout.component"
import {DetailsDialogLayoutComponent} from "@platform/components/layouts/details-dialog-layout/details-dialog-layout.component"
import {MomentModule} from "ngx-moment"
import {getEditorType} from "../helpers/editor-type"
import {MatSnackBar} from "@angular/material/snack-bar"
import {TemplateThumbnailRenderingService} from "@app/common/services/template-thumbnail-rendering/template-thumbnail-rendering.service"
import {NotificationsService} from "@app/common/services/notifications/notifications.service"

@Component({
    selector: "cm-template-details",
    templateUrl: "./template-details.component.html",
    styleUrls: ["./template-details.component.scss"],
    standalone: true,
    imports: [
        MatMenuModule,
        ButtonComponent,
        MatTooltipModule,
        MomentModule,
        MatInputModule,
        MatSelectModule,
        FormsModule,
        DetailsDialogLayoutComponent,
        CopyToClipboardButtonComponent,
        TagSelectComponent,
        DropdownButtonComponent,
        MatIconModule,
        MatButtonModule,
        GalleryImageComponent,
        InputContainerComponent,
        NativeInputTextAreaComponent,
        SectionComponent,
        RoutedDialogComponent,
        FilesSectionComponent,
        TitleSectionComponent,
        OrganizationSelectComponent,
        CommentSectionComponent,
        BooleanLabelSelectComponent,
        SectionContentComponent,
        CornersLayoutComponent,
        CopyLegacyIdButtonComponent,
    ],
})
export class TemplateDetailsComponent extends BaseDetailsComponent<TemplateDetailsFragment, Omit<MutationUpdateTemplateInput, "id">> implements OnInit {
    override _contentTypeModel = ContentTypeModel.Template
    override _fetchItem = this.sdk.gql.templateDetails
    override _updateItem = this.sdk.gql.updateTemplateDetails
    override _deleteItem = this.sdk.raw.deleteTemplateDetails

    matDialog = inject(MatDialog)
    utils = inject(UtilsService)
    snackBar = inject(MatSnackBar)
    templateRenderingService = inject(TemplateThumbnailRenderingService)
    notificationsService = inject(NotificationsService)

    renderThumbnailButtonDisabled = false

    deleteConfirmationMessage() {
        const item = this.$item()
        return (
            (item ? `The Template '${item.name}'` : "This Template") +
            " will be deleted. This action <strong>cannot be undone</strong>.<br><br>Are you sure you want to continue?"
        )
    }

    addOrEditTemplateRevision() {
        return this.$item()?.latestRevision ? this.editTemplateRevision() : this.addTemplateRevision()
    }

    addTemplateRevision() {
        void this.router.navigate(["revisions", "add"], {relativeTo: this.route, queryParamsHandling: "preserve"})
    }

    editTemplateRevision() {
        const latestRevisionId = this.$item()?.latestRevision?.id
        const latestRevisionGraph = this.$item()?.latestRevision?.graph
        if (latestRevisionId) {
            this.router.navigate(["revisions", latestRevisionId, getEditorType(latestRevisionGraph)], {
                relativeTo: this.route,
                queryParamsHandling: "preserve",
            })
        }
    }

    viewTemplate() {
        this.router.navigate(["view"], {relativeTo: this.route, queryParamsHandling: "preserve"})
    }

    async renderThumbnail() {
        // TBD: better UX for progress handling?
        this.renderThumbnailButtonDisabled = true
        try {
            const templateFragment = this.$item()
            if (templateFragment) {
                this.notificationsService.showInfo("Template thumbnail: processing")
                await this.templateRenderingService.renderTemplateThumbnail(templateFragment)
                this.notificationsService.showInfo("Template thumbnail: job submitted")
            } else {
                throw new Error("Template thumbnail: missing template fragment")
            }
        } catch (error) {
            this.notificationsService.showError(error)
        } finally {
            this.renderThumbnailButtonDisabled = false
        }
    }

    public getEditButtonLabel() {
        return this.$item()?.latestRevision ? "Edit" : "+ Edit"
    }

    copyTemplate(template: TemplateDetailsFragment) {
        void this.notifications.withUserFeedback(
            async () => {
                const {createTemplate: newTemplate} = await this.sdk.gql.templateDetailsCreateTemplate({
                    input: {
                        name: `Copy of ${template.name}`,
                        organizationId: template.organization.id,
                        templateType: template.type,
                        comment: template.comment,
                        public: template.public,
                        state: Enums.TemplateState.Draft,
                    },
                })
                await this.sdk.gql.templateDetailsCreateTemplateRevision({
                    input: {
                        templateId: newTemplate.id,
                        graph: template.latestRevision?.graph,
                        completed: false,
                    },
                })
                void this.router.navigate(["..", newTemplate.id], {
                    relativeTo: this.route,
                    queryParamsHandling: "preserve",
                })
            },
            {
                error: "Cannot create new template.",
            },
        )
    }

    deleteTemplateRevision(templateRevision: {id: string}) {
        const dialogRef = this.matDialog.open(DialogComponent, {
            disableClose: false,
            width: "400px",
            data: {
                title: "Discard revision",
                message:
                    "The last template revision including all the geometry and material configurations will be deleted. " +
                    "This action <strong>cannot be undone</strong>.<br><br>Are you sure you want to continue?",
                confirmLabel: "Discard revision",
                cancelLabel: "Cancel",
                isDestructive: true,
            },
        })
        dialogRef
            .afterClosed()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: async (confirmed) => {
                    if (!confirmed) {
                        return
                    }
                    await this.sdk.throwable.templateDetailsDeleteTemplateRevision({
                        id: templateRevision.id,
                    })
                    this.refresh.item(this.$item())
                    this.notifications.showInfo("Revision discarded.")
                },
                error: () => this.notifications.showError("Cannot discard revision."),
            })
    }

    protected readonly DialogSize = DialogSize
}
