import {KeyValuePipe} from "@angular/common"
import {Component, DestroyRef, inject, Input} from "@angular/core"
import {MatMenuModule} from "@angular/material/menu"
import {MatTooltipModule} from "@angular/material/tooltip"
import {OperatorListItemComponent} from "@app/textures/texture-editor/operators-panel/operator-list-item/operator-list-item.component"
import {ListItemComponent} from "@common/components/item"
import {Operator} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator"
import * as TextureEditNodes from "app/textures/texture-editor/texture-edit-nodes"
import {byTextureEditNodeType, TextureEditNodeDesc} from "app/textures/texture-editor/operators-panel/operator-desc"
import {OperatorStack} from "app/textures/texture-editor/operator-stack/operator-stack"
import {DialogComponent} from "@app/common/components/dialogs/dialog/dialog.component"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {MatDialog} from "@angular/material/dialog"
import {AutoTilingState, OperatorAutoTiling} from "app/textures/texture-editor/operator-stack/operators/auto-tiling/operator-auto-tiling"
import {PermissionsService} from "@common/services/permissions/permissions.service"

@Component({
    selector: "cm-operators-panel",
    templateUrl: "./operators-panel.component.html",
    styleUrls: ["./operators-panel.component.scss"],
    standalone: true,
    imports: [OperatorListItemComponent, MatTooltipModule, ListItemComponent, MatMenuModule, KeyValuePipe],
})
export class OperatorsPanelComponent {
    @Input() disabled = false
    @Input() operatorStack!: OperatorStack

    protected permission = inject(PermissionsService)
    $can = this.permission.$to

    constructor(
        private destroyRef: DestroyRef,
        private dialog: MatDialog,
    ) {}

    ngOnInit() {
        this.operatorStack.selectedOperatorChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.onSelectedOperatorChanged())
    }

    protected get operators(): readonly Operator[] {
        return this.operatorStack.operators
    }

    protected get selectedOperator(): Operator | null {
        return this.operatorStack.selectedOperator
    }

    protected get operatorDescByType(): Record<TextureEditNodes.OperatorType, TextureEditNodeDesc> {
        return byTextureEditNodeType
    }

    protected get canAddAutoTiling(): boolean {
        return !this.disabled && !this.operatorStack.hasAppliedOperators() // we can only add an auto-tiling operator if there are no other operators yet
    }

    protected get canAddOperator(): boolean {
        const autoTilingOperator = this.operators.find((operator) => operator instanceof OperatorAutoTiling) as OperatorAutoTiling | undefined
        return (
            !this.disabled &&
            this.operatorStack.hasAppliedOperators() &&
            (!autoTilingOperator || autoTilingOperator.autoTilingState === AutoTilingState.Complete)
        ) // we can only add non-auto-tiling operators if there is a auto-tiling operator and it has completed (or any other for backwards compatibility)
    }

    protected isOperatorTypeAllowed(operatorType: TextureEditNodes.OperatorType): boolean {
        // for now we only show the new tiling operator when in debug mode (as it is unfinished)
        if (operatorType === "operator-tiling") {
            return this.$can().create.tilingOperator()
        }
        return operatorType !== "operator-auto-tiling" // from the operator menu we omit the auto-tiling operator (it is handled as a special case)
    }

    protected async addOperator(operatorType: TextureEditNodes.OperatorType) {
        this.operatorStack.muteUpdates = true // creation and selection causes reevaluation of the operator stack, so we mute updates to avoid it
        const operator = await this.operatorStack.createOperator(operatorType)
        operator.markEdited()
        this.selectOperator(operator)
        this.operatorStack.muteUpdates = false
    }

    protected selectOperator(operator: Operator): void {
        this.operatorStack.selectedOperator = operator
    }

    protected lockOperator(operator: Operator): void {
        this.operatorStack.setOperatorLocked(operator, !operator.locked)
    }

    protected enableOperator(operator: Operator): void {
        this.operatorStack.setOperatorEnabled(operator, !operator.enabled)
    }

    protected async duplicateOperator(operator: Operator) {
        const newOperator = await this.operatorStack.duplicateOperator(operator)
        this.selectOperator(newOperator)
    }

    protected deleteOperator(operator: Operator) {
        const dialogRef = this.dialog.open(DialogComponent, {
            disableClose: false,
            width: "400px",
            data: {
                title: "Remove operator",
                message:
                    "The currently selected operator will be removed. " +
                    "This action <strong>cannot be undone</strong>.<br><br>Are you sure you want to continue?",
                confirmLabel: "Remove operator",
                cancelLabel: "Cancel",
            },
        })
        dialogRef
            .afterClosed()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((confirmed) => {
                if (confirmed) {
                    this.operatorStack.removeOperator(operator)
                }
            })
    }

    protected selectedOperatorIndex = 0

    private onSelectedOperatorChanged() {
        this.selectedOperatorIndex = this.selectedOperator ? this.operators.indexOf(this.selectedOperator) : -1
    }
}
