import {OperatorBase} from "@app/textures/texture-editor/operator-stack/operators/abstract-base/operator-base"
import * as TextureEditNodes from "@app/textures/texture-editor/texture-edit-nodes"
import {
    Operator,
    OperatorFlags,
    OperatorInput,
    OperatorOutput,
    OperatorPanelComponentType,
    OperatorParameterValue,
} from "@app/textures/texture-editor/operator-stack/operators/abstract-base/operator"
import {OperatorCallback} from "@app/textures/texture-editor/operator-stack/operators/abstract-base/operator-callback"
import {deepCopy} from "@cm/lib/utils/utils"
import {ImageOpNodeGraphEvaluator} from "@app/textures/texture-editor/operator-stack/image-op-system/image-op-node-graph-evaluator"
import {TextureType} from "@api"
import {TilingPanelComponent} from "@app/textures/texture-editor/operator-stack/operators/tiling/panel/tiling-panel.component"
import {TilingToolbox} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-toolbox"
import {Hotkeys} from "@common/services/hotkeys/hotkeys.service"
import {BehaviorSubject, filter, merge, Observable, Subject, takeUntil} from "rxjs"
import {Vector2, Vector2Like} from "@cm/lib/math/vector2"

import {ImagePtr} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ref"
import {DebugImage} from "@app/textures/texture-editor/operator-stack/image-op-system/util/debug-image"
import {blend} from "@app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/blend-node"
import {copyRegion} from "@app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/copy-region-node"
import {lambda} from "@app/textures/texture-editor/operator-stack/image-op-system/nodes/basic-nodes/lambda-node"
import {ParameterValue} from "@cm/lib/graph-system/node-graph"
import {Context} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/context"
import {
    BoundaryDirection,
    BoundarySide,
    ControlPointType,
    ViewMode,
} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area-toolbox-item"
import {crossCorrelate} from "@app/textures/texture-editor/operator-stack/operators/tiling/helpers/cross-correlation"
import {Color} from "@cm/lib/math/color"
import {TextureEditorSettings} from "@app/textures/texture-editor/texture-editor-settings"
import {gridMapping, GridPoint} from "@app/textures/texture-editor/operator-stack/image-op-system/nodes/high-level-nodes/grid-mapping-node"
import {colorGradient} from "@app/textures/texture-editor/operator-stack/image-op-system/nodes/high-level-nodes/color-gradient-node"

export class OperatorTiling extends OperatorBase<TextureEditNodes.OperatorTiling> {
    // OperatorBase
    override readonly flags = new Set<OperatorFlags>(["no-clone", "no-disable", "apply-to-all-texture-types"])

    readonly panelComponentType: OperatorPanelComponentType = TilingPanelComponent
    readonly canvasToolbox: TilingToolbox

    readonly type = "operator-tiling" as const

    readonly showGuides$: BehaviorSubject<boolean>
    readonly viewMode$: BehaviorSubject<ViewMode>
    readonly snapEnabled$: BehaviorSubject<boolean>
    readonly snapDistancePx$: BehaviorSubject<number>
    readonly alignmentSpacingPx$: BehaviorSubject<number>
    readonly alignmentSearchSizeRatio$: BehaviorSubject<number>
    readonly alignmentMinCorrelation$: BehaviorSubject<number>
    readonly borderBlendEnabled$: BehaviorSubject<boolean>
    readonly borderBlendDistancePx$: BehaviorSubject<number>
    readonly debugDrawEnabled$ = new BehaviorSubject(false)
    readonly rasterTestMode$ = new BehaviorSubject(RasterTestMode.None)

    constructor(callback: OperatorCallback, node: TextureEditNodes.OperatorTiling | null) {
        super(
            callback,
            deepCopy(node) ?? {
                type: "operator-tiling",
                enabled: true,
                cornerControlPoints: {
                    topLeft: {
                        positionPx: {
                            x: Math.round((callback.textureEditorData.textureTypeSpecific?.sourceDataObject.width ?? 0) * 0.1),
                            y: Math.round((callback.textureEditorData.textureTypeSpecific?.sourceDataObject.height ?? 0) * 0.1),
                        },
                    },
                    topRight: {
                        positionPx: {
                            x: Math.round((callback.textureEditorData.textureTypeSpecific?.sourceDataObject.width ?? 0) * 0.9),
                            y: Math.round((callback.textureEditorData.textureTypeSpecific?.sourceDataObject.height ?? 0) * 0.1),
                        },
                    },
                    bottomLeft: {
                        positionPx: {
                            x: Math.round((callback.textureEditorData.textureTypeSpecific?.sourceDataObject.width ?? 0) * 0.1),
                            y: Math.round((callback.textureEditorData.textureTypeSpecific?.sourceDataObject.height ?? 0) * 0.9),
                        },
                    },
                    bottomRight: {
                        positionPx: {
                            x: Math.round((callback.textureEditorData.textureTypeSpecific?.sourceDataObject.width ?? 0) * 0.9),
                            y: Math.round((callback.textureEditorData.textureTypeSpecific?.sourceDataObject.height ?? 0) * 0.9),
                        },
                    },
                },
                boundaries: {
                    horizontal: {
                        controlPoints: [],
                    },
                    vertical: {
                        controlPoints: [],
                    },
                },
                display: {
                    showGuides: true,
                    viewMode: "source",
                },
                snapping: {
                    enabled: true,
                    snapDistancePx: 32,
                },
                alignment: {
                    controlPointSpacingPx: 64,
                    searchSizeRatio: 0.25,
                    minCorrelation: 0.25,
                },
                borderBlending: {
                    enabled: true,
                    widthPx: 10,
                },
            },
        )

        // bidirectional binding from node to UI
        const biBind = <T>(initialValue: T, setter: (value: T) => void, markEdited = true) => {
            const obs = new BehaviorSubject(initialValue)
            let isInitialValue = true
            obs.pipe(takeUntil(this.destroyed)).subscribe((value) => {
                setter(value)
                if (!isInitialValue && markEdited) {
                    this.markEdited()
                }
                isInitialValue = false
            })
            return obs
        }
        this.showGuides$ = biBind(this.node.display.showGuides, (value) => (this.node.display.showGuides = value), false)
        this.viewMode$ = biBind(this.node.display.viewMode as ViewMode, (value) => (this.node.display.viewMode = value), false)
        this.snapEnabled$ = biBind(this.node.snapping.enabled, (value) => (this.node.snapping.enabled = value))
        this.snapDistancePx$ = biBind(this.node.snapping.snapDistancePx, (value) => (this.node.snapping.snapDistancePx = value))
        this.alignmentSpacingPx$ = biBind(this.node.alignment.controlPointSpacingPx, (value) => (this.node.alignment.controlPointSpacingPx = value))
        this.alignmentSearchSizeRatio$ = biBind(this.node.alignment.searchSizeRatio, (value) => (this.node.alignment.searchSizeRatio = value))
        this.alignmentMinCorrelation$ = biBind(this.node.alignment.minCorrelation, (value) => (this.node.alignment.minCorrelation = value))
        this.borderBlendEnabled$ = biBind(this.node.borderBlending.enabled, (value) => (this.node.borderBlending.enabled = value))
        this.borderBlendDistancePx$ = biBind(this.node.borderBlending.widthPx, (value) => (this.node.borderBlending.widthPx = value))

        this.canvasToolbox = new TilingToolbox(this)
        this.debugImage = new DebugImage(this.callback.imageOpContextWebGL2)

        const applyHotkeyPipe = <T>(obs: Observable<T>) =>
            obs.pipe(
                takeUntil(this.destroyed),
                filter(() => this.callback.selectedOperator === this),
            )
        const hotkeys = this.callback.injector.get(Hotkeys)
        applyHotkeyPipe(hotkeys.addShortcut(["k"])).subscribe(() => this.viewMode$.next(ViewMode.Source))
        applyHotkeyPipe(hotkeys.addShortcut(["l"])).subscribe(() => this.viewMode$.next(ViewMode.Result))
        applyHotkeyPipe(hotkeys.addShortcut(["s"])).subscribe(() => this.snapEnabled$.next(!this.snapEnabled$.value))
        applyHotkeyPipe(hotkeys.addShortcut(["v"])).subscribe(() => this.showGuides$.next(!this.showGuides$.value))

        this.showGuides$.subscribe((value) => (this.canvasToolbox.tilingArea.visible = value))
        this.canvasToolbox.tilingArea.changed$.subscribe(() => this.onTilingAreaChanged())
        merge(this.viewMode$, this.debugDrawEnabled$, this.rasterTestMode$, this.borderBlendEnabled$, this.borderBlendDistancePx$).subscribe(() =>
            this.requestEval(),
        )
    }

    // OperatorBase
    override dispose(): void {
        this.destroyed.next()
        this.destroyed.complete()
        super.dispose()
        this.canvasToolbox.remove()
    }

    // OperatorBase
    async clone(): Promise<Operator> {
        return new OperatorTiling(this.callback, deepCopy(this.node))
    }

    // OperatorBase
    async getImageOpNodeGraph(evaluator: ImageOpNodeGraphEvaluator, textureType: TextureType, input: OperatorInput): Promise<OperatorOutput> {
        let resultImage: ParameterValue<ImagePtr, Context>
        if (evaluator.mode === "preview" && this.selected && this.viewMode$.value === ViewMode.Source) {
            resultImage = lambda({sourceImage: input}, async ({parameters: {sourceImage}}) => new ImagePtr(sourceImage))
        } else {
            // mapping
            const numGridPointSubdivisions = this.canvasToolbox.tilingArea.getNumGridPointSubdivisions()
            if (!this.tessellatedGridPoints) {
                this.tessellatedGridPoints = this.canvasToolbox.tilingArea.computeGridPoints(
                    {numSteps: numGridPointSubdivisions.x, tMin: 0, tMax: 1},
                    {numSteps: numGridPointSubdivisions.y, tMin: 0, tMax: 1},
                )
            }
            resultImage = gridMapping({
                sourceImage: input,
                gridPoints: this.tessellatedGridPoints,
            })

            // test software rasterizer
            // const tessellatedGridPoints = this.tessellatedGridPoints
            // resultImage = lambda({textureImage: input, resultImage}, async ({context, parameters: {textureImage, resultImage}}) => {
            //     await this.testSoftwareRasterizer(context, resultImage, textureImage, tessellatedGridPoints)
            //     return new ImagePtr(resultImage)
            // })

            // border blending
            const blendDistanceInPixels = Math.round(this.borderBlendDistancePx$.value)
            if (this.borderBlendEnabled$.value && blendDistanceInPixels > 0) {
                // extract borders
                const extractBorder = (sourceImage: OperatorParameterValue<ImagePtr>, direction: BoundaryDirection, side: BoundarySide) => {
                    // compute border grid points
                    const tValues = this.canvasToolbox.tilingArea.computeBoundaryTValues(direction, side, blendDistanceInPixels)
                    const borderGridPoints = this.canvasToolbox.tilingArea.computeGridPoints(
                        {numSteps: direction === BoundaryDirection.Horizontal ? numGridPointSubdivisions.x : 2, tMin: tValues.tMinU, tMax: tValues.tMaxU},
                        {numSteps: direction === BoundaryDirection.Horizontal ? 2 : numGridPointSubdivisions.y, tMin: tValues.tMinV, tMax: tValues.tMaxV},
                    )
                    // shift border grid points to origin
                    const offset = new Vector2(Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY)
                    borderGridPoints.forEach((row) =>
                        row.forEach((point) => offset.set(Math.min(offset.x, point.targetPixel.x), Math.min(offset.y, point.targetPixel.y))),
                    )
                    borderGridPoints.forEach((row) =>
                        row.forEach((point) => (point.targetPixel = Vector2.fromVector2Like(point.targetPixel).subInPlace(offset))),
                    )
                    // map border
                    return gridMapping({
                        sourceImage,
                        gridPoints: borderGridPoints,
                    })
                }
                const topBorder = extractBorder(input, BoundaryDirection.Horizontal, BoundarySide.Low)
                const bottomBorder = extractBorder(input, BoundaryDirection.Horizontal, BoundarySide.High)
                const leftBorder = extractBorder(input, BoundaryDirection.Vertical, BoundarySide.Low)
                const rightBorder = extractBorder(input, BoundaryDirection.Vertical, BoundarySide.High)
                // blend by gradient
                const blendBorder = (
                    resultImage: OperatorParameterValue<ImagePtr>,
                    borderImage: OperatorParameterValue<ImagePtr>,
                    direction: BoundaryDirection,
                    side: BoundarySide,
                ) => {
                    // compute blending gradient
                    const gradientImage = colorGradient({
                        resultImageOrDescriptor: lambda({sourceImage: borderImage}, async ({context, parameters: {sourceImage}}) => {
                            const descriptor = await context.getImageDescriptor(sourceImage)
                            return {
                                width: descriptor.width,
                                height: descriptor.height,
                                channelLayout: "R",
                                format: TextureEditorSettings.PreviewProcessingImageFormat,
                                isSRGB: false,
                            }
                        }),
                        type: "linear",
                        startPos: new Vector2(0, 0),
                        endPos: lambda({sourceImage: borderImage}, async ({context, parameters: {sourceImage}}) => {
                            const descriptor = await context.getImageDescriptor(sourceImage)
                            if (direction === BoundaryDirection.Vertical) {
                                return {x: descriptor.width, y: 0}
                            } else {
                                return {x: 0, y: descriptor.height}
                            }
                        }),
                        stops: [
                            {t: 0, color: side === BoundarySide.High ? new Color(0) : new Color(0.5)},
                            {t: 1, color: side === BoundarySide.High ? new Color(0.5) : new Color(0)},
                        ],
                    })
                    // compute target offset
                    const targetRegion = lambda({resultImage}, async ({context, parameters: {resultImage}}) => {
                        const descriptor = await context.getImageDescriptor(resultImage)
                        if (side === BoundarySide.High) {
                            if (direction === BoundaryDirection.Vertical) {
                                return {x: descriptor.width - blendDistanceInPixels, y: 0, width: blendDistanceInPixels, height: descriptor.height}
                            } else {
                                return {x: 0, y: descriptor.height - blendDistanceInPixels, width: descriptor.width, height: blendDistanceInPixels}
                            }
                        } else {
                            if (direction === BoundaryDirection.Vertical) {
                                return {x: 0, y: 0, width: blendDistanceInPixels, height: descriptor.height}
                            } else {
                                return {x: 0, y: 0, width: descriptor.width, height: blendDistanceInPixels}
                            }
                        }
                    })
                    // cut out target region from result
                    const cutOutResult = copyRegion({
                        sourceImage: resultImage,
                        sourceRegion: targetRegion,
                    })
                    // blend border
                    const blendedCutOutResult = blend({
                        backgroundImage: cutOutResult,
                        foregroundImage: borderImage,
                        alpha: gradientImage,
                        premultipliedAlpha: false,
                        blendMode: "normal",
                    })
                    // copy back to result
                    resultImage = copyRegion({
                        sourceImage: blendedCutOutResult,
                        targetOffset: targetRegion,
                        resultImage,
                    })
                    return resultImage
                }
                resultImage = blendBorder(resultImage, bottomBorder, BoundaryDirection.Horizontal, BoundarySide.Low)
                resultImage = blendBorder(resultImage, topBorder, BoundaryDirection.Horizontal, BoundarySide.High)
                resultImage = blendBorder(resultImage, rightBorder, BoundaryDirection.Vertical, BoundarySide.Low)
                resultImage = blendBorder(resultImage, leftBorder, BoundaryDirection.Vertical, BoundarySide.High)
            }
        }
        if (this.debugImage && this.debugDrawEnabled$.value) {
            const blendDebugImage = false
            if (blendDebugImage) {
                // alpha blend the debug image on top
                const cutOut = copyRegion({
                    sourceImage: resultImage,
                    sourceRegion: lambda({debugImage: this.debugImage.imageRef}, async ({context, parameters: {debugImage}}) => {
                        const descriptor = await context.getImageDescriptor(debugImage)
                        return {x: 0, y: 0, width: descriptor.width, height: descriptor.height}
                    }),
                })
                const blendedCutOut = blend({
                    backgroundImage: cutOut,
                    foregroundImage: this.debugImage.imageRef,
                    premultipliedAlpha: false,
                    blendMode: "normal",
                })
                return {
                    resultImage: copyRegion({
                        sourceImage: blendedCutOut,
                        resultImage,
                    }),
                }
            } else {
                const sourceCopy = copyRegion({
                    sourceImage: resultImage,
                })
                const debugCopy = copyRegion({
                    sourceImage: this.debugImage.imageRef,
                    resultImage: sourceCopy,
                })
                return {resultImage: debugCopy}
            }
        } else {
            return {resultImage}
        }
    }

    async executeGridAlignment() {
        this.callback.setBusy(true)

        const tilingArea = this.canvasToolbox.tilingArea

        const adjustBoundary = async (boundaryDirection: BoundaryDirection) => {
            const snapDistance = (this.alignmentSpacingPx$.value / 2) * this.alignmentSearchSizeRatio$.value
            const correlationWindowSize = 64
            const searchSize = Math.ceil(snapDistance * 2)
            const controlPointsLow = tilingArea.getBoundaryPoints(boundaryDirection, BoundarySide.Low, false)
            const controlPointsHigh = tilingArea.getBoundaryPoints(boundaryDirection, BoundarySide.High, false)
            for (let i = 1; i < controlPointsLow.length; i++) {
                const posLowPrev = controlPointsLow[i - 1]
                const posLowNext = controlPointsLow[i]
                const posHighPrev = controlPointsHigh[i - 1]
                const posHighNext = controlPointsHigh[i]
                const tPrev = (posLowPrev.t + posHighPrev.t) / 2
                const tNext = (posLowNext.t + posHighNext.t) / 2
                const sourcePositionLowDelta = posLowNext.sourcePosition.sub(posLowPrev.sourcePosition)
                const sourcePositionHighDelta = posHighNext.sourcePosition.sub(posHighPrev.sourcePosition)
                const sourcePositionAvgLength = (sourcePositionLowDelta.norm() + sourcePositionHighDelta.norm()) / 2
                const numPointsToInsert = Math.floor(sourcePositionAvgLength / this.alignmentSpacingPx$.value)
                for (let j = 1; j <= numPointsToInsert; j++) {
                    const interpolator = j / (numPointsToInsert + 1)
                    const t = tPrev * (1 - interpolator) + tNext * interpolator
                    const posMin = posLowPrev.sourcePosition.add(sourcePositionLowDelta.mul(interpolator))
                    const posMax = posHighPrev.sourcePosition.add(sourcePositionHighDelta.mul(interpolator))
                    const correlationResult = await this.correlatePoints(posMax, posMin, correlationWindowSize, searchSize)
                    if (correlationResult && correlationResult.correlation >= this.alignmentMinCorrelation$.value) {
                        tilingArea.insertBoundaryPoints(boundaryDirection, ControlPointType.Alignment, t, posMin, correlationResult.position)
                    }
                }
            }
        }

        this.removeGridAlignment()
        await Promise.all([adjustBoundary(BoundaryDirection.Horizontal), adjustBoundary(BoundaryDirection.Vertical)])

        this.callback.setBusy(false)
    }

    removeGridAlignment() {
        this.canvasToolbox.tilingArea.removeControlPointsOfType(ControlPointType.Alignment)
    }

    private onTilingAreaChanged() {
        this.copyControlPointsToNode()
        this.tessellatedGridPoints = undefined
        this.requestEval()
    }

    private copyControlPointsToNode() {
        const tilingArea = this.canvasToolbox.tilingArea
        const node = this.node
        const isCornerControlPoint = (index: number, array: Array<unknown>) => index === 0 || index === array.length - 1
        // horizontal sub-division control points
        const horizontalLowBoundControlPoints = tilingArea.getBoundaryPoints(BoundaryDirection.Horizontal, BoundarySide.Low, false)
        const horizontalHighBoundControlPoints = tilingArea.getBoundaryPoints(BoundaryDirection.Horizontal, BoundarySide.High, false)
        node.boundaries.horizontal.controlPoints = horizontalLowBoundControlPoints
            .map((point, index) => ({
                type: point.type,
                loBound: {positionPx: point.sourcePosition, normalizedCurvePosition: point.t},
                hiBound: {
                    positionPx: horizontalHighBoundControlPoints[index].sourcePosition,
                    normalizedCurvePosition: horizontalHighBoundControlPoints[index].t,
                },
            }))
            .filter((_, index, array) => !isCornerControlPoint(index, array))
        // vertical sub-division control points
        const verticalLowBoundControlPoints = tilingArea.getBoundaryPoints(BoundaryDirection.Vertical, BoundarySide.Low, false)
        const verticalHighBoundControlPoints = tilingArea.getBoundaryPoints(BoundaryDirection.Vertical, BoundarySide.High, false)
        node.boundaries.vertical.controlPoints = verticalLowBoundControlPoints
            .map((point, index) => ({
                type: point.type,
                loBound: {positionPx: point.sourcePosition, normalizedCurvePosition: point.t},
                hiBound: {
                    positionPx: verticalHighBoundControlPoints[index].sourcePosition,
                    normalizedCurvePosition: verticalHighBoundControlPoints[index].t,
                },
            }))
            .filter((_, index, array) => !isCornerControlPoint(index, array))
        // corner control points
        node.cornerControlPoints.topLeft.positionPx = horizontalLowBoundControlPoints[0].sourcePosition
        node.cornerControlPoints.topRight.positionPx = horizontalLowBoundControlPoints[horizontalLowBoundControlPoints.length - 1].sourcePosition
        node.cornerControlPoints.bottomLeft.positionPx = horizontalHighBoundControlPoints[0].sourcePosition
        node.cornerControlPoints.bottomRight.positionPx = horizontalHighBoundControlPoints[horizontalHighBoundControlPoints.length - 1].sourcePosition
        // signal change
        this.markEdited()
    }

    private async correlatePoints(
        position: Vector2Like,
        referencePosition: Vector2Like,
        correlationWindowSize: number,
        searchSize: number,
        debugImage?: DebugImage,
    ) {
        const imageOpContextWebGL2 = this.callback.imageOpContextWebGL2
        using sourceImage = new ImagePtr(this.callback.selectedOperatorInput)
        if (!sourceImage) {
            return undefined
        }
        const sourceRegion = {
            x: Math.round(position.x - (correlationWindowSize + searchSize) / 2),
            y: Math.round(position.y - (correlationWindowSize + searchSize) / 2),
            width: correlationWindowSize + searchSize - 1,
            height: correlationWindowSize + searchSize - 1,
        }
        const templateRegion = {
            x: Math.round(referencePosition.x - correlationWindowSize / 2),
            y: Math.round(referencePosition.y - correlationWindowSize / 2),
            width: correlationWindowSize,
            height: correlationWindowSize,
        }
        const {peakOffset, peakValue} = await crossCorrelate(imageOpContextWebGL2, sourceImage, sourceRegion, sourceImage, templateRegion, debugImage)
        peakOffset.addInPlace(sourceRegion)
        peakOffset.addInPlace({x: correlationWindowSize / 2, y: correlationWindowSize / 2})
        return {position: peakOffset, correlation: peakValue}
    }

    async computeSnapPosition(position: Vector2Like, referencePosition: Vector2Like): Promise<Vector2 | undefined> {
        const debugImage = this.debugDrawEnabled$.value ? this.debugImage : undefined
        await debugImage?.init({width: 6000, height: 8192})
        if (!this.snapEnabled$.value) {
            return undefined
        }
        const snapDistance = Math.round(this.snapDistancePx$.value)
        if (snapDistance <= 0) {
            return undefined
        }
        const correlationWindowSize = 64
        const searchSize = Math.ceil(snapDistance * 2)
        const correlationResult = await this.correlatePoints(position, referencePosition, correlationWindowSize, searchSize, debugImage)
        if (debugImage) {
            this.requestEval()
        }
        return correlationResult?.position
    }

    // async computeSnapPosition_(position: Vector2Like, referencePosition: Vector2Like): Promise<Vector2 | undefined> {
    //     this.canvasToolbox.clearDebugRects()
    //     const debugImage = this.debugDrawEnabled$.value ? this.debugImage : undefined
    //
    //     if (!this.snapEnabled$.value) {
    //         return undefined
    //     }
    //     const snapDistance = Math.round(this.snapDistanceInPixels$.value)
    //     if (snapDistance <= 0) {
    //         return undefined
    //     }
    //     const correlationWindowSize = 8 // must be even
    //     const searchSize = 3
    //
    //     const imageOpContextWebGL2 = this.callback.imageOpContextWebGL2
    //     const sourceImageRef = this.callback.selectedOperatorInput
    //     if (!sourceImageRef) {
    //         return undefined
    //     }
    //
    //     await debugImage?.init({width: 512, height: 4096})
    //
    //     const numLevels = Math.ceil(Math.log2(snapDistance))
    //     const maxTemplateSize = 2 ** (numLevels - 1) * correlationWindowSize
    //     const maxSourceImageSize = 2 ** (numLevels - 1) * (correlationWindowSize + searchSize - 1)
    //
    //     // compute regions
    //     const templateRegion = {
    //         x: Math.round(referencePosition.x - maxTemplateSize / 2),
    //         y: Math.round(referencePosition.y - maxTemplateSize / 2),
    //         width: maxTemplateSize,
    //         height: maxTemplateSize,
    //     }
    //     if (this.debugDrawEnabled$.value) {
    //         this.canvasToolbox.createDebugRect(templateRegion, "green")
    //     }
    //     const sourceRegion = {
    //         x: Math.round(position.x - maxSourceImageSize / 2),
    //         y: Math.round(position.y - maxSourceImageSize / 2),
    //         width: maxSourceImageSize,
    //         height: maxSourceImageSize,
    //     }
    //     if (this.debugDrawEnabled$.value) {
    //         this.canvasToolbox.createDebugRect(sourceRegion, "blue")
    //     }
    //
    //     // compute falloff image, if needed
    //     // const applyFalloff = false
    //     // if (applyFalloff) {
    //     //     if (!this.falloffImage) {
    //     //         this.falloffImage = await imageOpCreateImage.WebGL2({
    //     //             context: imageOpContextWebGL2,
    //     //             parameters: {
    //     //                 descriptor: {
    //     //                     width: maxSourceImageSize,
    //     //                     height: maxSourceImageSize,
    //     //                     channelLayout: "R",
    //     //                     format: TextureEditorSettings.PreviewProcessingImageFormat,
    //     //                     isSRGB: false,
    //     //                 },
    //     //                 fillColor: {r: 0, g: 0, b: 0, a: 1},
    //     //             },
    //     //         })
    //     //         const halGenerateFalloffImage = await imageOpContextWebGL2.getOrCreateImageCompositor(`
    //     //         uniform float u_falloff;
    //     //
    //     //         vec4 computeColor(ivec2 targetPixel) {
    //     //             float normDistance = max(0.0, 1.0 - length(vec2(targetPixel) / vec2(u_targetSize) - 0.5) * 2.0);
    //     //             float value = pow(normDistance, u_falloff * 2.0);
    //     //             return vec4(value);
    //     //         }
    //     //     `)
    //     //         halGenerateFalloffImage.setParameter("u_falloff", {type: "float", value: this.snapFalloff$.value})
    //     //         const halGenerateFalloffImageWebGL2 = await imageOpContextWebGL2.getImage(this.falloffImage)
    //     //         await halGenerateFalloffImage.paint(halGenerateFalloffImageWebGL2.ref.halImage)
    //     //         halGenerateFalloffImageWebGL2.release()
    //     //     }
    //     //     await debugImage?.addImage(this.falloffImage)
    //     // }
    //
    //     const snappedPosition = hierarchicalCrossCorrelation(
    //         imageOpContextWebGL2,
    //         sourceImageRef,
    //         sourceRegion,
    //         sourceImageRef,
    //         templateRegion,
    //         correlationWindowSize,
    //         searchSize,
    //         this.debugDrawEnabled$.value ? debugImage : undefined,
    //         this.debugDrawEnabled$.value ? (rect, color) => this.canvasToolbox.createDebugRect(rect, color) : undefined,
    //     )
    //
    //     if (this.debugDrawEnabled$.value) {
    //         this.requestEval()
    //     }
    //
    //     return snappedPosition
    // }
    //
    // private async testSoftwareRasterizer(context: Context, resultImage: ImagePtr, sourceImage: ImagePtr, gridPoints: GridPoint[][]) {
    //     if (!(context instanceof ImageOpContextWebGL2)) {
    //         throw new Error("Software rasterizer test is only supported in WebGL2 context")
    //     }
    //     if (this.rasterTestMode$.value === RasterTestMode.None) {
    //         return
    //     }
    //     // compute software rasterized image
    //     using sourceImageWebGL2 = await context.getImage(sourceImage)
    //     const sourceImageWebGL2Data = await sourceImageWebGL2.ref.halImage.readRawImageData("float32")
    //     const textureImage: TypedImageData<Float32Array> = {
    //         width: sourceImageWebGL2.ref.halImage.descriptor.width,
    //         height: sourceImageWebGL2.ref.halImage.descriptor.height,
    //         colorSpace: "linear",
    //         channelLayout: getImgProcChannelLayout(sourceImageWebGL2.ref.halImage.descriptor.channelLayout),
    //         dataType: "float32",
    //         data: sourceImageWebGL2Data,
    //     }
    //     using resultImageWebGL2 = await context.getImage(resultImage)
    //     const framebufferImage = createImageData(
    //         resultImageWebGL2.ref.halImage.descriptor.width,
    //         resultImageWebGL2.ref.halImage.descriptor.height,
    //         getImgProcChannelLayout(resultImageWebGL2.ref.halImage.descriptor.channelLayout),
    //         "float32",
    //         "linear",
    //     ) as TypedImageData<Float32Array>
    //     const interpolateBarycentric = (a: Vector2Like, b: Vector2Like, c: Vector2Like, barycentricCoordinate: BarycentricCoordinate) => {
    //         return new Vector2(
    //             a.x * barycentricCoordinate.u + b.x * barycentricCoordinate.v + c.x * barycentricCoordinate.w,
    //             a.y * barycentricCoordinate.u + b.y * barycentricCoordinate.v + c.y * barycentricCoordinate.w,
    //         )
    //     }
    //     const sampleTexture = (
    //         texture: TypedImageData<Float32Array>,
    //         texelPosition: Vector2Like,
    //         addressMode: "wrap" | "clamp" | "border",
    //         interpolationMode: "nearest" | "linear",
    //     ) => {
    //         if (texture.dataType !== "float32") {
    //             throw new Error("Unsupported data type for sampleTexture: " + texture.dataType)
    //         }
    //         if (texture.channelLayout !== "RGBA") {
    //             throw new Error("Unsupported channel layout for sampleTexture: " + texture.channelLayout)
    //         }
    //         // re-align texel position from center to top-left
    //         texelPosition.x -= 0.5
    //         texelPosition.y -= 0.5
    //         const sample = (x: number, y: number) => {
    //             switch (addressMode) {
    //                 case "wrap":
    //                     x = wrap(x, texture.width)
    //                     y = wrap(y, texture.height)
    //                     break
    //                 case "clamp":
    //                     x = Math.min(Math.max(0, x), texture.width - 1)
    //                     y = Math.min(Math.max(0, y), texture.height - 1)
    //                     break
    //                 case "border":
    //                     if (x < 0 || x >= texture.width || y < 0 || y >= texture.height) {
    //                         return {r: 0, g: 0, b: 0, a: 0}
    //                     }
    //                     break
    //                 default:
    //                     throw new Error("Unsupported address mode: " + addressMode)
    //             }
    //             const bufferIndex = (y * texture.width + x) * 4
    //             const r = texture.data[bufferIndex]
    //             const g = texture.data[bufferIndex + 1]
    //             const b = texture.data[bufferIndex + 2]
    //             const a = texture.data[bufferIndex + 3]
    //             return {r, g, b, a}
    //         }
    //         if (interpolationMode === "nearest") {
    //             return sample(Math.round(texelPosition.x), Math.round(texelPosition.y))
    //         } else if (interpolationMode === "linear") {
    //             const xFloor = Math.floor(texelPosition.x)
    //             const xCeil = Math.ceil(texelPosition.x)
    //             const yFloor = Math.floor(texelPosition.y)
    //             const yCeil = Math.ceil(texelPosition.y)
    //             const xFraction = texelPosition.x - xFloor
    //             const yFraction = texelPosition.y - yFloor
    //             const sample00 = sample(xFloor, yFloor)
    //             const sample10 = sample(xCeil, yFloor)
    //             const sample01 = sample(xFloor, yCeil)
    //             const sample11 = sample(xCeil, yCeil)
    //             const sample0 = {
    //                 r: sample00.r * (1 - xFraction) + sample10.r * xFraction,
    //                 g: sample00.g * (1 - xFraction) + sample10.g * xFraction,
    //                 b: sample00.b * (1 - xFraction) + sample10.b * xFraction,
    //                 a: sample00.a * (1 - xFraction) + sample10.a * xFraction,
    //             }
    //             const sample1 = {
    //                 r: sample01.r * (1 - xFraction) + sample11.r * xFraction,
    //                 g: sample01.g * (1 - xFraction) + sample11.g * xFraction,
    //                 b: sample01.b * (1 - xFraction) + sample11.b * xFraction,
    //                 a: sample01.a * (1 - xFraction) + sample11.a * xFraction,
    //             }
    //             return {
    //                 r: sample0.r * (1 - yFraction) + sample1.r * yFraction,
    //                 g: sample0.g * (1 - yFraction) + sample1.g * yFraction,
    //                 b: sample0.b * (1 - yFraction) + sample1.b * yFraction,
    //                 a: sample0.a * (1 - yFraction) + sample1.a * yFraction,
    //             }
    //         } else {
    //             throw new Error("Unsupported interpolation mode: " + interpolationMode)
    //         }
    //     }
    //     const shadingFn = (vertex: [Vertex, Vertex, Vertex], barycentricCoordinate: BarycentricCoordinate) => {
    //         const uv = interpolateBarycentric(vertex[0].uv, vertex[1].uv, vertex[2].uv, barycentricCoordinate)
    //         return sampleTexture(textureImage, uv, "wrap", "linear")
    //     }
    //     const geometry = createGeometry(gridPoints)
    //     const numFaces = geometry.indices.length / 3
    //     for (let i = 0; i < numFaces; i++) {
    //         const vertexIndices = [geometry.indices[i * 3], geometry.indices[i * 3 + 1], geometry.indices[i * 3 + 2]]
    //         const vertices = vertexIndices.map((index) => ({
    //             position: geometry.vertexPositions[index],
    //             uv: geometry.vertexUVs[index],
    //         }))
    //         this.rasterTriangleClean(framebufferImage, [vertices[0], vertices[1], vertices[2]], shadingFn)
    //     }
    //     // write result
    //     if (this.rasterTestMode$.value === RasterTestMode.SoftwareRasterizer) {
    //         await resultImageWebGL2.ref.halImage.writeRawImageData("float32", framebufferImage.data)
    //     } else if (this.rasterTestMode$.value === RasterTestMode.SoftwareRasterizerDiff) {
    //         const hwImage = await resultImageWebGL2.ref.halImage.readRawImageData("float32")
    //         if (hwImage.length !== framebufferImage.data.length) {
    //             throw new Error("Image sizes do not match")
    //         }
    //         for (let i = 0; i < hwImage.length; i++) {
    //             hwImage[i] = Math.abs(hwImage[i] - framebufferImage.data[i]) * 100
    //         }
    //         await resultImageWebGL2.ref.halImage.writeRawImageData("float32", hwImage)
    //     }
    // }
    //
    // private rasterTriangleClean(
    //     resultImage: TypedImageData<Float32Array>,
    //     vertex: [Vertex, Vertex, Vertex],
    //     shadingFn: (vertex: [Vertex, Vertex, Vertex], barycentricCoordinate: BarycentricCoordinate) => ColorLike,
    // ) {
    //     const width = resultImage.width
    //     const height = resultImage.height
    //     const buffer = resultImage.data
    //     if (Math.abs(vertex[0].position.y - vertex[1].position.y) < 0.0001 && Math.abs(vertex[1].position.y - vertex[2].position.y) < 0.0001) {
    //         return
    //     }
    //
    //     const min = new Vector2(Number.MAX_VALUE, Number.MAX_VALUE)
    //     const max = new Vector2(-Number.MAX_VALUE, -Number.MAX_VALUE)
    //     for (let i = 0; i < 3; i++) {
    //         const p = vertex[i].position
    //         min.minInPlace(p)
    //         max.maxInPlace(p)
    //     }
    //
    //     const computeBarycentricCoordinates = (vertices: [Vertex, Vertex, Vertex], position: Vector2Like): BarycentricCoordinate => {
    //         const a = vertices[0].position
    //         const b = vertices[1].position
    //         const c = vertices[2].position
    //         const d = position
    //
    //         const edgeFunction = (a: Vector2Like, b: Vector2Like, p: Vector2Like) => {
    //             return Vector2.cross(Vector2.sub(b, a), Vector2.sub(p, a))
    //         }
    //
    //         const areaABC = edgeFunction(a, b, c)
    //         const areaPBC = edgeFunction(b, c, d)
    //         const areaPCA = edgeFunction(c, a, d)
    //
    //         const u = areaPBC / areaABC // alpha
    //         const v = areaPCA / areaABC // beta
    //         const w = 1 - u - v // gamma
    //         return {u, v, w}
    //     }
    //
    //     const xMin = Math.max(0, Math.min(width, Math.floor(min.x)))
    //     const yMin = Math.max(0, Math.min(height, Math.floor(min.y)))
    //     const xMax = Math.max(0, Math.min(width, Math.floor(max.x)))
    //     const yMax = Math.max(0, Math.min(height, Math.floor(max.y)))
    //     for (let y = yMin, endY = yMax; y <= endY; y++) {
    //         for (let x = xMin, endX = xMax; x <= endX; x++) {
    //             const barycentricCoordinate = computeBarycentricCoordinates(vertex, new Vector2(x + 0.5, y + 0.5))
    //             if (
    //                 !(
    //                     0.0 <= barycentricCoordinate.u &&
    //                     barycentricCoordinate.u <= 1.0 &&
    //                     0.0 <= barycentricCoordinate.v &&
    //                     barycentricCoordinate.v <= 1.0 &&
    //                     0.0 <= barycentricCoordinate.w &&
    //                     barycentricCoordinate.w <= 1.0
    //                 )
    //             ) {
    //                 continue
    //             }
    //             const color = shadingFn(vertex, barycentricCoordinate)
    //             const bufferIndex = (y * width + x) * 4
    //             buffer[bufferIndex] = color.r
    //             buffer[bufferIndex + 1] = color.g
    //             buffer[bufferIndex + 2] = color.b
    //             buffer[bufferIndex + 3] = color.a ?? 1
    //         }
    //     }
    // }

    private destroyed = new Subject<void>()
    private tessellatedGridPoints?: GridPoint[][]
    // private falloffImage?: ImageRef
    private debugImage: DebugImage
}

export enum RasterTestMode {
    None,
    SoftwareRasterizer,
    SoftwareRasterizerDiff,
}

//
// type Vertex = {
//     position: Vector2Like
//     uv: Vector2Like
// }
//
// type BarycentricCoordinate = {
//     u: number
//     v: number
//     w: number
// }
