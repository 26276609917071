import {SdkService} from "@app/common/services/sdk/sdk.service"
import {ConfiguratorParameterType} from "@cm/lib/templates/configurator-parameters"
import {ConfigInfo, InterfaceDescriptor, MaterialInfo} from "@cm/lib/templates/interface-descriptors"
import {FindMaterial} from "@cm/lib/templates/nodes/find-material"
import {MaterialReference} from "@cm/lib/templates/nodes/material-reference"
import {Parameters, TemplateParameterValue} from "@cm/lib/templates/nodes/template-instance"

export type ConfiguratorUrlParameters = {
    templateId: string | undefined
    sceneId: number | undefined
    parameters: Parameters
}

type DecodedUrlParameter = {
    id: string
    value: string
    type: string
}

type ParsedParameter = {
    id: string
    value: TemplateParameterValue
}

const PARAM_REGEX = /param\(([^)]*)\)/
const VALUE_REGEX = /([^(]+)\(([^)]*)\)/

const decodeUrlParameter = (key: string, value: string): DecodedUrlParameter | undefined => {
    const paramMatch = key.match(PARAM_REGEX)
    if (!paramMatch || !paramMatch[1]) return undefined

    const id = paramMatch[1]
    const valueMatch = value.match(VALUE_REGEX)
    if (!valueMatch || !valueMatch[1] || !valueMatch[2]) {
        console.error("Invalid parameter value:", value)
        return undefined
    }

    const type = valueMatch[1] as ConfiguratorParameterType

    return {id, value: valueMatch[2], type}
}

/*key has the format "param(id)", value has the format "type(value)".*/
const initializeParameterFromUrlFormat = async (key: string, val: string, sdk: SdkService): Promise<ParsedParameter | undefined> => {
    const decodedParameter = decodeUrlParameter(key, val)
    if (!decodedParameter) return undefined

    const {id, value, type} = decodedParameter
    const parsedValue = await initializeTemplateParameterValue(type, value, sdk)

    return {id, value: parsedValue}
}

/*Convert string based parameter representation from e.g. configurator url or outer website to an instance that can be handled by the
template system. This was previously done in SceneViewer.prepareParameter.*/
export const initializeTemplateParameterValue = async (type: string, value: string, sdk: SdkService): Promise<TemplateParameterValue> => {
    let parsedValue: TemplateParameterValue
    switch (type) {
        case "int":
            parsedValue = parseInt(value, 10)
            break
        case "material":
            {
                parsedValue = await createMaterialReference(parseInt(value, 10), sdk)
            }
            break
        case "material-article-id":
            parsedValue = new FindMaterial({articleId: value})
            break
        case "number":
        case "float":
            parsedValue = parseFloat(value)
            break
        case "boolean":
            parsedValue = value === "true" || value === "1"
            break
        default:
            parsedValue = value
            break
    }

    return parsedValue
}

export const createMaterialReference = async (materialLegacyId: number, sdk: SdkService): Promise<MaterialReference> => {
    const revisionLegacyId = (await sdk.throwable.latestMaterialRevisionLegacyIdForConfigurator({materialLegacyId})).material.latestRevision?.legacyId
    if (!revisionLegacyId) throw new Error(`Failed to get latest revision for material ${materialLegacyId}`)
    return new MaterialReference({name: "(Material Ref)", materialRevisionId: revisionLegacyId})
}

export const decodeConfiguratorUrlParameters = async (parameterString: string, sdk: SdkService): Promise<ConfiguratorUrlParameters> => {
    const {templateId, sceneId, ...restParameters} = Object.fromEntries(new URLSearchParams(parameterString))

    const parameterPromises = Object.entries(restParameters).map(([key, value]) => initializeParameterFromUrlFormat(key, value, sdk))
    const parsedParameters = (await Promise.all(parameterPromises)).filter((param): param is NonNullable<typeof param> => param != null)
    const parameters = new Parameters(Object.fromEntries(parsedParameters.map((param) => [param.id, param.value])))

    return {
        templateId: templateId,
        sceneId: sceneId ? parseInt(sceneId, 10) : undefined,
        parameters,
    }
}

export const encodeConfiguratorURLParams = (interfaceDescriptors: InterfaceDescriptor<unknown, object>[]): string => {
    let result = ""
    for (const descriptor of interfaceDescriptors) {
        if (descriptor instanceof ConfigInfo && descriptor.props.type === "input" && descriptor.props.value) {
            result += `&param(${descriptor.props.id})=config(${descriptor.props.value.id})`
        }
        if (
            descriptor instanceof MaterialInfo &&
            descriptor.props.type === "input" &&
            descriptor.props.value &&
            descriptor.props.origin instanceof MaterialReference
        ) {
            result += `&param(${descriptor.props.id})=material(${descriptor.props.value.materialId})`
        }
    }
    return result
}
