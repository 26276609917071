@if (scanJob && $can().read.scanJob(scanJob)) {
    <mat-card appearance="outlined" class="cm-scan-job" [class.cm-disabled]="!$can().read.scanJob(null, 'dialog')" (click)="showScanJobDialog(scanJob)">
        <div class="cm-scan-job-checkbox">
            <mat-checkbox
                [ngModel]="scanJob.active"
                [disabled]="!$can().update.scanJob()"
                (ngModelChange)="updateScanJob({active: $event})"
                (click)="$event.stopPropagation()"
            ></mat-checkbox>
        </div>
        <div class="cm-scan-job-details">
            <div class="cm-scan-job-title">
                Scan job {{ scanJob.id }} (Active: {{ activeSubJobsCount }} | Completed: {{ completedSubJobsCount }} | Total: {{ scanJob.subJobsCount }})
                @if ($can().read.scanJob(null, "logs") && scanJob.logAssignments.length > 0) {
                    <span
                        (click)="downloadLog(scanJob.logAssignments[0].dataObject.downloadUrl); $event.stopPropagation()"
                        matTooltip="Logs"
                        class="cm-scan-job-actions"
                    >
                        <i class="far fa-arrow-to-bottom"></i>
                    </span>
                }
                @if ($can().read.scanJob(null, "files")) {
                    <a
                        href
                        (click)="$event.stopPropagation()"
                        routerLink="/files"
                        matTooltip="Show files"
                        [queryParams]="{assignedToId: scanJob.id, assignedToContentType: Enums.ContentTypeModel.ScanJob}"
                        class="cm-scan-job-actions"
                    >
                        <i class="far fa-file"></i>
                    </a>
                }
            </div>
            <div class="cm-scan-job-subtitle">
                <cm-state-label
                    [stateLabels]="Labels.States.ScanJob"
                    (click)="$event.stopPropagation()"
                    [state]="scanJob.state"
                    [editable]="$can().update.scanJob()"
                    (stateChange)="updateScanJob({state: $event})"
                >
                </cm-state-label>
                <div matTooltip="Processing time" class="cm-scan-job-icon-container">
                    <mat-icon class="cm-scan-job-icon">access_time</mat-icon>
                    <span class="cm-scan-job-icon-text">{{ scanJobDuration | cmDuration }}</span>
                </div>
                <div matTooltip="Creation time" class="cm-scan-job-icon-container">
                    <mat-icon class="cm-scan-job-icon">create</mat-icon>
                    <span class="cm-scan-job-icon-text">
                        @if (scanJob.createdAt) {
                            {{ scanJob.createdAt | amLocal | amDateFormat: "YYYY-MM-DD HH:mm" }}
                        }
                    </span>
                </div>
                <div matTooltip="Scanner" class="cm-scan-job-icon-container">
                    <mat-icon class="cm-scan-job-icon">camera_alt</mat-icon>
                    <span class="cm-scan-job-icon-text">{{ scanJob.createdBy.name }}</span>
                </div>
                <div matTooltip="Sample rotation" class="cm-scan-job-icon-container">
                    <i class="cm-scan-job-icon cm-scan-job-icon-rotation far fa-redo"></i>
                    <span class="cm-scan-job-icon-text">{{ scanJob.sampleRotation }}°</span>
                </div>
            </div>
        </div>
    </mat-card>
}
