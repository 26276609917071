import {Context} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/context"
import {GetParameters} from "@cm/lib/graph-system/node-graph"
import {Size2Like, Vector2Like} from "@cm/lib/math"
import {ImagePtr} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ref"
import {AddressMode} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/common-types"
import {rasterizeGeometry} from "@app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/rasterize-geometry-node"
import {lambda} from "@app/textures/texture-editor/operator-stack/image-op-system/nodes/basic-nodes/lambda-node"
import {getProperty} from "@cm/lib/graph-system/utils"
import {Vertex} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-rasterize-geometry"

export type GridPoint = {
    sourcePixel: Vector2Like
    targetPixel: Vector2Like
}

export type ParameterType = {
    sourceImage: ImagePtr
    gridPoints: GridPoint[][] // 2D array of grid points (inner array = x-axis, outer array = y-axis)
    addressMode?: AddressMode // default: "wrap"
    resultSize?: Size2Like // default: computed from targetPixel in gridPoints
    resultImage?: ImagePtr
}

export function gridMapping(parameters: GetParameters<Context, ParameterType>) {
    const {sourceImage, gridPoints, resultSize, addressMode, resultImage} = parameters
    const geometryNode = lambda({gridPoints}, async ({parameters: {gridPoints}}) => {
        const vertices: Vertex[] = []
        const indices: number[] = []
        const numGridPointsX = gridPoints[0].length
        const numGridPointsY = gridPoints.length
        for (let y = 0; y < numGridPointsY; y++) {
            for (let x = 0; x < numGridPointsX; x++) {
                // vertex
                const point = gridPoints[y][x]
                vertices.push({
                    position: point.targetPixel,
                    uv: point.sourcePixel,
                })
                // index
                if (x > 0 && y > 0) {
                    const i0 = (y - 1) * numGridPointsX + x - 1
                    const i1 = i0 + 1
                    const i2 = i0 + numGridPointsX
                    const i3 = i0 + numGridPointsX + 1
                    indices.push(i0, i1, i2, i1, i3, i2)
                }
            }
        }
        return {vertices, indices}
    })
    const resultImageOrDescriptorNode = lambda(
        {sourceImage, resultImage, resultSize, gridPoints},
        async ({context, parameters: {sourceImage, resultImage, resultSize, gridPoints}}) => {
            if (resultImage && resultSize) {
                throw new Error("Only one of resultImage and resultSize can be specified")
            }
            if (resultImage) {
                return resultImage
            }
            const sourceImageDescriptor = await context.getImageDescriptor(sourceImage)
            if (resultSize) {
                return {
                    ...sourceImageDescriptor,
                    ...resultSize,
                }
            }
            // compute resultSize from gridPoints
            let maxX = Number.NEGATIVE_INFINITY
            let maxY = Number.NEGATIVE_INFINITY
            for (const row of gridPoints) {
                for (const point of row) {
                    maxX = Math.max(maxX, Math.round(point.targetPixel.x))
                    maxY = Math.max(maxY, Math.round(point.targetPixel.y))
                }
            }
            return {...sourceImageDescriptor, width: maxX, height: maxY}
        },
    )
    return rasterizeGeometry({
        topology: "triangleList",
        vertices: getProperty(geometryNode, "vertices"),
        indices: getProperty(geometryNode, "indices"),
        textureImage: sourceImage,
        addressMode: addressMode,
        resultImageOrDescriptor: resultImageOrDescriptorNode,
    })
}
