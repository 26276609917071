import {Component, DestroyRef, inject, Inject, OnInit} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatCheckboxModule} from "@angular/material/checkbox"
import {MAT_DIALOG_DATA} from "@angular/material/dialog"
import {MatTableModule} from "@angular/material/table"
import {ContentTypeModel, ScanJobDialogSubJobFragment, ScanSubJobState} from "@api"
import {UtilsService} from "@legacy/helpers/utils"
import {DurationFromMillisecondsPipe} from "@common/pipes/duration/duration.pipe"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {Labels} from "@labels"
import {FilesService} from "@common/services/files/files.service"
import {StateLabelComponent} from "@platform/components/shared/state-label/state-label.component"
import {MomentModule} from "ngx-moment"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {switchMap} from "rxjs"

@Component({
    selector: "cm-scan-job-dialog",
    templateUrl: "./scan-job-dialog.component.html",
    styleUrls: ["./scan-job-dialog.component.scss"],
    standalone: true,
    imports: [MatTableModule, MatCheckboxModule, FormsModule, MomentModule, DurationFromMillisecondsPipe, StateLabelComponent],
})
export class ScanJobDialogComponent implements OnInit {
    displayedColumns: string[] = ["id", "state", "active", "progress", "started", "completed", "duration", "tile_x", "tile_y", "log", "fitting", "assembly"]

    permission = inject(PermissionsService)
    destroyRef = inject(DestroyRef)
    notifications = inject(NotificationsService)
    refresh = inject(RefreshService)
    sdk = inject(SdkService)
    utils = inject(UtilsService)
    $can = this.permission.$to

    scanJobId: string
    scanSubJobs: ScanJobDialogSubJobFragment[]

    constructor(@Inject(MAT_DIALOG_DATA) public data: {scanJobId: string}) {
        this.scanJobId = data.scanJobId
        this.scanSubJobs = []
    }

    ngOnInit(): void {
        this.refresh
            .keepFetched$(this.scanJobId, ContentTypeModel.ScanJob, () => this.sdk.gql.scanJobDialog({id: this.scanJobId}))
            .pipe(
                switchMap(async (scanJob) => {
                    return Promise.all(
                        scanJob?.subJobs?.map((subJob) => this.sdk.gql.scanJobDialogSubJob({scanSubJobId: subJob.id}).then(({scanSubJob}) => scanSubJob)) || [],
                    )
                }),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe(async (subJobs) => {
                this.scanSubJobs = subJobs
            })
    }

    refreshSubJobs() {
        this.refresh.item({id: this.scanJobId, __typename: ContentTypeModel.ScanJob})
    }

    updateItem(input: {active?: boolean; state?: ScanSubJobState}, scanSubJobId: string) {
        void this.notifications.withUserFeedback(
            async () => {
                await this.sdk.gql.scanJobDialogUpdateSubJob({
                    input: {
                        active: input.active,
                        id: scanSubJobId,
                        state: input.state,
                    },
                })
                this.scanSubJobs = this.scanSubJobs.map((subJob) => {
                    if (subJob.id === scanSubJobId) {
                        return {...subJob, active: input.active ?? subJob.active, state: input.state ?? subJob.state}
                    }
                    return subJob
                })
            },
            {
                success: "Changes saved",
                error: "Cannot save changes",
            },
        )
    }

    downloadLog(scanSubJob: ScanJobDialogSubJobFragment): void {
        const url = scanSubJob.logAssignments?.[0].dataObject.downloadUrl
        if (url) {
            FilesService.downloadFile("test", url)
        }
    }

    downloadFitting(scanSubJob: ScanJobDialogSubJobFragment): void {
        const url = scanSubJob.fittingAssignments?.[0].dataObject.downloadUrl
        if (url) {
            FilesService.downloadFile("test", url)
        }
    }

    downloadAssembly(scanSubJob: ScanJobDialogSubJobFragment): void {
        const url = scanSubJob.assemblyAssignments?.[0].dataObject.downloadUrl
        if (url) {
            FilesService.downloadFile("test", url)
        }
    }

    protected readonly Labels = Labels
}
