import {ThreeObject, mathIsEqual, updateTransform} from "@template-editor/helpers/three-object"
import * as THREE from "three"
import {ThreeSceneManagerService} from "@template-editor/services/three-scene-manager.service"
import {SceneNodes} from "@cm/lib/templates/interfaces/scene-object"
import {Matrix4} from "@cm/lib/math"
import {objectFieldsDifferent} from "@template-editor/helpers/change-detection"

export class ThreeStandHelper extends ThreeObject<SceneNodes.SceneNode & {transform: Matrix4}> {
    protected override renderObject: THREE.Line<THREE.BufferGeometry, THREE.LineBasicMaterial>
    private bufferPositionStand: THREE.Float32BufferAttribute
    private materialStand = new THREE.LineBasicMaterial({color: 0xbbbbbb, fog: false})
    private tempObject = new THREE.Group()

    constructor(protected override threeSceneManagerService: ThreeSceneManagerService) {
        super(threeSceneManagerService)

        const geometryStand = new THREE.BufferGeometry()
        this.bufferPositionStand = new THREE.Float32BufferAttribute(6, 3)
        geometryStand.setAttribute("position", this.bufferPositionStand)
        this.renderObject = new THREE.Line(geometryStand, this.materialStand)
    }

    setup(sceneNode: SceneNodes.SceneNode & {transform: Matrix4}) {
        return objectFieldsDifferent(
            sceneNode,
            this.parameters,
            ["transform"],
            (valueA, valueB) => mathIsEqual(valueA, valueB),
            ({transform}) => {
                const {position} = updateTransform(transform, this.tempObject)
                this.bufferPositionStand.set([position.x, 0, position.z, position.x, position.y, position.z])
                this.bufferPositionStand.needsUpdate = true
            },
        )
    }

    override dispose() {
        this.renderObject.geometry.dispose()
        this.renderObject.material.dispose()
    }
}
