import {AsyncPipe, NgClass} from "@angular/common"
import {Component, DestroyRef, EventEmitter, inject, Input, OnDestroy, OnInit, Output} from "@angular/core"
import {takeUntilDestroyed} from "@angular/core/rxjs-interop"
import {MatMenuModule} from "@angular/material/menu"
import {MatSnackBar} from "@angular/material/snack-bar"
import {ActivatedRoute, Router} from "@angular/router"
import {ContentTypeModel, MutationUpdateTaskInput, TaskListItemFragment, TaskState, TaskType} from "@api"
import {ButtonComponent} from "@common/components/buttons/button/button.component"
import {InputContainerComponent} from "@common/components/inputs/input-container/input-container.component"
import {NativeInputTextAreaComponent} from "@common/components/inputs/native/native-input-text-area/native-input-text-area.component"
import {ListItemDetailedComponent} from "@common/components/item/list-item-detailed/list-item-detailed.component"
import {PlaceholderComponent} from "@common/components/placeholders/placeholder/placeholder.component"
import {IsLoadingDirective} from "@common/directives"
import {forceEnum} from "@common/helpers/utils/enum"
import {AuthService} from "@common/services/auth/auth.service"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {Enums} from "@enums"
import {Labels} from "@labels"
import {StateLabelComponent} from "@platform/components/shared/state-label/state-label.component"
import {TasksService} from "@platform/services/tasks/tasks.service"
import {MomentModule} from "ngx-moment"
import {BehaviorSubject, filter, switchMap} from "rxjs"
import {IsDefined} from "@cm/lib/utils/filter"

export type TaskListItemType = {id: string; __typename: string; organization?: {id: string}}

@Component({
    standalone: true,
    selector: "cm-task-list",
    templateUrl: "task-list.component.html",
    styleUrls: ["task-list.component.scss"],
    imports: [
        ButtonComponent,
        InputContainerComponent,
        ListItemDetailedComponent,
        MatMenuModule,
        MomentModule,
        NativeInputTextAreaComponent,
        NgClass,
        StateLabelComponent,
        IsLoadingDirective,
        PlaceholderComponent,
        AsyncPipe,
    ],
})
export class TaskListComponent implements OnInit, OnDestroy {
    auth = inject(AuthService)
    can = inject(PermissionsService)
    destroyRef = inject(DestroyRef)
    notifications = inject(NotificationsService)
    permission = inject(PermissionsService)
    refresh = inject(RefreshService)
    route = inject(ActivatedRoute)
    router = inject(Router)
    sdk = inject(SdkService)
    snackBar = inject(MatSnackBar)
    tasksService = inject(TasksService)
    $can = this.permission.$to

    organizationId?: string
    item$ = new BehaviorSubject<TaskListItemType | null | undefined>(undefined)
    @Input({required: true}) set item(item: TaskListItemType | null | undefined) {
        this.item$.next(
            item
                ? {
                      ...item,
                      __typename: forceEnum(item.__typename, ContentTypeModel),
                  }
                : null,
        )
        this.organizationId = item?.organization?.id
    }
    tasks?: (TaskListItemFragment | null)[]

    ngOnInit() {
        this.refresh
            .observeItem$(this.item$)
            .pipe(
                filter(IsDefined),
                switchMap((item) => {
                    return this.tasksService.tasks$(item.id, forceEnum(item.__typename, ContentTypeModel))
                }),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe((tasks) => {
                this.tasks = tasks
            })
        this.tasksService.selectedTaskId$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((taskId) => {
            this.selectedTask = this.tasks?.find((task) => task?.id === taskId) || null
        })
        this.refresh.observeAllContentTypeModel$(ContentTypeModel.Task).subscribe(() => {
            this.refresh.item(this.item$.value)
        })
    }

    @Input() selectedTask: TaskListItemFragment | null = null

    lastComment(task: TaskListItemFragment | null) {
        const comments = task?.comments
        if (comments?.length) {
            return comments[comments.length - 1]
        }
        return null
    }

    async updateTask(task: TaskListItemFragment, data: Omit<MutationUpdateTaskInput, "id">) {
        await this.sdk.gql.updateTaskListItem({input: {id: task.id, ...data}})
        this.refresh.item(this.item$.value)
    }

    @Output() addNewTask = new EventEmitter<null>()
    @Output() mouseOverTask = new EventEmitter<TaskListItemFragment>()
    @Output() clickToggleTask: EventEmitter<boolean> = new EventEmitter<boolean>()

    addingTask = false
    newTaskText = ""

    navigateToTask(task: {id: string}): void {
        void this.router.navigate(["tasks", task.id], {
            relativeTo: this.route,
            queryParamsHandling: "preserve",
            state: {task: task},
        })
    }

    toggleAddTask() {
        this.addingTask = !this.addingTask
        this.clickToggleTask.emit(this.addingTask)
    }

    async addTask(taskComment: string) {
        const itemId = this.item$.value?.id
        const contentTypeModel = this.item$.value?.__typename
        if (itemId && contentTypeModel) {
            const task = await this.sdk.gql
                .addTaskListItem({
                    input: {
                        objectId: itemId,
                        public: !this.$can().read.picture(null, "privateTasks"),
                        contentTypeModel: forceEnum(contentTypeModel, ContentTypeModel),
                        state: TaskState.InProgress,
                        // TODO: set correct type
                        type: TaskType.Generic,
                    },
                })
                .catch(() => {
                    this.snackBar.open("Could not create task.", "", {duration: 3000})
                })
            if (task) {
                this.newTaskText = ""
                await this.sdk.gql
                    .addCommentToTaskListItem({
                        input: {
                            text: taskComment,
                            taskId: task.createTask.id,
                        },
                    })
                    .catch(() => {
                        this.snackBar.open("Could not create comment.", "", {duration: 3000})
                    })
                this.addNewTask.emit()
            }
            this.refresh.item(this.item$.value)
        }
    }

    highlightTask(task: TaskListItemFragment) {
        this.tasksService.selectTask(task)
    }

    async archiveTask(task: TaskListItemFragment) {
        await this.notifications.withUserFeedback(async () => this.updateTask(task, {state: TaskState.Archived}), {
            success: "Task archived",
            error: "Could not archive task",
        })
    }

    ngOnDestroy() {
        // reset "show completed" state when a picture detail window is closed
        this.tasksService.setShowCompleted(false)
    }

    protected readonly Labels = Labels
    protected readonly Enums = Enums
}
