<div part="cm-config-menu-icons" class="cm-config-menu-icons" style="--icon-size: {{ $iconSize() + 'px' }}">
    @for (desc of $interfaceDescriptors(); track desc.props.id) {
        @if (isConfigInput(desc) || isMaterialInput(desc)) {
            <div
                part="cm-config-group-title"
                class="cm-config-group-title"
                [ngClass]="{'cm-config-group-title-accordion': $uiStyle() === 'accordion'}"
                (click)="onDescriptorClicked(desc.props.id)"
            >
                <span>{{ desc.props.name }}</span>
                @if ($uiStyle() === "accordion") {
                    <i [ngClass]="desc.props.id === desc.props.id ? 'far fa-chevron-down' : 'far fa-chevron-right'"></i>
                }
            </div>

            @if ($uiStyle() === "icons" || desc.props.id === $selectedDescriptorId()) {
                @if (isConfigInput(desc)) {
                    <div
                        [attr.part]="desc.props.displayWithLabels ? 'cm-labels-container' : 'cm-icons-container'"
                        [ngClass]="desc.props.displayWithLabels ? 'cm-labels-container' : 'cm-icons-container'"
                    >
                        @for (variant of desc.props.variants; track variant.id) {
                            <cm-config-button
                                [$asLabel]="desc.props.displayWithLabels"
                                [$tooltipContainer]="$tooltipContainer()"
                                [$config]="desc"
                                [$variant]="variant"
                                [$useCaptions]="false"
                                [$selectedVariant]="desc?.props?.value"
                                (click)="configMenuService.emitConfigSelected({config: desc, variant})"
                            ></cm-config-button>
                        }
                    </div>
                }
                @if (isMaterialInput(desc)) {
                    <cm-select-material-button [$materialInfo]="desc"></cm-select-material-button>
                }
            }
        }
    }
</div>
