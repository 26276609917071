import {AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, inject, output, viewChild} from "@angular/core"
import {ThreeMaterialManagerService} from "@app/template-editor/services/three-material-manager.service"
import {ThreeSceneManagerService} from "@app/template-editor/services/three-scene-manager.service"

@Component({
    selector: "cm-three-template-scene-provider",
    standalone: true,
    imports: [],
    templateUrl: "./three-template-scene-provider.component.html",
    styleUrl: "./three-template-scene-provider.component.scss",
    providers: [ThreeSceneManagerService, ThreeMaterialManagerService],
})
export class ThreeTemplateSceneProviderComponent implements OnInit, OnDestroy, AfterViewInit {
    private canvas = viewChild.required<ElementRef<HTMLCanvasElement>>("canvas")
    private canvasContainer = viewChild.required<ElementRef<HTMLDivElement>>("canvasContainer")
    private resizeObserver?: ResizeObserver

    onInitialized = output<ThreeSceneManagerService>()

    private localThreeSceneManagerService = inject(ThreeSceneManagerService)

    ngOnInit() {
        if (!this.canvas) throw new Error("Canvas not found")
        this.localThreeSceneManagerService.init(this.canvas().nativeElement)
        this.resizeRenderer()
        this.onInitialized.emit(this.localThreeSceneManagerService)
    }

    ngAfterViewInit() {
        this.resizeObserver = new ResizeObserver((entries) => {
            this.resizeRenderer()
        })
        if (this.canvasContainer()) this.resizeObserver.observe(this.canvasContainer().nativeElement)
    }

    ngOnDestroy() {}

    private resizeRenderer() {
        const parentElement = this.canvas().nativeElement.parentElement
        if (!parentElement) throw new Error("No parent element for canvas found")

        const {width, height} = parentElement.getBoundingClientRect()
        this.localThreeSceneManagerService.resizeRenderer(width, height)
    }

    @HostListener("window:resize", ["$event"]) onResize(event: Event) {
        this.resizeRenderer()
    }
}
