import {inject, Injectable} from "@angular/core"
import {
    CyclesNodePropertyConversions,
    mapColormassToCyclesInputName,
    mapColormassToCyclesNodeParameterSet,
    mapColormassToCyclesNodeType,
    mapColormassToCyclesOutputName,
    mapColormassToCyclesProperty,
} from "@cm/lib/rendering/material-converter"
import {
    DataObject,
    getDataObjectFromImageResourceForFormatAndResolution,
    IMaterialGraphManager,
    isNodeOfType,
    isOutputWrapper,
    isResolvedResourceWithTransientDataObject,
    isShadowCatcherMaterial,
    MaterialGraphNode,
    RelatedDataObject,
    unwrapNodeOutput,
    WrappedMaterialGraphNode,
} from "@cm/lib/materials/material-node-graph"
import {mapLegacyDataObjectToImageResource} from "@app/templates/legacy/material-node-graph"
import {ImageColorSpace} from "@api"
import {SdkService} from "@common/services/sdk/sdk.service"
import {UploadGqlService} from "@common/services/upload/upload.gql.service"
import {MaterialGraphService} from "@common/services/material-graph/material-graph.service"
import {Matrix4, Vector3} from "@cm/lib/math"
import {cmRenderTaskForPassNames, PictureRenderJobOutput, RenderOutputForPassName} from "@cm/lib/job-task/rendering"
import {JobNodes} from "@cm/lib/job-task/job-nodes"
import {RenderNodes} from "@cm/lib/rendering/render-nodes"
import {GlobalRenderConstants, MeshRenderSettings, SceneNodes} from "@cm/lib/templates/interfaces/scene-object"
import {IMaterialData} from "@cm/lib/templates/interfaces/material-data"
import {graphToJson} from "@cm/lib/utils/graph-json"
import {Settings} from "@common/models/settings/settings"
import {
    createImageTextureSetNodes,
    createScannedTransmissionNodes,
    createSetImageTextureNode,
    ImageTextureSetNodes,
} from "@app/common/helpers/utils/material-converter-utils"
import {environment} from "@environment"

type Color = readonly [number, number, number]
type Vector = readonly [number, number, number]
type ShaderExprInlet<T> = ShaderExpr | T

class ShaderExpr {
    private constructor(
        readonly node: RenderNodes.ShaderNode,
        readonly output?: string,
    ) {}

    static node(
        type: string,
        inputs?: {[name: string]: ShaderExprInlet<number | boolean | string | Color | Vector | RenderNodes.Image>},
        output?: string,
    ): ShaderExpr {
        const node: RenderNodes.ShaderNode = {
            type,
        }
        if (inputs) {
            for (const [key, value] of Object.entries(inputs)) {
                if (value instanceof ShaderExpr) {
                    if (!node.inputs) node.inputs = {}
                    if (!value.output) throw Error("Output parameter not defined")
                    node.inputs[key] = [value.node, value.output]
                } else if (value != null) {
                    if (typeof value === "object" && (value as {type: string}).type !== undefined) {
                        if (!node.resources) node.resources = {}
                        node.resources[key] = value as RenderNodes.Image
                    } else {
                        if (!node.parameters) node.parameters = {}
                        node.parameters[key] = value
                    }
                }
            }
        }
        return new ShaderExpr(node, output)
    }

    compile(): RenderNodes.ShaderNode {
        return this.node
    }

    select(name: string) {
        return new ShaderExpr(this.node, name)
    }

    private static mathOp(name: string, value1: ShaderExprInlet<number>, value2?: ShaderExprInlet<number>): ShaderExpr {
        return ShaderExpr.node(
            "math",
            {
                math_type: name,
                value1: value1,
                ...(value2
                    ? {
                          value2: value2,
                      }
                    : {}),
            },
            "value",
        )
    }

    private static vectorMathOp(name: string, value1: ShaderExprInlet<Vector>, value2?: ShaderExprInlet<Vector>): ShaderExpr {
        return ShaderExpr.node(
            "vector_math",
            {
                math_type: name,
                vector1: value1,
                ...(value2
                    ? {
                          value2: value2,
                      }
                    : {}),
            },
            "vector",
        )
    }

    static dot(vector1: ShaderExprInlet<Vector>, vector2: ShaderExprInlet<Vector>): ShaderExpr {
        return ShaderExpr.node(
            "vector_math",
            {
                math_type: "dot_product",
                vector1,
                vector2,
            },
            "value",
        )
    }

    static value(v: number): ShaderExpr {
        return ShaderExpr.node("value", {value: v}, "value")
    }

    static color(c: Color): ShaderExpr {
        return ShaderExpr.node("color", {value: c}, "color")
    }

    vadd(b: ShaderExprInlet<Vector>): ShaderExpr {
        return ShaderExpr.vectorMathOp("add", this, b)
    }

    vsub(b: ShaderExprInlet<Vector>): ShaderExpr {
        return ShaderExpr.vectorMathOp("subtract", this, b)
    }

    vmul(b: ShaderExprInlet<Vector>): ShaderExpr {
        return ShaderExpr.vectorMathOp("multiply", this, b)
    }

    vdiv(b: ShaderExprInlet<Vector>): ShaderExpr {
        return ShaderExpr.vectorMathOp("divide", this, b)
    }

    dot(b: ShaderExprInlet<Vector>): ShaderExpr {
        return ShaderExpr.dot(this, b)
    }

    norm(): ShaderExpr {
        return ShaderExpr.node(
            "vector_math",
            {
                math_type: "length",
                vector1: this,
            },
            "value",
        )
    }

    scale(scale: ShaderExprInlet<number>): ShaderExpr {
        return ShaderExpr.node(
            "vector_math",
            {
                math_type: "scale",
                vector1: this,
                scale,
            },
            "vector",
        )
    }

    normalize(): ShaderExpr {
        return ShaderExpr.node(
            "vector_math",
            {
                math_type: "normalize",
                vector1: this,
            },
            "vector",
        )
    }

    add(b: ShaderExprInlet<number>): ShaderExpr {
        return ShaderExpr.mathOp("add", this, b)
    }

    sub(b: ShaderExprInlet<number>): ShaderExpr {
        return ShaderExpr.mathOp("subtract", this, b)
    }

    mul(b: ShaderExprInlet<number>): ShaderExpr {
        return ShaderExpr.mathOp("multiply", this, b)
    }

    div(b: ShaderExprInlet<number>): ShaderExpr {
        return ShaderExpr.mathOp("divide", this, b)
    }

    // TODO: mul_add
    sin(): ShaderExpr {
        return ShaderExpr.mathOp("sine", this)
    }

    cos(): ShaderExpr {
        return ShaderExpr.mathOp("cosine", this)
    }

    tan(): ShaderExpr {
        return ShaderExpr.mathOp("tangent", this)
    }

    sinh(): ShaderExpr {
        return ShaderExpr.mathOp("sinh", this)
    }

    cosh(): ShaderExpr {
        return ShaderExpr.mathOp("cosh", this)
    }

    tanh(): ShaderExpr {
        return ShaderExpr.mathOp("tanh", this)
    }

    asin(): ShaderExpr {
        return ShaderExpr.mathOp("arcsine", this)
    }

    acos(): ShaderExpr {
        return ShaderExpr.mathOp("arccosine", this)
    }

    atan(): ShaderExpr {
        return ShaderExpr.mathOp("arctangent", this)
    }

    pow(b: ShaderExprInlet<number>): ShaderExpr {
        return ShaderExpr.mathOp("power", this, b)
    }

    log(): ShaderExpr {
        return ShaderExpr.mathOp("logarithm", this)
    }

    min(b: ShaderExprInlet<number>): ShaderExpr {
        return ShaderExpr.mathOp("minimum", this, b)
    }

    max(b: ShaderExprInlet<number>): ShaderExpr {
        return ShaderExpr.mathOp("maximum", this, b)
    }

    round(): ShaderExpr {
        return ShaderExpr.mathOp("round", this)
    }

    lt(b: ShaderExprInlet<number>): ShaderExpr {
        return ShaderExpr.mathOp("less_than", this, b)
    }

    gt(b: ShaderExprInlet<number>): ShaderExpr {
        return ShaderExpr.mathOp("greater_than", this, b)
    }

    mod(b: ShaderExprInlet<number>): ShaderExpr {
        return ShaderExpr.mathOp("modulo", this, b)
    }

    abs(): ShaderExpr {
        return ShaderExpr.mathOp("absolute", this)
    }

    atan2(): ShaderExpr {
        return ShaderExpr.mathOp("arctan2", this)
    }

    floor(): ShaderExpr {
        return ShaderExpr.mathOp("floor", this)
    }

    ceil(): ShaderExpr {
        return ShaderExpr.mathOp("ceil", this)
    }

    frac(): ShaderExpr {
        return ShaderExpr.mathOp("fraction", this)
    }

    trunc(): ShaderExpr {
        return ShaderExpr.mathOp("trunc", this)
    }

    sqrt(): ShaderExpr {
        return ShaderExpr.mathOp("sqrt", this)
    }

    rsqrt(): ShaderExpr {
        return ShaderExpr.mathOp("inversesqrt", this)
    }

    sign(): ShaderExpr {
        return ShaderExpr.mathOp("sign", this)
    }

    exp(): ShaderExpr {
        return ShaderExpr.mathOp("exponent", this)
    }

    radians(): ShaderExpr {
        return ShaderExpr.mathOp("radians", this)
    }

    degrees(): ShaderExpr {
        return ShaderExpr.mathOp("degrees", this)
    }

    oneMinus(): ShaderExpr {
        return ShaderExpr.mathOp("subtract", 1.0, this)
    }

    neg(): ShaderExpr {
        return ShaderExpr.mathOp("multiply", this, -1.0)
    }

    square(): ShaderExpr {
        return this.mul(this)
    }

    separateRGB() {
        const sep = ShaderExpr.node("separate_rgb", {color: this})
        return {
            r: sep.select("r"),
            g: sep.select("g"),
            b: sep.select("b"),
        }
    }

    separateXYZ() {
        const sep = ShaderExpr.node("separate_xyz", {vector: this})
        return {
            x: sep.select("x"),
            y: sep.select("y"),
            z: sep.select("z"),
        }
    }

    static combineRGB(r: ShaderExprInlet<number>, g: ShaderExprInlet<number>, b: ShaderExprInlet<number>) {
        return ShaderExpr.node("combine_rgb", {r, g, b}, "image")
    }

    static combineXYZ(x: ShaderExprInlet<number>, y: ShaderExprInlet<number>, z: ShaderExprInlet<number>) {
        return ShaderExpr.node("combine_xyz", {x, y, z}, "vector")
    }

    // static cameraViewDirection(): ShaderExpr {
    //     // pointing _away_ from the camera!
    //     let vector = ShaderExpr.node('camera_info', undefined, 'view_vector');
    //     return ShaderExpr.node('vector_transform', {
    //         vector,
    //         transform_type: 'vector',
    //         convert_from: 'camera',
    //         convert_to: 'world'
    //     }, 'vector');
    // }

    static geometryInfo() {
        const expr = ShaderExpr.node("geometry")
        return {
            position: expr.select("position"),
            normal: expr.select("normal"),
            // tangent: expr.select('tangent'),
            incoming: expr.select("incoming"),
            backfacing: expr.select("backfacing"),
        }
    }

    static emissionBRDF(color: ShaderExprInlet<Color>, strength: ShaderExprInlet<number>) {
        return ShaderExpr.node("emission", {color, strength}, "emission")
    }

    static transparentBRDF() {
        return ShaderExpr.node("transparent_bsdf", undefined, "BSDF")
    }

    static backgroundBRDF(color: ShaderExprInlet<Color>, strength: ShaderExprInlet<number>) {
        return ShaderExpr.node("background_shader", {color, strength}, "background")
    }

    static addBRDF(a: ShaderExpr, b: ShaderExpr) {
        return ShaderExpr.node("add_closure", {closure1: a, closure2: b}, "closure")
    }

    static mapping(
        vector: ShaderExprInlet<Vector>,
        parameters: {
            rotation?: ShaderExprInlet<Vector>
            scale?: ShaderExprInlet<Vector>
        },
    ) {
        return ShaderExpr.node("mapping", {vector, ...parameters}, "vector")
    }

    static environmentTexture(vector: ShaderExprInlet<Vector>, image: RenderNodes.Image) {
        return ShaderExpr.node("environment_texture", {vector, image}, "color")
    }

    static imageTexture(vector: ShaderExprInlet<Vector>, image: RenderNodes.Image) {
        return ShaderExpr.node("image_texture", {vector, image}, "color")
    }

    static materialOutput(surface: ShaderExpr | null, parameters = {use_mis: true}) {
        return ShaderExpr.node("material_output", {...(surface ? {surface} : {}), use_mis: parameters.use_mis})
    }
}

@Injectable({
    providedIn: "root",
})
export class RenderingService {
    private sdkService = inject(SdkService)
    private uploadGqlService = inject(UploadGqlService)
    private materialGraphManager = inject(MaterialGraphService)

    async submitRenderJob(data: {nodes: SceneNodes.SceneNode[]; final: boolean; name: string; organizationLegacyId: number}) {
        const jobName = `RenderJob: ${data.name}`
        const {mainRenderTask, shadowMaskRenderTask, combinedRenderTask} = await this.createRenderTask(data)
        const jobGraph = JobNodes.jobGraph<PictureRenderJobOutput>(combinedRenderTask, {
            platformVersion: Settings.APP_VERSION,
            progress: JobNodes.progressGroupNoWeights(shadowMaskRenderTask ? [mainRenderTask, shadowMaskRenderTask] : [mainRenderTask], {
                output: mainRenderTask,
            }),
        })

        return this.sdkService.gql
            .renderingServiceCreateJob({
                input: {
                    name: jobName,
                    organizationLegacyId: data.organizationLegacyId,
                    graph: graphToJson(jobGraph),
                },
            })
            .then(({createJob}) => createJob)
    }

    async createRenderTask(data: {nodes: SceneNodes.SceneNode[]; final: boolean; name: string; organizationLegacyId: number}) {
        const mainRenderGraph = await buildRenderGraph(data, this.sdkService, this.materialGraphManager)
        const mainRenderTask = await this._createRenderTask(mainRenderGraph, data)

        let shadowMaskRenderTask: JobNodes.TypedTaskNode<RenderOutputForPassName<"ShadowCatcher">> | null = null
        if (mainRenderGraph.session.options.passes?.includes("ShadowCatcher")) {
            const shadowMaskRenderGraph = await buildRenderGraph(
                {
                    ...data,
                    aoMaskPass: true,
                },
                this.sdkService,
                this.materialGraphManager,
            )
            shadowMaskRenderTask = await this._createRenderTask(shadowMaskRenderGraph, data, ["ShadowCatcher"])
        }

        return {
            mainRenderTask,
            shadowMaskRenderTask,
            combinedRenderTask: JobNodes.struct({
                renderPasses: JobNodes.get(mainRenderTask, "renderPasses"),
                preview: JobNodes.get(mainRenderTask, "preview"),
                metadata: JobNodes.get(mainRenderTask, "metadata"),
                aoShadowMaskPass: shadowMaskRenderTask
                    ? JobNodes.get(JobNodes.get(shadowMaskRenderTask, "renderPasses"), "ShadowCatcher")
                    : JobNodes.value(undefined),
            }),
        }
    }

    private async _createRenderTask<T extends RenderNodes.PassName>(
        graph: RenderNodes.Render,
        data: {
            organizationLegacyId: number
        },
        passes: T[] = [],
    ) {
        const uploadResult = await this.uploadGqlService.createAndUploadDataObject(
            new File([JSON.stringify(graphToJson(graph))], "render.json", {type: "application/json"}),
            {
                organizationLegacyId: data.organizationLegacyId,
            },
            {showUploadToolbar: false, processUpload: true},
        )

        return JobNodes.task(cmRenderTaskForPassNames(...passes), {
            input: JobNodes.value({
                renderGraph: JobNodes.dataObjectReference(uploadResult.legacyId),
                customerId: data.organizationLegacyId,
            }),
            queueDomain: environment.rendering.defaultQueueDomain,
        })
    }
}

function getMeshGraphInfo(graph: RenderNodes.MeshData) {
    const dataObjectReferences: RenderNodes.DataObjectReference[] = []
    let maxSubdivLevel = 0
    const traverseMeshData = (node: RenderNodes.MeshData) => {
        if (node.type === "geomGraph") {
            traverseGeomExpr(node.graph)
        } else if (node.type === "loadMesh") {
            if (node.data.type === "dataObjectReference") {
                dataObjectReferences.push(node.data)
            }
        } else if (node.type === "subdivide") {
            maxSubdivLevel = Math.max(maxSubdivLevel, node.levels)
        }
    }
    const traverseGeomExpr = (node: RenderNodes.GeometryExpr) => {
        if (node.op === "meshToGeom") {
            traverseMeshData((node as RenderNodes.MeshDataToGeometry).args[0])
        } else {
            for (const arg of (node as RenderNodes.GeometryOperator).args) {
                if (typeof arg === "object") {
                    traverseGeomExpr(arg)
                }
            }
        }
    }
    traverseMeshData(graph)
    return {
        dataObjectReferences,
        maxSubdivLevel,
    }
}

type IdDetails = {
    legacyId: number
}

type RenderGraphBuilderInput = {
    nodes: SceneNodes.SceneNode[]
    final: boolean
    aoMaskPass?: boolean
}

//TODO: move this to ts-lib
class RenderGraphBuilder {
    constructor(private getHdriDataObjectIdDetails: (hdriIdDetails: IdDetails) => Promise<IdDetails>) {}

    async build(data: RenderGraphBuilderInput): Promise<RenderNodes.Render> {
        const nodes = data.nodes
        const final = data.final
        const aoMaskPass = data.aoMaskPass
        const renderNode = nodes.find(SceneNodes.RenderSettings.is)
        if (!renderNode) throw Error("Cannot find render node")

        const rootRenderNode: RenderNodes.Render = {
            type: "render",
            session: {
                options: {
                    // threads: Math.floor(navigator.hardwareConcurrency * 0.9),
                    // use_path_guiding: false,
                    // use_light_tree: false,
                    gpu: true,
                    transparent_background: true,
                    transparent_glass: true,
                    final_render: final,
                    use_denoising: true,
                    adaptive_subdivision_offscreen_dicing_scale: 256.0,
                    passes: final
                        ? [
                              "Combined",
                              "CryptoObject00",
                              // 'CryptoObject01',
                              // 'CryptoObject02',
                              "CryptoMaterial00",
                              // 'CryptoMaterial01',
                              // 'CryptoMaterial02',
                              "CryptoAsset00",
                              // 'CryptoAsset01',
                              // 'CryptoAsset02',
                          ]
                        : ["Combined"],
                },
            },
            scene: {
                camera: {
                    transform: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
                    shiftX: 0,
                    shiftY: 0,
                    fStop: 128,
                    focalLength: 50,
                    focalDistance: 100,
                    sensorSize: 35,
                    exposure: 1,
                },
                objects: [],
                lights: [],
            },
            width: Math.round(renderNode.width),
            height: Math.round(renderNode.height),
            samples: Math.round(renderNode.samples),
        }

        const optionsNode = nodes.find((x): x is SceneNodes.SceneOptions => x.type === "SceneOptions")
        const scene: RenderNodes.Scene = {
            camera: {
                transform: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
                shiftX: 0,
                shiftY: 0,
                fStop: 128,
                focalLength: 50,
                focalDistance: 100,
                sensorSize: 35,
                exposure: 1,
            },
            objects: [],
            lights: [],
        }
        rootRenderNode.scene = scene
        let shadowCatcherUsed = false
        let bestEnvNode: SceneNodes.Environment | null = null
        for (const node of nodes) {
            if (SceneNodes.Mesh.is(node)) {
                const shaders: RenderNodes.Object["shaders"] = {}
                let meshData: RenderNodes.MeshData
                if (node.meshData.graph) {
                    //TODO: traverse graph looking for data object references, add them to the pending list
                    meshData = node.meshData.graph
                } else {
                    meshData = {type: "empty"}
                }
                const meshDataInfo = getMeshGraphInfo(meshData)
                if (aoMaskPass && node.visibleDirectly == false) {
                    // (default is true, so compare to explicit false value)
                    // NOTE: just setting visibleToShadowRays to false for non-shadow catcher meshes doesn't work with environment lights.
                    // (Cycles considers this case as a diffuse ray, apparently)
                    // just skip this mesh as it should not contribute to the shadow mask
                } else {
                    const mesh: RenderNodes.Object = {
                        id: node.id,
                        transform: node.transform.toArray(),
                        meshData,
                        shaders,
                        visibleInCamera: node.visibleDirectly ? undefined : false, // omit true (default) to optimize graph JSON size
                        visibleInReflections: node.visibleInReflections ? undefined : false,
                        visibleInRefractions: node.visibleInRefractions ? undefined : false,
                        cryptomatteObjectName: node.meshRenderSettings.cryptoMatteObjectName,
                        cryptomatteAssetName: node.meshRenderSettings.cryptoMatteAssetName,
                    }
                    if (aoMaskPass) {
                        // Hide normal meshes from the camera so only the shadow catcher (mask) is visible
                        mesh.visibleInCamera = false
                    }
                    scene.objects.push(mesh)
                    for (const [slot, materialData] of node.materialMap?.entries() ?? []) {
                        if (!materialData) continue
                        //TODO: cache converted materials
                        if (optionsNode?.enableAdaptiveSubdivision && this.materialHasDisplacement(materialData)) {
                            if (meshDataInfo.maxSubdivLevel == 0) {
                                console.warn("Enabling adaptive subdivision for mesh with subdivLevel = 0")
                            }
                            mesh.adaptiveSubdivisionRate = 1.0
                            console.log(`Enabling adaptive subdivision for material ${materialData.name}`)
                        }
                        // real materials must be used even for aoMask pass, because they may have transparency
                        //TODO: check for mixed shadow catcher / non shadow catcher materials on mesh
                        const shader = await this.convertMaterial(materialData, node.meshRenderSettings, final)
                        if (!shader) continue

                        if (isShadowCatcherMaterial(materialData.materialGraph)) {
                            console.warn("TODO: deprecate shadow_catcher material parameter")
                            if (shader.parameters) delete shader.parameters["shadow_catcher"]
                            mesh.shadowCatcher = true
                            shadowCatcherUsed = true
                            if (aoMaskPass) {
                                mesh.visibleToShadowRays = false
                                mesh.visibleInCamera = true
                            }
                        }
                        if (!shader.parameters) shader.parameters = {}
                        shader.parameters["name"] = materialData.name // this is used as the cryptomatte material name
                        shaders[slot] = shader
                    }
                }
            } else if (SceneNodes.Camera.is(node)) {
                scene.camera.transform = node.transform.toArray()
                scene.camera.shiftX = node.shiftX
                scene.camera.shiftY = node.shiftY
                scene.camera.nearClip = node.nearClip
                scene.camera.farClip = node.farClip
                scene.camera.fStop = node.fStop
                scene.camera.focalLength = node.focalLength
                scene.camera.focalDistance = node.focalDistance
                scene.camera.sensorSize = node.filmGauge
                scene.camera.exposure = GlobalRenderConstants.exposureScale
            } else if (SceneNodes.AreaLight.is(node)) {
                // emissive mesh:
                //TODO: For an emissive mesh to work with shadow catcher objects, it must _also_ be set as a shadow catcher, and thus be invisible to the camera. This seems like a Cycles design issue...
                // scene.objects.push({
                //     id: node.id,
                //     transform: node.transform.toArray(),
                //     meshData: {
                //         type: 'plane',
                //         width: node.width,
                //         height: node.height,
                //         normalAxis: 'z-',
                //     },
                //     shaders: {
                //         0: this.createAreaLightShader(node.color, node.on ? (node.intensity * GlobalRenderConstants.lightIntensityScale) : 0, node.directionality, node.transparent)
                //     },
                //     visibleInCamera: node.visibleDirectly ? undefined : false, // omit true (default) to optimize graph JSON size
                //     visibleInReflections: node.visibleInReflections ? undefined : false,
                //     visibleInRefractions: node.visibleInRefractions ? undefined : false,
                // });

                if (node.on && !aoMaskPass) {
                    scene.lights.push({
                        type: "area",
                        id: node.id,
                        transform: node.transform.toArray(),
                        width: node.width,
                        height: node.height,
                        strength: node.intensity * GlobalRenderConstants.lightIntensityScale,
                        directionality: node.directionality ?? 0,
                        visibleInCamera: node.visibleDirectly ? undefined : false, // omit true (default) to optimize graph JSON size
                        visibleInReflections: node.visibleInReflections ? undefined : false,
                        visibleInRefractions: node.visibleInRefractions ? undefined : false,
                        shader: this.createEmissionShader(node.color),
                    })
                }
                if (!node.transparent && !aoMaskPass) {
                    scene.objects.push({
                        id: node.id + "-mesh",
                        transform: node.transform.multiply(Matrix4.translation(0, 0, 0.1)).toArray(), // offset 1mm along z+ axis
                        meshData: {
                            type: "plane",
                            width: node.width,
                            height: node.height,
                            normalAxis: "z-",
                        },
                        shaders: {
                            0: this.createNullShader(),
                        },
                        visibleInCamera: node.visibleDirectly ? undefined : false, // omit true (default) to optimize graph JSON size
                        visibleInReflections: node.visibleInReflections ? undefined : false,
                        visibleInRefractions: node.visibleInRefractions ? undefined : false,
                    })
                }
            } else if (SceneNodes.LightPortal.is(node)) {
                if (!aoMaskPass) {
                    scene.lights.push({
                        type: "portal",
                        id: node.id,
                        transform: node.transform.toArray(),
                        width: node.width,
                        height: node.height,
                    })
                }
            } else if (SceneNodes.Environment.is(node)) {
                if (!bestEnvNode || node.priority <= bestEnvNode.priority) {
                    bestEnvNode = node
                }
            }
        }

        if (bestEnvNode) {
            const node = bestEnvNode
            //TODO: clampHighlights?
            if (!aoMaskPass) {
                if (node.envData?.type === "hdri") {
                    const hdriDataObjectIdDetails = await this.getHdriDataObjectIdDetails({legacyId: node.envData.hdriID})
                    const imageNode = RenderNodes.loadImage(hdriDataObjectIdDetails.legacyId)
                    scene.environment = this.createEnvironmentShader(imageNode, node.rotation, node.intensity, node.mirror)
                } else {
                    console.warn("TODO: create env map shader from URL")
                }
            }
        }

        if (aoMaskPass) {
            rootRenderNode.session.options["max_bounces"] = 0
            scene.environment = this.createUniformEnvironmentShader()
            rootRenderNode.session.options["passes"] = ["ShadowCatcher"]
        } else {
            const setPassEnabled = (name: RenderNodes.PassName, enabled: boolean) => {
                const list = rootRenderNode.session.options.passes ?? []
                const idx = list.indexOf(name)
                if (enabled && idx === -1) {
                    list.push(name)
                } else if (!enabled && idx !== -1) {
                    list.splice(idx, 1)
                }
            }
            setPassEnabled("ShadowCatcher", shadowCatcherUsed)
        }
        return rootRenderNode
    }

    private createNullShader(): RenderNodes.ShaderNode {
        return ShaderExpr.materialOutput(null).compile()
    }

    private createEmissionShader(color: [number, number, number], strength = 1.0) {
        return ShaderExpr.materialOutput(ShaderExpr.emissionBRDF(color, strength)).compile()
    }

    private createAreaLightShader(color: [number, number, number], intensity: number, directionality: number, transparent: boolean): RenderNodes.ShaderNode {
        // conversion table from Corona directonality to platform [directionality, scale factor]:
        // 0.0 -> [0.000, 1.000]
        // 0.1 -> [0.000, 1.000]
        // 0.2 -> [0.001, 1.000]
        // 0.3 -> [0.015, 1.025]
        // 0.4 -> [0.231, 1.182]
        // 0.5 -> [0.578, 1.724]
        // 0.6 -> [0.817, 1.837]
        // 0.7 -> [0.914, 1.853]
        // 0.8 -> [0.956, 1.890]
        // 0.9 -> [0.976, 1.798]
        const geom = ShaderExpr.geometryInfo()
        let strength = geom.backfacing.oneMinus().mul(intensity)
        if (directionality > 0) {
            // mimic behavior of Cycles area lights
            const minAngle = (1 * Math.PI) / 180
            const spreadAngle = Math.max(minAngle, Math.PI * (1 - directionality))
            const a = (Math.PI - spreadAngle) * 0.5
            const tanSpread = Math.tan(a)
            const normalizeSpread = 2 / (2 + (2 * a - Math.PI) * tanSpread)
            const cos_a = geom.incoming.dot(geom.normal)
            const sin_a = cos_a.square().oneMinus().sqrt()
            const tan_a = sin_a.div(cos_a)
            strength = strength.mul(tan_a.mul(tanSpread).oneMinus().max(0).mul(normalizeSpread))
        }
        let brdf = ShaderExpr.emissionBRDF(color, strength)
        if (transparent) {
            brdf = ShaderExpr.addBRDF(brdf, ShaderExpr.transparentBRDF())
        }
        return ShaderExpr.materialOutput(brdf, {use_mis: intensity > 0}).compile()
    }

    private createEnvironmentShader(image: RenderNodes.Image, rotation: Vector3, intensity: number, mirror?: boolean): RenderNodes.ShaderNode {
        //TODO: enabling importance sampling by default introduces extra noise when the env map is not the primary source of light. Perhaps use (intensity > 0) as the condition for enabling it?
        const degToRad = Math.PI / 180
        const map1 = ShaderExpr.mapping(ShaderExpr.geometryInfo().position, {
            rotation: [rotation.x * degToRad, rotation.y * degToRad, rotation.z * degToRad],
            scale: mirror ? [-1, 1, 1] : [1, 1, 1],
        })
        const map2 = ShaderExpr.mapping(map1, {rotation: [Math.PI / 2, 0.0, 0.0]})
        const tex = ShaderExpr.environmentTexture(map2, image)
        const brdf = ShaderExpr.backgroundBRDF(tex, intensity)
        return ShaderExpr.materialOutput(brdf, {use_mis: intensity > 0}).compile()
    }

    private createUniformEnvironmentShader(color: Color = [1, 1, 1], intensity = 1.0): RenderNodes.ShaderNode {
        const brdf = ShaderExpr.backgroundBRDF(ShaderExpr.color(color), intensity)
        return ShaderExpr.materialOutput(brdf).compile()
    }

    private materialHasDisplacement(materialData: IMaterialData, _final = false): boolean {
        const rootNode = materialData.materialGraph?.rootNode
        return !!rootNode.inputs && (!!rootNode.inputs["Displacement"] || !!rootNode.inputs["displacement"])
    }

    private async convertMaterial(materialData: IMaterialData, meshRenderSettings: MeshRenderSettings, final = false): Promise<RenderNodes.ShaderNode | null> {
        if (!materialData?.materialGraph) return null

        // TODO remove this once fetching of mesh related data also migrated to the new API
        if (!meshRenderSettings.displacementImageResource) {
            meshRenderSettings.displacementImageResource = meshRenderSettings.displacementDataObject
                ? mapLegacyDataObjectToImageResource(meshRenderSettings.displacementDataObject)
                : undefined
        }

        const textureFormatAndResolutionForPreview = {format: "jpg", resolution: "2000px"} as const

        const imageTextureSetNodesCache = new Map<MaterialGraphNode, ImageTextureSetNodes>()
        const setImageTextureNodesCache = new Map<MaterialGraphNode, WrappedMaterialGraphNode>()
        const nodeMap = new Map<MaterialGraphNode, RenderNodes.ShaderNode>()
        const evalNode = (node: MaterialGraphNode): [RenderNodes.ShaderNode, string | undefined] => {
            const [unwrappedNode, outputName] = unwrapNodeOutput(node)
            if (outputName) {
                // special handling of nodes which are defined by the platform but not part of Cycles
                if (isNodeOfType(unwrappedNode, "ShaderNodeTextureSet")) {
                    let imageTextureSetNodes = imageTextureSetNodesCache.get(unwrappedNode)
                    if (!imageTextureSetNodes) {
                        imageTextureSetNodes = createImageTextureSetNodes(unwrappedNode)
                        imageTextureSetNodesCache.set(unwrappedNode, imageTextureSetNodes)
                    }
                    switch (outputName) {
                        case "BaseColor":
                            return evalNode(imageTextureSetNodes.baseColor)
                        case "Metallic":
                            return evalNode(imageTextureSetNodes.metallic)
                        case "Specular":
                            return evalNode(imageTextureSetNodes.specular)
                        case "Roughness":
                            return evalNode(imageTextureSetNodes.roughness)
                        case "Anisotropic":
                            return evalNode(imageTextureSetNodes.anisotropic)
                        case "AnisotropicRotation":
                            return evalNode(imageTextureSetNodes.anisotropicRotation)
                        case "Alpha":
                            return evalNode(imageTextureSetNodes.alpha)
                        case "Normal":
                            return evalNode(imageTextureSetNodes.normal)
                        case "Displacement":
                            return evalNode(imageTextureSetNodes.displacement)
                        case "Transmission":
                            return evalNode(imageTextureSetNodes.transmission)
                        default:
                            throw new Error("ShaderNodeTextureSet: Unknown output name")
                    }
                } else if (isNodeOfType(unwrappedNode, "ShaderNodeSetTexture")) {
                    let setImageTextureNode = setImageTextureNodesCache.get(unwrappedNode)
                    if (!setImageTextureNode) {
                        setImageTextureNode = createSetImageTextureNode(unwrappedNode)
                        setImageTextureNodesCache.set(unwrappedNode, setImageTextureNode)
                    }
                    return evalNode(setImageTextureNode)
                }
                // regular handling
                const [evaledNode, evaledOutputName] = evalNode(unwrappedNode)
                return [evaledNode, evaledOutputName ?? mapColormassToCyclesOutputName(evaledNode.type, outputName)]
            }
            let outNode = nodeMap.get(node)
            if (outNode) {
                return [outNode, undefined]
            }
            if (isOutputWrapper(node)) {
                throw new Error("Unwrapped node expected!")
            } else if (node.nodeType === "ShaderNodeScannedTransmission") {
                return evalNode(createScannedTransmissionNodes(node))
            }

            const mappedNodeType = mapColormassToCyclesNodeType(node.nodeType)
            if (!mappedNodeType) {
                throw new Error(`Unknown material node type: ${node.nodeType}`)
            }
            outNode = {
                type: mappedNodeType,
            }
            const mappedNodeParameters = mapColormassToCyclesNodeParameterSet(node)
            if (mappedNodeParameters) {
                if (!outNode.parameters) outNode.parameters = {}
                for (const [name, value] of Object.entries(mappedNodeParameters)) {
                    const mappedParam = mapColormassToCyclesProperty(mappedNodeType, name, value)
                    if (mappedParam) {
                        const [mappedName, mappedValue] = mappedParam
                        outNode.parameters[mappedName] = mappedValue
                    }
                }
            }
            if (node.inputs) {
                if (!outNode.inputs) outNode.inputs = {}
                for (const [name, input] of Object.entries(node.inputs)) {
                    const [sourceNode, sourceOutputName] = evalNode(input)
                    if (sourceNode) {
                        if (!sourceOutputName) {
                            console.error("No output socket name:", node)
                            throw Error(`Cannot connect node '${sourceNode.type}' without output socket name`)
                        }
                        const mappedInputName = mapColormassToCyclesInputName(mappedNodeType, name)
                        if (mappedInputName) {
                            outNode.inputs[mappedInputName] = [sourceNode, sourceOutputName]
                        }
                    }
                }
            }
            if (isNodeOfType(node, "OutputMaterial")) {
                const imageResource = meshRenderSettings.displacementImageResource
                if (imageResource) {
                    const displacementMin = meshRenderSettings.displacementMin ?? -1
                    const displacementMax = meshRenderSettings.displacementMax ?? 1

                    const existingDisplacementOutput = outNode.inputs?.["displacement"]

                    const uvMapNode: RenderNodes.ShaderNode = {
                        type: "uvmap",
                        parameters: {attribute: CyclesNodePropertyConversions.uvMapAttrName(meshRenderSettings.displacementUvChannel)},
                    }
                    if (isResolvedResourceWithTransientDataObject(imageResource)) throw Error("imageResource data must be a stored DataObject!")

                    const texNode: RenderNodes.ShaderNode = {
                        type: "image_texture",
                        parameters: {
                            colorspace: "none",
                        },
                        resources: {
                            image: RenderNodes.loadImage(imageResource.mainDataObject.legacyId),
                        },
                        inputs: {vector: [uvMapNode, "uv"]},
                    }
                    const dispNode: RenderNodes.ShaderNode = {
                        type: "displacement",
                        parameters: {
                            midlevel: -displacementMin / (displacementMax - displacementMin),
                            scale: displacementMax - displacementMin,
                        },
                        inputs: {height: [texNode, "color"]},
                    }
                    let newDisplacementOutput: [RenderNodes.ShaderNode, string]
                    if (existingDisplacementOutput) {
                        newDisplacementOutput = [
                            {
                                type: "vector_math",
                                parameters: {type: "add"},
                                inputs: {
                                    vector1: existingDisplacementOutput,
                                    vector2: [dispNode, "displacement"],
                                },
                            },
                            "vector",
                        ]
                    } else {
                        newDisplacementOutput = [dispNode, "displacement"]
                    }

                    if (!outNode.inputs) {
                        outNode.inputs = {}
                    }
                    outNode.inputs!["displacement"] = newDisplacementOutput
                }
            } else if (isNodeOfType(node, "TexImage")) {
                if (node.resolvedResources) {
                    if (node.resolvedResources.length !== 1) {
                        throw new Error("TexImage: Expected exactly one image resource")
                    }
                    const resolvedResource = node.resolvedResources[0]
                    if (isResolvedResourceWithTransientDataObject(resolvedResource)) throw Error("resolvedResource must be a stored DataObject!")
                    let dataObject: DataObject | RelatedDataObject = resolvedResource.mainDataObject

                    if (!final) {
                        const previewdataObject = getDataObjectFromImageResourceForFormatAndResolution(
                            resolvedResource,
                            textureFormatAndResolutionForPreview.format,
                            textureFormatAndResolutionForPreview.resolution,
                        )
                        if (!previewdataObject) throw Error("Failed to get preview data object")
                        dataObject = previewdataObject
                    }
                    if (!outNode.parameters) outNode.parameters = {}
                    outNode.parameters["colorspace"] = dataObject.imageColorSpace === ImageColorSpace.Srgb ? "__builtin_srgb" : "__builtin_raw"
                    if (!outNode.resources) outNode.resources = {}
                    outNode.resources["image"] = RenderNodes.loadImage(dataObject.legacyId)
                }
            }
            nodeMap.set(node, outNode)
            return [outNode, undefined]
        }

        const [outputNode, _outputSocket] = evalNode(materialData.materialGraph.rootNode)
        return outputNode
    }
}

export function buildRenderGraph(
    data: RenderGraphBuilderInput,
    sdkService: SdkService,
    _materialGraphManager?: IMaterialGraphManager,
): Promise<RenderNodes.Render> {
    const getHdriDataObjectIdDetails = async (hdriIdDetails: IdDetails): Promise<IdDetails> => {
        const dataObjectIdDetails = (await sdkService.gql.hdriForRendering(hdriIdDetails)).hdri.dataObject
        if (!dataObjectIdDetails) throw Error("Failed to query hdri data object id details")
        return dataObjectIdDetails
    }

    return new RenderGraphBuilder(getHdriDataObjectIdDetails).build(data)
}
