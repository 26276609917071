export type Vector3Like = {x: number; y: number; z: number}

export class Vector3 {
    constructor(
        public x = 0,
        public y = 0,
        public z = 0,
    ) {}

    set(x: number, y: number, z: number) {
        this.x = x
        this.y = y
        this.z = z
    }

    toArray(): [number, number, number] {
        return [this.x, this.y, this.z]
    }

    static fromArray(a: number[]) {
        return new this(a[0], a[1], a[2])
    }

    toJsonString() {
        return `[${this.toArray()}]`
    }

    static fromJsonString(s: string) {
        return this.fromArray(JSON.parse(s))
    }

    static fromVector3Like(v: Vector3Like) {
        return new this(v.x, v.y, v.z)
    }

    equals(other: Vector3) {
        return this.x == other.x && this.y == other.y && this.z == other.z
    }

    withinEpsilon(other: Vector3, epsilon: number) {
        return Math.abs(this.x - other.x) < epsilon && Math.abs(this.y - other.y) < epsilon && Math.abs(this.z - other.z) < epsilon
    }

    copy(): Vector3 {
        return new Vector3(this.x, this.y, this.z)
    }

    dot(v: Vector3): number {
        return this.x * v.x + this.y * v.y + this.z * v.z
    }

    norm(): number {
        return Math.sqrt(this.dot(this))
    }

    distance(v: Vector3): number {
        return this.sub(v).norm()
    }

    add(b: Vector3): Vector3 {
        return new Vector3(this.x + b.x, this.y + b.y, this.z + b.z)
    }

    addInPlace(b: Vector3): Vector3 {
        this.x += b.x
        this.y += b.y
        this.z += b.z
        return this
    }

    sub(b: Vector3): Vector3 {
        return new Vector3(this.x - b.x, this.y - b.y, this.z - b.z)
    }

    subInPlace(b: Vector3): Vector3 {
        this.x -= b.x
        this.y -= b.y
        this.z -= b.z
        return this
    }

    mul(s: number): Vector3 {
        return new Vector3(this.x * s, this.y * s, this.z * s)
    }

    mulInPlace(s: number): Vector3 {
        this.x *= s
        this.y *= s
        this.z *= s
        return this
    }

    div(s: number): Vector3 {
        return new Vector3(this.x / s, this.y / s, this.z / s)
    }

    divInPlace(s: number): Vector3 {
        this.x /= s
        this.y /= s
        this.z /= s
        return this
    }

    cross(b: Vector3): Vector3 {
        return new Vector3(this.y * b.z - this.z * b.y, this.z * b.x - this.x * b.z, this.x * b.y - this.y * b.x)
    }

    normalized(): Vector3 {
        return this.mul(1 / this.norm())
    }
}
