import {ImagePtr} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ref"
import {Context} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/context"
import {Vector2Like} from "@cm/lib/math/vector2"
import {GetParameters} from "@cm/lib/graph-system/node-graph"
import {getImageDesc} from "@app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/get-image-desc-node"
import {resize} from "@app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/resize-node"
import {convolve} from "@app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/convolve-node"
import {lambda} from "@app/textures/texture-editor/operator-stack/image-op-system/nodes/basic-nodes/lambda-node"

export type ParameterType = {
    sourceImage: ImagePtr
    blurKernelSize: Vector2Like
    borderMode: "wrap" | "clamp" | "wrap-mirrored" | "zero"
}

export function blur(parameters: GetParameters<Context, ParameterType>) {
    const {sourceImage, blurKernelSize, borderMode} = parameters
    const sourceImageDesc = getImageDesc({
        sourceImage,
    })
    const downsampledSourceImage = resize({
        sourceImage,
        resultSize: lambda({sourceImageDesc, blurKernelSize}, async ({parameters: {sourceImageDesc, blurKernelSize}}) => {
            return {
                width: blurKernelSize.x > 0 ? Math.ceil(sourceImageDesc.width * Math.min(1, MAX_KERNEL_SIZE / blurKernelSize.x)) : 1,
                height: blurKernelSize.y > 0 ? Math.ceil(sourceImageDesc.height * Math.min(1, MAX_KERNEL_SIZE / blurKernelSize.y)) : 1,
            }
        }),
    })
    const downSampledBlurredImageH = convolve({
        sourceImage: downsampledSourceImage,
        kernel: lambda({blurKernelSize}, async ({parameters: {blurKernelSize}}) => {
            const smoothingDistanceH = blurKernelSize.x ? blurKernelSize.x : 1
            const kernelSizeH = Math.min(MAX_KERNEL_SIZE, smoothingDistanceH)
            const kernelH = computeKernel(kernelSizeH)
            return {
                width: kernelH.length,
                height: 1,
                values: kernelH,
            }
        }),
        borderMode,
    })
    const downSampledBlurredImage = convolve({
        sourceImage: downSampledBlurredImageH,
        kernel: lambda({blurKernelSize}, async ({parameters: {blurKernelSize}}) => {
            const smoothingDistanceV = blurKernelSize.y ? blurKernelSize.y : 1
            const kernelSizeV = Math.min(MAX_KERNEL_SIZE, smoothingDistanceV)
            const kernelV = computeKernel(kernelSizeV)
            return {
                width: 1,
                height: kernelV.length,
                values: kernelV,
            }
        }),
        borderMode,
    })
    return resize({
        sourceImage: downSampledBlurredImage,
        resultSize: sourceImageDesc,
    })
}

function computeKernel(spread: number): number[] {
    // compute Gaussian kernel
    const halfKernelSize = Math.ceil(spread)
    const sigma = spread / 3
    const kernel = []
    let sum = 0
    for (let i = -halfKernelSize; i <= halfKernelSize; i++) {
        const v = Math.exp((-i * i) / (2 * sigma * sigma))
        kernel.push(v)
        sum += v
    }
    // make area under curve equal to 1
    for (let i = 0; i < kernel.length; i++) {
        kernel[i] /= sum
    }
    return kernel
}

const MAX_KERNEL_SIZE = 64
