import {skipped, visitAll} from "@src/graph-system/declare-visitor-node"
import {registerNode} from "@src/graph-system/register-node"
import {BooleanLike, booleanLike} from "@src/templates/node-types"
import {DeclareTemplateNodeTS} from "@src/templates/declare-template-node"
import {TemplateNode} from "@src/templates/types"
import {z} from "zod"
import {groupNodeParameters, GroupNodeParameters} from "@src/templates/nodes/group-node"
import {namedNodeParameters, NamedNodeParameters} from "@src/templates/nodes/named-node"
import {NodeEvaluator} from "../node-evaluator"

export const groupParameters = namedNodeParameters.merge(groupNodeParameters).merge(z.object({active: booleanLike}))
export type GroupParameters = NamedNodeParameters & GroupNodeParameters & {active: BooleanLike}

@registerNode
export class Group extends DeclareTemplateNodeTS<GroupParameters>(
    {
        validation: {paramsSchema: groupParameters},
        onVisited: {
            onFilterActive: function (this: Group, {visit, context, parameters}) {
                const {evaluator} = context
                const {active} = parameters

                return evaluator.evaluateActive(active) ? visitAll(parameters, visit) : skipped
            },
            onCompile: ({visit, context, parameters}) => {
                return visitAll(parameters, visit)
            },
        },
    },
    {nodeClass: "Group"},
) {}

export type GroupFwd = TemplateNode<GroupParameters>
