<cm-action-panel #infoPanel arrowRight="13px" minWidth="250px" maxWidth="250px">
    @if (dataObject) {
        <cm-action-panel-row cmIconClass="far fa-image-polaroid">
            <span class="cm-file-name" [matTooltip]="dataObject.originalFileName" matTooltipPosition="above" matTooltipClass="cm-untruncated-tooltip">
                {{ dataObject.originalFileName }}
            </span>
        </cm-action-panel-row>
    }
    @if (pictureRevision) {
        <cm-action-panel-row cmIconClass="far fa-history">
            v{{ pictureRevision.number }} (<span
                [matTooltip]="pictureRevision.createdAt | amLocal | amDateFormat: 'D MMMM, YYYY HH:mm'"
                matTooltipPosition="above"
                >{{ pictureRevision.createdAt | amTimeAgo }}</span
            >)
        </cm-action-panel-row>
    }
    @if (pictureRevision) {
        <cm-action-panel-row cmIconClass="far fa-user">
            {{ pictureRevision.createdBy.firstName }} {{ pictureRevision.createdBy.lastName }}
        </cm-action-panel-row>
    }
    @if (dataObject) {
        <cm-action-panel-row cmIconClass="far fa-up-right-and-down-left-from-center">
            {{ dataObject.width }} px × {{ dataObject.height }} px
            @if (dataObject.size) {
                ({{ dataObject.size | filesize: {base: 2} }})
            }
        </cm-action-panel-row>
    }
    @if (dataObject?.imageColorProfile) {
        <cm-action-panel-row cmIconClass="far fa-palette">
            {{ dataObject.imageColorProfile == "none" ? "No color profile" : dataObject.imageColorProfile }}
        </cm-action-panel-row>
    }
    <cm-action-panel-row cmIconClass="far fa-magnifying-glass">{{ getZoomLevel() }}%</cm-action-panel-row>
</cm-action-panel>
<cm-action-panel #editPanel arrowRight="70px" minWidth="250px" maxWidth="250px" (onShow)="edit.emit()">
    <ng-content select="[cm-picture-controls-edit-content]"></ng-content>
    <cm-action-bar>
        <ng-container cm-action-items>
            <cm-action-item (click)="discard.emit(); editItem.active = false" tooltip="Discard modifications" cmIconClass="far fa-ban"></cm-action-item>
            <cm-action-item
                (click)="save.emit()"
                tooltip="Save modifications as new revision / Save selected mask as new revision"
                cmIconClass="far fa-save"
            ></cm-action-item>
        </ng-container>
    </cm-action-bar>
</cm-action-panel>
<cm-action-bar>
    <ng-container cm-action-items>
        <cm-action-item (click)="revisionChange.emit('previous')" tooltip="Previous revision" cmIconClass="far fa-left"></cm-action-item>
        <cm-action-item (click)="revisionChange.emit('next')" tooltip="Next revision" cmIconClass="far fa-right"></cm-action-item>
        @if ($can().read.menu("pictureAssignmentType")) {
            <cm-action-item tooltip="Select assignment type" cmIconClass="far fa-list" [matMenuTriggerFor]="selectAssignmentMenu"></cm-action-item>
        }
        <cm-action-item (click)="pictureCanvas.navigation?.zoomTo(1)" tooltip="Zoom to 100%" cmIconClass="far fa-expand"></cm-action-item>
        <cm-action-item (click)="pictureCanvas.navigation?.zoomToFitImage()" tooltip="Zoom to fit" cmIconClass="far fa-compress"></cm-action-item>
        <cm-action-item [hidden]="!$can().update.picture()" #editItem tooltip="Edit" [panel]="editPanel" cmIconClass="far fa-edit"></cm-action-item>
        <cm-action-item tooltip="Download" cmIconClass="far fa-arrow-to-bottom" [matMenuTriggerFor]="downloadPictureMenu"></cm-action-item>
        <cm-action-item [showPanelByDefault]="!isMobileDevice" tooltip="Info" [panel]="infoPanel" cmIconClass="far fa-info-circle"></cm-action-item>
    </ng-container>
</cm-action-bar>
<mat-menu #downloadPictureMenu="matMenu">
    @if (dataObject) {
        <button mat-menu-item (click)="downloadOriginal(dataObject)">Original ({{ getFileTypeLabel(dataObject) }})</button>
    }
    @if (dataObject) {
        <button mat-menu-item (click)="downloadJpeg(dataObject)">JPEG</button>
    }
</mat-menu>
<mat-menu #selectAssignmentMenu="matMenu">
    @if (pictureRevision) {
        <mat-button-toggle-group style="margin: 5px" vertical [ngModel]="selectedAssignment" (ngModelChange)="selectedAssignmentChange.emit($event)">
            @for (assignment of pictureRevision.dataObjectAssignments; track assignment) {
                <mat-button-toggle [value]="assignment">{{ labelForAssignment(assignment) }}</mat-button-toggle>
            }
        </mat-button-toggle-group>
    }
</mat-menu>
