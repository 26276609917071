import {DeclareTemplateNodeTS} from "@src/templates/declare-template-node"
import {TemplateNode} from "@src/templates/types"
import {registerNode} from "@src/graph-system/register-node"
import {EvaluableTemplateNode} from "@src/templates/evaluable-template-node"
import {NodeEvaluator} from "@src/templates/node-evaluator"
import {GraphBuilderScope} from "@src/templates/runtime-graph/graph-builder-scope"
import {skipped, visitAll} from "@src/graph-system/declare-visitor-node"
import {SceneProperties} from "@src/templates/nodes/scene-properties"
import {groupNodeParameters, GroupNodeParameters} from "@src/templates/nodes/group-node"
import {namedNodeParameters, NamedNodeParameters} from "@src/templates/nodes/named-node"

const templateGraphParameters = namedNodeParameters.merge(groupNodeParameters)
export type TemplateGraphParameters = NamedNodeParameters & GroupNodeParameters

@registerNode
export class TemplateGraph
    extends DeclareTemplateNodeTS<TemplateGraphParameters>(
        {
            validation: {paramsSchema: templateGraphParameters},
            onVisited: {
                onFilterActive: function (this: TemplateGraph, {visit, context, parameters}) {
                    const {root, onVisitedSceneProperties} = context
                    if (this !== root) return skipped // skip internal template definitions

                    if (onVisitedSceneProperties) {
                        for (const node of parameters.nodes.parameters.list) {
                            if (node instanceof SceneProperties) onVisitedSceneProperties(node)
                        }
                    }

                    return visitAll(parameters, visit)
                },
                onCompile: function (this: TemplateGraph, {visit, context, parameters}) {
                    const {root} = context
                    if (this !== root) return skipped // skip internal template definitions

                    return visitAll(parameters, visit)
                },
            },
        },
        {nodeClass: "TemplateGraph"},
    )
    implements EvaluableTemplateNode<TemplateGraph>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator) {
        return this
    }
}

export type TemplateGraphFwd = TemplateNode<TemplateGraphParameters> & EvaluableTemplateNode<TemplateGraph>
