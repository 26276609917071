@if ($isDrawing()) {
    <div (click)="drawing.disable()" class="cm-drawing-backdrop"></div>
}
<cm-drop-files (filesDropped)="uploadFiles($event)" [maxFiles]="1" [mimeTypeFilter]="MimeType.Images">
    <div class="cm-aspect-ratio-container">
        <div class="cm-aspect-ratio-content">
            <cm-sidenav #sidenav [attachToParent]="true" [sidenavOpen]="revisionSidebarOpen" (backgroundClick)="revisionSidebarOpen = false">
                <ng-container cm-content>
                    <div class="cm-revisions-sidenav">
                        @if ($picture(); as picture) {
                            @for (revision of picture.revisions; track revision) {
                                <cm-card
                                    [class.cm-revision-selected]="revision.number === $currentRevision()?.number"
                                    [defaultThumbnail]="false"
                                    (click)="revisionSidebarOpen = false; navigateToRevision(revision.number)"
                                >
                                    <ng-container cm-thumbnail>
                                        <cm-data-object-thumbnail
                                            [dataObjectId]="revision.pictureDataAssignments?.[0]?.dataObject.id"
                                        ></cm-data-object-thumbnail>
                                    </ng-container>
                                    <ng-container cm-title> Revision {{ revision.number }}</ng-container>
                                    <ng-container cm-subtitle>
                                        <span
                                            class="cm-revision-created"
                                            matTooltip="Created by {{ revision.createdBy.firstName }} {{ revision.createdAt | amTimeAgo }}"
                                        >
                                            Created by {{ revision.createdBy.firstName }} {{ revision.createdAt | amTimeAgo }}
                                        </span>
                                    </ng-container>
                                </cm-card>
                            }
                        }
                    </div>
                </ng-container>
            </cm-sidenav>
            <div #feedbackCanvasContainer class="cm-canvas-with-toolbar">
                <cm-feedback-canvas
                    [pictureRevisionId]="currentRevisionId"
                    [organizationId]="organizationId"
                    (imageClick)="openPictureViewer()"
                    #feedbackCanvas
                >
                </cm-feedback-canvas>
                <div class="cm-state-container">
                    @if ($picture(); as picture) {
                        @if ($can().update.picture(picture, "drawing") && !$isDrawing()) {
                            @if ($can().read.picture(picture, "paymentState")) {
                                <cm-payment-state-label
                                    class="cm-left-tag-container-1"
                                    [item]="picture"
                                    [editable]="$can().update.picture(picture, 'paymentState')"
                                    (stateChange)="updatePicture({paymentState: $event})"
                                ></cm-payment-state-label>
                            }
                            <cm-state-label
                                class="cm-left-tag-container-2"
                                [stateLabels]="Labels.PictureState"
                                [state]="picture.state"
                                [editable]="true"
                                (stateChange)="updatePicture({state: $event})"
                            >
                            </cm-state-label>
                        }
                    }
                </div>
                @if ($isDrawing()) {
                    <div class="cm-action-container-2">
                        <a class="cm-action-icon-container" (click)="drawing.drawInBlue()" matTooltipPosition="above" matTooltip="Blue">
                            <div class="cm-blue-rectangle" [class.cm-rectangle-active]="(drawing.activeTool$ | async) === 'blue'"></div>
                        </a>
                        <a class="cm-action-icon-container" (click)="drawing.drawInPink()" matTooltipPosition="above" matTooltip="Pink">
                            <div class="cm-pink-rectangle" [class.cm-rectangle-active]="(drawing.activeTool$ | async) === 'pink'"></div>
                        </a>
                        <a class="cm-action-icon-container" (click)="drawing.startEditing()" matTooltipPosition="above" matTooltip="Edit">
                            <i [ngClass]="(drawing.activeTool$ | async) === 'cursor' ? 'fas' : 'fal'" class="fa-mouse-pointer cm-action-icon"></i>
                        </a>
                        <a class="cm-action-icon-container" (click)="initClearDrawing()" matTooltipPosition="above" matTooltip="Clear drawing">
                            <i class="far fa-trash cm-action-icon"></i>
                        </a>
                    </div>
                }
                @if ($currentRevision(); as currentRevision) {
                    @if (!!currentRevision?.pictureDataAssignments?.length) {
                        <div class="cm-action-container" (click)="$isDrawing() ? drawing.disable() : ''">
                            @if (isLatestRevision() && $can().delete.pictureRevision({picture: $picture()})) {
                                <a
                                    class="cm-action-icon-container"
                                    matTooltip="Delete revision"
                                    (click)="initDeleteRevision(currentRevision)"
                                    matTooltipPosition="above"
                                >
                                    <i [class.cm-hide]="$isDrawing()" class="far fa-trash cm-action-icon cm-action-icon-trash"></i>
                                </a>
                            }
                            <a class="cm-action-icon-container" (click)="revisionSidebarOpen = true" matTooltip="Revision history" matTooltipPosition="above">
                                <i [class.cm-hide]="$isDrawing()" class="far fa-history cm-action-icon"></i>
                            </a>
                            <a
                                class="cm-action-icon-container"
                                (click)="downloadFile('image.jpg', currentRevision?.pictureDataAssignments?.[0]?.dataObject?.downloadUrl)"
                            >
                                @if ($picture().state === Enums.PictureState.Completed) {
                                    <i
                                        [class.cm-hide]="$isDrawing()"
                                        class="far fa-arrow-to-bottom cm-action-icon cm-download-high-res"
                                        matTooltip="Download high-res picture"
                                        matTooltipPosition="above"
                                    ></i>
                                } @else {
                                    <i
                                        [class.cm-hide]="$isDrawing()"
                                        class="far fa-arrow-to-bottom cm-action-icon"
                                        matTooltip="Download"
                                        matTooltipPosition="above"
                                    ></i>
                                }
                            </a>
                            @if (isLatestRevision()) {
                                <a
                                    class="cm-action-icon-container"
                                    matTooltip="Draw"
                                    (click)="$isDrawing() ? drawing.disable() : drawing.enable(); $event.stopPropagation()"
                                    matTooltipPosition="above"
                                >
                                    <i class="far fa-paint-brush-alt cm-action-icon"></i>
                                </a>
                            }
                        </div>
                    }
                }
            </div>
        </div>
    </div>
</cm-drop-files>
