import {MeshRenderSettings, ObjectId, SceneNodes} from "@src/templates/interfaces/scene-object"
import {BoundsData, MeshData} from "@src/geometry-processing/mesh-data"
import {mergeBounds, transformBounds} from "@src/templates/utils/scene-geometry-utils"
import {WeakCompactUIDTable, parseColor} from "@src/utils/utils"
import {Nodes} from "@src/templates/legacy/template-nodes"
import {NodeUtils} from "@src/templates/legacy/template-node-utils"
import {BuilderInlet, BuilderOutlet, GraphBuilder} from "@src/templates/runtime-graph/graph-builder"
import {Inlet, Outlet, NotReady} from "@src/templates/runtime-graph/slots"
import {TemplateImageData, TypeDescriptors} from "@src/templates/runtime-graph/type-descriptors"
import {Transform, TransformAccessor, TransformState} from "@src/templates/runtime-graph/nodes/transform"
import {TransformColorOverlay} from "@src/templates/runtime-graph/nodes/transform-color-overlay"
import {SolverObjectData} from "@src/templates/runtime-graph/nodes/solver/object-data"
import {SolverData} from "@src/templates/runtime-graph/nodes/solver/data"
import {SolverVariableData} from "@src/templates/runtime-graph/nodes/solver/variable-data"
import {SolverRelationData} from "@src/templates/runtime-graph/nodes/solver/relation-data"
import {MergeSolverData} from "@src/templates/runtime-graph/nodes/merge-solver-data"
import {FilterAndWrapInterface} from "@src/templates/runtime-graph/nodes/filter-and-wrap-interface"
import {GenerateMesh} from "@src/templates/runtime-graph/nodes/generate-mesh"
import {LoadMesh} from "@src/templates/runtime-graph/nodes/load-mesh"
import {MeshDecal} from "@src/templates/runtime-graph/nodes/mesh-decal"
import {LoadGraph} from "@src/templates/runtime-graph/nodes/load-graph"
import {RunTemplate} from "@src/templates/runtime-graph/nodes/run-template"
import {EvaluatedTemplateInputs, EvaluatedTemplateValueType, getTemplateInput} from "@src/templates/legacy/evaluation/template-slots"
import {TemplateContext, makeTemplateNodeEvaluator} from "@src/templates/legacy/evaluation/template-node"
import {GraphBuilderScope} from "@src/templates/runtime-graph/graph-builder-scope"
import {NodeClassImpl, ThisStructID} from "@src/templates/runtime-graph/types"
import {IMaterialData, IMaterialGraph, keyForMaterialData} from "@src/templates/interfaces/material-data"
import {Matrix4, Vector3} from "@src/math"
import {IDataObject} from "@src/templates/interfaces/data-object"
import {ISceneManager, UVOffset} from "@src/templates/interfaces/scene-manager"
import {ObjectData} from "@src/templates/interfaces/object-data"
import {defaultsForToneMapping} from "@src/image-processing/tone-mapping"
import {cameraAutomaticTarget, automaticVerticalTilt} from "@src/templates/utils/camera-utils"

const TD = TypeDescriptors

export interface TransformAccessorListEntry {
    objectId: ObjectId
    transformAccessor: TransformAccessor
}

const compileTemplateDescriptor = {
    templateContext: TD.inlet(TD.Identity<TemplateContext>()),
    graph: TD.inlet(TD.TemplateNode<Nodes.TemplateGraph>()), //TODO: deepCompare for graph?
    inputs: TD.inlet(TD.ShallowJSON<EvaluatedTemplateInputs>()),
    overrides: TD.inlet(TD.JSON<Nodes.TemplateInstance["overrides"]>()),
    topLevelObjectId: TD.inlet(TD.Nullable(TD.Primitive<ObjectId>())),
    templateDepth: TD.inlet(TD.Number),
    exposeClaimedSubTemplateInputs: TD.inlet(TD.Boolean),
    sceneManager: TD.inlet(TD.Identity<ISceneManager>()),
    builder: TD.builder(),
    compiledTemplate: TD.outlet(TD.Function<(instanceToBind: any) => () => void>()),
    activeNodeSet: TD.outlet(TD.Set<Nodes.Node>()),
    externalIdToNodeMap: TD.outlet(TD.Map<string, Nodes.Node>()),
    objectToNodeMap: TD.outlet(TD.Map<ObjectId, Nodes.Node>()),
    nodeToObjectMap: TD.outlet(TD.Map<Nodes.Node, ObjectId>()),
}

export class CompileTemplate implements NodeClassImpl<typeof compileTemplateDescriptor, typeof CompileTemplate> {
    static descriptor = compileTemplateDescriptor
    static uniqueName = "CompileTemplate"
    templateContext!: Inlet<TemplateContext>
    graph!: Inlet<Nodes.TemplateGraph>
    inputs!: Inlet<EvaluatedTemplateInputs>
    overrides: Inlet<Nodes.TemplateInstance["overrides"]>
    topLevelObjectId!: Inlet<ObjectId | null>
    templateDepth!: Inlet<number>
    exposeClaimedSubTemplateInputs!: Inlet<boolean>
    sceneManager!: Inlet<ISceneManager>
    builder!: GraphBuilder
    compiledTemplate!: Outlet<(instanceToBind: any) => () => void>
    activeNodeSet!: Outlet<Set<Nodes.Node>>
    externalIdToNodeMap!: Outlet<Map<string, Nodes.Node>>
    objectToNodeMap!: Outlet<Map<ObjectId, Nodes.Node>>
    nodeToObjectMap!: Outlet<Map<Nodes.Node, ObjectId>>

    private _activeNodeSet = new Set<Nodes.Node>()
    private _externalIdToNodeMap = new Map<string, Nodes.Node>()
    private _nodeToObjectMap = new Map<Nodes.Node, ObjectId>()
    private _objectToNodeMap = new Map<ObjectId, Nodes.Node>()
    private idMap = new WeakCompactUIDTable()

    run() {
        const graph = this.graph
        const templateOverrides = this.overrides
        const topLevelObjectId = this.topLevelObjectId
        const templateDepth = this.templateDepth
        const exposeClaimedSubTemplateInputs = this.exposeClaimedSubTemplateInputs
        const sceneManager = this.sceneManager
        const templateInputs = this.inputs

        if (graph === NotReady) return
        if (templateOverrides === NotReady) return
        if (topLevelObjectId === NotReady) return
        if (templateDepth === NotReady) return
        if (this.templateContext === NotReady) return
        if (exposeClaimedSubTemplateInputs === NotReady) return
        if (sceneManager === NotReady) return
        if (templateInputs === NotReady) return

        const templateContext: TemplateContext = {...this.templateContext} // fields can be overridden by SceneProperties node

        const templateScope = this.builder.scope()

        const templateMatrix = templateScope.input<Matrix4>("matrix")
        const templateSolverObject = templateScope.input<SolverObjectData>("solverObject")

        const activeNodeSet = this._activeNodeSet
        const externalIdToNodeMap = this._externalIdToNodeMap
        activeNodeSet.clear()
        externalIdToNodeMap.clear()
        this._nodeToObjectMap.clear()
        this._objectToNodeMap.clear()

        const lookupByExternalIdPathMap = new Map<Nodes.Node, BuilderOutlet<(x: string[]) => Nodes.Node | null>>()

        const transformAccessorList: BuilderOutlet<TransformAccessorListEntry>[] = []

        const overridesForNode = (node: Nodes.Node) => {
            const externalId = Nodes.getExternalId(node)
            const overrides = externalId != undefined && templateOverrides && templateOverrides[externalId]
            if (overrides) return overrides
            else return {}
        }

        const evalNodeResolvingInstancesAndOverrides = (node: Nodes.Node): Nodes.Node & {$localId: string} => {
            let retNode: Nodes.Node & {$localId: string} = {
                $localId: tev.getLocalId(node),
                ...node,
                ...overridesForNode(node),
            } as any
            while (retNode.type === "instance") {
                const {type: _type, node: _node, ...properties} = retNode
                retNode = {
                    ..._node,
                    ...overridesForNode(_node),
                    ...properties,
                } as any
            }
            return retNode
        }

        const activeConfigVariants: {[configId: string]: string} = {}

        const traverseActive = (node: Nodes.Node) => {
            if (!node) return
            if (node.type === "configGroup") {
                activeNodeSet.add(node)
                let selectedId = getTemplateInput<string>(templateInputs, node) //TODO: default?
                for (const subNode of node.nodes) {
                    if (subNode.type === "configVariant") {
                        if (selectedId === undefined || selectedId === null) {
                            selectedId = Nodes.getExternalId(subNode)
                            // console.log(`No config selected for group ${node.name}. Using first (${subNode.name}) as default.`);
                        }
                        if (Nodes.getExternalId(subNode) === selectedId && selectedId != undefined) {
                            activeConfigVariants[node.id] = selectedId
                            traverseActive(subNode)
                        }
                    } else {
                        traverseActive(subNode)
                    }
                }
            } else if (node.type === "templateGraph") {
                activeNodeSet.add(node)
                node.nodes.forEach(traverseActive)
            } else if (node.type === "configVariant") {
                activeNodeSet.add(node)
                node.nodes.forEach(traverseActive)
            } else if (node.type === "group") {
                if (node.active) {
                    activeNodeSet.add(node)
                    node.nodes.forEach(traverseActive)
                }
            } else {
                activeNodeSet.add(node)
            }
        }

        if (graph) {
            traverseActive(graph)
        }

        let traverse: (node: Nodes.Node) => void

        const solverVariables: BuilderInlet<SolverVariableData>[] = []
        const solverObjects: BuilderInlet<SolverObjectData>[] = []
        const solverRelations: BuilderInlet<SolverRelationData>[] = []
        const childSolverData: BuilderInlet<SolverData>[] = []
        const preDisplayLists: BuilderInlet<SceneNodes.SceneNode[]>[] = []
        const displayLists: BuilderInlet<SceneNodes.SceneNode[]>[] = []
        const thisPreDisplayList: BuilderInlet<SceneNodes.SceneNode>[] = []
        const thisDisplayList: BuilderInlet<SceneNodes.SceneNode>[] = []
        const descriptorLists: BuilderInlet<Nodes.Meta.InterfaceDescriptor[]>[] = []
        const thisDescriptorList: BuilderInlet<Nodes.Meta.InterfaceDescriptor>[] = []
        const templateOutputs: {[id: string]: BuilderInlet<any>} = {}

        const setupTransform = (scope: GraphBuilderScope, node: Nodes.Node) => {
            if (NodeUtils.isTransformable(node)) {
                const transform = scope.node(Transform, {
                    state: scope.state(TransformState, "transformState"),
                    defaultTransform: sceneManager.defaultTransformForObject(node)?.toArray(),
                    lockedTransform: node.lockedTransform ? convertMatrix4_JSON(node.lockedTransform) : undefined,
                    parentMatrix: templateMatrix,
                })
                const solverObject = scope.struct<SolverObjectData>("SolverObjectData", {
                    id: ThisStructID,
                    transformAccessor: transform.accessor,
                })
                solverObjects.push(solverObject)
                return [transform.matrix, transform.accessor, solverObject] as const
            } else {
                throw new Error(`Node type ${node.type} is not transformable`)
            }
        }

        const setupTransformAccessor = (scope: GraphBuilderScope, objId: ObjectId, transformAccessor: BuilderInlet<TransformAccessor>) => {
            transformAccessorList.push(
                scope.struct("TransformAccessorListEntry", {
                    objectId: objId,
                    transformAccessor,
                }),
            )
        }

        const readyList: BuilderOutlet<any>[] = []
        const allBounds: BuilderOutlet<BoundsData>[] = []

        const setupObject = (
            scope: GraphBuilderScope,
            typeName: string,
            node: Nodes.Node,
            originalNode: Nodes.Node,
            meshData: BuilderInlet<MeshData> | undefined,
        ) => {
            const objId = scope.genStructId(typeName)
            this._objectToNodeMap.set(objId, originalNode)
            this._nodeToObjectMap.set(originalNode, objId)
            const [transformMatrix, transformAccessor, solverObject] = setupTransform(scope, node)

            let bounds: BuilderInlet<BoundsData>
            if (meshData) {
                bounds = scope.get(meshData, "bounds")
            } else {
                //TODO: proper bounds for planes, etc.
                bounds = {
                    centroid: [0, 0, 0],
                    surfaceArea: 0,
                    aabb: [
                        [0, 0, 0],
                        [0, 0, 0],
                    ],
                    radii: {xy: 0, xz: 0, yz: 0, xyz: 0},
                }
            }

            if (bounds) {
                bounds = scope.lambda(scope.tuple(bounds, transformMatrix), ([bounds, matrix]) => transformBounds(bounds, matrix), "transformBounds")
                allBounds.push(bounds)
            }

            setupTransformAccessor(scope, objId, transformAccessor)

            const objectData = scope.struct<ObjectData>("ObjectData", {
                matrix: transformMatrix,
                solverObject,
                bounds,
                meshData,
            })

            templateScope.alias(objectData, `objectData-${tev.getLocalId(node)}`)

            return {
                $id: objId,
                id: objId,
                topLevelObjectId: topLevelObjectId ?? objId,
                transform: transformMatrix,
            }
        }

        const emitObject = (scope: GraphBuilderScope, node: Nodes.Node, sceneObj: BuilderInlet<SceneNodes.SceneNode>, preDisplay = false) => {
            if (preDisplay) {
                thisPreDisplayList.push(sceneObj)
            } else {
                thisDisplayList.push(sceneObj)
            }
        }

        const tev = makeTemplateNodeEvaluator({
            idMap: this.idMap,
            templateScope,
            templateContext,
            ambientInputs: templateInputs,
            readyList,
            activeNodeSet,
            solverVariables,
        })

        traverse = (node: Nodes.Node) => {
            //console.log("Traverse", node);
            if (!activeNodeSet.has(node)) {
                return
            }
            const originalNode = node
            const _externalId = Nodes.getExternalId(originalNode)
            const localId = this.idMap.intern(originalNode) // has to be the _original_ node object, not the result of evalNodeResolvingInstancesAndOverrides!
            if (_externalId != undefined) {
                externalIdToNodeMap.set(_externalId, originalNode)
            }
            const scope = templateScope.scope(`${node.type}@${localId}`)
            // evaluate all properties and overrides
            node = evalNodeResolvingInstancesAndOverrides(node)
            //console.log("traverse", node);

            if (node.type === "templateGraph") {
                if (originalNode === graph) {
                    // this is the root
                    node.nodes.forEach(traverse)
                } else {
                    // skip internal template definitions
                }
            } else if (node.type === "configGroup") {
                const variantInfo: Nodes.Meta.VariantInfo[] = []
                const configId = Nodes.getExternalId(node)
                if (configId != undefined) {
                    thisDescriptorList.push({
                        id: configId,
                        name: node.name,
                        interfaceType: "input",
                        inputType: "config",
                        variants: variantInfo,
                        // tags: node.tags,
                        value: activeConfigVariants[configId],
                        displayWithLabels: node.displayWithLabels,
                    })
                }

                for (const subNode of node.nodes) {
                    if (subNode.type === "configVariant") {
                        const variantId = Nodes.getExternalId(subNode)
                        if (variantId != undefined) {
                            variantInfo.push({
                                id: variantId,
                                name: subNode.name,
                                iconColor: subNode.iconColor,
                                iconDataObjectId: subNode.iconDataObjectId,
                            })
                        }
                    }
                    traverse(subNode)
                }
            } else if (node.type === "configVariant") {
                node.nodes.forEach(traverse)
            } else if (node.type === "templateInput") {
                const inputId = Nodes.getExternalId(node)
                if (inputId != undefined) {
                    const emitInputForType = <TK extends EvaluatedTemplateValueType, T>(type: TK, name: string, tags?: Nodes.TagReference[], value?: T) => {
                        thisDescriptorList.push(
                            scope.struct<Nodes.Meta.InterfaceDescriptor>("InterfaceDescriptor", {
                                id: inputId,
                                name,
                                interfaceType: "input",
                                inputType: type as any, //TODO: fix type checking
                                tags,
                                value, //NOTE: this may be an outlet!
                            }),
                        )
                    }
                    if (node.inputType === "material") {
                        emitInputForType(
                            node.inputType,
                            node.name,
                            node.tags,
                            getTemplateInput<IMaterialGraph>(templateInputs, node) ?? tev.evalMaterial(scope, node.default as Nodes.Material),
                        )
                    } else if (node.inputType === "template") {
                        emitInputForType(
                            node.inputType,
                            node.name,
                            node.tags,
                            getTemplateInput<Nodes.TemplateGraph>(templateInputs, node) ??
                                tev.evalTemplateDefinition(scope, node.default as Nodes.TemplateDefinition),
                        )
                    } else if (node.inputType === "object") {
                        emitInputForType(
                            node.inputType,
                            node.name,
                            node.tags,
                            getTemplateInput<Nodes.Object>(templateInputs, node) ?? tev.evalObjectData(scope, node.default as Nodes.Object),
                        )
                    } else if (node.inputType === "image") {
                        emitInputForType(
                            node.inputType,
                            node.name,
                            node.tags,
                            getTemplateInput<TemplateImageData>(templateInputs, node) ?? tev.evalImage(scope, node.default as Nodes.Image),
                        )
                    } else if (node.inputType === "string") {
                        emitInputForType(
                            node.inputType,
                            node.name,
                            node.tags,
                            getTemplateInput<string>(templateInputs, node) ?? tev.evalString(scope, node.default),
                        )
                    } else if (node.inputType === "number") {
                        emitInputForType(
                            node.inputType,
                            node.name,
                            node.tags,
                            getTemplateInput<number>(templateInputs, node) ?? tev.evalNumber(scope, node.default),
                        )
                    } else if (node.inputType === "boolean") {
                        emitInputForType(
                            node.inputType,
                            node.name,
                            node.tags,
                            getTemplateInput<boolean>(templateInputs, node) ?? tev.evalBoolean(scope, node.default),
                        )
                    } else {
                        console.error(`Unrecognized input type: ${(node as Nodes.TemplateInput).inputType}`)
                    }
                }
            } else if (node.type === "materialReference") {
                const externalId = Nodes.getExternalId(node)
                if (node.allowOverride && externalId != undefined) {
                    // console.warn(`MaterialReference.allowOverride is deprecated! Exposing as input ${externalId}: ${node.name}`);
                    //TODO: deprecate allowOverride
                    const materialGraph = getTemplateInput<IMaterialGraph>(templateInputs, node) ?? tev.evalMaterial(scope, node)
                    thisDescriptorList.push(
                        scope.struct<Nodes.Meta.MaterialInputInfo>("InterfaceDescriptor", {
                            id: externalId,
                            name: "Custom Material",
                            interfaceType: "input",
                            inputType: "material",
                            //@ts-ignore
                            value: materialGraph, //NOTE: this may be an outlet!
                        }),
                    )
                }
            } else if (node.type === "group") {
                if (tev.evalBoolean(scope, node.active) === true) {
                    node.nodes.forEach(traverse)
                }
            } else if (node.type === "mesh" || node.type === "proceduralMesh") {
                const displacementImage = tev.evalSwitch<Nodes.TextureReference | Nodes.DataObjectReference>(node.displacementTexture)

                const maxSubdivisionLimit = 5
                const defaultDeviceSubdivisionLimit = sceneManager.isMobileDevice() ? 0 : maxSubdivisionLimit

                // note that templateContext.maxSubdivisionLevel may be undefined, which will turn to NaN if passed to Math.min
                let displacementDataObject: BuilderInlet<IDataObject> | undefined = undefined
                if (displacementImage) {
                    if (
                        displacementImage.type === "dataObjectReference" &&
                        displacementImage.dataObjectId !== undefined &&
                        displacementImage.dataObjectId !== null
                    ) {
                        displacementDataObject = tev.fetchDataObject(scope, displacementImage.dataObjectId)
                    } else if (displacementImage.type === "textureReference") {
                        console.warn("TODO: resolve displacement textureReference")
                    }
                }

                const assignmentEntries: BuilderOutlet<[number, IMaterialData]>[] = []
                for (const [key, assignment] of Object.entries(node.materialAssignments)) {
                    if (!assignment) continue
                    let [materialGraph, matGraphInvalid] = scope.branch(tev.evalMaterial(scope, assignment.node ?? null))
                    if (assignment.horizontalOffset || assignment.verticalOffset || assignment.rotation) {
                        const offset = scope.struct<UVOffset>("UVOffset", {
                            horizontal: tev.evalNumber(scope, assignment.horizontalOffset ?? 0),
                            vertical: tev.evalNumber(scope, assignment.verticalOffset ?? 0),
                            rotation: tev.evalNumber(scope, assignment.rotation ?? 0),
                        })
                        materialGraph = scope.lambda(
                            scope.tuple(materialGraph, offset),
                            ([materialGraph, offset]) => sceneManager.transformOffsetUVs(materialGraph, offset),
                            `transformOffsetUVs-${key}`,
                        )
                    }
                    const materialData = scope.struct<IMaterialData>("IMaterialData", {
                        name: scope.lambda(
                            materialGraph,
                            (materialGraph) => ("name" in materialGraph ? materialGraph.name : "(no name)"),
                            `materialName-${key}`,
                        ),
                        materialGraph,
                        side: assignment.side ?? "front",
                    })
                    //TODO: merge redundant PreloadMaterial nodes
                    thisPreDisplayList.push(
                        scope.struct<SceneNodes.PreloadMaterial>("PreloadMaterial", {
                            type: "PreloadMaterial",
                            id: scope.lambda(materialData, keyForMaterialData, `preloadMatKey-${key}`),
                            materialData,
                        }),
                    )
                    //@ts-ignore
                    assignmentEntries.push(scope.phi(scope.tuple(parseInt(key), materialData), matGraphInvalid))
                }

                const matMap = scope.entriesToMap(scope.filter(scope.list(assignmentEntries, "matAssignmentList")))

                let meshData: BuilderInlet<MeshData>
                if (node.type === "proceduralMesh") {
                    ;({meshData} = scope.node(GenerateMesh, {
                        sceneManager: templateContext.sceneManager,
                        graphPresetName: node.geometryGraph,
                        parameters: node.parameters,
                    }))
                } else {
                    const displaySubdivLevel = Math.min(
                        node.subdivisionRenderIterations ?? 0,
                        templateContext.maxSubdivisionLevel ?? defaultDeviceSubdivisionLimit,
                        maxSubdivisionLimit,
                    )
                    ;({meshData} = scope.node(LoadMesh, {
                        sceneManager: templateContext.sceneManager,
                        drcDataObject: tev.fetchDataObject(scope, node.drcDataObjectId),
                        plyDataObjectId: node.plyDataObjectId ?? null,
                        displaySubdivisionLevel: displaySubdivLevel,
                        renderSubdivisionLevel: node.subdivisionRenderIterations ?? 0,
                    }))
                }
                readyList.push(meshData)

                const objProps = setupObject(scope, "Mesh", node, originalNode, meshData)

                const meshRenderSettings = scope.struct<MeshRenderSettings>("MeshRenderSettings", {
                    displacementUvChannel: node.displacementUvChannel,
                    displacementMin: node.displacementMin,
                    displacementMax: node.displacementMax,
                    displacementDataObject,
                    // cryptoMatteObjectName: topLevelObjectId ?? objProps.id,
                    cryptoMatteAssetName: topLevelObjectId ?? undefined,
                })
                const sceneMesh = scope.struct<SceneNodes.Mesh>("Mesh", {
                    type: "Mesh",
                    ...objProps,
                    meshData,
                    materialMap: matMap,
                    meshRenderSettings,
                    visibleDirectly: node.visibleDirectly ?? true,
                    visibleInReflections: node.visibleInReflections ?? true,
                    visibleInRefractions: node.visibleInRefractions ?? true,
                    receiveRealtimeShadows: true,
                    castRealtimeShadows: true,
                    isDecal: false,
                    isProcedural: node.type === "proceduralMesh",
                })
                emitObject(scope, node, sceneMesh)
            } else if (node.type === "meshDecal") {
                const decalName = node.name
                const meshNode = node.mesh
                if (meshNode && activeNodeSet.has(meshNode)) {
                    const meshObjectData = tev.evalObjectData(scope, meshNode)

                    const maskDataObjectId = node.mask?.type === "dataObjectReference" ? node.mask.dataObjectId : undefined
                    const maskDataObject = maskDataObjectId ? tev.fetchDataObject(scope, maskDataObjectId) : null

                    const colorDataObjectId = node.color?.type === "dataObjectReference" ? node.color.dataObjectId : undefined
                    const colorDataObject = colorDataObjectId ? tev.fetchDataObject(scope, colorDataObjectId) : null

                    const size = computeConstrainedSizeFromDataObject(scope, node.size, maskDataObject ?? colorDataObject) // prefer getting size defaults from mask, rather than overlay image

                    const {meshData} = scope.node(MeshDecal, {
                        sceneManager: templateContext.sceneManager,
                        //@ts-ignore
                        inputMeshData: scope.get(meshObjectData, "meshData"),
                        offset: node.offset,
                        rotation: node.rotation * (Math.PI / 180),
                        distance: node.distance,
                        size,
                    })

                    readyList.push(meshData)

                    let materialMap: BuilderInlet<Map<number, IMaterialData>>

                    //@ts-ignore
                    let matGraph = tev.evalMaterial(scope, node.material)
                    if (matGraph) {
                        const invert = node.invertMask
                        const DEFAULT_DECAL_MASK_TYPE = "binary"
                        const alphaMaskThreshold = getAlphaMaskThresholdFromDecalMaskType(node.maskType ?? DEFAULT_DECAL_MASK_TYPE)

                        if (colorDataObject) {
                            matGraph = scope.node(TransformColorOverlay, {
                                //@ts-ignore
                                material: matGraph,
                                image: scope.struct<TemplateImageData>("TemplateImageData", {dataObject: colorDataObject}),
                                size,
                                useAlpha: false,
                                sceneManager,
                            }).outputMaterial
                        }

                        materialMap = scope.lambda(
                            scope.tuple(matGraph, maskDataObject ?? scope.valueWithoutType(null), colorDataObject ?? scope.valueWithoutType(null), size),
                            ([matGraph, maskDataObject, colorDataObject, size]) => {
                                const materialDataWithMask: IMaterialData = {
                                    //@ts-ignore
                                    name: matGraph.name,
                                    //@ts-ignore
                                    materialGraph: sceneManager.transformDecalMask(matGraph, {
                                        maskImage: maskDataObject,
                                        colorOverlayImage: colorDataObject,
                                        widthCm: size[0],
                                        heightCm: size[1],
                                        invert,
                                    }),
                                    side: "front",
                                    alphaMaskThreshold,
                                }
                                return new Map([[0, materialDataWithMask]])
                            },
                            "materialMap",
                        )
                    }

                    const objId = scope.genStructId("Mesh")
                    const sceneMesh = scope.struct<SceneNodes.Mesh>("Mesh", {
                        type: "Mesh",
                        $id: objId,
                        id: objId,
                        topLevelObjectId: topLevelObjectId ?? objId,
                        meshRenderSettings: scope.struct<MeshRenderSettings>("MeshRenderSettings", {}),
                        meshData,
                        //@ts-ignore
                        materialMap,
                        //@ts-ignore
                        transform: scope.get(meshObjectData, "matrix"),
                        visibleDirectly: meshNode.visibleDirectly ?? true,
                        visibleInReflections: meshNode.visibleInReflections ?? true,
                        visibleInRefractions: meshNode.visibleInRefractions ?? true,
                        receiveRealtimeShadows: true,
                        castRealtimeShadows: true,
                        isDecal: true,
                        isProcedural: true,
                    })

                    this._objectToNodeMap.set(objId, originalNode)
                    this._nodeToObjectMap.set(originalNode, objId)
                    emitObject(scope, node, sceneMesh)
                }
            } else if (node.type === "templateInstance" || node.type === "templateReference") {
                const externalId = Nodes.getExternalId(node)
                const [inputs, claimedInputIds] = tev.evalTemplateInputs(scope, node)

                let graph: BuilderInlet<Nodes.TemplateGraph | null>
                if (node.type === "templateReference") {
                    graph = scope.node(LoadGraph, {
                        sceneManager: templateContext.sceneManager,
                        templateRevisionId: node.templateRevisionId,
                    }).graph
                } else {
                    graph = node.template ? tev.evalTemplateDefinition(scope, node.template) : null
                }

                const [transformMatrix, transformAccessor, solverObject] = setupTransform(scope, node)
                const objId = scope.genNodeId(RunTemplate)
                const compileTemplate = scope.node(CompileTemplate, {
                    //@ts-ignore
                    graph,
                    inputs,
                    overrides: (node as Nodes.TemplateOrInstance).overrides,
                    templateContext,
                    topLevelObjectId: topLevelObjectId ?? objId,
                    templateDepth: templateDepth + 1,
                    exposeClaimedSubTemplateInputs: false,
                    sceneManager,
                })
                const runTemplate = scope.node(RunTemplate, {
                    $id: objId,
                    compiledTemplate: compileTemplate.compiledTemplate,
                    matrix: transformMatrix,
                    solverObject,
                })
                readyList.push(runTemplate.ready)
                preDisplayLists.push(runTemplate.preDisplayList)
                displayLists.push(runTemplate.displayList)
                childSolverData.push(runTemplate.solverData)
                lookupByExternalIdPathMap.set(node, runTemplate.lookupByExternalIdPath)

                descriptorLists.push(
                    scope.node(FilterAndWrapInterface, {
                        interface: runTemplate.descriptorList,
                        wrapWithId: externalId,
                        claimedInputIds,
                        includeClaimed: exposeClaimedSubTemplateInputs,
                    }).output,
                )

                this._objectToNodeMap.set(objId, originalNode)
                this._nodeToObjectMap.set(originalNode, objId)
                setupTransformAccessor(scope, objId, transformAccessor)
                allBounds.push(scope.get(runTemplate.objectData, "bounds"))
                templateScope.alias(runTemplate.outputs, `templateOutputs-${localId}`)
                templateScope.alias(runTemplate.objectData, `objectData-${localId}`)
            } else if (node.type === "areaLight") {
                const objProps = setupObject(scope, "AreaLight", node, originalNode, undefined)
                const light = scope.struct<SceneNodes.AreaLight>("AreaLight", {
                    type: "AreaLight",
                    ...objProps,
                    width: node.width,
                    height: node.height,
                    color: node.color as [number, number, number],
                    on: node.on,
                    intensity: node.intensity,
                    directionality: node.directionality,
                    visibleDirectly: node.visibleDirectly ?? true,
                    visibleInReflections: node.visibleInReflections ?? true,
                    visibleInRefractions: node.visibleInRefractions ?? true,
                    target: Vector3.fromArray(convertPosition_JSON(node.target)),
                    targeted: true,
                    transparent: node.transparent ?? false,
                })
                emitObject(scope, node, light, true)
            } else if (node.type === "lightPortal") {
                const objProps = setupObject(scope, "LightPortal", node, originalNode, undefined)
                const light = scope.struct<SceneNodes.LightPortal>("LightPortal", {
                    type: "LightPortal",
                    ...objProps,
                    width: node.width,
                    height: node.height,
                })
                emitObject(scope, node, light)
            } else if (node.type === "camera") {
                const objProps = setupObject(scope, "Camera", node, originalNode, undefined)
                //@ts-ignore
                const autoTargetBounds = node.automaticTarget ? scope.get(tev.evalObjectData(scope, node.automaticTarget), "bounds") : undefined
                let target: BuilderInlet<Vector3>
                if (autoTargetBounds) {
                    //@ts-ignore
                    target = scope.lambda(autoTargetBounds, (bounds) => Vector3.fromArray(bounds.centroid), "autoTargetBounds")
                } else {
                    target = Vector3.fromArray(convertPosition_JSON(node.target))
                }
                const aspectRatio = node.resolutionX && node.resolutionY ? node.resolutionX / node.resolutionY : 1
                const viewportSizeParameter: [number, number] | undefined = templateContext.sceneManager.getRootNode().parameters?.$viewportSize // we need to read this param from the root node as this camera might be specified in a sub-template, but the param is only set to the root
                const uiSize = viewportSizeParameter ?? [node.resolutionX ?? 0, node.resolutionY ?? 0]
                if (uiSize[0] <= 0 || uiSize[1] <= 0) {
                    console.warn(`Invalid camera viewport size: ${uiSize}`)
                    uiSize[0] = uiSize[1] = 1000
                }
                const focalLength =
                    node.zoomFactor != null
                        ? adjustFocalLengthForZoomFactor(node.filmGauge, node.focalLength, node.zoomFactor, uiSize[0], uiSize[1])
                        : node.focalLength
                let focalDistance: BuilderInlet<number> = node.focalDistance
                let shiftY: BuilderInlet<number> = node.shiftY
                if (autoTargetBounds) {
                    const transformAndFocus = scope.lambda(
                        scope.tuple(objProps.transform, autoTargetBounds, node.filmGauge, focalLength, uiSize),
                        ([transform, bounds, filmGauge, focalLength, uiSize]) => {
                            const position = transform.getTranslation()
                            //@ts-ignore
                            const target = Vector3.fromArray(bounds.centroid)
                            //@ts-ignore
                            const matrix = cameraAutomaticTarget(position, target, bounds.aabb, focalLength, filmGauge, uiSize)
                            return [matrix, target, matrix.getTranslation().sub(target).norm()] as const
                        },
                        "transformAndFocus",
                    )
                    objProps.transform = scope.get(transformAndFocus, 0)
                    target = scope.get(transformAndFocus, 1)
                    focalDistance = scope.get(transformAndFocus, 2)
                }
                if (node.automaticVerticalTilt) {
                    const matrixAndShiftY = scope.lambda(
                        scope.tuple(objProps.transform, node.filmGauge, focalLength, shiftY, uiSize),
                        ([transform, filmGauge, focalLength, shiftY, [width, height]]) => {
                            const [newTransform, additionalShiftY] = automaticVerticalTilt(transform, filmGauge, focalLength, width, height)
                            return [newTransform, shiftY + additionalShiftY] as const
                        },
                        "matrixAndShiftY",
                    )
                    objProps.transform = scope.get(matrixAndShiftY, 0)
                    shiftY = scope.get(matrixAndShiftY, 1)
                }
                const camera = scope.struct<SceneNodes.Camera>("Camera", {
                    type: "Camera",
                    ...objProps,
                    name: node.name,
                    focalLength,
                    focalDistance,
                    autoFocus: false,
                    aspectRatio,
                    target,
                    targeted: true,
                    filmGauge: node.filmGauge,
                    fStop: node.fStop,
                    exposure: node.exposure,
                    toneMapping: node.toneMapping ? scope.struct<Nodes.ToneMapping>("ToneMapping", node.toneMapping) : defaultsForToneMapping("linear"), //TODO: better way to get change detection for nested structs
                    shiftX: node.shiftX,
                    shiftY,
                    nearClip: node.nearClip,
                    farClip: node.farClip,
                    minDistance: node.minDistance,
                    maxDistance: node.maxDistance,
                    minPolarAngle: node.minPolarAngle,
                    maxPolarAngle: node.maxPolarAngle,
                    minAzimuthAngle: node.minAzimuthAngle ?? undefined,
                    maxAzimuthAngle: node.maxAzimuthAngle ?? undefined,
                    enablePanning: node.enablePanning,
                    screenSpacePanning: node.screenSpacePanning,
                })
                emitObject(scope, node, camera)
            } else if (node.type === "hdriLight") {
                if (node.hdri) {
                    const env = scope.struct<SceneNodes.Environment>("Environment", {
                        type: "Environment",
                        id: ThisStructID,
                        clampHighlights: node.clampHighlights,
                        intensity: node.intensity,
                        rotation: Vector3.fromArray([node.rotation[0] ?? 0, node.rotation[1] ?? 0, node.rotation[2] ?? 0]),
                        mirror: node.mirror ?? false,
                        envData: {
                            type: "hdri",
                            hdriID: node.hdri.hdriId,
                        },
                        priority: templateDepth,
                    })
                    thisPreDisplayList.push(env)
                }
            } else if (node.type === "sceneProperties") {
                // (handled above)
            } else if (node.type === "planeGuide") {
                const objProps = setupObject(scope, "Rectangle", node, originalNode, undefined)
                const rect = scope.struct<SceneNodes.Rectangle>("Rectangle", {
                    type: "Rectangle",
                    ...objProps,
                    width: node.width,
                    height: node.height,
                })
                emitObject(scope, node, rect)
            } else if (node.type === "pointGuide") {
                const objProps = setupObject(scope, "Point", node, originalNode, undefined)
                const point = scope.struct<SceneNodes.Point>("Point", {
                    type: "Point",
                    ...objProps,
                    size: 1,
                })
                emitObject(scope, node, point)
            } else if (node.type === "annotation") {
                if (templateContext.showAnnotations ?? true) {
                    const objProps = setupObject(scope, "Rectangle", node, originalNode, undefined)
                    const annotation = scope.struct<SceneNodes.Annotation>("Annotation", {
                        type: "Annotation",
                        ...objProps,
                        label: node.label,
                        description: node.description,
                        annotationID: Nodes.getExternalId(node),
                    })
                    emitObject(scope, node, annotation)
                }
            } else if (NodeUtils.isRelation(node)) {
                if (node.type === "attachSurfaces") {
                    //TODO:
                } else if (node.type === "rigidRelation") {
                    const [targetA, targetAInvalid] = scope.branch(tev.evalObjectData(scope, node.targetA ?? null))
                    const [targetB, targetBInvalid] = scope.branch(tev.evalObjectData(scope, node.targetB ?? null))
                    const rel = scope.struct<SolverRelationData>("SolverRelationData", {
                        id: ThisStructID,
                        translation: tev.evalTranslation(scope, node.translation),
                        rotation: tev.evalRotation(scope, node.rotation),
                        objA: scope.get(targetA, "solverObject"),
                        objB: scope.get(targetB, "solverObject"),
                        //@ts-ignore
                        surfA: null,
                        //@ts-ignore
                        surfB: null,
                    })
                    //@ts-ignore
                    solverRelations.push(scope.phi(rel, targetAInvalid, targetBInvalid))
                }
            } else if (node.type === "templateExport") {
                const srcNode = tev.evalSwitch<Nodes.Node>(node.node)
                const externalId = Nodes.getExternalId(node)
                if (srcNode && externalId != undefined) {
                    const emitOutputForType = <TK extends EvaluatedTemplateValueType, T>(type: TK, name: string, tags?: Nodes.TagReference[], value?: T) => {
                        templateOutputs[`output-${type}-${externalId}`] = value
                        thisDescriptorList.push(
                            scope.struct<Nodes.Meta.InterfaceDescriptor>("InterfaceDescriptor", {
                                id: externalId,
                                name,
                                interfaceType: "output",
                                outputType: type as any, //TODO: fix type checking
                                tags,
                                value, //NOTE: this may be an outlet!
                            }),
                        )
                    }
                    if (NodeUtils.resolvesToObject(srcNode)) {
                        emitOutputForType("object", node.name, node.tags, tev.evalObjectData(scope, srcNode))
                    } else if (NodeUtils.resolvesToMaterial(srcNode)) {
                        emitOutputForType("material", node.name, node.tags, tev.evalMaterial(scope, srcNode))
                    } else if (NodeUtils.resolvesToTemplateDefinition(srcNode)) {
                        emitOutputForType("template", node.name, node.tags, tev.evalTemplateDefinition(scope, srcNode))
                    } else if (NodeUtils.resolvesToImage(srcNode)) {
                        emitOutputForType("image", node.name, node.tags, tev.evalImage(scope, srcNode))
                    } else if (NodeUtils.resolvesToString(srcNode)) {
                        emitOutputForType("string", node.name, node.tags, tev.evalString(scope, srcNode))
                    } else if (NodeUtils.resolvesToNumber(srcNode)) {
                        emitOutputForType("number", node.name, node.tags, tev.evalNumber(scope, srcNode))
                    } else if (NodeUtils.resolvesToBoolean(srcNode)) {
                        emitOutputForType("boolean", node.name, node.tags, tev.evalBoolean(scope, srcNode))
                    } else {
                        //TODO:
                        console.error(`Unrecognized output type: ${srcNode.type}`)
                    }
                }
            } else if (NodeUtils.isRender(node)) {
                thisDisplayList.push(
                    templateScope.struct<SceneNodes.RenderSettings>("RenderSettings", {
                        id: ThisStructID,
                        type: "RenderSettings",
                        width: tev.evalNumber(scope, node.width),
                        height: tev.evalNumber(scope, node.height),
                        samples: tev.evalNumber(scope, node.samples),
                    }),
                )
            } else if (NodeUtils.isPostProcessRender(node)) {
                thisDisplayList.push(
                    templateScope.struct<SceneNodes.RenderPostProcessingSettings>("RenderPostProcessingSettings", {
                        id: ThisStructID,
                        type: "RenderPostProcessingSettings",
                        mode: node.mode,
                        exposure: scope.lambda(tev.evalNumber(scope, node.exposure), (ev) => Math.pow(2, ev ?? 0), "exposure"),
                        whiteBalance: tev.evalNumber(scope, node.whiteBalance),
                        toneMapping: node.toneMapping ? scope.struct<Nodes.ToneMapping>("ToneMapping", node.toneMapping) : defaultsForToneMapping("linear"), //TODO: better way to get change detection for nested structs
                        lutUrl: node.lutUrl ?? undefined,
                        transparent: node.transparent,
                        composite: node.composite,
                        backgroundColor: node.backgroundColor ? parseColor(node.backgroundColor) : undefined,
                        processShadows: node.processShadows,
                        shadowInner: node.shadowInner ?? undefined,
                        shadowOuter: node.shadowOuter,
                        shadowFalloff: node.shadowFalloff,
                        autoCrop: node.autoCrop,
                        autoCropMargin: node.autoCropMargin,
                    }),
                )
            }
        }
        if (graph) {
            // deal with scene properties first, so that modified templateContext is valid for all children
            const scenePropertiesNode = NodeUtils.findSceneProperties(graph)
            if (scenePropertiesNode) {
                const opt = templateScope.struct<SceneNodes.SceneOptions>("SceneOptions", {
                    type: "SceneOptions",
                    id: ThisStructID,
                    backgroundColor: scenePropertiesNode.backgroundColor ?? undefined,
                    textureResolution: scenePropertiesNode.textureResolution,
                    textureFiltering: scenePropertiesNode.textureFiltering,
                    environmentMapMode: scenePropertiesNode.environmentMapMode,
                    shadowCatcherFalloff: scenePropertiesNode.shadowCatcherFalloff,
                    enableAdaptiveSubdivision: scenePropertiesNode.enableAdaptiveSubdivision,
                    enableRealtimeShadows: true,
                    enableRealtimeLights: true,
                    enableRealtimeMaterials: true,
                })
                thisPreDisplayList.push(opt)
                templateContext.maxSubdivisionLevel = sceneManager.isMobileDevice()
                    ? scenePropertiesNode.maxSubdivisionLevelOnMobile
                    : scenePropertiesNode.maxSubdivisionLevel
                if (scenePropertiesNode.showAnnotations !== undefined) {
                    templateContext.showAnnotations = scenePropertiesNode.showAnnotations
                }
            }
            traverse(graph)
        }
        const preDisplayList = templateScope.merge([templateScope.sparseList(thisPreDisplayList, "thisPreDisplayList"), ...preDisplayLists], "preDisplayList")
        const displayList = templateScope.merge([templateScope.list(thisDisplayList, "thisDisplayList"), ...displayLists], "displayList")
        const descriptorList = templateScope.merge([templateScope.list(thisDescriptorList, "thisDescriptorList"), ...descriptorLists], "descriptorList")
        const thisSolverData = templateScope.struct<SolverData>("SolverData", {
            objects: templateScope.list(solverObjects),
            relations: templateScope.list(solverRelations),
            variables: templateScope.list(solverVariables),
        })
        const mergeSolverData = templateScope.node(MergeSolverData, {
            input: templateScope.list([thisSolverData, ...childSolverData]),
        }).output

        const ready = templateScope.and(readyList)

        const mergedBounds = templateScope.lambda(templateScope.list(allBounds), (allBounds) => mergeBounds(allBounds), "mergedBounds")

        const objectData = templateScope.group<ObjectData>("ObjectData", {
            solverObject: templateSolverObject,
            matrix: templateMatrix,
            bounds: mergedBounds,
        })

        templateScope.output("ready", ready)
        templateScope.output("preDisplayList", preDisplayList)
        templateScope.output("displayList", displayList)
        templateScope.output("descriptorList", descriptorList)
        templateScope.output("solverData", mergeSolverData)
        templateScope.output("outputs", templateScope.record(templateOutputs, "outputDict"))
        templateScope.output("objectData", objectData)
        templateScope.output("transformAccessorList", templateScope.list(transformAccessorList))
        const childLookupFnKeys = [...lookupByExternalIdPathMap.keys()]
        templateScope.output(
            "lookupByExternalIdPath",
            templateScope.lambda(
                templateScope.list([...lookupByExternalIdPathMap.values()]),
                (childLookupFns) => {
                    return (path: string[]) => {
                        const shiftedPath = path.shift()
                        if (shiftedPath === undefined) {
                            return false
                        } else {
                            const childNode = this._externalIdToNodeMap.get(shiftedPath)
                            if (!childNode) return null
                            else if (path.length === 1) return childNode
                            const idx = childLookupFnKeys.indexOf(childNode)
                            if (idx < 0) return false
                            return childLookupFns[idx](path)
                        }
                    }
                },
                "lookupByExternalIdPath",
            ),
        )

        const compiledTemplate = this.builder.finalizeChanges()

        this.compiledTemplate.emit(compiledTemplate)
        this.activeNodeSet.emit(this._activeNodeSet)
        this.externalIdToNodeMap.emit(this._externalIdToNodeMap)
        this.objectToNodeMap.emit(this._objectToNodeMap)
        this.nodeToObjectMap.emit(this._nodeToObjectMap)
    }
}

function adjustFocalLengthForZoomFactor(filmGauge: number, focalLength: number, zoomFactor: number, width: number, height: number): number {
    const maxFOV = 175.0 * (Math.PI / 180.0)
    const aspect = width > height ? width / height : height / width
    const origFOV = 2 * Math.atan(filmGauge / aspect / (2 * focalLength))
    const newFOV = Math.min(origFOV / Math.max(0.01, zoomFactor), maxFOV)
    return filmGauge / aspect / (2 * Math.tan(newFOV / 2))
}

function stringOrNumberToNumber(item: string | number): number {
    return typeof item === "string" ? Number(item) : item
}

function convertMatrix4_JSON(matrix: (number | string)[]): number[] {
    return matrix.map((x) => stringOrNumberToNumber(x))
}

function convertPosition_JSON(position: [number | string, number | string, number | string]): [number, number, number] {
    return [stringOrNumberToNumber(position[0]), stringOrNumberToNumber(position[1]), stringOrNumberToNumber(position[2])]
}

function getAlphaMaskThresholdFromDecalMaskType(decalMaskType: Nodes.DecalMaskType) {
    switch (decalMaskType) {
        case "binary":
            return 0.5
        case "opacity":
            return 0.0
        default:
            throw Error("Unrecognized decal mask type.")
    }
}

// compute size based on (partially) specified values and DataObject width/height
function computeConstrainedSizeFromDataObject(
    scope: GraphBuilderScope,
    inputSize: [number | undefined | null, number | undefined | null],
    dataObject: BuilderInlet<IDataObject> | null,
) {
    //NOTE: these coerced equality checks (!=,  ==) are intentional, to catch both null and undefined values
    if (inputSize[0] != undefined && inputSize[1] != undefined) {
        // need to use scope.tuple here, because change detection for scope.value will not work when inputSize array is reused (it assumes reference equality check is sufficient)
        return scope.tuple(inputSize[0], inputSize[1]) // size is fully specified
    } else if ((inputSize[0] == undefined && inputSize[1] == undefined) || !dataObject) {
        return scope.tuple(10, 10) // size is unspecified, or data object is not available
    } else {
        // size is partially specified, use aspect ratio of mask
        return scope.lambda(
            scope.tuple(inputSize, dataObject),
            ([inputSize, dataObject]): [number, number] => {
                if (typeof dataObject.width !== "number" || typeof dataObject.height !== "number") {
                    console.error("DataObject ${dataObject.id} width/height not available")
                    return [10, 10]
                } else if (inputSize[0] == undefined) {
                    if (inputSize[1] == undefined) {
                        console.error("DataObject ${dataObject.id} input size 0/1 not available")
                        return [10, 10]
                    }
                    return [(inputSize[1] * dataObject.width) / dataObject.height, inputSize[1]]
                } else {
                    return [inputSize[0], (inputSize[0] * dataObject.height) / dataObject.width]
                }
            },
            "constrainedSize",
        )
    }
}
