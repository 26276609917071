// @ts-strict-ignore
import {MeshDataCache as MeshDataCacheOld} from "./mesh-data-cache"
import {mapGqlToRestImageColorSpace} from "@app/legacy/helpers/image-color-space"
import {MeshDataCache} from "@app/template-editor/helpers/mesh-data-cache"
import {TemplateRevisionGraphCache} from "@app/template-editor/helpers/template-revision-graph-cache"
import {mapLegacyDataObjectToImageResource} from "@app/templates/legacy/material-node-graph"
import {TransientDataObject as TransientDataObjectNew} from "@app/common/helpers/transient-data-object/transient-data-object"
import {map, Observable, of as observableOf, publishReplay, refCount, shareReplay, Subject, switchMap, take, from, catchError, firstValueFrom} from "rxjs"
import {MeshData} from "@cm/lib/geometry-processing/mesh-data"
import {IMaterialGraphManager} from "@cm/lib/materials/material-node-graph"
import {transformColorOverlay, transformDecalMask, transformOffsetUVs} from "@cm/lib/materials/material-node-graph-transformations"
import {RenderNodes} from "@cm/lib/rendering/render-nodes"
import {InterfaceDescriptor} from "@cm/lib/templates/interface-descriptors"
import {IDataObject, IDataObjectNew, ITransientDataObject} from "@cm/lib/templates/interfaces/data-object"
import {IMaterialGraph} from "@cm/lib/templates/interfaces/material-data"
import {DecalMaskData, ISceneManager, ISceneManagerNew, MeshLoadSettings, UVOffset} from "@cm/lib/templates/interfaces/scene-manager"
import {ObjectId, SceneNodes} from "@cm/lib/templates/interfaces/scene-object"
import {LegacyTemplateConverter} from "@cm/lib/templates/legacy-template-converter"
import {TemplateContext, makeTemplateNodeEvaluator} from "@cm/lib/templates/legacy/evaluation/template-node"
import {NodeUtils} from "@cm/lib/templates/legacy/template-node-utils"
import {Nodes} from "@cm/lib/templates/legacy/template-nodes"
import {NodeEvaluator} from "@cm/lib/templates/node-evaluator"
import {Object} from "@cm/lib/templates/node-types"
import {StoredMesh} from "@cm/lib/templates/nodes/stored-mesh"
import {TemplateInstance} from "@cm/lib/templates/nodes/template-instance"
import {BuilderInlet, GraphBuilder} from "@cm/lib/templates/runtime-graph/graph-builder"
import {GraphScheduler} from "@cm/lib/templates/runtime-graph/graph-scheduler"
import {CompileTemplate, TransformAccessorListEntry} from "@cm/lib/templates/runtime-graph/nodes/compile-template"
import {CompileTemplateNew} from "@cm/lib/templates/runtime-graph/nodes/compile-template-new"
import {LoadGraph} from "@cm/lib/templates/runtime-graph/nodes/load-graph"
import {RunTemplate} from "@cm/lib/templates/runtime-graph/nodes/run-template"
import {RunTemplateNew} from "@cm/lib/templates/runtime-graph/nodes/run-template-new"
import {Solver} from "@cm/lib/templates/runtime-graph/nodes/solver"
import {SolverState} from "@cm/lib/templates/runtime-graph/nodes/solver/state"
import {NotReady} from "@cm/lib/templates/runtime-graph/slots"
import {ImageColorSpace, TemplateContext as TemplateContextNew, TemplateNode} from "@cm/lib/templates/types"
import {graphToJson, jsonToGraph} from "@cm/lib/utils/graph-json"
import {cancelDeferredTask, CompactUIDTable, queueDeferredTask, removeFromArray, TwoWayReadonlyMap, WeakCompactUIDTable} from "@cm/lib/utils/utils"
import {AsyncCacheMap} from "@common/helpers/async-cache-map/async-cache-map"
import {forkJoinZeroOrMore} from "@legacy/helpers/utils"
import {Matrix4, Vector3, AABB} from "@cm/lib/math"
import {ConnectionSolver} from "@editor/helpers/connection-solver/connection-solver"
import {clipAndOffsetMeshForDecal, WebAssemblyWorkerService} from "@editor/helpers/mesh-processing/mesh-processing"
import {DataObject, TransientDataObject} from "@legacy/api-model/data-object"
import {SdkService} from "@app/common/services/sdk/sdk.service"
import {MediaTypeSchema} from "@cm/lib/api-gql/data-object"
import {contentTypeForExtension} from "@cm/lib/utils/content-types"
import {ProceduralMeshDataCache} from "@app/template-editor/helpers/procedural-mesh-data-cache"
import {RefreshService} from "@app/common/services/refresh/refresh.service"
import {Material} from "@app/legacy/api-model/material"
import {TemplateRevision} from "@app/legacy/api-model/template-revision"
import {getGraphJSONForTemplateRevision} from "../template-graph-migration"
import {isMobileDevice} from "@app/common/helpers/device-browser-detection/device-browser-detection"
import {APIService} from "@legacy/services/api/api.service"
import {MeshDataBatchApiCallService} from "@app/templates/template-system/mesh-data-batch-api-call.service"
import {DataObjectBatchApiCallService} from "@app/templates/template-system/data-object-batch-api-call.service"
import {TemplateRevisionBatchApiCallService} from "@app/templates/template-system/template-revision-batch-api-call.service"

export class SceneManager implements ISceneManager, ISceneManagerNew {
    readonly connectionSolver = new ConnectionSolver()

    maxSubdivisionLevel = 1
    defaultCustomerId?: number

    private graphUpdatePending: number | null = null
    private rootNode: Nodes.TemplateInstance
    private rootNodeNew: TemplateInstance

    readonly modified$ = new Subject<Nodes.Node[]>()
    readonly updateComplete$ = new Subject<void>()
    readonly errorReport$ = new Subject<Error>()

    readonly meshCacheOld: MeshDataCacheOld
    readonly meshCache: MeshDataCache
    readonly proceduralMeshCache: ProceduralMeshDataCache

    private requestUpdate: (modifiedNodes: Nodes.Node[]) => void

    templateRevisionGraphCache = new AsyncCacheMap<number, Nodes.TemplateGraph>((id: number) =>
        from(this.templateRevisionBatchApiCallService.fetch({legacyId: id})).pipe(
            map((templateRevision) => getGraphJSONForTemplateRevision(templateRevision)),
            map((graphJson) => {
                const graph = jsonToGraph(graphJson) as Nodes.TemplateGraph
                if (graph.schema !== Nodes.currentTemplateGraphSchema) {
                    throw new Error(`Invalid template graph schema: '${graph.schema}' != '${Nodes.currentTemplateGraphSchema}'`)
                }
                return graph
            }),
        ),
    )
    templateRevisionGraphCacheNew = new TemplateRevisionGraphCache(this.sdk)

    constructor(
        readonly sdk: SdkService,
        readonly refresh: RefreshService,
        readonly workerService: WebAssemblyWorkerService,
        readonly materialGraphManager: IMaterialGraphManager,
        readonly exposeClaimedSubTemplateInputs: boolean,
        readonly meshDataBatchApiCallService: MeshDataBatchApiCallService,
        readonly dataObjectBatchApiCallService: DataObjectBatchApiCallService,
        readonly templateRevisionBatchApiCallService: TemplateRevisionBatchApiCallService,
        readonly legacyApi: APIService,
    ) {
        this.meshCacheOld = new MeshDataCacheOld(workerService, meshDataBatchApiCallService, legacyApi)
        this.meshCache = new MeshDataCache(workerService)
        this.proceduralMeshCache = new ProceduralMeshDataCache(workerService)
        const triggerUpdate = () => {
            if (this.graphUpdatePending !== null) return
            this.graphUpdatePending = queueDeferredTask(() => {
                this.graphUpdatePending = null
                this.updateRoot(this.rootNode)
                this.updateComplete$.next()
            })
        }
        this.requestUpdate = (modifiedNodes) => {
            if (modifiedNodes?.length) {
                this.modified$.next(modifiedNodes)
            }
            triggerUpdate()
        }
    }

    private _tasks: Observable<void>[] = []

    addTask(task: Observable<void>) {
        task = task.pipe(
            map(() => {
                //TODO: handle case where observable completes without emitting
                const idx = this._tasks.indexOf(task)
                this._tasks.splice(idx, 1)
            }),
            shareReplay(1),
        ) //TODO: this should probably use publish, see below
        this._tasks.push(task)
        return task.subscribe()
    }

    addBinding(binding: Observable<void>) {
        binding = binding.pipe(
            publishReplay(1), // don't use shareReplay, which will _not_ unsubscribe from the source when the ref count drops to zero
            refCount(),
        )
        const task = binding.pipe(
            take(1),
            map(() => {
                //TODO: handle case where observable completes without emitting
                const idx = this._tasks.indexOf(task)
                this._tasks.splice(idx, 1)
            }),
            shareReplay(1),
            catchError((err) => {
                console.error(err)
                this.errorReport$.next(err)
                err.alreadyReported = true // TODO: workaround to prevent double error reporting
                throw err
            }),
        )
        this._tasks.push(task)
        task.subscribe()
        return binding.subscribe()
    }

    private solverIdle$ = new Subject<void>()

    sync(syncSolver: boolean): Observable<void> {
        if (this.graphUpdatePending) {
            return this.updateComplete$.pipe(
                take(1),
                switchMap(() => this.sync(syncSolver)),
            )
        } else if (this.scheduler.hasPendingUpdates) {
            return this.scheduler.sync().pipe(switchMap(() => this.sync(syncSolver)))
        } else if (this._tasks.length !== 0) {
            return forkJoinZeroOrMore([...this._tasks]).pipe(switchMap(() => this.sync(syncSolver)))
        } else if (syncSolver && this.connectionSolver.running) {
            return this.solverIdle$.pipe(take(1))
        } else {
            return observableOf(null)
        }
    }

    invalidateCaches(): void {
        this.meshCacheOld.invalidate()
        this.meshCache.invalidate()
    }

    exportGraph(graph: Nodes.TemplateGraph) {
        return graphToJson(graph)
    }

    importGraph(json: unknown): Nodes.TemplateGraph {
        return jsonToGraph(json)
    }

    getGraphBuilder(): GraphBuilder {
        return this.builder
    }

    getTemplateGraph(templateRevisionId: number): Observable<Nodes.TemplateGraph> {
        return this.templateRevisionGraphCache.get(templateRevisionId)
    }

    isNodeActive(node: Nodes.Node): boolean {
        return this.activeNodeSet?.has(node)
    }

    lookupByExternalIdPath(path: string[]): Nodes.Node | null {
        return this._lookupByExternalIdPath ? this._lookupByExternalIdPath(path) : null
    }

    getNodeErrors(_node: Nodes.Node): string[] | undefined {
        //TODO: getNodeErrors
        return undefined
    }

    getObjectIdForNode(node: Nodes.Node): ObjectId | null {
        return this.nodeToObjectMap?.get(node)
    }

    resolveSwitch<T extends Nodes.Node>(node: any): T | null {
        if (!node) {
            return null
        } else if (NodeUtils.isSwitch(node)) {
            for (const targ of node.nodes) {
                if (targ && this.isNodeActive(targ)) {
                    return targ
                }
            }
        } else {
            return node
        }
        return null
    }

    getNodeForObjectId(id: ObjectId): Nodes.Node | null {
        return this.objectToNodeMap?.get(id)
    }

    getTopLevelObjectIds(): ObjectId[] {
        if (!this.objectToNodeMap) return []
        return [...this.objectToNodeMap.keys()]
    }

    getMeshDataForObject(id: ObjectId): MeshData | null {
        //TODO: hack
        for (const obj of this.displayList) {
            if (obj.id === id && "meshData" in obj) {
                return obj.meshData
            }
        }
        return null
    }

    getWorldTransformForObject(id: ObjectId): Matrix4 | null {
        //TODO: hack
        if (this.transformAccessorList) {
            for (const entry of this.transformAccessorList) {
                if (entry && entry.objectId === id) {
                    const retVal = entry.transformAccessor.getTransform()
                    if (!(retVal instanceof Matrix4)) throw new Error("Unexpected matrix value type from transform accessor")
                    return retVal
                }
            }
        }
        return null
    }

    getInterfaceForNode(node: Nodes.Node): Nodes.Meta.InterfaceDescriptor[] {
        if (!node || !this.descriptorList) return []
        if (node === this.rootNode) return this.descriptorList
        let filteredList: Nodes.Meta.InterfaceDescriptor[] = this.filteredDescriptorListCache.get(node)
        if (filteredList) return filteredList
        const matchPrefix = Nodes.getExternalId(node)
        filteredList = []
        for (const desc of this.descriptorList) {
            const [prefix, id] = Nodes.Meta.unwrapInterfaceId(desc.id)
            if (prefix == matchPrefix) {
                filteredList.push({
                    ...desc,
                    id,
                })
            }
        }
        this.filteredDescriptorListCache.set(node, filteredList)
        return filteredList
    }

    getConfigurationString(includeAllSubTemplateInputs: boolean): string {
        return Nodes.Meta.getConfigurationString(this.descriptorList, includeAllSubTemplateInputs)
    }

    setTransform(id: ObjectId, transform: Matrix4, interactive?: boolean) {
        //TODO:
        // const obj = Components.Transform.get(this.findObject(id));
        // if (!obj) return;
        // obj.forceTransform = transform.copy();
        // const solver = this.connectionSolver;
        // if (interactive) {
        //     solver.setObjectBeingMoved(id);
        // } else {
        //     solver.setObjectInstantMove(id);
        // }
        // solver.triggerUpdate();
        if (this.transformAccessorList) {
            for (const entry of this.transformAccessorList) {
                if (entry.objectId === id) {
                    return entry.transformAccessor.setTransform(transform, interactive)
                }
            }
        }
    }

    endTransform(id: ObjectId) {
        //TODO: this.connectionSolver.setObjectReleased(id);
        if (this.transformAccessorList) {
            for (const entry of this.transformAccessorList) {
                if (entry.objectId === id) {
                    return entry.transformAccessor.endTransform()
                }
            }
        }
    }

    markNodeChanged(node: Nodes.Node): void {
        if (!node) return
        this.requestUpdate([node])
    }

    updateAllMeshPositions(): boolean {
        const solver = this.connectionSolver
        const wasRunning = this.connectionSolver.running
        if (this.solverUpdateAll) {
            this.solverUpdateAll(false)
        }
        if (!solver.step()) {
            if (wasRunning) {
                this.solverIdle$.next()
            }

            if (this.needDisplayUpdate) {
                this.needDisplayUpdate = false
                return true
            }
            return false
        }
        if (this.solverUpdateAll) {
            this.solverUpdateAll(true)
        }
        return true
    }

    private scheduler = new GraphScheduler()
    private builder = new GraphBuilder("root", this.scheduler)
    private needDisplayUpdate = false
    private activeNodeSet: Set<Nodes.Node>
    private nodeToObjectMap: Map<Nodes.Node, ObjectId>
    private objectToNodeMap: Map<ObjectId, Nodes.Node>
    private transformAccessorList: TransformAccessorListEntry[]
    private _lookupByExternalIdPath: (x: string[]) => Nodes.Node | null
    private solverUpdateAll: (postStep: boolean) => void
    private pendingReady$: Subject<void> | null

    updateRoot(node: Nodes.TemplateInstance) {
        return this.updateRootOld(node)
    }

    updateRootOld(node: Nodes.TemplateInstance) {
        this.rootNode = node

        const scope = this.builder.scope()

        const templateContext: TemplateContext = {
            sceneManager: this,
            defaultCustomerId: this.defaultCustomerId,
        }

        const tev = makeTemplateNodeEvaluator({
            idMap: new WeakCompactUIDTable(),
            templateScope: scope,
            templateContext,
            ambientInputs: {},
            readyList: undefined, //TODO: top-level ready list
            activeNodeSet: undefined, // assume all top level nodes are active
            solverVariables: undefined,
        })

        let graph: BuilderInlet<Nodes.TemplateGraph | null>
        if (node.template.type === "templateReference") {
            graph = scope.node(LoadGraph, {
                sceneManager: templateContext.sceneManager,
                templateRevisionId: node.template.templateRevisionId,
            }).graph
        } else if (node.template.type === "templateGraph") {
            graph = node.template
        } else {
            graph = null
        }

        // const rootInputs: EvaluatedTemplateInputs = {};
        // const allDefinedInputs = Nodes.Meta.getAllParameters(node);
        // for (const [id, value] of Object.entries(allDefinedInputs)) {
        //     //TODO: unify evaluation with CompileTemplate
        //     if (NodeUtils.isNode(value)) {
        //         const node = value;
        //         if (node.type === 'materialReference' && node.materialRevisionId != null) {
        //             const { materialGraph } = scope.node(LoadMaterialRevision, {
        //                 sceneManager: templateContext.sceneManager,
        //                 materialRevisionId: node.materialRevisionId
        //             });
        //             setTemplateInput(scope, rootInputs, 'material', id, materialGraph);
        //         } else if (node.type === 'templateReference' && node.templateRevisionId != null) {
        //             const { graph } = scope.node(LoadGraph, {
        //                 sceneManager: templateContext.sceneManager,
        //                 templateRevisionId: node.templateRevisionId
        //             });
        //             setTemplateInput(scope, rootInputs, 'template', id, graph);
        //         } else {
        //             console.warn("Invalid root parameter (node):", id, value);
        //         }
        //     } else if (typeof value === 'string') {
        //         setTemplateInput(scope, rootInputs, 'string', id, value);
        //     } else if (typeof value === 'number') {
        //         setTemplateInput(scope, rootInputs, 'number', id, value);
        //     } else if (typeof value === 'boolean') {
        //         setTemplateInput(scope, rootInputs, 'boolean', id, value);
        //     } else {
        //         setTemplateInput(scope, rootInputs, 'unknown', id, value);
        //     }
        // }

        const [inputs, _claimedInputIds] = tev.evalTemplateInputs(scope, node)

        const compileTemplate = scope.node(CompileTemplate, {
            graph,
            inputs,
            overrides: node.overrides,
            templateContext,
            topLevelObjectId: undefined,
            templateDepth: 0,
            exposeClaimedSubTemplateInputs: this.exposeClaimedSubTemplateInputs,
            sceneManager: this,
        })
        const runTemplate = scope.node(RunTemplate, {
            compiledTemplate: compileTemplate.compiledTemplate,
            matrix: Matrix4.identity(),
            solverObject: undefined,
        })

        const solver = scope.node(Solver, {
            state: scope.state(SolverState, "solverState"),
            sceneManager: this,
            data: runTemplate.solverData,
        })

        const onlyReady = <T>(x: T | typeof NotReady) => (x === NotReady ? undefined : x)

        scope.tap(compileTemplate.activeNodeSet, (activeNodeSet) => (this.activeNodeSet = onlyReady(activeNodeSet)))
        scope.tap(compileTemplate.nodeToObjectMap, (nodeToObjectMap) => (this.nodeToObjectMap = onlyReady(nodeToObjectMap)))
        scope.tap(compileTemplate.objectToNodeMap, (objectToNodeMap) => (this.objectToNodeMap = onlyReady(objectToNodeMap)))

        scope.tap(runTemplate.preDisplayList, (preDisplayList) => {
            if (preDisplayList !== NotReady) {
                this.preDisplayList = preDisplayList
                this.needDisplayUpdate = true
            }
        })
        scope.tap(runTemplate.displayList, (displayList) => {
            if (displayList !== NotReady) {
                this.displayList = displayList
                this.needDisplayUpdate = true
            }
        })
        scope.tap(runTemplate.descriptorList, (descriptorList) => {
            if (descriptorList !== NotReady) {
                this.filteredDescriptorListCache.clear()
                this.descriptorList = onlyReady(descriptorList)
            }
        })
        scope.tap(runTemplate.lookupByExternalIdPath, (lookupByExternalIdPath) => (this._lookupByExternalIdPath = onlyReady(lookupByExternalIdPath)))
        scope.tap(runTemplate.transformAccessorList, (transformAccessorList) => (this.transformAccessorList = onlyReady(transformAccessorList)))

        scope.tap(solver.updateAll, (updateAll) => (this.solverUpdateAll = onlyReady(updateAll)))

        if (this.pendingReady$) {
            // complete previous task if it still exists, otherwise it will sit around in the task list forever
            this.pendingReady$.next()
            this.pendingReady$.complete()
        }
        this.pendingReady$ = new Subject()
        this.addTask(this.pendingReady$)
        scope.tap(runTemplate.ready, (ready) => {
            if (onlyReady(ready) && this.pendingReady$) {
                this.pendingReady$.next()
                this.pendingReady$.complete()
                this.pendingReady$ = null
            }
        })

        this.builder.finalizeChanges()
    }

    instanceIdMap = new Map<Nodes.Node, string>()
    updateRootNew(node: Nodes.TemplateInstance) {
        this.rootNode = node

        const scope = this.builder.scope()

        const templateContext: TemplateContextNew = {
            sceneManager: this,
            lodType: "web",
            defaultCustomerId: this.defaultCustomerId,
        }

        const legacyTemplateConverter = new LegacyTemplateConverter()
        const nodeNew = legacyTemplateConverter.convertTemplateInstance(node)
        this.rootNodeNew = nodeNew
        const nodeMap = new TwoWayReadonlyMap(legacyTemplateConverter.nodeCache)
        for (const [node, templateNode] of legacyTemplateConverter.nodeCache) {
            if (this.instanceIdMap.has(node)) templateNode.instanceId = this.instanceIdMap.get(node)
            else this.instanceIdMap.set(node, templateNode.instanceId)
        }

        const evaluator = new NodeEvaluator(new CompactUIDTable<string>(), scope, Matrix4.identity(), templateContext, {}, undefined)
        const graph = evaluator.evaluateTemplate(scope, nodeNew.parameters.template)

        const [inputs] = evaluator.evaluateTemplateInputs(scope, nodeNew)

        scope.tap(inputs, (inputs) => {
            console.log("inputs", inputs)
        })

        const compileTemplate = scope.node(CompileTemplateNew, {
            graph,
            sceneProperties: null,
            inputs,
            templateContext,
            topLevelObjectId: null,
            templateDepth: 0,
            exposeClaimedSubTemplateInputs: this.exposeClaimedSubTemplateInputs,
            sceneManager: this,
            overrideMaterial: null,
        })
        const runTemplate = scope.node(RunTemplateNew, {
            compiledTemplate: compileTemplate.compiledTemplate,
            matrix: Matrix4.identity(),
            solverObject: undefined,
        })

        const solver = scope.node(Solver, {
            state: scope.state(SolverState, "solverState"),
            sceneManager: this,
            data: runTemplate.solverData,
        })

        const onlyReady = <T>(x: T | typeof NotReady) => (x === NotReady ? undefined : x)

        const mapActiveNodeSet = (activeNodeSet: Set<TemplateNode> | typeof NotReady) => {
            if (activeNodeSet === NotReady) return NotReady

            const activeNodeSetNew = new Set<Nodes.Node>()
            for (const node of activeNodeSet) {
                const nodeNew = nodeMap.reverseGet(node)
                if (nodeNew) activeNodeSetNew.add(nodeNew)
            }
            return activeNodeSetNew
        }

        const mapNodeToObjectMap = (nodeToObjectMap: Map<TemplateNode, ObjectId> | typeof NotReady) => {
            if (nodeToObjectMap === NotReady) return NotReady

            const nodeToObjectMapNew = new Map<Nodes.Node, ObjectId>()
            for (const [node, objectId] of nodeToObjectMap) {
                const nodeNew = nodeMap.reverseGet(node)
                if (nodeNew) nodeToObjectMapNew.set(nodeNew, objectId)
            }
            return nodeToObjectMapNew
        }

        const mapObjectToNodeMap = (objectToNodeMap: Map<ObjectId, TemplateNode> | typeof NotReady) => {
            if (objectToNodeMap === NotReady) return NotReady

            const objectToNodeMapNew = new Map<ObjectId, Nodes.Node>()
            for (const [objectId, node] of objectToNodeMap) {
                const nodeNew = nodeMap.reverseGet(node)
                if (nodeNew) objectToNodeMapNew.set(objectId, nodeNew)
            }
            return objectToNodeMapNew
        }

        scope.tap(compileTemplate.activeNodeSet, (activeNodeSet) => (this.activeNodeSet = onlyReady(mapActiveNodeSet(activeNodeSet))))
        scope.tap(compileTemplate.nodeToObjectMap, (nodeToObjectMap) => (this.nodeToObjectMap = onlyReady(mapNodeToObjectMap(nodeToObjectMap))))
        scope.tap(compileTemplate.objectToNodeMap, (objectToNodeMap) => (this.objectToNodeMap = onlyReady(mapObjectToNodeMap(objectToNodeMap))))

        scope.tap(runTemplate.preDisplayList, (preDisplayList) => {
            if (preDisplayList !== NotReady) {
                this.preDisplayList = preDisplayList
                this.needDisplayUpdate = true
            }
        })
        scope.tap(runTemplate.displayList, (displayList) => {
            if (displayList !== NotReady) {
                this.displayList = displayList
                this.needDisplayUpdate = true
            }
        })

        const mapDescriptorList = (descriptorList: InterfaceDescriptor<unknown, object>[] | typeof NotReady) => {
            if (descriptorList === NotReady) return NotReady
            return descriptorList.map((desc) => desc.toLegacy())
        }

        scope.tap(runTemplate.descriptorList, (descriptorList) => {
            if (descriptorList !== NotReady) {
                this.filteredDescriptorListCache.clear()
                this.descriptorList = onlyReady(mapDescriptorList(descriptorList))
            }
        })

        const mapLookupByExternalIdPath = (lookupByExternalIdPath: ((x: string[]) => TemplateNode | null) | typeof NotReady) => {
            if (lookupByExternalIdPath === NotReady) return NotReady

            return (path: string[]) => {
                const node = lookupByExternalIdPath(path)
                return node ? nodeMap.reverseGet(node) : null
            }
        }

        scope.tap(
            runTemplate.lookupByExternalIdPath,
            (lookupByExternalIdPath) => (this._lookupByExternalIdPath = onlyReady(mapLookupByExternalIdPath(lookupByExternalIdPath))),
        )
        scope.tap(runTemplate.transformAccessorList, (transformAccessorList) => (this.transformAccessorList = onlyReady(transformAccessorList)))

        scope.tap(solver.updateAll, (updateAll) => (this.solverUpdateAll = onlyReady(updateAll)))

        if (this.pendingReady$) {
            // complete previous task if it still exists, otherwise it will sit around in the task list forever
            this.pendingReady$.next()
            this.pendingReady$.complete()
        }
        this.pendingReady$ = new Subject()
        this.addTask(this.pendingReady$)
        scope.tap(runTemplate.ready, (ready) => {
            if (onlyReady(ready) && this.pendingReady$) {
                this.pendingReady$.next()
                this.pendingReady$.complete()
                this.pendingReady$ = null
            }
        })

        this.builder.finalizeChanges()
    }

    destroy() {
        this.builder.destroy()
        this.scheduler.destroy()
        this.solverIdle$.complete()
        this.updateComplete$.complete()
        this.modified$.complete()
        if (this.pendingReady$) {
            this.pendingReady$.next() // let the task complete
            this.pendingReady$.complete()
            this.pendingReady$ = null
        }
        if (this.graphUpdatePending !== null) {
            cancelDeferredTask(this.graphUpdatePending)
            this.graphUpdatePending = null
        }
        this.connectionSolver.destroy()
    }

    deleteNode(context: Nodes.Context, node: Nodes.Node): void {
        removeFromArray(context.nodes, node)
        this.markNodeChanged(context as Nodes.Node)
    }

    private staticSceneComponents: SceneNodes.SceneNode[] = []
    private preDisplayList: SceneNodes.SceneNode[] = []
    private displayList: SceneNodes.SceneNode[] = []
    private descriptorList: Nodes.Meta.InterfaceDescriptor[] = []
    private filteredDescriptorListCache = new Map<Nodes.Node, Nodes.Meta.InterfaceDescriptor[]>()

    addStaticSceneNode<T extends SceneNodes.SceneNode>(comp: T): void {
        this.staticSceneComponents.push(comp)
        this.requestUpdate([])
    }

    getAllSceneNodes(): SceneNodes.SceneNode[] {
        return [...this.staticSceneComponents, ...(this.preDisplayList ?? []), ...(this.displayList ?? [])]
    }

    transformColorOverlay(graph: IMaterialGraph, image: IDataObject | ITransientDataObject, size: [number, number], useAlpha: boolean) {
        // return transformColorOverlay(graph, image, size, useAlpha)
        // FIXME temp workaround until data object / image resource fetching in scene manager is also migrated to the new api
        return transformColorOverlay(graph, mapLegacyDataObjectToImageResource(image), size, useAlpha)
    }

    transformDecalMask(graph: IMaterialGraph, maskData: DecalMaskData) {
        // return transformDecalMask(graph, maskData)
        // FIXME temp workaround until data object / image resource fetching in scene manager is also migrated to the new api
        const maskData_ = {
            ...maskData,
            colorOverlayImage: maskData.colorOverlayImage ? mapLegacyDataObjectToImageResource(maskData.colorOverlayImage) : undefined,
            maskImage: maskData.maskImage ? mapLegacyDataObjectToImageResource(maskData.maskImage) : undefined,
        }
        return transformDecalMask(graph, maskData_)
    }

    transformOffsetUVs(graph: IMaterialGraph, offset: UVOffset) {
        return transformOffsetUVs(graph, offset)
    }

    getConnectionSolver() {
        return this.connectionSolver
    }

    isMobileDevice() {
        return isMobileDevice
    }

    defaultTransformForObject(node: Nodes.Object): Matrix4 | undefined {
        if (node.lockedTransform) {
            return Matrix4.fromArray(node.lockedTransform.map((x) => (typeof x === "string" ? Number(x) : x)))
        } else if (node.type === "mesh" && node.metadata?.defaultPosition) {
            const [x, y, z] = node.metadata.defaultPosition as [number, number, number]
            return Matrix4.translation(x, y, z)
        } else if (node.type === "instance") {
            return this.defaultTransformForObject(node.node)
        } else {
            return undefined
        }
    }

    defaultTransformForObjectNew(node: Object): Matrix4 | undefined {
        if (node.parameters.lockedTransform) {
            return Matrix4.fromArray(node.parameters.lockedTransform)
        } else if (node instanceof StoredMesh) {
            const {metaData} = node.parameters
            const {defaultPosition} = metaData
            if (defaultPosition) {
                const [x, y, z] = defaultPosition
                return Matrix4.translation(x, y, z)
            }
        }

        return undefined
    }

    getRootNode() {
        return this.rootNode
    }

    getRootNodeNew() {
        return this.rootNodeNew
    }

    createTransientDataObject(data: Uint8Array, contentType: string, imageColorSpace: ImageColorSpace) {
        return new TransientDataObject({data, contentType, imageColorSpace: mapGqlToRestImageColorSpace(imageColorSpace)})
    }

    createTransientDataObjectNew(data: Uint8Array, contentType: string, imageColorSpace: ImageColorSpace) {
        const parsedMediaType = MediaTypeSchema.safeParse(contentType)
        if (!parsedMediaType.success) throw Error(`Invalid media/content type: ${contentType}`)
        return new TransientDataObjectNew({data, mediaType: parsedMediaType.data, imageColorSpace: imageColorSpace})
    }

    loadDataObject(dataObjectId: number) {
        return DataObject.get(dataObjectId)
    }

    async loadDataObjectNew(dataObjectId: number) {
        const result = await this.sdk.gql.getDataObjectDetailsForSceneManager({legacyId: dataObjectId})
        const {dataObject} = result

        const convertToDataObject = (dataObject: Omit<typeof result.dataObject, "related">) => {
            const {mediaType: dataObjectMediaType, ...rest} = dataObject

            const extension = dataObject.originalFileName.split(".").pop()
            const mediaType = dataObjectMediaType && dataObjectMediaType.length > 0 ? dataObjectMediaType : contentTypeForExtension(extension ?? "")

            const parsedMediaType = MediaTypeSchema.safeParse(mediaType)
            if (!parsedMediaType.success) throw Error(`Invalid media/content type: ${mediaType}`)

            return {...rest, mediaType: parsedMediaType.data} as Omit<IDataObjectNew, "related">
        }

        const {related, ...rest} = dataObject
        return {...convertToDataObject(rest), related: related.map(convertToDataObject)}
    }

    findMaterial(articleId: string | null, customerId: number | null) {
        const query = {article_id: articleId ?? undefined, customer: customerId ?? undefined}

        return Material.findOne(query).pipe(
            switchMap((material) => {
                if (material) {
                    return Material.get(material.id) // findOne does not return the full material :(
                } else {
                    console.warn(`Material not found: ${JSON.stringify(query)}`)
                    return observableOf(null)
                }
            }),
        )
    }

    async findMaterialNew(articleId: string, customerId: number) {
        const {materials} = await this.sdk.gql.getMaterialsDetailsForSceneManagerService({
            filter: {articleId: {equals: articleId ?? undefined}, organizationLegacyId: {equals: customerId ?? undefined}},
        })

        const latestCyclesRevision = materials[0]?.latestCyclesRevision
        if (!latestCyclesRevision) return null

        return this.materialGraphManager.graphFromMaterialRevisionId({legacyId: latestCyclesRevision.legacyId})
    }

    loadMaterial(materialRevisionId: number) {
        return from(this.materialGraphManager.graphFromMaterialRevisionId({legacyId: materialRevisionId}))
    }

    loadMaterialNew(materialRevisionId: number) {
        return this.materialGraphManager.graphFromMaterialRevisionId({legacyId: materialRevisionId})
    }

    loadMeshData(dataObject: DataObject, plyDataObjectId: number, settings: MeshLoadSettings) {
        return this.meshCacheOld.getMeshData(dataObject, plyDataObjectId, settings)
    }

    loadMeshDataNew(dataObject: IDataObjectNew, plyDataObjectId: number, settings: MeshLoadSettings) {
        return firstValueFrom(this.meshCache.getMeshData(dataObject, plyDataObjectId, settings))
    }

    generateProceduralMeshData(graph: RenderNodes.MeshData): Observable<MeshData> {
        return this.proceduralMeshCache.getMeshData(graph)
    }

    loadTemplateGraph(templateRevisionId: number) {
        return this.templateRevisionGraphCache.get(templateRevisionId)
    }

    loadTemplateGraphNew(templateRevisionId: number) {
        return firstValueFrom(this.templateRevisionGraphCacheNew.get(templateRevisionId))
    }

    clipAndOffsetMeshForDecal(mesh: MeshData, uvCenter: [number, number], uvRotation: number, size: [number, number], offset: number) {
        return clipAndOffsetMeshForDecal(this.workerService, mesh, uvCenter, uvRotation, size, offset)
    }

    generateProceduralMeshDataNew(graph: RenderNodes.MeshData) {
        return firstValueFrom(this.generateProceduralMeshData(graph))
    }

    clipAndOffsetMeshForDecalNew(mesh: MeshData, uvCenter: [number, number], uvRotation: number, size: [number, number], offset: number) {
        return firstValueFrom(this.clipAndOffsetMeshForDecal(mesh, uvCenter, uvRotation, size, offset))
    }
}
