import {srgbToLinearNoClip} from "@cm/lib/image-processing/tone-mapping"
import {ShaderBallPostRenderStepInput, shaderBallPostRenderStepTask} from "@cm/lib/job-task/asset-rendering"
import {CreateJobGraphData, JobNodes} from "@cm/lib/job-task/job-nodes"
import {cmRenderTaskForPassNames} from "@cm/lib/job-task/rendering"
import {NodeUtils} from "@cm/lib/templates/legacy/template-node-utils"
import {Nodes} from "@cm/lib/templates/legacy/template-nodes"
import {jsonToGraph} from "@cm/lib/utils/graph-json"
import {LegacyId} from "@common/helpers/rendering/material/material-assets-rendering/common"
import {Matrix4} from "@cm/lib/math"
import {JobGraphMaterial} from "@common/models/material-assets-rendering/job-graph-material"
import {Settings} from "@common/models/settings/settings"
import {SdkService} from "@common/services/sdk/sdk.service"
import {environment} from "@environment"
import {combinedPassFromRenderTask} from "@common/helpers/rendering/rendering"

const SHADER_BALL_TEMPLATE_ID = Settings.MATERIAL_ASSETS_RENDERING_SHADER_BALL_TEMPLATE_ID
const SHADER_BALL_HDRI_ID = Settings.MATERIAL_ASSETS_RENDERING_SHADER_BALL_HDRI_ID

export async function setupTemplateGraphForShaderBallRender(
    materialRevisionId: LegacyId,
    resolutionX: number,
    resolutionY: number,
    samples: number,
    sensorSize: number,
    sdkService: SdkService,
): Promise<Nodes.TemplateGraph> {
    const materialNode: Nodes.MaterialReference = {
        name: "matName",
        type: "materialReference",
        materialRevisionId,
    }

    const FROM_BLENDER_CS = new Matrix4([1, 0, 0, 0, 0, 0, 1, 0, 0, -1, 0, 0, 0, 0, 0, 1])

    const BLENDER_CAMERA_MATRIX = new Matrix4([
        0.9999978542327881, -0.0009130112011916935, 0.0018655003514140844, 0.11999999405816197, 0.0020769403781741858, 0.4395934045314789, -0.8981944918632507,
        -133.542001247406, 0.0, 0.8981963992118835, 0.4395943284034729, 78.78099679946899, 0.0, 0.0, 0.0, 1.0,
    ])

    const BLENDER_AREA_LIGHT_MATRIX = new Matrix4([
        -0.7071067690849304, -3.0908619663705394e-8, -0.7071067690849304, -69.81599926948547, -0.7071067690849304, -9.272586254382986e-8, 0.7071067690849304,
        52.6229977607727, -8.742277657347586e-8, 1.0, 4.371138828673793e-8, 39.56499993801117, 0.0, 0.0, 0.0, 1.0,
    ])
    const BLENDER_AREA_LIGHT_COLOR = [1.00245, 0.98751, 1.11557].map(srgbToLinearNoClip) // output from blackbody node, T = 7047

    const BLENDER_AREA_LIGHT_001_MATRIX = new Matrix4([
        1.0, 0.0, 0.0, -0.6610000040382147, 0.0, 1.0, 0.0, -27.23200023174286, 0.0, 0.0, 1.0, 83.31499695777893, 0.0, 0.0, 0.0, 1.0,
    ])

    const CAMERA_MATRIX = BLENDER_CAMERA_MATRIX.multiply(FROM_BLENDER_CS)

    const graph: Nodes.TemplateGraph = {
        type: "templateGraph",
        schema: Nodes.currentTemplateGraphSchema,
        name: "Untitled Scene",
        nodes: [
            {
                type: "camera",
                name: "Camera01",
                resolutionX: resolutionX,
                resolutionY: resolutionY,
                lockedTransform: CAMERA_MATRIX.transpose().toArray(),
                target: [0, 0, 0],
                filmGauge: sensorSize,
                focalLength: 80,
                focalDistance: 140,
                fStop: 10000, // aperture size = 0 in blender scene
                exposure: 1,
                shiftX: 0,
                shiftY: 0,
                automaticVerticalTilt: false,
                enablePanning: true,
                screenSpacePanning: true,
                minDistance: 10,
                maxDistance: 10000,
                minPolarAngle: 0,
                maxPolarAngle: 180,
                minAzimuthAngle: -Infinity,
                maxAzimuthAngle: Infinity,
            },
            {
                type: "areaLight",
                name: "AreaLight01",
                intensity: 2.55,
                width: 40,
                height: 40,
                color: BLENDER_AREA_LIGHT_COLOR as [number, number, number],
                lockedTransform: BLENDER_AREA_LIGHT_MATRIX.multiply(FROM_BLENDER_CS).transpose().toArray(),
                target: [0, 0, 0],
                on: true,
                directionality: 0.0,
                visibleDirectly: false,
                visibleInReflections: true,
                visibleInRefractions: true,
            },
            {
                type: "areaLight",
                name: "AreaLight02",
                intensity: 4.1,
                width: 73.92,
                height: 15.1,
                color: [1, 1, 1],
                lockedTransform: BLENDER_AREA_LIGHT_001_MATRIX.multiply(FROM_BLENDER_CS).transpose().toArray(),
                target: [0, 0, 0],
                on: true,
                directionality: 0.0,
                visibleDirectly: false,
                visibleInReflections: true,
                visibleInRefractions: true,
            },
            {
                type: "hdriLight",
                name: "hdriLight01",
                hdri: {
                    type: "hdriReference",
                    hdriId: SHADER_BALL_HDRI_ID,
                    name: "hdriLightReference01",
                },
                intensity: 1.25,
                rotation: [0, 205, 0],
                mirror: true,
            },
            {
                type: "sceneProperties",
                backgroundColor: [1, 1, 1],
                maxSubdivisionLevel: 3,
                uiStyle: "default",
                uiColor: [0, 0, 0],
            },
            {
                type: "render",
                width: resolutionX,
                height: resolutionY,
                samples: samples,
            },
        ],
    }

    const templateDetails = (await sdkService.gql.getTemplateDetailsForMaterialAssetsRendering({legacyId: SHADER_BALL_TEMPLATE_ID})).template
    if (!templateDetails.latestRevision) throw new Error("Missing latest revision!")
    if (!templateDetails.latestRevision.graph) throw new Error("Missing graph in latest revision")
    const templateRefGraph = jsonToGraph(templateDetails.latestRevision.graph) as Nodes.TemplateGraph //TODO use zod
    const templateInputs = templateRefGraph.nodes.filter((n) => NodeUtils.isTemplateInput(n)) as Nodes.TemplateInput[]
    if (templateInputs.length !== 1) {
        throw new Error("Expected single template input!")
    }
    const templateInst: Nodes.TemplateInstance = {
        type: "templateInstance",
        id: "shaderBall",
        name: "shaderBall",
        template: {
            type: "templateReference",
            templateRevisionId: templateDetails.latestRevision.legacyId,
        },
        lockedTransform: Matrix4.identity().toArray(),
    }
    const inputId = Nodes.getExternalId(templateInputs[0], true)
    Nodes.Meta.setParameter(templateInst, inputId, materialNode)
    graph.nodes.push(templateInst)
    return graph
}

export function jobGraphFn_shaderBall(renderGraphDataObjectId: number, materialDetails: JobGraphMaterial): CreateJobGraphData {
    // TODO: need to make these types more precise: JSON graph !== deduplicated DAG (!)
    const inputPlaceholder = {}
    const image = {
        type: "convert",
        dataType: "float32",
        channelLayout: "RGBA",
        input: {
            type: "adjustExposure",
            input: {
                type: "input",
                image: inputPlaceholder,
            },
            ev: -0.7,
        },
    }

    const renderTask = JobNodes.task(cmRenderTaskForPassNames("Combined"), {input: JobNodes.input(), queueDomain: environment.rendering.defaultQueueDomain})

    const postRenderTask = JobNodes.task(shaderBallPostRenderStepTask, {
        input: JobNodes.struct({
            renderImage: combinedPassFromRenderTask(renderTask),
            imageProcessingGraph: JobNodes.value({
                inputPlaceholder,
                output: image,
            }),
            materialId: JobNodes.value(materialDetails.legacyId),
            customerId: JobNodes.value(materialDetails.organization.legacyId),
            renderFileName: JobNodes.value("Thumbnail - " + materialDetails.name + ".exr"),
        }) as JobNodes.TypedDataNode<ShaderBallPostRenderStepInput>,
    })

    return JobNodes.jobGraph(postRenderTask, {
        progress: {
            type: "progressGroup",
            items: [
                {node: renderTask, factor: 18},
                {node: postRenderTask, factor: 1},
            ],
        },
        input: {
            renderGraph: JobNodes.dataObjectReference(renderGraphDataObjectId),
            customerId: materialDetails.organization.legacyId,
        },
        platformVersion: Settings.APP_VERSION,
    })
}
