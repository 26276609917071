import {registerNode} from "@src/graph-system/register-node"
import {
    MaterialLike,
    StringLike,
    NumberLike,
    BooleanLike,
    Node,
    materialLike,
    stringLike,
    numberLike,
    booleanLike,
    ObjectLike,
    objectLike,
    ImageLike,
    imageLike,
    jsonLike,
    JSONLike,
    templateLike,
    TemplateLike,
} from "@src/templates/node-types"
import {
    DeclareTemplateListNode,
    DeclareTemplateNodeTS,
    TemplateListNode,
    TemplateNodeImplementation,
    TemplateNodeMeta,
} from "@src/templates/declare-template-node"
import {AnyJSONValue, TemplateNode} from "@src/templates/types"
import {NodeEvaluator} from "@src/templates/node-evaluator"
import {EvaluableTemplateNode} from "@src/templates/evaluable-template-node"
import {IMaterialGraph} from "@src/templates/interfaces/material-data"
import {GraphBuilderScope} from "@src/templates/runtime-graph/graph-builder-scope"
import {ObjectData} from "@src/templates/interfaces/object-data"
import {TemplateImageDataNew} from "@src/templates/runtime-graph/type-descriptors"
import {z} from "zod"
import {namedNodeParameters, NamedNodeParameters} from "@src/templates/nodes/named-node"
import {TemplateGraph} from "@src/templates/nodes/template-graph"
import {nodeInstance} from "@src/graph-system/instance"
import {NodeGraphClass} from "@src/graph-system/node-graph"

type ZodListNode<T> = z.ZodType<TemplateListNode<T>>

const switchParameters = <T>(listValidation: ZodListNode<T>) =>
    namedNodeParameters.merge(
        z.object({
            nodes: listValidation,
        }),
    )
type SwitchParameters<T> = NamedNodeParameters & {nodes: TemplateListNode<T>} //owns nodes

const generateSwitch = <T>(
    listValidation: ZodListNode<T>,
    implementation: TemplateNodeImplementation<SwitchParameters<T>>,
    meta: TemplateNodeMeta<SwitchParameters<T>>,
): NodeGraphClass<
    TemplateNode<SwitchParameters<T>> & {
        getValue(evaluator: NodeEvaluator): T | null
    }
> => {
    const retClass = class extends DeclareTemplateNodeTS<SwitchParameters<T>>(
        {
            validation: {paramsSchema: switchParameters(listValidation)},
            ...implementation,
        },
        meta,
    ) {
        getValue(evaluator: NodeEvaluator) {
            const {activeNodeSet} = evaluator

            for (const src of this.parameters.nodes.parameters.list) {
                if (!activeNodeSet || activeNodeSet.has(src as Node)) {
                    return src
                }
            }
            return null
        }
    }

    return retClass
}

////////////////////////////////////////////////////////////////////

@registerNode
export class ObjectLikes extends DeclareTemplateListNode({item: objectLike}, {nodeClass: "ObjectLikes"}) {}

export interface ObjectLikesSwitchParameters extends SwitchParameters<ObjectLike> {} // workaround for recursive type

@registerNode
export class ObjectSwitch
    extends generateSwitch<ObjectLike>(nodeInstance(ObjectLikes), {}, {nodeClass: "ObjectSwitch"})
    implements EvaluableTemplateNode<ObjectData | null>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator) {
        return evaluator.evaluateObject(scope, this.getValue(evaluator))
    }
}

export type ObjectSwitchFwd = TemplateNode<ObjectLikesSwitchParameters> & EvaluableTemplateNode<ObjectData | null>

////////////////////////////////////////////////////////////////////

@registerNode
export class MaterialLikes extends DeclareTemplateListNode({item: materialLike}, {nodeClass: "MaterialLikes"}) {}

export interface MaterialSwitchParameters extends SwitchParameters<MaterialLike> {} // workaround for recursive type

@registerNode
export class MaterialSwitch
    extends generateSwitch<MaterialLike>(nodeInstance(MaterialLikes), {}, {nodeClass: "MaterialSwitch"})
    implements EvaluableTemplateNode<IMaterialGraph | null>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator) {
        return evaluator.evaluateMaterial(scope, this.getValue(evaluator))
    }
}

export type MaterialSwitchFwd = TemplateNode<MaterialSwitchParameters> & EvaluableTemplateNode<IMaterialGraph | null>

////////////////////////////////////////////////////////////////////
@registerNode
export class TemplateLikes extends DeclareTemplateListNode({item: templateLike}, {nodeClass: "TemplateLikes"}) {}

export interface TemplateSwitchParameters extends SwitchParameters<TemplateLike> {} // workaround for recursive type

@registerNode
export class TemplateSwitch
    extends generateSwitch<TemplateLike>(nodeInstance(TemplateLikes), {}, {nodeClass: "TemplateSwitch"})
    implements EvaluableTemplateNode<TemplateGraph | null>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator) {
        return evaluator.evaluateTemplate(scope, this.getValue(evaluator))
    }
}

export type TemplateSwitchFwd = TemplateNode<TemplateSwitchParameters> & EvaluableTemplateNode<TemplateGraph | null>

////////////////////////////////////////////////////////////////////
@registerNode
export class ImageLikes extends DeclareTemplateListNode({item: imageLike}, {nodeClass: "ImageLikes"}) {}

export interface ImageSwitchParameters extends SwitchParameters<ImageLike> {} // workaround for recursive type

@registerNode
export class ImageSwitch
    extends generateSwitch<ImageLike>(nodeInstance(ImageLikes), {}, {nodeClass: "ImageSwitch"})
    implements EvaluableTemplateNode<TemplateImageDataNew | null>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator) {
        return evaluator.evaluateImage(scope, this.getValue(evaluator))
    }
}

export type ImageSwitchFwd = TemplateNode<ImageSwitchParameters> & EvaluableTemplateNode<TemplateImageDataNew | null>

////////////////////////////////////////////////////////////////////

@registerNode
export class StringLikes extends DeclareTemplateListNode({item: stringLike}, {nodeClass: "StringLikes"}) {}

export interface StringSwitchParameters extends SwitchParameters<StringLike> {} // workaround for recursive type

@registerNode
export class StringSwitch
    extends generateSwitch<StringLike>(nodeInstance(StringLikes), {}, {nodeClass: "StringSwitch"})
    implements EvaluableTemplateNode<string | null>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator) {
        return evaluator.evaluateString(scope, this.getValue(evaluator))
    }
}

export type StringSwitchFwd = TemplateNode<StringSwitchParameters> & EvaluableTemplateNode<string | null>

////////////////////////////////////////////////////////////////////

@registerNode
export class NumberLikes extends DeclareTemplateListNode({item: numberLike}, {nodeClass: "NumberLikes"}) {}

export interface NumberSwitchParameters extends SwitchParameters<NumberLike> {} // workaround for recursive type

@registerNode
export class NumberSwitch
    extends generateSwitch<NumberLike>(nodeInstance(NumberLikes), {}, {nodeClass: "NumberSwitch"})
    implements EvaluableTemplateNode<number | null>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator) {
        return evaluator.evaluateNumber(scope, this.getValue(evaluator))
    }
}

export type NumberSwitchFwd = TemplateNode<NumberSwitchParameters> & EvaluableTemplateNode<number | null>

////////////////////////////////////////////////////////////////////

@registerNode
export class BooleanLikes extends DeclareTemplateListNode({item: booleanLike}, {nodeClass: "BooleanLikes"}) {}

export interface BooleanSwitchParameters extends SwitchParameters<BooleanLike> {} // workaround for recursive type

@registerNode
export class BooleanSwitch
    extends generateSwitch<BooleanLike>(nodeInstance(BooleanLikes), {}, {nodeClass: "BooleanSwitch"})
    implements EvaluableTemplateNode<boolean | null>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator) {
        return evaluator.evaluateBoolean(scope, this.getValue(evaluator))
    }
}

export type BooleanSwitchFwd = TemplateNode<BooleanSwitchParameters> & EvaluableTemplateNode<boolean | null>

////////////////////////////////////////////////////////////////////

@registerNode
export class JSONLikes extends DeclareTemplateListNode({item: jsonLike}, {nodeClass: "JSONLikes"}) {}

export interface JSONSwitchParameters extends SwitchParameters<JSONLike> {} // workaround for recursive type

@registerNode
export class JSONSwitch
    extends generateSwitch<JSONLike>(nodeInstance(JSONLikes), {}, {nodeClass: "JSONSwitch"})
    implements EvaluableTemplateNode<AnyJSONValue | null>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator) {
        return evaluator.evaluateJSON(scope, this.getValue(evaluator))
    }
}

export type JSONSwitchFwd = TemplateNode<JSONSwitchParameters> & EvaluableTemplateNode<AnyJSONValue | null>
