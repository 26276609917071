import {ImageDescriptor} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-get-image-desc"
import {ColorLike, Vector2, Vector2Like} from "@cm/lib/math"
import {ImagePtr} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ref"
import {GetParameters} from "@cm/lib/graph-system/node-graph"
import {Context} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/context"
import {lambda} from "@app/textures/texture-editor/operator-stack/image-op-system/nodes/basic-nodes/lambda-node"
import {Vertex} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-rasterize-geometry"
import {rasterizeGeometry} from "@app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/rasterize-geometry-node"
import {getProperty} from "@cm/lib/graph-system/utils"

export type ParameterType = {
    resultImageOrDescriptor: ImagePtr | ImageDescriptor
    type: "linear"
    startPos: Vector2Like
    endPos: Vector2Like
    stops: {t: number; color: ColorLike}[] // stops must be sorted by t
}

export type ReturnType = ImagePtr

export function colorGradient(parameters: GetParameters<Context, ParameterType>) {
    const {resultImageOrDescriptor, type, startPos, endPos, stops} = parameters
    const geometryNode = lambda(
        {
            type,
            startPos,
            endPos,
            stops,
        },
        async ({parameters: {type, startPos, endPos, stops}}) => {
            if (type !== "linear") {
                throw new Error(`Unsupported type: ${type}`)
            }
            const maxWidth = 100000 // width of triangle strip TODO this should consider the actual dimensions of the image
            const start = Vector2.fromVector2Like(startPos)
            const end = Vector2.fromVector2Like(endPos)
            const delta = end.sub(start)
            const deltaNormalized = delta.normalized()
            const perpNormalized = deltaNormalized.perp()
            const vertices: Vertex[] = []
            const indices: number[] = []
            const addVertexPair = (pos: Vector2, color: ColorLike) => {
                const posLo = pos.sub(perpNormalized.mul(maxWidth))
                const posHi = pos.add(perpNormalized.mul(maxWidth))
                vertices.push({
                    position: posLo,
                    color,
                })
                vertices.push({
                    position: posHi,
                    color,
                })
            }
            // create two vertices for pre-start
            addVertexPair(start.sub(deltaNormalized.mul(maxWidth)), stops[0].color)
            // create two vertices for each stop
            for (let i = 0; i < stops.length; i++) {
                const pos = start.add(delta.mul(stops[i].t))
                addVertexPair(pos, stops[i].color)
            }
            // create two vertices for post-end
            addVertexPair(end.add(deltaNormalized.mul(maxWidth)), stops[stops.length - 1].color)
            // create indices
            for (let i = 0; i < vertices.length - 2; i += 2) {
                indices.push(i, i + 1, i + 2, i + 2, i + 1, i + 3)
            }
            return {vertices, indices}
        },
    )
    return rasterizeGeometry({
        topology: "triangleList",
        vertices: getProperty(geometryNode, "vertices"),
        indices: getProperty(geometryNode, "indices"),
        resultImageOrDescriptor: resultImageOrDescriptor,
    })
}
