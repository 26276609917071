import {
    NodeParameters,
    GetParameters,
    NodeParamEvaluator,
    NodeGraph,
    NodeParamSyncEvaluator,
    Evaluated,
    getParametersSchema,
    NodeGraphMeta,
    registerNodeGraph,
    DeclareNodeGraphTS,
    parameterValueSchema,
    GraphParameter,
    NodeGraphClass,
    nodeParameters,
} from "@src/graph-system/node-graph"
import {promiseAllProperties, mapFields, PromisesMap} from "@src/utils/utils"
import {z} from "zod"

export type GetAll<ParamTypes extends NodeParameters> = {
    [P in keyof ParamTypes]: Evaluated<ParamTypes[P]>
}

export async function getAll<Context, ParamTypes extends NodeParameters>(
    parameters: GetParameters<Context, ParamTypes>,
    get: NodeParamEvaluator,
): Promise<GetAll<ParamTypes>> {
    return promiseAllProperties(mapFields(parameters, (value) => get(value)) as PromisesMap<GetAll<ParamTypes>>)
}

export function getAllSync<Context, ParamTypes extends NodeParameters>(
    parameters: GetParameters<Context, ParamTypes>,
    get: NodeParamSyncEvaluator,
): GetAll<ParamTypes> {
    return mapFields(parameters, (value) => get(value)) as GetAll<ParamTypes>
}

@registerNodeGraph
export class GetProperty extends DeclareNodeGraphTS<unknown, unknown, {value: NodeGraph<object, unknown>; key: string}>({
    run: async ({parameters, get}) => {
        const value = (await get(parameters.value)) as NodeParameters
        return value[parameters.key]
    },
    runSync: ({parameters, get}) => {
        const value = get(parameters.value) as NodeParameters
        return value[parameters.key]
    },
}) {}

export function getProperty<StructType extends object, Key extends keyof StructType, Context>(value: NodeGraph<StructType, Context>, key: Key) {
    return new GetProperty({
        value: value as NodeGraph<StructType, unknown>,
        key: key as string,
        // @ts-ignore
    }) as NodeGraph<StructType[Key], Context, StructType>
}

export function DeclareStructNode<ZodContextType extends z.ZodType, ZodStructureType extends z.ZodType<NodeParameters>>(
    definition: {
        context: ZodContextType
        structure: ZodStructureType
    },
    meta?: NodeGraphMeta<GetParameters<z.infer<typeof definition.context>, z.infer<typeof definition.structure>>>,
) {
    const {context: contextSchema, structure: structureSchema} = definition
    type Context = z.infer<typeof contextSchema>
    type StructureType = z.infer<typeof structureSchema>

    return DeclareStructNodeTS<Context, StructureType>({contextSchema, structureSchema}, meta)
}

export function DeclareStructNodeTS<Context, StructureType extends NodeParameters>(
    validation?: {
        contextSchema?: z.ZodType
        structureSchema?: z.ZodType<NodeParameters>
    },
    meta?: NodeGraphMeta<GetParameters<Context, StructureType>>,
) {
    type ReturnType = GetAll<StructureType>

    const returnSchema = validation?.structureSchema ?? nodeParameters
    const contextSchema = validation?.contextSchema ?? z.any()
    const paramsSchema = getParametersSchema(returnSchema)

    return DeclareNodeGraphTS<ReturnType, Context, GetParameters<Context, StructureType>>(
        {
            run: async ({parameters, get}) => {
                return getAll(parameters, get)
            },
            runSync: ({parameters, get}) => {
                return getAllSync(parameters, get)
            },
            validation: {returnSchema, contextSchema, paramsSchema},
        },
        meta,
    )
}

export type StructNode<Context, StructureType extends NodeParameters> = NodeGraph<GetAll<StructureType>, Context, GetParameters<Context, StructureType>>

export function DeclareListNode<ZodContextType extends z.ZodType, ZodItemType extends z.ZodType>(
    definition: {
        context: ZodContextType
        item: ZodItemType
    },
    meta?: NodeGraphMeta<{list: GraphParameter<z.infer<typeof definition.item>, z.infer<typeof definition.context>>[]}>,
) {
    const {context: contextSchema, item: itemSchema} = definition
    type Context = z.infer<typeof contextSchema>
    type ItemType = z.infer<typeof itemSchema>

    return DeclareListNodeTS<Context, ItemType>({contextSchema, itemSchema}, meta)
}

export function DeclareListNodeTS<Context, ItemType>(
    validation?: {
        contextSchema?: z.ZodType
        itemSchema?: z.ZodType
    },
    meta?: NodeGraphMeta<{list: GraphParameter<ItemType, Context>[]}>,
): NodeGraphClass<ListNode<Context, ItemType>> {
    type ReturnType = Evaluated<GraphParameter<ItemType, Context>>[]
    type ParamTypes = {list: GraphParameter<ItemType, Context>[]}

    const itemTypeSchema = validation?.itemSchema ?? z.any()
    const returnSchema = z.array(itemTypeSchema)
    const contextSchema = validation?.contextSchema ?? z.any()
    const paramsSchema = z.object({list: z.array(parameterValueSchema(itemTypeSchema))})

    const retClass = class extends DeclareNodeGraphTS<ReturnType, Context, ParamTypes>(
        {
            run: async ({parameters, get}) => {
                return Promise.all(parameters.list.map((entry) => get(entry)))
            },
            runSync: ({parameters, get}) => {
                return parameters.list.map((entry) => get(entry))
            },
            validation: {returnSchema, contextSchema, paramsSchema},
        },
        meta,
    ) {
        addEntry(entry: GraphParameter<ItemType, Context>) {
            const newList = [...this.parameters.list, entry]
            this.replaceParameters({list: newList})
        }

        removeEntry(entry: GraphParameter<ItemType, Context>) {
            const {list} = this.parameters

            const index = list.indexOf(entry)
            if (index === -1) return

            const newList = [...list]
            newList.splice(index, 1)
            this.replaceParameters({list: newList})
        }

        clear() {
            this.replaceParameters({list: []})
        }
    }

    return retClass
}

export type ListNode<Context, ItemType> = NodeGraph<Evaluated<GraphParameter<ItemType, Context>>[], Context, {list: GraphParameter<ItemType, Context>[]}> & {
    addEntry(entry: GraphParameter<ItemType, Context>): void
    removeEntry(entry: GraphParameter<ItemType, Context>): void
    clear(): void
}
