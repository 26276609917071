import * as TextureEditNodes from "app/textures/texture-editor/texture-edit-nodes"
import {assertNever, deepCopy} from "@cm/lib/utils/utils"
import {Operator, OperatorInput, OperatorOutput, OperatorPanelComponentType} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator"
import {OperatorBase} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-base"
import {OperatorCallback} from "app/textures/texture-editor/operator-stack/operators/abstract-base/operator-callback"
import {HighpassPanelComponent} from "app/textures/texture-editor/operator-stack/operators/highpass/panel/highpass-panel.component"
import {HighpassToolbox} from "app/textures/texture-editor/operator-stack/operators/highpass/toolbox/highpass-toolbox"
import {getImageDesc} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/get-image-desc-node"
import {blur} from "@app/textures/texture-editor/operator-stack/image-op-system/nodes/high-level-nodes/blur-node"
import {lambda} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/basic-nodes/lambda-node"
import {reduce} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/reduce-node"
import {resize} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/resize-node"
import {math} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/math-node"
import {affineTransform} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/affine-transform-node"
import {Matrix3x2} from "@cm/lib/math/matrix3x2"
import {Vector2} from "@cm/lib/math/vector2"
import {getProperty} from "@cm/lib/graph-system/utils"
import {struct} from "@app/textures/texture-editor/operator-stack/image-op-system/nodes/basic-nodes/struct-node"
import {createImage} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/create-image-node"
import {BrushSettings} from "app/textures/texture-editor/operator-stack/operators/shared/toolbox/brush-toolbox-item"
import {DrawableImageRef} from "app/textures/texture-editor/operator-stack/image-op-system/drawable-image-ref"
import {drawableImage} from "@app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/drawable-image-node"
import {TextureEditorSettings} from "app/textures/texture-editor/texture-editor-settings"
import {blend} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/blend-node"
import {toGrayscale} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/to-grayscale-node"
import {TextureType} from "@api"
import {descriptorByTextureType} from "@app/textures/utils/texture-type-descriptor"
import {ImageOpNodeGraphEvaluator} from "app/textures/texture-editor/operator-stack/image-op-system/image-op-node-graph-evaluator"

export class OperatorHighpass extends OperatorBase<TextureEditNodes.OperatorHighpass> {
    readonly panelComponentType: OperatorPanelComponentType = HighpassPanelComponent
    readonly canvasToolbox: HighpassToolbox

    readonly type = "operator-highpass" as const

    constructor(callback: OperatorCallback, node: TextureEditNodes.OperatorHighpass | null) {
        super(
            callback,
            deepCopy(node) ?? {
                type: "operator-highpass",
                enabled: true,
                blurSettingsH: {
                    enabled: true,
                    smoothingDistance: 150,
                },
                blurSettingsV: {
                    enabled: true,
                    smoothingDistance: 150,
                },
                intensity: 1,
                wrapAround: false,
                angleOffset: 0,
                maskReference: undefined,
                correctionMode: "modulation",
            },
        )

        // convert legacy smoothingDistance to blurSettings
        if (this.node.smoothingDistance) {
            this.node.blurSettingsH = {
                enabled: true,
                smoothingDistance: this.node.smoothingDistance.x,
            }
            this.node.blurSettingsV = {
                enabled: true,
                smoothingDistance: this.node.smoothingDistance.y,
            }
            this.node.angleOffset = 0
            delete this.node.smoothingDistance
        }

        this.canvasToolbox = new HighpassToolbox(this)

        this.updateDrawableImageRef()
    }

    // OperatorBase
    override dispose(): void {
        super.dispose()
        this.canvasToolbox.remove()
    }

    // OperatorBase
    async clone(): Promise<Operator> {
        const clonedOperator = new OperatorHighpass(this.callback, deepCopy(this.node))
        if (this._maskDrawableImageRef) {
            clonedOperator.addMask()
            await this.callback.drawableImageCache.cloneImage(this._maskDrawableImageRef, clonedOperator._maskDrawableImageRef!)
        }
        return clonedOperator
    }

    // OperatorBase
    async getImageOpNodeGraph(evaluator: ImageOpNodeGraphEvaluator, textureType: TextureType, input: OperatorInput): Promise<OperatorOutput> {
        const sourceImage = input
        const blurSettingsH = {
            enabled: this.node.blurSettingsH.enabled,
            smoothingDistance: Math.round(this.node.blurSettingsH.smoothingDistance),
        }
        const blurSettingsV = {
            enabled: this.node.blurSettingsV.enabled,
            smoothingDistance: Math.round(this.node.blurSettingsV.smoothingDistance),
        }
        const wrapAround = this.node.wrapAround
        const intensity = this.node.intensity ?? 1
        const angleOffset = this.node.angleOffset
        const sourceImageDesc = getImageDesc({
            sourceImage,
        })

        const applySourceImageConversion = (sourceImage: OperatorInput) => {
            const correctLuminanceOnly = false
            if (correctLuminanceOnly) {
                const grayscaleConversionMode = descriptorByTextureType(textureType).isColorData ? "luminance" : "average"
                return toGrayscale({
                    sourceImage: sourceImage,
                    mode: grayscaleConversionMode,
                })
            } else {
                return sourceImage
            }
        }
        const filterSourceImage = applySourceImageConversion(sourceImage)

        // blur to compute low-pass
        let blurSource: OperatorInput
        const useAngleOffset =
            angleOffset !== 0 && (blurSettingsH.enabled || blurSettingsV.enabled) && blurSettingsH.smoothingDistance !== blurSettingsV.smoothingDistance
        const rotationTransform = new Matrix3x2().rotate(angleOffset)
        const expandedSize = lambda({sourceImageDesc}, async ({parameters: {sourceImageDesc}}) => {
            const p00 = rotationTransform.multiplyVector(new Vector2(-sourceImageDesc.width, -sourceImageDesc.height).mulInPlace(0.5))
            const p10 = rotationTransform.multiplyVector(new Vector2(sourceImageDesc.width, -sourceImageDesc.height).mulInPlace(0.5))
            const p01 = rotationTransform.multiplyVector(new Vector2(-sourceImageDesc.width, sourceImageDesc.height).mulInPlace(0.5))
            const p11 = rotationTransform.multiplyVector(new Vector2(sourceImageDesc.width, sourceImageDesc.height).mulInPlace(0.5))
            const min = Vector2.min(p00, Vector2.min(p10, Vector2.min(p01, p11)))
            const max = Vector2.max(p00, Vector2.max(p10, Vector2.max(p01, p11)))
            return max.sub(min).add(new Vector2(blurSettingsH.smoothingDistance, blurSettingsV.smoothingDistance)).ceil()
        })
        if (useAngleOffset) {
            blurSource = affineTransform({
                sourceImage: filterSourceImage,
                transform: lambda({sourceImageDesc, expandedSize, angleOffset}, async ({parameters: {sourceImageDesc, expandedSize, angleOffset}}) =>
                    new Matrix3x2()
                        .translate(expandedSize.mul(0.5))
                        .rotate(-(angleOffset ?? 0))
                        .translate(new Vector2(sourceImageDesc.width, sourceImageDesc.height).mul(-0.5)),
                ),
                addressMode: wrapAround ? "wrap" : "clamp",
                resultImage: createImage({
                    descriptor: struct({
                        width: getProperty(expandedSize, "x"),
                        height: getProperty(expandedSize, "y"),
                        channelLayout: getProperty(sourceImageDesc, "channelLayout"),
                        format: getProperty(sourceImageDesc, "format"),
                        isSRGB: getProperty(sourceImageDesc, "isSRGB"),
                    }),
                }),
            })
        } else {
            blurSource = filterSourceImage
        }
        let lowpassImage: OperatorInput = blur({
            sourceImage: blurSource,
            blurKernelSize: lambda(
                {
                    sourceImageDesc,
                    blurSettingsH,
                    blurSettingsV,
                },
                async ({parameters: {sourceImageDesc, blurSettingsH, blurSettingsV}}) => {
                    return {
                        x: blurSettingsH.enabled ? blurSettingsH.smoothingDistance : sourceImageDesc.width,
                        y: blurSettingsV.enabled ? blurSettingsV.smoothingDistance : sourceImageDesc.height,
                    }
                },
            ),
            borderMode: wrapAround ? "wrap" : "wrap-mirrored",
        })
        if (useAngleOffset) {
            lowpassImage = affineTransform({
                sourceImage: lowpassImage,
                transform: lambda({sourceImageDesc, expandedSize, angleOffset}, async ({parameters: {sourceImageDesc, expandedSize, angleOffset}}) =>
                    new Matrix3x2()
                        .translate(new Vector2(sourceImageDesc.width, sourceImageDesc.height).mul(0.5))
                        .rotate(angleOffset ?? 0)
                        .translate(expandedSize.mul(-0.5)),
                ),
                resultImage: createImage({
                    descriptor: sourceImageDesc,
                }),
            })
        }

        // get optional mask
        const mask = this._maskDrawableImageRef
            ? drawableImage({
                  drawableImageRef: this._maskDrawableImageRef,
                  descriptor: struct({
                      width: getProperty(sourceImageDesc, "width"),
                      height: getProperty(sourceImageDesc, "height"),
                      channelLayout: "R" as const,
                      format: TextureEditorSettings.PreviewProcessingImageFormat,
                      isSRGB: false,
                  }),
              })
            : undefined
        if (mask && evaluator.mode === "preview" && this.selected && this.showMask) {
            return {
                resultImage: mask,
                options: {
                    stopEvaluation: true,
                },
            }
        } else if (evaluator.mode === "preview" && this.selected && this.showLowpass) {
            return {
                resultImage: lowpassImage,
                options: {
                    stopEvaluation: true,
                },
            }
        } else {
            // compute mean
            const sourceImageMean1x1 = reduce({
                sourceImage: filterSourceImage,
                operator: "mean",
            })
            const sourceImageMean = resize({
                sourceImage: sourceImageMean1x1,
                resultSize: sourceImageDesc,
            })

            // compute highpass
            let highpassImage: OperatorInput
            if (this.correctionMode === "offset") {
                const meanMinusLowpass = math({
                    operandA: sourceImageMean,
                    operandB: lowpassImage,
                    operator: "-",
                })
                highpassImage = math({
                    operandA: sourceImage,
                    operandB: meanMinusLowpass,
                    operator: "+",
                })
            } else if (this.correctionMode === "modulation") {
                const meanDivLowpass = math({
                    operandA: sourceImageMean,
                    operandB: math({
                        operandA: lowpassImage,
                        operandB: 1e-8, // epsilon to avoid division by zero
                        operator: "+",
                    }),
                    operator: "/",
                })
                highpassImage = math({
                    operandA: sourceImage,
                    operandB: meanDivLowpass,
                    operator: "*",
                })
            } else {
                assertNever(this.correctionMode)
            }

            // optionally apply mask
            let resultImage: OperatorInput
            if (!mask) {
                resultImage = highpassImage
            } else {
                resultImage = blend({
                    backgroundImage: sourceImage,
                    foregroundImage: highpassImage,
                    alpha: mask,
                    blendMode: "normal",
                    premultipliedAlpha: false,
                })
            }

            // optionally blend by intensity
            if (intensity !== 1) {
                resultImage = blend({
                    backgroundImage: sourceImage,
                    foregroundImage: resultImage,
                    alpha: intensity,
                    blendMode: "normal",
                    premultipliedAlpha: false,
                })
            }

            return {
                resultImage,
            }
        }
    }

    set showGuides(value: boolean) {
        this.canvasToolbox.showGuides = value
    }

    get showGuides(): boolean {
        return this.canvasToolbox.showGuides
    }

    set showLowpass(value: boolean) {
        if (this._showLowpass === value) {
            return
        }
        this._showLowpass = value
        if (this.showLowpass) {
            this.showMask = false
        }
        this.requestEval()
    }

    get showLowpass(): boolean {
        return this._showLowpass
    }

    set showMask(value: boolean) {
        if (this._showMask === value) {
            return
        }
        this._showMask = value
        if (this.showMask) {
            this.showLowpass = false
        }
        this.requestEval()
    }

    get showMask(): boolean {
        return this._showMask
    }

    set intensity(value: number) {
        if (this.node.intensity === value) {
            return
        }
        this.node.intensity = value
        this.markEdited()
        this.requestEval()
    }

    get intensity(): number {
        return this.node.intensity ?? 1
    }

    set useWrapAround(value: boolean) {
        if (this.node.wrapAround === value) {
            return
        }
        this.node.wrapAround = value
        this.markEdited()
        this.requestEval()
    }

    get useWrapAround(): boolean {
        return this.node.wrapAround
    }

    get isIsotropic(): boolean {
        return this.useH && this.useV && this.smoothingDistanceH === this.smoothingDistanceV
    }

    set useH(value: boolean) {
        if (this.useH === value) {
            return
        }
        this.node.blurSettingsH.enabled = value
        this.markEdited()
        this.requestEval()
        this.canvasToolbox.updateLineGuides()
    }

    get useH(): boolean {
        return this.node.blurSettingsH.enabled
    }

    set smoothingDistanceH(value: number) {
        if (this.smoothingDistanceH === value) {
            return
        }
        this.node.blurSettingsH.smoothingDistance = value
        this.markEdited()
        this.requestEval()
        this.canvasToolbox.updateLineGuides()
    }

    get smoothingDistanceH(): number {
        return this.node.blurSettingsH.smoothingDistance
    }

    set useV(value: boolean) {
        if (this.useV === value) {
            return
        }
        this.node.blurSettingsV.enabled = value
        this.markEdited()
        this.requestEval()
        this.canvasToolbox.updateLineGuides()
    }

    get useV(): boolean {
        return this.node.blurSettingsV.enabled
    }

    set smoothingDistanceV(value: number) {
        if (this.smoothingDistanceV === value) {
            return
        }
        this.node.blurSettingsV.smoothingDistance = value
        this.markEdited()
        this.requestEval()
        this.canvasToolbox.updateLineGuides()
    }

    get smoothingDistanceV(): number {
        return this.node.blurSettingsV.smoothingDistance
    }

    set angleOffset(value: number) {
        if (this.angleOffset === value) {
            return
        }
        this.node.angleOffset = value
        this.markEdited()
        this.requestEval()
        this.canvasToolbox.updateLineGuides()
    }

    get angleOffset(): number {
        return this.node.angleOffset
    }

    get hasMask(): boolean {
        return this.node.maskReference !== undefined
    }

    get brushSettings(): BrushSettings {
        return this._brushSettings
    }

    set correctionMode(value: TextureEditNodes.OperatorHighpassCorrectionMode) {
        if (this.node.correctionMode === value) {
            return
        }
        this.node.correctionMode = value
        this.markEdited()
        this.requestEval()
    }

    get correctionMode(): TextureEditNodes.OperatorHighpassCorrectionMode {
        return this.node.correctionMode ?? "offset"
    }

    addMask(): void {
        if (this.hasMask) {
            return
        }
        this.node.maskReference = {
            type: "data-object-reference",
            dataObjectId: "",
        }
        this.updateDrawableImageRef()
        this.markEdited()
        this.requestEval()
    }

    removeMask(): void {
        if (!this.hasMask) {
            return
        }
        this.node.maskReference = undefined
        this.showMask = false
        this.updateDrawableImageRef()
        this.markEdited()
        this.requestEval()
    }

    private updateDrawableImageRef(): void {
        this._maskDrawableImageRef = this.node.maskReference ? new DrawableImageRef(this.node.maskReference, this.callback.customerLegacyId) : undefined
        this.canvasToolbox.setMaskReference(this._maskDrawableImageRef)
    }

    private _showLowpass = false
    private _showMask = false
    private _brushSettings = new BrushSettings()
    private _maskDrawableImageRef: DrawableImageRef | undefined
}
