import {Component, DestroyRef, OnInit, computed, inject, input, signal} from "@angular/core"
import {takeUntilDestroyed, toObservable} from "@angular/core/rxjs-interop"
import {MatProgressBarModule} from "@angular/material/progress-bar"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {ConfigInfo} from "@cm/lib/templates/interface-descriptors"
import {Parameters} from "@cm/lib/templates/nodes/template-instance"
import {Observable, combineLatest, concatMap, firstValueFrom, from, map, of, switchMap, tap} from "rxjs"
import {ListItemComponent} from "../../../common/components/item/list-item/list-item.component"
import {MatTooltipModule} from "@angular/material/tooltip"
import {SdkService} from "@app/common/services/sdk/sdk.service"
import {
    JobData,
    createPostProcessJob,
    createRenderJob,
    deletePostProcessJob,
    deleteRenderJob,
    getAssignmentKey,
    getJobAssignments,
} from "@app/template-editor/helpers/render-jobs"
import {TemplateJobIconComponent} from "../template-job-icon/template-job-icon.component"
import {ButtonComponent} from "../../../common/components/buttons/button/button.component"
import {MatMenuModule} from "@angular/material/menu"
import {SceneNodes} from "@cm/lib/templates/interfaces/scene-object"
import {MatDialog, MatDialogRef} from "@angular/material/dialog"
import {DialogComponent} from "@app/common/components/dialogs/dialog/dialog.component"
import {NotificationsService} from "@app/common/services/notifications/notifications.service"
import {RenderingService} from "@app/common/services/rendering/rendering.service"
import {hashObject} from "@cm/lib/utils/hashing"
import {DIALOG_DEFAULT_WIDTH} from "@app/template-editor/helpers/constants"

type ConfigurationInfo = {
    name: string
    parameters: Parameters
    hash: string
}

type ConfigurationData = ConfigurationInfo & {
    render?: JobData
    postProcess?: JobData
}

@Component({
    selector: "cm-template-all-variations",
    standalone: true,
    imports: [MatProgressBarModule, MatTooltipModule, ListItemComponent, TemplateJobIconComponent, ButtonComponent, MatMenuModule],
    providers: [SceneManagerService],
    templateUrl: "./template-all-variations.component.html",
    styleUrl: "./template-all-variations.component.scss",
})
export class TemplateAllVariationsComponent implements OnInit {
    sceneManagerService = input.required<SceneManagerService>()

    private localSceneManagerService = inject(SceneManagerService)
    private destroyRef = inject(DestroyRef)
    private sdkService = inject(SdkService)
    private matDialog = inject(MatDialog)
    private notifications = inject(NotificationsService)
    private renderingService = inject(RenderingService)

    private _progress = signal<number>(0)
    progress = this._progress.asReadonly()

    state = signal<"loading" | "loaded" | "error">("loading")

    renderNode = computed(() => this.sceneManagerService().$scene().find(SceneNodes.RenderSettings.is))
    renderAllDisabled = computed(() => this.state() !== "loaded" || this.renderNode() === undefined)
    postProcessingSettings = computed(() => this.sceneManagerService().$scene().find(SceneNodes.RenderPostProcessingSettings.is))
    finalizeAllDisabled = computed(() => this.state() !== "loaded" || this.postProcessingSettings() === undefined)
    deleteAllDisabled = computed(() => this.state() !== "loaded")

    allVariations = signal<ConfigurationData[]>([])

    currentLocalConfigurationHash = computed(() => {
        return this.sceneManagerService().$currentLocalConfiguration().getHash()
    })

    async setConfiguration(configuration: ConfigurationData) {
        this.sceneManagerService().$lodType.set("pathTraced")
        this.sceneManagerService().$instanceParameters.set(configuration.parameters)

        const templateRevisionId = this.sceneManagerService().$templateRevisionId()
        if (!templateRevisionId) return

        const [render, postProcess] = await getJobAssignments(this.sdkService, templateRevisionId, [
            getAssignmentKey("render", configuration.hash),
            getAssignmentKey("postProcess", configuration.hash),
        ])

        this.allVariations.update((variations) => variations.map((v) => (v.hash === configuration.hash ? {...configuration, render, postProcess} : v)))
    }

    ngOnInit() {
        this.localSceneManagerService.$lodType.set("pathTraced")
        const includeAllSubTemplateInputs = false
        this.localSceneManagerService.$exposeClaimedSubTemplateInputs.set(includeAllSubTemplateInputs)

        const sceneManagerService = this.sceneManagerService()

        combineLatest([sceneManagerService.templateRevisionId$, sceneManagerService.defaultCustomerId$, sceneManagerService.templateGraph$])
            .pipe(
                tap(() => {
                    this.allVariations.set([])
                    this._progress.set(0)

                    this.state.set("loading")
                }),
                switchMap(([templateRevisionId, defaultCustomerId, templateGraph]) => {
                    this.localSceneManagerService.$templateRevisionId.set(templateRevisionId)
                    this.localSceneManagerService.$defaultCustomerId.set(defaultCustomerId)
                    const clonedTemplateGraph = templateGraph.clone({cloneSubNode: () => true})
                    this.localSceneManagerService.$templateGraph.set(clonedTemplateGraph)

                    return gatherAllVariations(this.localSceneManagerService, (progress) => this._progress.set(progress)).pipe(
                        map((variation) => [variation, templateRevisionId] as const),
                    )
                }),
                concatMap(([variation, templateRevisionId]) => {
                    if (variation === "done") return of("done" as const)

                    if (!templateRevisionId) return of(variation)

                    return from(
                        getJobAssignments(this.sdkService, templateRevisionId, [
                            getAssignmentKey("render", variation.hash),
                            getAssignmentKey("postProcess", variation.hash),
                        ]),
                    ).pipe(
                        map(([render, postProcess]) => ({
                            ...variation,
                            render,
                            postProcess,
                        })),
                    )
                }),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe({
                next: (variation) => {
                    if (variation === "done") {
                        console.log("Done gathering variations", this.allVariations().length)
                        this.state.set("loaded")
                    } else {
                        console.log("Added variation to allVariations")
                        this.allVariations.update((oldValue) => {
                            const newValue = [...oldValue, variation]
                            newValue.sort((a, b) => a.name.localeCompare(b.name))
                            return newValue
                        })
                    }
                },
                error: (e) => {
                    this.state.set("error")
                    console.error("Error while gathering variations", e)
                },
            })
    }

    async submitAllJobs(type: "render" | "postProcess") {
        const variations = this.allVariations().filter(
            (variation) => variation[type] === undefined && (type === "render" ? true : variation.render !== undefined),
        )
        if (variations.length > 200) this.notifications.showInfo(`Starting more than 200 render jobs is not allowed.`)

        const dialogRef: MatDialogRef<DialogComponent, boolean> = this.matDialog.open(DialogComponent, {
            disableClose: false,
            width: DIALOG_DEFAULT_WIDTH,
            data: {
                title: "Submit jobs",
                message: `You are submitting <b>${variations.length}</b> ${type === "render" ? "render" : "finalization"} jobs.</br></br>Are you sure you want to continue?`,
                confirmLabel: "Submit jobs",
                cancelLabel: "Cancel",
            },
        })

        const confirmed = await firstValueFrom(dialogRef.afterClosed().pipe(takeUntilDestroyed(this.destroyRef)))
        if (confirmed !== true) return

        const templateRevisionId = this.localSceneManagerService.$templateRevisionId()
        if (!templateRevisionId) throw Error("No template revision id set")

        const defaultCustomerId = this.localSceneManagerService.$defaultCustomerId()
        if (!defaultCustomerId) throw Error("No default customer id set")

        let numSubmitted = 0
        let numFailed = 0
        for (const variation of variations) {
            try {
                const [jobAssignment, job] = await (async () => {
                    if (type === "render") {
                        this.localSceneManagerService.$instanceParameters.set(variation.parameters)

                        this.localSceneManagerService.compileTemplate()
                        await this.localSceneManagerService.sync()

                        return createRenderJob(
                            this.sdkService,
                            this.renderingService,
                            templateRevisionId,
                            variation.hash,
                            this.localSceneManagerService.$scene(),
                            defaultCustomerId,
                        )
                    } else {
                        const postProcessingSettings = this.postProcessingSettings()
                        if (!postProcessingSettings) throw Error("No post processing settings set")

                        return createPostProcessJob(this.sdkService, templateRevisionId, variation.hash, postProcessingSettings, defaultCustomerId)
                    }
                })()
                this.allVariations.update((variations) => variations.map((v) => (v.hash === variation.hash ? {...variation, [type]: job} : v)))
                numSubmitted += 1
            } catch (e) {
                console.error("Error while submitting job", e)
                numFailed += 1
            }
        }

        this.notifications.showInfo(
            `Submitted ${numSubmitted} ${type === "render" ? "render" : "finalization"} jobs.${numFailed > 0 ? ` Failed to submit ${numFailed} jobs.` : ""}`,
        )
    }

    async deleteAllJobs(type: "postProcess" | "both") {
        const variations = this.allVariations().filter((variation) => {
            if (type === "both") return variation.render !== undefined || variation.postProcess !== undefined
            return variation[type] !== undefined
        })

        const dialogRef: MatDialogRef<DialogComponent, boolean> = this.matDialog.open(DialogComponent, {
            disableClose: false,
            width: DIALOG_DEFAULT_WIDTH,
            data: {
                title: "Submit jobs",
                message: `You are deleting ${type === "postProcess" ? "finalizations" : "images"} of <b>${variations.length}</b> configurations.</br></br>Are you sure you want to continue?`,
                confirmLabel: "Submit jobs",
                cancelLabel: "Cancel",
            },
        })

        const confirmed = await firstValueFrom(dialogRef.afterClosed().pipe(takeUntilDestroyed(this.destroyRef)))
        if (confirmed !== true) return

        const templateRevisionId = this.localSceneManagerService.$templateRevisionId()
        if (!templateRevisionId) throw Error("No template revision id set")

        let numDeleted = 0
        let numFailed = 0
        for (const variation of variations) {
            try {
                if (type === "both" || type === "postProcess") await deletePostProcessJob(this.sdkService, templateRevisionId, variation.hash)
                if (type === "both") await deleteRenderJob(this.sdkService, templateRevisionId, variation.hash)
                this.allVariations.update((variations) =>
                    variations.map((v) =>
                        v.hash === variation.hash ? {...variation, render: type === "both" ? undefined : variation.render, postProcess: undefined} : v,
                    ),
                )
                numDeleted += 1
            } catch (e) {
                console.error("Error while deleting job", e)
                numFailed += 1
            }
        }

        this.notifications.showInfo(
            `Deleted ${numDeleted} ${type === "postProcess" ? "finalizations" : "images"} of configurations.${
                numFailed > 0 ? ` Failed to delete ${numFailed} ${type === "postProcess" ? "finalizations" : "images"} of configurations.` : ""
            }`,
        )
    }
}

function gatherAllVariations(localSceneManagerService: SceneManagerService, progress: (progress: number) => void) {
    const includeAllSubTemplateInputs = localSceneManagerService.$exposeClaimedSubTemplateInputs()

    return new Observable<ConfigurationInfo | "done">((observer) => {
        let isAborted = false

        ;(async () => {
            type PermutationProposal = Record<string, string>

            const allConfigurations = new Set<string>()
            const visitedProposals = new Set<string>()
            const pendingProposals: PermutationProposal[] = [{}]
            let countUpperBound = 1

            progress(0)
            while (true) {
                if (isAborted) break

                const proposal = pendingProposals.shift()
                if (!proposal) break

                localSceneManagerService.$instanceParameters.set(new Parameters(proposal))

                console.log("Compiling template graph")
                localSceneManagerService.compileTemplate()
                try {
                    await localSceneManagerService.sync()
                    //await new Promise((resolve) => setTimeout(resolve, 5000))
                } catch (e) {
                    console.error("Error while syncing template graph", e)
                    continue
                }
                console.log("Compiled template graph")

                const currentConfiguration = includeAllSubTemplateInputs
                    ? localSceneManagerService.$currentGlobalConfiguration()
                    : localSceneManagerService.$currentLocalConfiguration()
                const hash = currentConfiguration.getHash()

                if (!allConfigurations.has(hash)) {
                    const descriptors = localSceneManagerService
                        .getDescriptorsForInstance(localSceneManagerService.$templateInstance())
                        .filter((descriptor): descriptor is ConfigInfo => descriptor instanceof ConfigInfo)

                    const curConfigs: PermutationProposal = {}

                    const configNames: string[] = []
                    for (const descriptor of descriptors) {
                        const {id, name, value} = descriptor.props

                        if (!value) continue

                        curConfigs[id] = value.id
                        configNames.push(`${name}: ${value.name}`)
                    }

                    for (const key of Object.keys(currentConfiguration.parameters)) {
                        if (proposal[key] === undefined) {
                            currentConfiguration.updateParameters({[key]: undefined})
                        }
                    }

                    const newConfiguration: ConfigurationInfo = {
                        name: configNames.length > 0 ? configNames.join(", ") : "Single Variation",
                        parameters: currentConfiguration,
                        hash,
                    }

                    observer.next(newConfiguration)

                    allConfigurations.add(hash)

                    for (const descriptor of descriptors) {
                        const {id, variants} = descriptor.props

                        for (const variant of variants.filter((x) => !x.excludeFromPermutations)) {
                            const proposal: PermutationProposal = {
                                ...curConfigs,
                                [id]: variant.id,
                            }

                            const key = hashObject(proposal)
                            if (!visitedProposals.has(key)) {
                                countUpperBound += 1
                                visitedProposals.add(key)
                                pendingProposals.push(proposal)
                            }
                        }
                    }
                }

                console.log(`Proposals remaining: ${pendingProposals.length} / ${countUpperBound}`)
                progress(((countUpperBound - pendingProposals.length) / countUpperBound) * 100)
            }

            observer.next("done")
            observer.complete()
        })()

        return () => {
            isAborted = true
        }
    })
}
