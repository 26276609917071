import {ImageInputFwd, JSONInput, MaterialInputFwd, ObjectInputFwd, TemplateInputFwd} from "@src/templates/nodes/input"
import {
    BooleanOutputFwd,
    ImageOutput,
    ImageOutputFwd,
    JSONOutput,
    MaterialOutput,
    MaterialOutputFwd,
    NumberOutput,
    NumberOutputFwd,
    ObjectOutput,
    ObjectOutputFwd,
    StringOutput,
    StringOutputFwd,
    TemplateOutput,
    TemplateOutputFwd,
} from "@src/templates/nodes/output"
import {
    BooleanSwitchFwd,
    BooleanLikes,
    ImageSwitch,
    ImageSwitchFwd,
    ImageLikes,
    MaterialSwitchFwd,
    MaterialLikes,
    NumberSwitchFwd,
    NumberLikes,
    ObjectSwitchFwd,
    ObjectLikes,
    StringSwitchFwd,
    StringLikes,
    JSONSwitchFwd,
    JSONSwitch,
    JSONLikes,
    TemplateSwitch,
    TemplateSwitchFwd,
    TemplateLikes,
} from "@src/templates/nodes/switch"
import {CameraFwd} from "@src/templates/nodes/camera"
import {OverlayMaterialColorFwd} from "@src/templates/nodes/overlay-material-color"
import {TemplateGraphFwd} from "@src/templates/nodes/template-graph"
import {TemplateInstanceFwd} from "@src/templates/nodes/template-instance"
import {GroupFwd} from "@src/templates/nodes/group"
import {ConfigVariantFwd} from "@src/templates/nodes/config-variant"
import {ConfigGroupFwd} from "@src/templates/nodes/config-group"
import {AnyJSONValue, TemplateNode, anyJsonValue, isAnyJSONValue} from "@src/templates/types"
import {z} from "zod"
import {ProceduralMesh} from "@src/templates/nodes/procedural-mesh"
import {StoredMesh} from "@src/templates/nodes/stored-mesh"
import {BooleanValue, JSONValue, NumberValue, ObjectValue, StringValue} from "@src/templates/nodes/value"
import {BooleanInput, ImageInput, MaterialInput, NumberInput, ObjectInput, StringInput, TemplateInput} from "@src/templates/nodes/input"
import {BooleanOutput} from "@src/templates/nodes/output"
import {BooleanSwitch, MaterialSwitch, NumberSwitch, ObjectSwitch, StringSwitch} from "@src/templates/nodes/switch"
import {Annotation} from "@src/templates/nodes/annotation"
import {PlaneGuide} from "@src/templates/nodes/plane-guide"
import {PointGuide} from "@src/templates/nodes/point-guide"
import {AreaLight} from "@src/templates/nodes/area-light"
import {LightPortal} from "@src/templates/nodes/light-portal"
import {MaterialReference} from "@src/templates/nodes/material-reference"
import {FindMaterial} from "@src/templates/nodes/find-material"
import {MaterialGraphReference} from "@src/templates/nodes/material-graph-reference"
import {TemplateReference} from "@src/templates/nodes/template-reference"
import {DataObjectReference} from "@src/templates/nodes/data-object-reference"
import {TransientDataObject} from "@src/templates/nodes/transient-data-object"
import {MeshDecal} from "@src/templates/nodes/mesh-decal"
import {HDRILight} from "@src/templates/nodes/hdri-light"
import {SceneProperties} from "@src/templates/nodes/scene-properties"
import {Render} from "@src/templates/nodes/render"
import {PostProcessRender} from "@src/templates/nodes/post-process-render"
import {RigidRelation} from "@src/templates/nodes/rigid-relation"
import {RegexReplaceFwd} from "@src/templates/nodes/regex-replace"
import {R1Variable, S1Variable, S3Variable} from "@src/templates/nodes/variable"
import {StringResolve} from "@src/templates/nodes/string-resolve"
import {BooleanExport, ImageExport, JSONExport, MaterialExport, NumberExport, ObjectExport, StringExport, TemplateExport} from "@src/templates/nodes/export"
import {Nodes} from "@src/templates/nodes/nodes"
import {templateNode} from "@src/templates/types"
import {LodType} from "./nodes/lod-type"

export type Mesh = StoredMesh | ProceduralMesh
export type Guide = PlaneGuide | PointGuide
export type Light = AreaLight | LightPortal
export type Object = Mesh | TemplateInstanceFwd | Guide | Light | CameraFwd | Annotation | MeshDecal
export type ObjectLike = Object | ObjectValue | ObjectInputFwd | ObjectSwitchFwd | ObjectOutputFwd

export type MaterialLike =
    | FindMaterial
    | MaterialReference
    | MaterialGraphReference
    | OverlayMaterialColorFwd
    | MaterialInputFwd
    | MaterialSwitchFwd
    | MaterialOutputFwd

export type TemplateLike = TemplateReference | TemplateGraphFwd | TemplateInputFwd | TemplateSwitchFwd | TemplateOutputFwd

export type ImageLike = DataObjectReference | TransientDataObject | ImageInputFwd | ImageSwitchFwd | ImageOutputFwd

export type StringLikeNode = StringResolve | RegexReplaceFwd | StringValue | StringInput | StringSwitchFwd | StringOutputFwd
export type StringLike = string | StringLikeNode
export type NumberLikeNode = NumberValue | NumberInput | NumberSwitchFwd | NumberOutputFwd
export type NumberLike = number | NumberLikeNode
export type BooleanLikeNode = BooleanValue | LodType | BooleanInput | BooleanSwitchFwd | BooleanOutputFwd
export type BooleanLike = boolean | BooleanLikeNode
export type JSONLikeNode = JSONValue | JSONInput | JSONSwitchFwd | JSONOutput
export type JSONLike = AnyJSONValue | JSONLikeNode

export type Variable = R1Variable | S1Variable | S3Variable

export type Input = ObjectInput | MaterialInput | TemplateInput | ImageInput | StringInput | NumberInput | BooleanInput | JSONInput
export type Output = ObjectOutput | MaterialOutput | TemplateOutput | ImageOutput | StringOutput | NumberOutput | BooleanOutput | JSONOutput
export type Export = ObjectExport | MaterialExport | TemplateExport | ImageExport | StringExport | NumberExport | BooleanExport | JSONExport
export type Value = StringValue | NumberValue | BooleanValue | JSONValue | ObjectValue

export type Switch = ObjectSwitch | MaterialSwitch | TemplateSwitch | ImageSwitch | StringSwitch | NumberSwitch | BooleanSwitch | JSONSwitch
export type SwitchContainer = ObjectLikes | MaterialLikes | TemplateLikes | ImageLikes | StringLikes | NumberLikes | BooleanLikes | JSONLikes

export type NodeOwner = TemplateGraphFwd | GroupFwd | ConfigVariantFwd | ConfigGroupFwd
export type TemplateOwningContainer = Nodes
export type TemplateReferenceContainer = SwitchContainer
export type TemplateContainer = TemplateOwningContainer | TemplateReferenceContainer

export type ValueNode =
    | Exclude<ObjectLike, Object>
    | MaterialLike
    | TemplateLike
    | ImageLike
    | StringLikeNode
    | NumberLikeNode
    | BooleanLikeNode
    | JSONLikeNode
    | Export
    | Variable

export type Node =
    | ObjectLike
    | MaterialLike
    | TemplateLike
    | ImageLike
    | StringLikeNode
    | NumberLikeNode
    | BooleanLikeNode
    | JSONLikeNode
    | Export
    | RigidRelation
    | Variable
    | HDRILight
    | SceneProperties
    | Render
    | PostProcessRender
    | NodeOwner

///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const meshClasses = ["StoredMesh", "ProceduralMesh"]
export const lightClasses = ["AreaLight", "LightPortal"]
export const guideClasses = ["PlaneGuide", "PointGuide"]
export const objectClasses = [...meshClasses, "TemplateInstance", ...guideClasses, ...lightClasses, "Camera", "Annotation", "MeshDecal"]
export const objectLikeClasses = [...objectClasses, "ObjectValue", "ObjectInput", "ObjectSwitch", "ObjectOutput"]

export const materialLikeClasses = [
    "FindMaterial",
    "MaterialReference",
    "MaterialGraphReference",
    "OverlayMaterialColor",
    "MaterialInput",
    "MaterialSwitch",
    "MaterialOutput",
]

export const templateLikeClasses = ["TemplateReference", "TemplateGraph", "TemplateInput", "TemplateSwitch", "TemplateOutput"]

export const imageLikeClasses = ["DataObjectReference", "TransientDataObject", "ImageInput", "ImageSwitch", "ImageOutput"]

export const stringLikeNodeClasses = ["StringResolve", "RegexReplace", "StringValue", "StringInput", "StringSwitch", "StringOutput"]
export const numberLikeNodeClasses = ["NumberValue", "NumberInput", "NumberSwitch", "NumberOutput"]
export const booleanLikeNodeClasses = ["BooleanValue", "LodType", "BooleanInput", "BooleanSwitch", "BooleanOutput"]
export const jsonLikeNodeClasses = ["JSONValue", "JSONInput", "JSONSwitch", "JSONOutput"]

export const variableClasses = ["R1Variable", "S1Variable", "S3Variable"]

export const inputClasses = ["ObjectInput", "MaterialInput", "TemplateInput", "ImageInput", "StringInput", "NumberInput", "BooleanInput", "JSONInput"]
export const outputClasses = ["ObjectOutput", "MaterialOutput", "TemplateOutput", "ImageOutput", "StringOutput", "NumberOutput", "BooleanOutput", "JSONOutput"]
export const exportClasses = ["ObjectExport", "MaterialExport", "TemplateExport", "ImageExport", "StringExport", "NumberExport", "BooleanExport", "JSONExport"]
export const valueClasses = ["StringValue", "NumberValue", "BooleanValue", "JSONValue", "ObjectValue"]

export const switchClasses = ["ObjectSwitch", "MaterialSwitch", "TemplateSwitch", "ImageSwitch", "StringSwitch", "NumberSwitch", "BooleanSwitch", "JSONSwitch"]
export const switchContainerClasses = ["ObjectLikes", "MaterialLikes", "TemplateLikes", "ImageLikes", "StringLikes", "NumberLikes", "BooleanLikes", "JSONLikes"]

export const nodeOwnerClasses = ["TemplateGraph", "Group", "ConfigVariant", "ConfigGroup"]
export const templateOwningContainerClasses = ["Nodes"]
export const templateReferenceContainerClasses = [...switchContainerClasses]
export const templateContainerClasses = [...templateOwningContainerClasses, ...templateReferenceContainerClasses]

export const valueNodeClasses = [
    ...objectLikeClasses.filter((c) => !objectClasses.includes(c)),
    ...materialLikeClasses,
    ...templateLikeClasses,
    ...imageLikeClasses,
    ...stringLikeNodeClasses,
    ...numberLikeNodeClasses,
    ...booleanLikeNodeClasses,
    ...jsonLikeNodeClasses,
    ...exportClasses,
    ...variableClasses,
]

export const nodeClasses = [
    ...objectLikeClasses,
    ...materialLikeClasses,
    ...templateLikeClasses,
    ...imageLikeClasses,
    ...stringLikeNodeClasses,
    ...numberLikeNodeClasses,
    ...booleanLikeNodeClasses,
    ...jsonLikeNodeClasses,
    ...exportClasses,
    "RigidRelation",
    ...variableClasses,
    "HDRILight",
    "SceneProperties",
    "Render",
    "PostProcessRender",
    ...nodeOwnerClasses,
]

///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const isMesh = (instance: TemplateNode): instance is Mesh => meshClasses.includes(instance.getNodeClass())
export const isGuide = (instance: TemplateNode): instance is Guide => guideClasses.includes(instance.getNodeClass())
export const isLight = (instance: TemplateNode): instance is Light => lightClasses.includes(instance.getNodeClass())
export const isObject = (instance: TemplateNode): instance is Object => objectClasses.includes(instance.getNodeClass())
export const isObjectLike = (instance: TemplateNode): instance is ObjectLike => objectLikeClasses.includes(instance.getNodeClass())

export const isMaterialLike = (instance: TemplateNode): instance is MaterialLike => materialLikeClasses.includes(instance.getNodeClass())

export const isTemplateLike = (instance: TemplateNode): instance is TemplateLike => templateLikeClasses.includes(instance.getNodeClass())

export const isImageLike = (instance: TemplateNode): instance is ImageLike => imageLikeClasses.includes(instance.getNodeClass())

export const isStringLikeNode = (instance: TemplateNode): instance is StringLikeNode => stringLikeNodeClasses.includes(instance.getNodeClass())
export const isStringLike = (instance: TemplateNode | string): instance is StringLike => typeof instance === "string" || isStringLikeNode(instance)
export const isNumberLikeNode = (instance: TemplateNode): instance is NumberLikeNode => numberLikeNodeClasses.includes(instance.getNodeClass())
export const isNumberLike = (instance: TemplateNode | number): instance is NumberLike => typeof instance === "number" || isNumberLikeNode(instance)
export const isBooleanLikeNode = (instance: TemplateNode): instance is BooleanLikeNode => booleanLikeNodeClasses.includes(instance.getNodeClass())
export const isBooleanLike = (instance: TemplateNode | boolean): instance is BooleanLike => typeof instance === "boolean" || isBooleanLikeNode(instance)
export const isJSONLikeNode = (instance: TemplateNode): instance is JSONLikeNode => jsonLikeNodeClasses.includes(instance.getNodeClass())
export const isJSONLike = (instance: TemplateNode | AnyJSONValue): instance is JSONLike => isAnyJSONValue(instance) || isJSONLikeNode(instance)

export const isVariable = (instance: TemplateNode): instance is Variable => variableClasses.includes(instance.getNodeClass())

export const isInput = (instance: TemplateNode): instance is Input => inputClasses.includes(instance.getNodeClass())
export const isOutput = (instance: TemplateNode): instance is Output => outputClasses.includes(instance.getNodeClass())
export const isExport = (instance: TemplateNode): instance is Export => exportClasses.includes(instance.getNodeClass())
export const isValue = (instance: TemplateNode): instance is Value => valueClasses.includes(instance.getNodeClass())

export const isSwitch = (instance: TemplateNode): instance is Switch => switchClasses.includes(instance.getNodeClass())
export const isSwitchContainer = (instance: TemplateNode): instance is SwitchContainer => switchContainerClasses.includes(instance.getNodeClass())

export const isNodeOwner = (instance: TemplateNode): instance is NodeOwner => nodeOwnerClasses.includes(instance.getNodeClass())
export const isTemplateOwningContainer = (instance: TemplateNode): instance is TemplateOwningContainer =>
    templateOwningContainerClasses.includes(instance.getNodeClass())
export const isTemplateReferenceContainer = (instance: TemplateNode): instance is TemplateReferenceContainer =>
    templateReferenceContainerClasses.includes(instance.getNodeClass())
export const isTemplateContainer = (instance: TemplateNode): instance is TemplateContainer => templateContainerClasses.includes(instance.getNodeClass())

export const isNodeValue = (instance: TemplateNode): instance is ValueNode => valueNodeClasses.includes(instance.getNodeClass())

export const isNode = (instance: TemplateNode): instance is Node => nodeClasses.includes(instance.getNodeClass())

///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const mesh = templateNode.refine(isMesh, {message: "Expected mesh"})
export const guide = templateNode.refine(isGuide, {message: "Expected guide"})
export const light = templateNode.refine(isLight, {message: "Expected light"})
export const object = templateNode.refine(isObject, {message: "Expected object"})
export const objectLike = templateNode.refine(isObjectLike, {message: "Expected object like"})

export const materialLike = templateNode.refine(isMaterialLike, {message: "Expected material like"})

export const templateLike = templateNode.refine(isTemplateLike, {message: "Expected template definition"})

export const imageLike = templateNode.refine(isImageLike, {message: "Expected image like"})

export const stringLikeNode = templateNode.refine(isStringLikeNode, {message: "Expected string like node"})
export const stringLike = templateNode.or(z.string()).refine(isStringLike, {message: "Expected string like"})
export const numberLikeNode = templateNode.refine(isNumberLikeNode, {message: "Expected number like node"})
export const numberLike = templateNode.or(z.number()).refine(isNumberLike, {message: "Expected number like"})
export const booleanLikeNode = templateNode.refine(isBooleanLikeNode, {message: "Expected boolean like node"})
export const booleanLike = templateNode.or(z.boolean()).refine(isBooleanLike, {message: "Expected boolean like"})
export const jsonLikeNode = templateNode.refine(isJSONLikeNode, {message: "Expected JSON like node"})
export const jsonLike = templateNode.or(anyJsonValue).refine(isJSONLike, {message: "Expected JSON like"})

export const variable = templateNode.refine(isVariable, {message: "Expected variable"})

export const input = templateNode.refine(isInput, {message: "Expected input"})
export const output = templateNode.refine(isOutput, {message: "Expected output"})
export const exportNode = templateNode.refine(isExport, {message: "Expected export"})
export const value = templateNode.refine(isValue, {message: "Expected value"})

export const switchNode = templateNode.refine(isSwitch, {message: "Expected switch"})
export const switchContainer = templateNode.refine(isSwitchContainer, {message: "Expected switch container"})

export const nodeOwner = templateNode.refine(isNodeOwner, {message: "Expected node owner"})
export const templateOwningContainer = templateNode.refine(isTemplateOwningContainer, {message: "Expected template owning container"})
export const templateReferenceContainer = templateNode.refine(isTemplateReferenceContainer, {message: "Expected template reference container"})
export const templateNodeContainer = templateNode.refine(isTemplateContainer, {message: "Expected template node container"})

export const valueNode = templateNode.refine(isNodeValue, {message: "Expected value node"})

export const node = templateNode.refine(isNode, {message: "Expected node"})
