import {NodeGraphClass, NodeParameters, nodeParameters} from "@src/graph-system/node-graph"
import {EvaluableTemplateNode} from "@src/templates/evaluable-template-node"
import {ObjectData} from "@src/templates/interfaces/object-data"
import {NodeEvaluator} from "@src/templates/node-evaluator"
import {GraphBuilderScope} from "@src/templates/runtime-graph/graph-builder-scope"
import {DeclareTemplateNodeTS, TemplateNodeTSImplementation, TemplateNodeImplementation, TemplateNodeMeta} from "@src/templates/declare-template-node"
import {TemplateNode} from "@src/templates/types"
import {z} from "zod"
import {OnCompileContext, matrix4Value} from "@src/templates/types"
import {SolverObjectData} from "@src/templates/runtime-graph/nodes/solver/object-data"
import {Transform, TransformState} from "@src/templates/runtime-graph/nodes/transform"
import {ThisStructID} from "@src/templates/runtime-graph/types"
import {MeshData, BoundsData} from "@src/geometry-processing/mesh-data"
import {BuilderInlet, BuilderOutlet} from "@src/templates/runtime-graph/graph-builder"
import {transformBounds} from "@src/templates/utils/scene-geometry-utils"
import {Matrix4} from "@src/math"
import {TransformAccessorListEntry} from "@src/templates/runtime-graph/nodes/compile-template-new"

const objectNode = z.object({
    lockedTransform: matrix4Value.optional(),
    $defaultTransform: matrix4Value.optional(),
})
export type ObjectNode = z.infer<typeof objectNode>

export function DeclareObjectNode<ZodParamTypes extends z.ZodType<NodeParameters>>(
    definition: {
        parameters: ZodParamTypes
    },
    implementation: TemplateNodeImplementation<z.infer<typeof definition.parameters> & ObjectNode>,
    meta: TemplateNodeMeta<z.infer<typeof definition.parameters> & ObjectNode>,
) {
    const {parameters: paramsSchema} = definition
    type ParamTypes = z.infer<typeof paramsSchema>

    return DeclareObjectNodeTS<ParamTypes>({...implementation, validation: {paramsSchema}}, meta)
}

export function DeclareObjectNodeTS<ParamTypes extends NodeParameters>(
    implementation: TemplateNodeTSImplementation<ParamTypes & ObjectNode>,
    meta: TemplateNodeMeta<ParamTypes & ObjectNode>,
): NodeGraphClass<TemplateObjectNode<ParamTypes>> {
    const retClass = class
        extends DeclareTemplateNodeTS<ParamTypes & ObjectNode>(
            {...implementation, validation: {paramsSchema: objectNode.and(implementation.validation?.paramsSchema ?? nodeParameters)}},
            meta,
        )
        implements EvaluableTemplateNode<ObjectData>
    {
        evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator) {
            return evaluator.templateScope.resolve<ObjectData>(`objectData-${evaluator.getLocalId(this)}`)
        }

        setupTransform(
            scope: GraphBuilderScope,
            context: OnCompileContext,
            objectId: string,
        ): readonly [BuilderOutlet<Matrix4>, BuilderOutlet<SolverObjectData>] {
            const {evaluator, currentTemplate} = context
            const {solverData, transformAccessorList} = currentTemplate
            const {templateContext, templateMatrix} = evaluator
            const {sceneManager} = templateContext
            const {lockedTransform} = this.parameters
            const {solverObjects} = solverData

            const transform = scope.node(Transform, {
                state: scope.state(TransformState, "transformState"),
                defaultTransform: sceneManager.defaultTransformForObjectNew(this)?.toArray(),
                lockedTransform,
                parentMatrix: templateMatrix,
            })

            const solverObject = scope.struct<SolverObjectData>("SolverObjectData", {
                id: ThisStructID,
                transformAccessor: transform.accessor,
            })
            solverObjects.push(solverObject)

            transformAccessorList.push(
                scope.struct<TransformAccessorListEntry>("TransformAccessorListEntry", {
                    objectId,
                    transformAccessor: transform.accessor,
                }),
            )

            return [transform.matrix, solverObject] as const
        }

        setupObject(
            scope: GraphBuilderScope,
            context: OnCompileContext,
            typeName: string,
            meshData: BuilderInlet<MeshData> | undefined,
            transform: BuilderInlet<Matrix4> | undefined,
        ) {
            const {evaluator, topLevelObjectId, currentTemplate} = context
            const {allBounds, objectToNodeMap, nodeToObjectMap} = currentTemplate
            const {templateScope} = evaluator

            const objectId = scope.genStructId(typeName)
            objectToNodeMap.set(objectId, this)
            nodeToObjectMap.set(this, objectId)

            const [transformMatrix, solverObject] = transform ? [transform, null] : this.setupTransform(scope, context, objectId)

            const bounds = scope.lambda(
                scope.tuple(
                    ((): BuilderInlet<BoundsData> => {
                        if (meshData) return scope.get(meshData, "bounds")
                        else {
                            //TODO: proper bounds for planes, etc.
                            return {
                                centroid: [0, 0, 0],
                                surfaceArea: 0,
                                aabb: [
                                    [0, 0, 0],
                                    [0, 0, 0],
                                ],
                                radii: {xy: 0, xz: 0, yz: 0, xyz: 0},
                            }
                        }
                    })(),
                    transformMatrix,
                ),
                ([bounds, matrix]) => transformBounds(bounds, matrix),
                "transformBounds",
            )
            allBounds.push(bounds)

            if (solverObject) {
                const objectData = scope.struct<ObjectData>("ObjectData", {
                    matrix: transformMatrix,
                    solverObject,
                    bounds,
                    meshData,
                })

                templateScope.alias(objectData, `objectData-${evaluator.getLocalId(this)}`)
            }

            return {
                $id: objectId,
                id: objectId,
                topLevelObjectId: topLevelObjectId ?? objectId,
                transform: transformMatrix,
            }
        }
    }
    return retClass
}

export type TemplateObjectNode<ParamTypes extends NodeParameters = {}> = TemplateNode<ParamTypes & ObjectNode> &
    EvaluableTemplateNode<ObjectData> & {
        setupTransform(
            scope: GraphBuilderScope,
            context: OnCompileContext,
            objectId: string,
        ): readonly [BuilderOutlet<Matrix4>, BuilderOutlet<SolverObjectData>]
        setupObject(
            scope: GraphBuilderScope,
            context: OnCompileContext,
            typeName: string,
            meshData: BuilderInlet<MeshData> | undefined,
            transform: BuilderInlet<Matrix4> | undefined,
        ): {
            $id: string
            id: string
            topLevelObjectId: string
            transform: BuilderInlet<Matrix4>
        }
    }
