import {ContentTypeModel, TextureSetRevisionDataFragment, TextureType} from "@api"
import {Settings} from "@app/common/models/settings/settings"
import {AuthService} from "@app/common/services/auth/auth.service"
import {InjectorService} from "@app/common/services/injector/injector.service"
import {ImageProcessingService} from "@app/common/services/rendering/image-processing.service"
import {SdkService} from "@app/common/services/sdk/sdk.service"
import {OperatorStack} from "@app/textures/texture-editor/operator-stack/operator-stack"
import {AutoTilingState, OperatorAutoTiling} from "@app/textures/texture-editor/operator-stack/operators/auto-tiling/operator-auto-tiling"
import {ImageProcessingNodes as Nodes} from "@cm/lib/image-processing/image-processing-nodes"
import {JobNodes} from "@cm/lib/job-task/job-nodes"
import {displacementGenerationTask} from "@cm/lib/job-task/tiling"
import {uploadProcessingThumbnailsTask} from "@cm/lib/job-task/upload-processing"
import {Utility} from "@cm/lib/job-task/utility"
import {graphToJson} from "@cm/lib/utils/graph-json"
import {TexturesApiService} from "app/textures/service/textures-api.service"
import {getSimpleJobState, SimpleJobState} from "app/textures/utils/simple-job-state"
import {descriptorByTextureType} from "app/textures/utils/texture-type-descriptor"
import {ImageOpNodeGraphEvaluatorImgProc} from "@app/textures/texture-editor/operator-stack/image-op-system/image-op-node-graph-evaluator-imgproc"
import {getSourceMapDataObjectIds} from "@app/textures/texture-editor/texture-editor-callback"

export class TextureEditProcessing {
    constructor(injectorService: InjectorService) {
        this.textureApi = injectorService.injector.get(TexturesApiService)
        this.sdk = injectorService.injector.get(SdkService)
        this.imageProcessingService = injectorService.injector.get(ImageProcessingService)
        this.authService = injectorService.injector.get(AuthService)
    }

    async createBakingJob(operatorStack: OperatorStack, textureSetRevision: TextureSetRevisionDataFragment, pxPerCm: number): Promise<string> {
        // abort previous job if it is still running
        await this.cancelBakingJob(textureSetRevision)
        // create new job
        return await this.createJob(operatorStack, textureSetRevision, pxPerCm)
    }

    async cancelBakingJob(textureSetRevision: TextureSetRevisionDataFragment) {
        if (textureSetRevision.editsProcessingJob && getSimpleJobState(textureSetRevision.editsProcessingJob.state) === SimpleJobState.InProgress) {
            await this.sdk.gql.textureEditorCancelProcessingJob({jobId: textureSetRevision.editsProcessingJob.id})
        }
    }

    private async createJob(operatorStack: OperatorStack, textureSetRevision: TextureSetRevisionDataFragment, pxPerCm: number) {
        const user = await this.authService.user
        if (!user) {
            throw new Error("User not found")
        }
        const organizationId = textureSetRevision.textureSet.textureGroup.organization.id
        const organizationLegacyId = textureSetRevision.textureSet.textureGroup.organization.legacyId
        const sourceMapDataObjectIdByTextureType = getSourceMapDataObjectIds(textureSetRevision)

        // do not process displacement
        // const displacementSourceMapDataObjectId = sourceMapDataObjectIdByTextureType.get(TextureType.Displacement)
        // sourceMapDataObjectIdByTextureType.delete(TextureType.Displacement)

        const progressItems: {node: JobNodes.ProgressNode; factor?: number}[] = []
        const PROGRESS_FACTOR_TILING = 2.0
        const PROGRESS_FACTOR_DISPLACEMENT_GENERATION = 0.4
        const PROGRESS_FACTOR_IMAGE_PROCESSING = 0.2
        const PROGRESS_FACTOR_THUMBNAILS = 0.1
        const PROGRESS_FACTOR_MINOR = 0.01

        const filenameForTextureType = (textureType: TextureType) => `TextureMap-${textureType}.exr`

        const processingByTextureType: Map<TextureType, number | JobNodes.TypedDataNode<JobNodes.DataObjectReference>> = new Map()
        const autoTilingOperatorToProcess = operatorStack.operators.find((operator) => operator instanceof OperatorAutoTiling) as OperatorAutoTiling | undefined
        if (autoTilingOperatorToProcess && autoTilingOperatorToProcess.autoTilingState !== AutoTilingState.Complete) {
            const tilingJob = await autoTilingOperatorToProcess.createTilingTaskFromParams(organizationLegacyId, sourceMapDataObjectIdByTextureType, pxPerCm)
            progressItems.push({node: tilingJob, factor: PROGRESS_FACTOR_TILING})
            const dataObjectReferenceByTextureTypeId = JobNodes.get(tilingJob, "dataObjectReferenceByTextureTypeId")
            for (const textureType of sourceMapDataObjectIdByTextureType.keys()) {
                const dataObjectReference = JobNodes.get(dataObjectReferenceByTextureTypeId, descriptorByTextureType(textureType).legacyEnumId)
                const updateOriginalFilenameTask = Utility.DataObject.update(dataObjectReference, {
                    originalFileName: `TextureMap-${textureType}.exr`,
                })
                progressItems.push({node: updateOriginalFilenameTask, factor: PROGRESS_FACTOR_MINOR})
                const updatedDataObjectReference = JobNodes.get(updateOriginalFilenameTask, "dataObject")
                processingByTextureType.set(textureType, updatedDataObjectReference)
            }
        } else {
            const getTrivialGraphDataObjectLegacyId = (graph: Nodes.Node): number | undefined => {
                const findTrivialGraphDataObjectLegacyId = (node: Nodes.Node): number | undefined => {
                    switch (node.type) {
                        case "levels": {
                            if (typeof node.blackLevel === "number" && typeof node.whiteLevel === "number" && node.blackLevel === 0 && node.whiteLevel === 1) {
                                return findTrivialGraphDataObjectLegacyId(node.input)
                            } else {
                                return undefined
                            }
                        }
                        case "convert": {
                            return findTrivialGraphDataObjectLegacyId(node.input)
                        }
                        case "decode": {
                            return findTrivialGraphDataObjectLegacyId(node.input)
                        }
                        case "encode": {
                            return findTrivialGraphDataObjectLegacyId(node.input)
                        }
                        case "externalData": {
                            return node.sourceData.type === "dataObjectReference" ? node.sourceData.dataObjectId : undefined
                        }
                        default: {
                            return undefined
                        }
                    }
                }
                return findTrivialGraphDataObjectLegacyId(graph)
            }

            const getProcessingForTextureType = async (
                textureType: TextureType,
                sourceDataObjectId: string,
            ): Promise<number | JobNodes.TypedDataNode<JobNodes.DataObjectReference>> => {
                const imageProcessingGraph = await this.bakeTextureEditsGraph(operatorStack, textureType, sourceDataObjectId)
                const trivialGraphDataObjectLegacyId = getTrivialGraphDataObjectLegacyId(imageProcessingGraph)
                if (trivialGraphDataObjectLegacyId) {
                    // there is no need to processes the image and generate a new one, as the graph is trivial and only contains a decode/encode operation
                    // console.log(`Reusing texture data object for texture type ${textureType} due to trivial image processing graph`)
                    return trivialGraphDataObjectLegacyId
                } else {
                    const imgProcTask = await this.imageProcessingService.createImageProcessingTask(
                        imageProcessingGraph,
                        {organizationId},
                        `img-proc-${descriptorByTextureType(textureType).label}.json`,
                    )
                    progressItems.push({node: imgProcTask, factor: PROGRESS_FACTOR_IMAGE_PROCESSING})
                    const dataObjectUpdateTask = Utility.DataObject.update(imgProcTask, {
                        originalFileName: filenameForTextureType(textureType),
                        imageColorSpace: "LINEAR",
                        imageDataType: descriptorByTextureType(textureType).isColorData ? "COLOR" : "NON_COLOR",
                    })
                    progressItems.push({node: dataObjectUpdateTask, factor: PROGRESS_FACTOR_MINOR})
                    const dataObjectReference = JobNodes.get(dataObjectUpdateTask, "dataObject")
                    return dataObjectReference
                }
            }

            // we need to do a bit of a stunt here as we want to process the normal map first to determine if we want to process the displacement map as well
            // - if the normal map has operators applied to it, we regenerate the displacement map (further down)
            // - if the normal map is untouched, we can reuse the existing displacement map but for that we need to process the (trivial) displacement map (as auto-tiling might have changed the source)
            let textureTypeAndSourceMapDataObjectId = Array.from(sourceMapDataObjectIdByTextureType.entries())
            const normalMapSourceMapDataObjectId = textureTypeAndSourceMapDataObjectId.find(
                ([textureType, _dataObjectId]) => textureType === TextureType.Normal,
            )?.[1]
            const normalMapProcessing = normalMapSourceMapDataObjectId
                ? await getProcessingForTextureType(TextureType.Normal, normalMapSourceMapDataObjectId)
                : undefined
            if (normalMapProcessing) {
                processingByTextureType.set(TextureType.Normal, normalMapProcessing)
                if (typeof normalMapProcessing !== "number") {
                    // if we process the normal map then we don't need to process the displacement map as it will be regenerated by the normal map
                    textureTypeAndSourceMapDataObjectId = textureTypeAndSourceMapDataObjectId.filter(
                        ([textureType]) => textureType !== TextureType.Displacement,
                    )
                }
            }
            textureTypeAndSourceMapDataObjectId = textureTypeAndSourceMapDataObjectId.filter(([textureType]) => textureType !== TextureType.Normal) // we already computed the normal map's processing above
            await Promise.all(
                textureTypeAndSourceMapDataObjectId.map(async ([textureType, dataObjectId]) => {
                    const processing = await getProcessingForTextureType(textureType, dataObjectId)
                    processingByTextureType.set(textureType, processing)
                }),
            )
        }

        // for the displacement map we either use the existing one when the normal map is untouched, or we regenerate it from the normal map
        let customDisplacementValue: JobNodes.TypedDataNode<number | undefined> = JobNodes.value(undefined)
        const normalMapProcessing = processingByTextureType.get(TextureType.Normal)
        if (normalMapProcessing && typeof normalMapProcessing !== "number") {
            // we process the normal map so let's regenerate the displacement map as well
            const displacementGenerationJob = JobNodes.task(displacementGenerationTask, {
                input: JobNodes.struct({
                    organizationLegacyId: JobNodes.value(textureSetRevision.textureSet.textureGroup.organization.legacyId),
                    normalMapDataObject: normalMapProcessing,
                    pxPerCm: JobNodes.value(pxPerCm),
                }),
            })
            progressItems.push({node: displacementGenerationJob, factor: PROGRESS_FACTOR_DISPLACEMENT_GENERATION})
            const displacementMapDataObjectReference = JobNodes.get(displacementGenerationJob, "displacementMapDataObjectReference")
            customDisplacementValue = JobNodes.get(displacementGenerationJob, "displacementCm")
            const updateOriginalFilenameTask = Utility.DataObject.update(displacementMapDataObjectReference, {
                originalFileName: `TextureMap-${TextureType.Displacement}.exr`,
            })
            progressItems.push({node: updateOriginalFilenameTask, factor: PROGRESS_FACTOR_MINOR})
            const updatedDataObjectReference = JobNodes.get(updateOriginalFilenameTask, "dataObject")
            processingByTextureType.set(TextureType.Displacement, updatedDataObjectReference)
        }
        // TODO this would be simpler, but auto-tiling might change the source map data object id so we can't do it like this
        // else if (displacementSourceMapDataObjectId) {
        //     const displacementMapDataObjectLegacyId = await this.textureApi.getDataObjectLegacyId(displacementSourceMapDataObjectId)
        //     processingByTextureType.set(TextureType.Displacement, displacementMapDataObjectLegacyId)
        // }

        // create thumbnails for all new data objects and generate new map assignments
        const newMapAssignments = JobNodes.list(
            Array.from(processingByTextureType.entries()).map(([textureType, newDataObjectReferenceOrId]) => {
                if (typeof newDataObjectReferenceOrId === "number") {
                    return JobNodes.struct({textureType: JobNodes.value(textureType), dataObjectId: JobNodes.value(newDataObjectReferenceOrId)})
                } else {
                    const thumbnailTask = JobNodes.task(uploadProcessingThumbnailsTask, {
                        input: JobNodes.struct({
                            dataObject: newDataObjectReferenceOrId,
                        }),
                    })
                    progressItems.push({node: thumbnailTask, factor: PROGRESS_FACTOR_THUMBNAILS})
                    const dataObjectReference = JobNodes.get(thumbnailTask, "dataObject")
                    const dataObjectId = JobNodes.get(dataObjectReference, "dataObjectId")
                    return JobNodes.struct({textureType: JobNodes.value(textureType), dataObjectId: dataObjectId})
                }
            }),
        )

        // create the texture-set-revision update task
        const updateTextureSetRevisionJobTask = JobNodes.task(Utility.TextureSetRevision.task, {
            input: JobNodes.struct({
                operation: JobNodes.value("update" as const),
                fields: JobNodes.struct({
                    textureSetRevisionId: JobNodes.value(textureSetRevision.id),
                    updatedById: JobNodes.value(user.id),
                    autoRescaleSize: JobNodes.value(true),
                    displacement: customDisplacementValue,
                    mapAssignments: newMapAssignments,
                }),
            }),
        })
        progressItems.push({node: updateTextureSetRevisionJobTask, factor: PROGRESS_FACTOR_MINOR})

        const jobGraph = JobNodes.jobGraph(updateTextureSetRevisionJobTask, {
            progress: {
                type: "progressGroup",
                items: progressItems,
            },
            platformVersion: Settings.APP_VERSION,
        })
        const result = await this.sdk.gql.textureEditorCreateJob({
            input: {
                name: `Baking edits for texture set ${textureSetRevision.textureSet.legacyId} (revision ${textureSetRevision.id})`,
                organizationLegacyId: textureSetRevision.textureSet.textureGroup.organization.legacyId,
                graph: graphToJson(jobGraph),
            },
        })
        const jobId = result.createJob.id
        if (jobId === undefined) {
            throw new Error("Failed to create job")
        }
        await this.sdk.gql.textureEditorCreateJobAssignment({
            input: {
                objectId: textureSetRevision.textureSet.id,
                contentTypeModel: ContentTypeModel.TextureSet,
                jobId,
            },
        })
        return jobId
    }

    private async bakeTextureEditsGraph(operatorStack: OperatorStack, textureType: TextureType, dataObjectId: string): Promise<Nodes.Output> {
        // const channelLayout = descriptorByTextureType(textureType).channelLayout
        // const decodedImage = Nodes.trace("decodedImage", await this.decodeImage(textureRevision.dataObject.legacyId))
        // const format: TypedImageData["dataType"] = "float32"
        // const convertedImage = Nodes.trace("convertedImage", Nodes.convert(decodedImage, format, channelLayout, false))
        // // TODO the image width/height should be determined from source, but currently image-processing does not support reading dimensions from image-nodes and using these in subsequent nodes :'(
        // if (textureRevision.dataObject.width == null || textureRevision.dataObject.height == null) {
        //     throw Error("Texture revision data-object width/height not set")
        // }
        // const convertedImageDescriptor: ImageDescriptor = {
        //     width: textureRevision.dataObject.width,
        //     height: textureRevision.dataObject.height,
        //     channelLayout: imageOpChannelLayoutByTextureChannelLayout(channelLayout),
        //     format: format,
        // }
        const evaluator = new ImageOpNodeGraphEvaluatorImgProc(this.textureApi, operatorStack.drawableImageCache)
        using sourceImagePtr = await evaluator.createDataObjectImage(dataObjectId)
        const generatedNodeGraph = await operatorStack.generateOperatorNodeGraph(evaluator, textureType, sourceImagePtr)
        using result = await evaluator.evaluate(generatedNodeGraph.nodeGraph)
        return Nodes.encode(result.ref.node, "image/x-exr")
    }

    async decodeImage(dataObjectId: number): Promise<Nodes.ImageNode> {
        const dataObjRef = JobNodes.dataObjectReference(dataObjectId)
        return Nodes.decode(Nodes.externalData(dataObjRef, "encodedData"))
    }

    private textureApi: TexturesApiService
    private sdk: SdkService
    private imageProcessingService: ImageProcessingService
    private authService: AuthService
}
