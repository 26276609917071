import {Component, computed, inject, input} from "@angular/core"
import {TemplateTreeComponent, TemplateTreeNode} from "@template-editor/components/template-tree/template-tree.component"
import {MatTooltipModule} from "@angular/material/tooltip"
import {
    isBooleanLikeNode,
    isImageLike,
    isMaterialLike,
    isNode,
    isNodeOwner,
    isNumberLikeNode,
    isObject,
    isObjectLike,
    isStringLikeNode,
    isSwitch,
    Node,
    NodeOwner,
    Object,
    Switch,
} from "@cm/lib/templates/node-types"
import {TemplateMenuComponent} from "@template-editor/components/template-menu/template-menu.component"
import {TemplateMenuSectionComponent} from "@template-editor/components/template-menu-section/template-menu-section.component"
import {MatMenuModule} from "@angular/material/menu"
import {AuthService} from "@app/common/services/auth/auth.service"
import {MemoizePipe} from "@common/pipes/memoize/memoize.pipe"
import {getNodeIconClass} from "@app/template-editor/helpers/template-icons"
import {TemplateInstance} from "@cm/lib/templates/nodes/template-instance"
import {MaterialReference} from "@cm/lib/templates/nodes/material-reference"
import {ConfigGroup} from "@cm/lib/templates/nodes/config-group"
import {ProceduralMesh} from "@cm/lib/templates/nodes/procedural-mesh"
import {AreaLight} from "@cm/lib/templates/nodes/area-light"
import {LightPortal} from "@cm/lib/templates/nodes/light-portal"
import {HDRILight} from "@cm/lib/templates/nodes/hdri-light"
import {Render} from "@cm/lib/templates/nodes/render"
import {PostProcessRender} from "@cm/lib/templates/nodes/post-process-render"
import {SceneProperties} from "@cm/lib/templates/nodes/scene-properties"
import {Camera} from "@cm/lib/templates/nodes/camera"
import {BooleanInput, ImageInput, JSONInput, MaterialInput, NumberInput, ObjectInput, StringInput, TemplateInput} from "@cm/lib/templates/nodes/input"
import {TemplateMenuItemComponent} from "@template-editor/components/template-menu-item/template-menu-item.component"
import {BooleanExport, ImageExport, JSONExport, MaterialExport, NumberExport, ObjectExport, StringExport, TemplateExport} from "@cm/lib/templates/nodes/export"
import {Group} from "@cm/lib/templates/nodes/group"
import {ListItemComponent} from "@common/components/item/list-item/list-item.component"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {MeshDecal} from "@cm/lib/templates/nodes/mesh-decal"
import {Annotation} from "@cm/lib/templates/nodes/annotation"
import {RigidRelation} from "@cm/lib/templates/nodes/rigid-relation"
import {PointGuide} from "@cm/lib/templates/nodes/point-guide"
import {PlaneGuide} from "@cm/lib/templates/nodes/plane-guide"
import {OverlayMaterialColor} from "@cm/lib/templates/nodes/overlay-material-color"
import {BooleanValue, JSONValue, NumberValue, StringValue} from "@cm/lib/templates/nodes/value"
import {ConfigVariant} from "@cm/lib/templates/nodes/config-variant"
import {
    BooleanLikes,
    BooleanSwitch,
    ImageLikes,
    ImageSwitch,
    JSONLikes,
    JSONSwitch,
    MaterialLikes,
    MaterialSwitch,
    NumberLikes,
    NumberSwitch,
    ObjectLikes,
    ObjectSwitch,
    StringLikes,
    StringSwitch,
    TemplateLikes,
    TemplateSwitch,
} from "@cm/lib/templates/nodes/switch"
import {v4 as uuid4} from "uuid"
import {TemplateListNode} from "@cm/lib/templates/declare-template-node"
import {getTemplateSwitchItemLabel} from "@cm/lib/templates/utils"
import {SelectMaterialComponent} from "@platform/components/materials/select-material/select-material.component"
import {SelectTemplateComponent} from "app/templates/select-template/select-template.component"
import {MatListModule} from "@angular/material/list"
import {OrganizationsService} from "@app/common/services/organizations/organizations.service"
import {SdkService} from "@app/common/services/sdk/sdk.service"
import {SceneNodes} from "@cm/lib/templates/interfaces/scene-object"
import {SelectHdriComponent} from "@app/platform/components/hdris/select-hdri/select-hdri.component"
import {Nodes} from "@cm/lib/templates/nodes/nodes"
import {StoredMesh} from "@cm/lib/templates/nodes/stored-mesh"
import {SelectModelComponent} from "@platform/components/models/select-model/select-model.component"
import {HdriFilterInput, MaterialFilterInput, TemplateFilterInput} from "@api"
import {DialogService} from "@common/services/dialog/dialog.service"
import {TriggeredDialogComponent} from "@app/common/components/dialogs/triggered-dialog/triggered-dialog.component"
import {TemplateNodeClipboardService} from "@app/template-editor/services/template-node-clipboard.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"

@Component({
    selector: "cm-template-tree-add",
    standalone: true,
    templateUrl: "./template-tree-add.component.html",
    styleUrls: ["./template-tree-add.component.scss", "./../../helpers/template-icons.scss"],
    imports: [
        MatTooltipModule,
        TemplateMenuComponent,
        TemplateMenuSectionComponent,
        MatMenuModule,
        MemoizePipe,
        TemplateMenuItemComponent,
        ListItemComponent,
        SelectMaterialComponent,
        SelectTemplateComponent,
        MatListModule,
        TriggeredDialogComponent,
        SelectModelComponent,
        SelectHdriComponent,
    ],
})
export class TemplateTreeAddComponent {
    treeNode = input.required<TemplateTreeNode<NodeOwner | Switch>>()
    templateTree = input.required<TemplateTreeComponent>()
    private underCursor = false
    mode: "placeholder" | "buttons" | "menu" = "placeholder"
    public authService = inject(AuthService)
    organizations = inject(OrganizationsService)
    protected permission = inject(PermissionsService)
    clipboardService = inject(TemplateNodeClipboardService)
    sdk = inject(SdkService)
    $can = this.permission.$to

    hdriFilters: HdriFilterInput = {}
    templateFilters: TemplateFilterInput = {}
    materialFilters: MaterialFilterInput = {hasCyclesMaterial: true}
    getNodeIconClass = getNodeIconClass
    private sceneManagerService = inject(SceneManagerService)
    hasSelection = computed(() => this.sceneManagerService.$selectedNodeParts().length > 0)
    node = computed(() => this.treeNode().node)
    configGroupNode = computed(() => {
        const node = this.node()
        if (node instanceof ConfigGroup) return node
        else return undefined
    })
    switchNode = computed(() => {
        const node = this.node()
        if (isSwitch(node)) return node
        else return undefined
    })
    nodeOwnerNode = computed(() => {
        const node = this.node()
        if (isNodeOwner(node)) return node
        else return undefined
    })
    selectedNodes = computed(() => this.sceneManagerService.$selectedNodeParts().map((node) => node.templateNode))
    selectedPointGuideNodes = computed(() =>
        this.sceneManagerService
            .$selectedNodeParts()
            .map((templateNodePart) => this.sceneManagerService.getSceneNodeParts(templateNodePart))
            .flat()
            .map((node) => node.sceneNode)
            .filter((x): x is SceneNodes.Mesh => SceneNodes.Mesh.is(x)),
    )
    selectedObjects = computed(() => this.selectedNodes().filter((node): node is Object => isObject(node)))
    Group = Group
    TemplateInstance = TemplateInstance
    MaterialReference = MaterialReference
    ConfigGroup = ConfigGroup
    ConfigVariant = ConfigVariant
    MaterialSwitch = MaterialSwitch
    ImageSwitch = ImageSwitch
    TemplateSwitch = TemplateSwitch
    ObjectSwitch = ObjectSwitch
    StringSwitch = StringSwitch
    NumberSwitch = NumberSwitch
    BooleanSwitch = BooleanSwitch
    JSONSwitch = JSONSwitch
    StoredMesh = StoredMesh
    ProdecureMesh = ProceduralMesh
    AreaLight = AreaLight
    HDRILight = HDRILight
    LightPortal = LightPortal
    Camera = Camera
    Render = Render
    PostProcessRender = PostProcessRender
    SceneProperties = SceneProperties
    ObjectInput = ObjectInput
    ObjectExport = ObjectExport
    MaterialInput = MaterialInput
    MaterialExport = MaterialExport
    TemplateInput = TemplateInput
    TemplateExport = TemplateExport
    ImageInput = ImageInput
    ImageExport = ImageExport
    StringInput = StringInput
    StringExport = StringExport
    NumberInput = NumberInput
    NumberExport = NumberExport
    BooleanInput = BooleanInput
    BooleanExport = BooleanExport
    JSONInput = JSONInput
    JSONExport = JSONExport
    MeshDecal = MeshDecal
    Annotation = Annotation
    RigidRelation = RigidRelation
    PointGuide = PointGuide
    PlaneGuide = PlaneGuide
    OverlayMaterialColor = OverlayMaterialColor
    StringValue = StringValue
    NumberValue = NumberValue
    BooleanValue = BooleanValue
    JSONValue = JSONValue
    getTemplateSwitchItemLabel = getTemplateSwitchItemLabel

    onMenuOpen() {
        this.mode = "menu"
        this.templateTree().highlightNode(this.treeNode(), "addMenu", true)
    }

    onMenuClose() {
        this.templateTree().highlightNode(this.treeNode(), "addMenu", false)
        this.mode = "buttons"
    }

    private addToConfigGroup(node: Node) {
        const configGroup = this.configGroupNode()
        if (!configGroup) return

        this.sceneManagerService.modifyTemplateGraph(() => configGroup.parameters.nodes.addEntry(node))
    }

    addConfigVariant() {
        this.addToConfigGroup(new ConfigVariant({name: "Config Variant", nodes: new Nodes({list: []}), id: uuid4()}))
    }

    addMaterialSwitch() {
        this.addToConfigGroup(new MaterialSwitch({name: "Material Switch", nodes: new MaterialLikes({list: []})}))
    }

    addTemplateSwitch() {
        this.addToConfigGroup(new TemplateSwitch({name: "Template Switch", nodes: new TemplateLikes({list: []})}))
    }

    addImageSwitch() {
        this.addToConfigGroup(new ImageSwitch({name: "Image Switch", nodes: new ImageLikes({list: []})}))
    }

    addObjectReferenceSwitch() {
        this.addToConfigGroup(new ObjectSwitch({name: "Object Switch", nodes: new ObjectLikes({list: []})}))
    }

    addStringSwitch() {
        this.addToConfigGroup(new StringSwitch({name: "String Switch", nodes: new StringLikes({list: []})}))
    }

    addNumberSwitch() {
        this.addToConfigGroup(new NumberSwitch({name: "Number Switch", nodes: new NumberLikes({list: []})}))
    }

    addBooleanSwitch() {
        this.addToConfigGroup(new BooleanSwitch({name: "Boolean Switch", nodes: new BooleanLikes({list: []})}))
    }

    addJSONSwitch() {
        this.addToConfigGroup(new JSONSwitch({name: "JSON Switch", nodes: new JSONLikes({list: []})}))
    }

    private addReference<T>(nodes: TemplateListNode<T>, node: T) {
        if (nodes.parameters.list.includes(node)) return
        nodes.addEntry(node)
    }

    addSelectionToSwitch() {
        const switchNode = this.switchNode()
        if (!switchNode) return

        const otherSelectedNodes = this.selectedNodes().filter((node) => node !== this.node())

        if (otherSelectedNodes.length === 0) return

        this.sceneManagerService.modifyTemplateGraph(() => {
            if (switchNode instanceof ImageSwitch)
                otherSelectedNodes.filter(isImageLike).forEach((node) => this.addReference(switchNode.parameters.nodes, node))
            else if (switchNode instanceof MaterialSwitch)
                otherSelectedNodes.filter(isMaterialLike).forEach((node) => this.addReference(switchNode.parameters.nodes, node))
            else if (switchNode instanceof ObjectSwitch)
                otherSelectedNodes.filter(isObjectLike).forEach((node) => this.addReference(switchNode.parameters.nodes, node))
            else if (switchNode instanceof StringSwitch)
                otherSelectedNodes.filter(isStringLikeNode).forEach((node) => this.addReference(switchNode.parameters.nodes, node))
            else if (switchNode instanceof NumberSwitch)
                otherSelectedNodes.filter(isNumberLikeNode).forEach((node) => this.addReference(switchNode.parameters.nodes, node))
            else if (switchNode instanceof BooleanSwitch)
                otherSelectedNodes.filter(isBooleanLikeNode).forEach((node) => this.addReference(switchNode.parameters.nodes, node))
            else throw new Error("Unsupported switch node type")
        })
    }
}
