import {Component, EventEmitter, inject, Input, Output} from "@angular/core"
import {FormsModule} from "@angular/forms"
import {MatButtonToggleModule} from "@angular/material/button-toggle"
import {MatMenuModule} from "@angular/material/menu"
import {MatTooltipModule} from "@angular/material/tooltip"
import {
    DataObjectAssignmentType,
    PictureViewerDataObjectAssignmentFragment,
    PictureViewerPictureDataObjectFragment,
    PictureViewerPictureRevisionFragment,
} from "@api"
import {isMobileDevice} from "@app/common/helpers/device-browser-detection/device-browser-detection"
import {CanvasBaseComponent} from "@common/components/canvas"
import {ActionBarComponent, ActionItemComponent, ActionPanelComponent, ActionPanelRowComponent} from "@common/components/menu"
import {FilesizePipe} from "@common/pipes/filesize/filesize.pipe"
import {MemoizePipe} from "@common/pipes/memoize/memoize.pipe"
import {AuthService} from "@common/services/auth/auth.service"
import {FilesService} from "@common/services/files/files.service"
import {MomentModule} from "ngx-moment"
import {PermissionsService} from "@common/services/permissions/permissions.service"

@Component({
    selector: "cm-picture-controls",
    templateUrl: "./picture-controls.component.html",
    styleUrls: ["./picture-controls.component.scss"],
    standalone: true,
    imports: [
        ActionPanelRowComponent,
        ActionPanelComponent,
        MatTooltipModule,
        MomentModule,
        FilesizePipe,
        ActionBarComponent,
        ActionItemComponent,
        MatMenuModule,
        MatButtonToggleModule,
        FormsModule,
        MemoizePipe,
    ],
})
export class PictureControlsComponent {
    @Input() dataObject?: PictureViewerPictureDataObjectFragment
    @Input() pictureRevision?: PictureViewerPictureRevisionFragment
    @Input() pictureCanvas?: CanvasBaseComponent

    @Output() revisionChange = new EventEmitter<"previous" | "next">()

    @Output() edit = new EventEmitter<void>()
    @Output() save = new EventEmitter<void>()
    @Output() discard = new EventEmitter<void>()

    @Input() selectedAssignment?: PictureViewerDataObjectAssignmentFragment
    @Output() selectedAssignmentChange = new EventEmitter<PictureViewerDataObjectAssignmentFragment>()

    isMobileDevice = isMobileDevice

    permission = inject(PermissionsService)
    $can = this.permission.$to

    constructor(public auth: AuthService) {}

    getZoomLevel(): number {
        return Math.round((this.pictureCanvas?.navigation?.getZoomLevel() ?? 1) * 100)
    }

    getFileTypeLabel(dataObject: {mediaType?: string}): string {
        switch (dataObject?.mediaType) {
            case "image/x-exr":
                return "EXR"
            case "image/tiff":
                return "TIFF"
            case "image/jpeg":
                return "JPEG"
            default:
                return dataObject?.mediaType ?? "?"
        }
    }

    labelForAssignment(assignment: {assignmentType: DataObjectAssignmentType}): string {
        switch (assignment.assignmentType) {
            case DataObjectAssignmentType.Attachment:
                return "Attachment"
            case DataObjectAssignmentType.GalleryImage:
                return "GalleryImage"
            case DataObjectAssignmentType.CustomerLogo:
                return "CustomerLogo"
            case DataObjectAssignmentType.PictureData:
                return "PictureData"
            case DataObjectAssignmentType.PictureDataSecondary:
                return "PictureDataSecondary"
            case DataObjectAssignmentType.PictureDataTertiary:
                return "PictureDataTertiary"
            case DataObjectAssignmentType.PictureDataLinear:
                return "PictureDataLinear"
            case DataObjectAssignmentType.CryptoMatte01:
                return "CryptoMatte01"
            case DataObjectAssignmentType.CryptoMatte02:
                return "CryptoMatte02"
            case DataObjectAssignmentType.CryptoMatte03:
                return "CryptoMatte03"
            case DataObjectAssignmentType.ProfilePicture:
                return "ProfilePicture"
            case DataObjectAssignmentType.DrawingData:
                return "DrawingData"
            case DataObjectAssignmentType.ScanMergedFile:
                return "ScanMergedFile"
            case DataObjectAssignmentType.ScanFittingWorkPackage:
                return "ScanFittingWorkPackage"
            case DataObjectAssignmentType.ScanAssemblyWorkPackage:
                return "ScanAssemblyWorkPackage"
            case DataObjectAssignmentType.ScanCalibrationData:
                return "ScanCalibrationData"
            case DataObjectAssignmentType.ScanLog:
                return "ScanLog"
            case DataObjectAssignmentType.MeshDataDrc:
                return "MeshDataDRC"
            case DataObjectAssignmentType.MeshDataDrcProxy:
                return "MeshDataDRCProxy"
            case DataObjectAssignmentType.MeshDataPly:
                return "MeshDataPLY"
            case DataObjectAssignmentType.TemplateConfigIcon:
                return "TemplateConfigIcon"
            case DataObjectAssignmentType.CachedTemplateGltf:
                return "CachedTemplateGLTF"
            case DataObjectAssignmentType.CachedTemplateUsdz:
                return "CachedTemplateUSDZ"
            case DataObjectAssignmentType.Icon:
                return "Icon"
            default:
                return "?"
        }
    }

    downloadOriginal(dataObject: {originalFileName: string; downloadUrl: string}): void {
        FilesService.downloadFile(dataObject.originalFileName, dataObject.downloadUrl)
    }

    downloadJpeg(dataObject: {originalFileName: string; thumbnail?: {downloadUrl: string}}): void {
        if (dataObject.thumbnail?.downloadUrl) {
            FilesService.downloadFile(dataObject.originalFileName, dataObject.thumbnail?.downloadUrl)
        }
    }
}
