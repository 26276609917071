import * as paper from "paper"
import {ColorLike} from "@cm/lib/math/color"
import {clearPaintable} from "@common/helpers/webgl2/webgl2-painter-utils"
import {WebGl2Image} from "@common/models/webgl2/webgl2-image"
import {HalImageOptions, HalImageSource} from "@common/models/hal/hal-image/types"
import {isImageDescriptor} from "@common/helpers/hal"

function fixFormatForRenderableRGB(source: HalImageSource): HalImageSource {
    if (isImageDescriptor(source)) {
        if (source.channelLayout === "RGB" && (source.format === "float32" || source.format === "float16")) {
            // console.warn("Float32/Float16 is not supported with RGB channel layout for renderable images; reverting to RGBA channel layout")
            return {
                width: source.width,
                height: source.height,
                channelLayout: "RGBA",
                format: source.format,
            }
        }
    }
    return source
}

export class WebGl2PaintableImage extends WebGl2Image {
    // WebGl2Image
    override dispose() {
        this.releaseFrameBuffer()
        super.dispose()
    }

    // WebGl2Image
    override get texture(): WebGLTexture {
        // regenerate mipmaps if image has been modified
        if (this._isModified) {
            this._isModified = false
            this.generateMipmaps()
        }
        return super.texture
    }

    // HalPaintable
    get width(): number {
        return this.descriptor.width
    }

    // HalPaintable
    get height(): number {
        return this.descriptor.height
    }

    // HalPaintable
    preDraw(): void {}

    // HalPaintable
    postDraw(): void {
        const gl = this.context.gl
        gl.bindFramebuffer(gl.FRAMEBUFFER, null) // unbind framebuffer to avoid potential subsequent feedback framebuffer operation
    }

    // HalPaintable
    getNumDrawPasses(): number {
        return this.numShards
    }

    // HalPaintable
    setDrawPass(pass: number): void {
        if (!this._frameBuffers) {
            throw Error("Frame buffers not initialized")
        }
        const gl = this.context.gl
        const renderTargetShardIndex = pass * this._shardsPerPass
        gl.bindFramebuffer(gl.FRAMEBUFFER, this._frameBuffers[renderTargetShardIndex])
        gl.viewport(0, 0, this.shardWidth, this.shardHeight)
        this._isModified = true // we assume that the image will be modified
    }

    // HalPaintable
    computeTransform(pass: number): number[] {
        const renderTargetShardIndex = pass * this._shardsPerPass
        const shardPosX = (renderTargetShardIndex % this.numShardsX) * this.shardWidth
        const shardPosY = Math.floor(renderTargetShardIndex / this.numShardsX) * this.shardHeight
        const transform = new paper.Matrix(1, 0, 0, 1, -1, -1) // origin at top left
        transform.append(new paper.Matrix(2 / this.shardWidth, 0, 0, 2 / this.shardHeight, 0, 0)) // normalize to [-1, 1]
        transform.append(new paper.Matrix(1, 0, 0, 1, -shardPosX, -shardPosY)) // offset to shard
        return [transform.a, transform.b, transform.c, transform.d, transform.tx, transform.ty]
    }

    // HalPaintable
    async clear(color?: ColorLike): Promise<void> {
        return clearPaintable(this, color)
    }

    // WebGl2Image
    override async create(source: HalImageSource, options?: Partial<HalImageOptions>) {
        this.releaseFrameBuffer()
        await super.create(fixFormatForRenderableRGB(source), options)
        this.createFrameBuffer()
    }

    private createFrameBuffer(): void {
        this.releaseFrameBuffer()
        if (this.numShards > 0) {
            if (this.descriptor.format === "float32") {
                if (!this.context.EXT_color_buffer_float) {
                    throw new Error("Float32 format for paintable images not supported by device")
                }
                if (this.descriptor.channelLayout === "RGB") {
                    throw new Error("Float32 format for paintable images not supported for RGB channel layout")
                }
            } else if (this.descriptor.format === "float16") {
                if (!this.context.EXT_color_buffer_half_float) {
                    throw new Error("Float16 format for paintable images not supported by device")
                }
                if (this.descriptor.channelLayout === "RGB") {
                    throw new Error("Float16 format for paintable images not supported for RGB channel layout")
                }
            }
            const gl = this.context.gl
            const currentBinding = gl.getParameter(gl.FRAMEBUFFER_BINDING)
            this._frameBuffers = []
            for (let i = 0; i < this.numShards; i++) {
                const frameBuffer = gl.createFramebuffer()
                if (!frameBuffer) {
                    throw new Error("Failed to create framebuffer")
                }
                this._frameBuffers.push(frameBuffer)
                gl.bindFramebuffer(gl.FRAMEBUFFER, frameBuffer)
                gl.framebufferTextureLayer(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, super.texture, 0, i)
            }
            gl.bindFramebuffer(gl.FRAMEBUFFER, currentBinding) // restore previous binding
        }
    }

    private releaseFrameBuffer(): void {
        if (this._frameBuffers) {
            const gl = this.context.gl
            this._frameBuffers.forEach((frameBuffer) => gl.deleteFramebuffer(frameBuffer))
            this._frameBuffers = undefined
        }
    }

    private _frameBuffers?: WebGLFramebuffer[]
    private _isModified = false
    private readonly _shardsPerPass = 1 // TODO there is some optimization potential here as we could render maxDrawBuffers shards simultaneously per pass (but this makes the shaders more complex)
}
