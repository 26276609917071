import {GetParameters} from "@cm/lib/graph-system/node-graph"
import {declareImageOpNode} from "app/textures/texture-editor/operator-stack/image-op-system/nodes/image-op-nodes/abstract/image-op-node"
import {Context} from "app/textures/texture-editor/operator-stack/image-op-system/detail/context"
import {imageOpRasterizeGeometry, ParameterType} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-rasterize-geometry"

const RasterizeGeometryNode = declareImageOpNode(imageOpRasterizeGeometry)

export function rasterizeGeometry(parameters: GetParameters<Context, ParameterType>) {
    return new RasterizeGeometryNode(parameters)
}
