<cm-inspector-section>
    <ng-container cm-title>Display</ng-container>
    <ng-container cm-content>
        <cm-toggle
            matTooltip="Toggle display of visual guides [V]"
            [toggle]="operator.showGuides$.value"
            (toggleChange)="operator.showGuides$.next($event)"
            [disabled]="disabled"
        >
            <ng-container cm-text><span [class.cm-disabled]="disabled">Show guides </span></ng-container>
        </cm-toggle>
        <span class="cm-label">View mode</span>
        <div class="cm-mode-selections">
            <cm-button
                class="cm-mode-selection"
                [class.cm-active]="operator.viewMode$.value === ViewMode.Source"
                matTooltip="View original image [K]"
                (click)="operator.viewMode$.next(ViewMode.Source)"
                [disabled]="disabled"
            >
                <ng-container cm-left-icon><i class="far fa-inbox-arrow-down"></i></ng-container>
                <ng-container cm-text>Source</ng-container>
            </cm-button>
            <cm-button
                class="cm-mode-selection"
                [class.cm-active]="operator.viewMode$.value === ViewMode.Result"
                matTooltip="View mapped image [L]"
                (click)="operator.viewMode$.next(ViewMode.Result)"
                [disabled]="disabled"
            >
                <ng-container cm-left-icon><i class="far fa-inbox-arrow-up"></i></ng-container>
                <ng-container cm-text>Result</ng-container>
            </cm-button>
        </div>
    </ng-container>
</cm-inspector-section>

<cm-inspector-section>
    <ng-container cm-title>Snapping</ng-container>
    <ng-container cm-content>
        <cm-toggle
            matTooltip="Toggle snapping of control point to similar feature of last touched corresponding control point [S]"
            [toggle]="operator.snapEnabled$ | async"
            (toggleChange)="operator.snapEnabled$.next($event)"
            [disabled]="disabled"
        >
            <ng-container cm-text><span [class.cm-disabled]="disabled">Enable snapping</span></ng-container>
        </cm-toggle>
        <div>
            <span class="cm-label">Snap distance ({{ measureUnit }})</span>
            <cm-slider
                matTooltip="Distance used to search for similar feature"
                [min]="px2measure(4)"
                [max]="px2measure(128)"
                [displayValuePrecision]="2"
                [value]="px2measure(operator.snapDistancePx$ | async)"
                (valueChange)="operator.snapDistancePx$.next(measure2px($event))"
                [disabled]="disabled || !operator.snapEnabled$.value"
            ></cm-slider>
        </div>
    </ng-container>
</cm-inspector-section>
<cm-inspector-section>
    <ng-container cm-title>Alignment</ng-container>
    <ng-container cm-content>
        <div>
            <span class="cm-label">Control point spacing ({{ measureUnit }})</span>
            <cm-slider
                matTooltip="Alignment point spacing used for alignment"
                [min]="px2measure(8)"
                [max]="px2measure(256)"
                [displayValuePrecision]="2"
                [value]="px2measure(operator.alignmentSpacingPx$ | async)"
                (valueChange)="operator.alignmentSpacingPx$.next(measure2px($event))"
                [disabled]="disabled"
            ></cm-slider>
        </div>
        <div>
            <span class="cm-label">Search size ratio</span>
            <cm-slider
                matTooltip="Ratio to point spacing to search for similar feature"
                [min]="0.01"
                [max]="1"
                [value]="operator.alignmentSearchSizeRatio$ | async"
                (valueChange)="operator.alignmentSearchSizeRatio$.next($event)"
                [disabled]="disabled"
            ></cm-slider>
        </div>
        <div>
            <span class="cm-label">Minimum correlation</span>
            <cm-slider
                matTooltip="Minimum correlation for similar feature to be considered"
                [min]="0"
                [max]="1"
                [value]="operator.alignmentMinCorrelation$ | async"
                (valueChange)="operator.alignmentMinCorrelation$.next($event)"
                [disabled]="disabled"
            ></cm-slider>
        </div>
        <div class="cm-mode-selections">
            <cm-button
                class="cm-mode-selection"
                matTooltip="Run alignment (this may take a few seconds)"
                (click)="operator.executeGridAlignment()"
                [disabled]="disabled"
            >
                <ng-container cm-left-icon><i class="far fa-location-dot"></i></ng-container>
                <ng-container cm-text>Run alignment</ng-container>
            </cm-button>
            <cm-button class="cm-mode-selection" matTooltip="Remove alignment control points" (click)="operator.removeGridAlignment()" [disabled]="disabled">
                <ng-container cm-left-icon><i class="far fa-location-dot-slash"></i></ng-container>
                <ng-container cm-text>Remove</ng-container>
            </cm-button>
        </div>
    </ng-container>
</cm-inspector-section>
<cm-inspector-section>
    <ng-container cm-title>Border blending</ng-container>
    <ng-container cm-content>
        <cm-toggle
            matTooltip="Toggle border blending"
            [toggle]="operator.borderBlendEnabled$ | async"
            (toggleChange)="operator.borderBlendEnabled$.next($event)"
            [disabled]="disabled"
        >
            <ng-container cm-text><span [class.cm-disabled]="disabled">Enable border blending</span></ng-container>
        </cm-toggle>
        <div>
            <span class="cm-label">Border width ({{ measureUnit }})</span>
            <cm-slider
                matTooltip="Width of blended border"
                [min]="px2measure(0)"
                [max]="px2measure(256)"
                [displayValuePrecision]="2"
                [value]="px2measure(operator.borderBlendDistancePx$ | async)"
                (valueChange)="operator.borderBlendDistancePx$.next(measure2px($event))"
                [disabled]="disabled"
            ></cm-slider>
        </div>
    </ng-container>
</cm-inspector-section>
@if (isDebugEnabled) {
    <cm-inspector-section>
        <ng-container cm-title>Debugging</ng-container>
        <ng-container cm-content>
            <cm-toggle [toggle]="operator.debugDrawEnabled$ | async" (toggleChange)="operator.debugDrawEnabled$.next($event)" [disabled]="disabled">
                <ng-container cm-text><span [class.cm-disabled]="disabled">Enable debug draw</span></ng-container>
            </cm-toggle>
            <span class="cm-label">Rasterizer test mode</span>
            <div class="cm-mode-selections">
                <cm-button
                    class="cm-mode-selection"
                    [class.cm-active]="operator.rasterTestMode$.value === RasterTestMode.None"
                    (click)="operator.rasterTestMode$.next(RasterTestMode.None)"
                    [disabled]="disabled"
                >
                    <ng-container cm-text>None</ng-container>
                </cm-button>
                <cm-button
                    class="cm-mode-selection"
                    [class.cm-active]="operator.rasterTestMode$.value === RasterTestMode.SoftwareRasterizer"
                    (click)="operator.rasterTestMode$.next(RasterTestMode.SoftwareRasterizer)"
                    [disabled]="disabled"
                >
                    <ng-container cm-text>SW Raster</ng-container>
                </cm-button>
                <cm-button
                    class="cm-mode-selection"
                    [class.cm-active]="operator.rasterTestMode$.value === RasterTestMode.SoftwareRasterizerDiff"
                    (click)="operator.rasterTestMode$.next(RasterTestMode.SoftwareRasterizerDiff)"
                    [disabled]="disabled"
                >
                    <ng-container cm-text>SW Raster Diff</ng-container>
                </cm-button>
            </div>
        </ng-container>
    </cm-inspector-section>
}
