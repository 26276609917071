import {EventEmitter} from "@angular/core"
import {Vector2, Vector2Like} from "@cm/lib/math/vector2"
import * as paper from "paper"
import {CanvasBaseToolboxItemBase} from "@common/helpers/canvas/canvas-base-toolbox/canvas-base-toolbox-item-base"
import {TilingAreaToolboxItem} from "@app/textures/texture-editor/operator-stack/operators/tiling/toolbox/tiling-area-toolbox-item"

export class TilingAreaBoundaryCurveToolboxItem extends CanvasBaseToolboxItemBase<TilingAreaToolboxItem> {
    readonly clicked = new EventEmitter<{position: Vector2; t: number}>()

    constructor(parent: TilingAreaToolboxItem) {
        super(parent)

        this.viewChange.subscribe(() => this.updateZoomDependent())

        this.sendToBack()
    }

    override remove() {
        super.remove()
        this._curve?.remove()
    }

    override hitTest(point: Vector2Like): boolean {
        if (this._curve?.hitTest(point, {stroke: true, tolerance: (5 * window.devicePixelRatio) / this.zoomLevel})) {
            this.cursor = "crosshair"
            return true
        }
        return false
    }

    override onMouseDown(event: paper.ToolEvent): boolean {
        if (this._curve) {
            const t = this.computeClosestT(event.downPoint)
            this.clicked.emit({position: Vector2.fromVector2Like(event.downPoint), t})
            return true
        }
        return false
    }

    setCurvePoints(positions: Vector2[], tValues: number[]) {
        this._positions = positions
        this._tValues = tValues

        this.beginPaperCreation()

        this._curve?.remove()
        this._curve = new paper.Path(this._positions)
        this._curve.strokeColor = new paper.Color("red")
        this._curve.strokeColor.alpha = 0.7

        this.updateZoomDependent()
    }

    private computeClosestT(point: Vector2Like) {
        if (!this._curve) {
            throw new Error("Curve not found")
        }
        const curvePoint = this._curve.getNearestPoint(point)
        const location = this._curve.getLocationOf(curvePoint)
        const p0 = this._positions[location.index]
        const p1 = this._positions[location.index + 1]
        const t = curvePoint.getDistance(p0) / Vector2.distance(p0, p1) // compute t value between p0 and p1 based on the distance between curvePoint and p0 (as the location.time value is non-linear)
        const t0 = this._tValues[location.index]
        const t1 = this._tValues[location.index + 1]
        return t0 + (t1 - t0) * t
    }

    private updateZoomDependent() {
        if (this._curve) {
            this._curve.strokeWidth = window.devicePixelRatio / this.zoomLevel
        }
    }

    private _positions: Vector2[] = []
    private _tValues: number[] = []
    private _curve: paper.Path | undefined
}
