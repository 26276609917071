import {Component, inject, input} from "@angular/core"
import {ButtonComponent} from "@app/common/components/buttons/button/button.component"
import {FilesService} from "@app/common/services/files/files.service"
import {GltfExportService} from "@app/common/services/gltf-export/gltf-export.service"
import {StlExportService} from "@app/common/services/stl-export/stl-export.service"
import {SceneManagerService} from "@app/template-editor/services/scene-manager.service"
import {ThreeSceneManagerService} from "@app/template-editor/services/three-scene-manager.service"
import {TemplateGraph} from "@cm/lib/templates/nodes/template-graph"
import {Parameters} from "@cm/lib/templates/nodes/template-instance"
import {ThreeTemplateSceneProviderComponent} from "../three-template-scene-provider/three-template-scene-provider.component"
import {Settings} from "@app/common/models/settings/settings"
import {NotificationsService} from "@app/common/services/notifications/notifications.service"

@Component({
    selector: "cm-ar-configurator-actions",
    standalone: true,
    imports: [ButtonComponent, ThreeTemplateSceneProviderComponent],
    providers: [SceneManagerService],
    templateUrl: "./ar-configurator-actions.component.html",
    styleUrl: "./ar-configurator-actions.component.scss",
})
export class ArConfiguratorActionsComponent {
    templateId = input.required<string>()
    templateGraph = input.required<TemplateGraph>()
    parameters = input.required<Parameters>()
    configuratorUrlParams = input.required<string>()
    localSceneManagerService = inject(SceneManagerService)
    threeSceneManagerService: ThreeSceneManagerService | undefined
    stlExportService = inject(StlExportService)
    gltfExportService = inject(GltfExportService)
    notificatioNService = inject(NotificationsService)

    constructor() {}

    setThreeSceneManagerService(threeSceneManagerService: ThreeSceneManagerService) {
        this.threeSceneManagerService = threeSceneManagerService
        this.threeSceneManagerService.$showGrid.set(false)
        this.threeSceneManagerService.$ambientLight.set(false)
    }

    async downloadStl() {
        const binaryStl = await this.stlExportService.exportStlFiles(this.templateGraph(), this.parameters(), this.localSceneManagerService)
        FilesService.downloadFile("stl-export.zip", binaryStl)
    }

    async copyConfiguratorUrl() {
        await navigator.clipboard.writeText(Settings.CONFIGURATOR_NEW_URL_TMP + "&templateId=" + this.templateId() + this.configuratorUrlParams())
        this.notificatioNService.showInfo("Configurator URL copied to clipboard")
    }

    async downloadGltf() {
        if (!this.threeSceneManagerService) throw new Error("ThreeSceneManagerService not set")
        const binaryGltf = await this.gltfExportService.exportGltfFile(
            this.templateGraph(),
            this.parameters(),
            this.localSceneManagerService,
            this.threeSceneManagerService,
        )
        FilesService.downloadFile("glb-export.glb", new Blob([binaryGltf]))
    }
}
