import {registerNode} from "@src/graph-system/register-node"
import {MaterialLike, TemplateLike, ObjectLike, ImageLike, materialLike, templateLike, objectLike, imageLike} from "@src/templates/node-types"
import {BooleanValue, JSONValue, NumberValue, StringValue} from "@src/templates/nodes/value"
import {StringResolve} from "@src/templates/nodes/string-resolve"
import {DeclareTemplateNodeTS, TemplateNodeImplementation, TemplateNodeMeta} from "@src/templates/declare-template-node"
import {AnyJSONValue, TemplateNode, anyJsonValue} from "@src/templates/types"
import {EvaluableTemplateNode} from "@src/templates/evaluable-template-node"
import {IMaterialGraph} from "@src/templates/interfaces/material-data"
import {NodeEvaluator} from "@src/templates/node-evaluator"
import {GraphBuilderScope} from "@src/templates/runtime-graph/graph-builder-scope"
import {EvaluatedTemplateValueType} from "@src/templates/types"
import {ObjectData} from "@src/templates/interfaces/object-data"
import {TemplateImageDataNew} from "@src/templates/runtime-graph/type-descriptors"
import {TemplateGraph} from "@src/templates/nodes/template-graph"
import {BuilderInlet} from "@src/templates/runtime-graph/graph-builder"
import {z} from "zod"
import {visitNone} from "@src/graph-system/declare-visitor-node"
import {BooleanInfo, ImageInfo, JSONInfo, MaterialInfo, NumberInfo, ObjectInfo, StringInfo, TemplateInfo} from "@src/templates/interface-descriptors"
import {idNodeParameters, IdNodeParameters} from "@src/templates/nodes/id-node"
import {namedNodeParameters, NamedNodeParameters} from "@src/templates/nodes/named-node"
import {nodeInstance} from "@src/graph-system/instance"
import {NodeGraphClass} from "@src/graph-system/node-graph"
import {TemplateParameterValue} from "@src/templates/nodes/template-instance"

export const inputNode = z.object({})
export type InputNode = z.infer<typeof inputNode>

type ZodNode<T> = z.ZodType<T, z.ZodTypeDef, any>

const inputParameters = <T>(tValidation: ZodNode<T>) =>
    namedNodeParameters
        .merge(idNodeParameters)
        .merge(inputNode)
        .merge(
            z.object({
                default: tValidation.optional(),
            }),
        )
type InputParameters<T> = NamedNodeParameters & IdNodeParameters & InputNode & {default?: T}

const generateInput = <T, E>(
    tValidation: ZodNode<T>,
    type: EvaluatedTemplateValueType,
    implementation: TemplateNodeImplementation<InputParameters<T>>,
    meta: TemplateNodeMeta<InputParameters<T>>,
): NodeGraphClass<
    TemplateNode<InputParameters<T>> & {
        getTemplateInput(evaluator: NodeEvaluator): {value: E; origin: TemplateParameterValue} | undefined
    }
> => {
    const retClass = class extends DeclareTemplateNodeTS<InputParameters<T>>(
        {validation: {paramsSchema: inputParameters(tValidation)}, ...implementation},
        meta,
    ) {
        getTemplateInput(evaluator: NodeEvaluator) {
            const externalId = this.parameters.id
            const {ambientInputs} = evaluator
            const entry = ambientInputs[externalId]
            if (!entry) return undefined

            if (entry.type === type) return {value: entry.value as E, origin: entry.origin}
            return undefined
        }
    }
    return retClass
}

////////////////////////////////////////////////////////////////////

export interface ObjectInputParameters extends InputParameters<ObjectLike> {} // workaround for recursive type

@registerNode
export class ObjectInput
    extends generateInput<ObjectLike, ObjectData>(
        objectLike,
        "object",
        {
            onVisited: {
                onCompile: function (this: ObjectInput, {context, parameters}) {
                    const {id, name} = parameters
                    const {evaluator, currentTemplate} = context
                    const scope = evaluator.getScope(this)
                    const {descriptorList} = currentTemplate
                    const {value, origin} = this.evaluateImpl(scope, evaluator)

                    descriptorList.push(scope.lambda(value, (object) => new ObjectInfo({id, name, value: object, type: "input", origin}), "objectInfo"))

                    return visitNone(parameters)
                },
            },
        },
        {nodeClass: "ObjectInput"},
    )
    implements EvaluableTemplateNode<ObjectData | null>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator): BuilderInlet<ObjectData | null> {
        return this.evaluateImpl(scope, evaluator).value
    }

    private evaluateImpl(
        scope: GraphBuilderScope,
        evaluator: NodeEvaluator,
    ): {value: BuilderInlet<ObjectData | null>; origin: TemplateParameterValue | undefined} {
        const templateInput = this.getTemplateInput(evaluator)
        if (templateInput === undefined) return {value: evaluator.evaluateObject(scope, this.parameters.default ?? null), origin: undefined}
        else return templateInput
    }
}

export type ObjectInputFwd = TemplateNode<ObjectInputParameters> & EvaluableTemplateNode<ObjectData | null>

////////////////////////////////////////////////////////////////////

export interface MaterialInputParameters extends InputParameters<MaterialLike> {} // workaround for recursive type

@registerNode
export class MaterialInput
    extends generateInput<MaterialLike, IMaterialGraph>(
        materialLike,
        "material",
        {
            onVisited: {
                onCompile: function (this: MaterialInput, {context, parameters}) {
                    const {id, name} = parameters
                    const {evaluator, currentTemplate} = context
                    const {descriptorList} = currentTemplate
                    const scope = evaluator.getScope(this)
                    const {value, origin} = this.evaluateImpl(scope, evaluator)

                    descriptorList.push(
                        scope.lambda(
                            value,
                            (material) => {
                                this.getTemplateInput(evaluator)
                                return new MaterialInfo({id, name, value: material, type: "input", origin})
                            },
                            "materialInfo",
                        ),
                    )

                    return visitNone(parameters)
                },
            },
        },
        {nodeClass: "MaterialInput"},
    )
    implements EvaluableTemplateNode<IMaterialGraph | null>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator): BuilderInlet<IMaterialGraph | null> {
        return this.evaluateImpl(scope, evaluator).value
    }

    private evaluateImpl(
        scope: GraphBuilderScope,
        evaluator: NodeEvaluator,
    ): {value: BuilderInlet<IMaterialGraph | null>; origin: TemplateParameterValue | undefined} {
        const templateInput = this.getTemplateInput(evaluator)
        if (templateInput === undefined) return {value: evaluator.evaluateMaterial(scope, this.parameters.default ?? null), origin: undefined}
        else return templateInput
    }
}

export type MaterialInputFwd = TemplateNode<MaterialInputParameters> & EvaluableTemplateNode<IMaterialGraph | null>

////////////////////////////////////////////////////////////////////

export interface TemplateInputParameters extends InputParameters<TemplateLike> {} // workaround for recursive type

@registerNode
export class TemplateInput
    extends generateInput<TemplateLike, TemplateGraph>(
        templateLike,
        "template",
        {
            onVisited: {
                onCompile: function (this: TemplateInput, {context, parameters}) {
                    const {id, name} = parameters
                    const {evaluator, currentTemplate} = context
                    const scope = evaluator.getScope(this)
                    const {descriptorList} = currentTemplate
                    const {value, origin} = this.evaluateImpl(scope, evaluator)

                    descriptorList.push(scope.lambda(value, (template) => new TemplateInfo({id, name, value: template, type: "input", origin}), "templateInfo"))

                    return visitNone(parameters)
                },
            },
        },
        {nodeClass: "TemplateInput"},
    )
    implements EvaluableTemplateNode<TemplateGraph | null>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator): BuilderInlet<TemplateGraph | null> {
        return this.evaluateImpl(scope, evaluator).value
    }

    private evaluateImpl(
        scope: GraphBuilderScope,
        evaluator: NodeEvaluator,
    ): {value: BuilderInlet<TemplateGraph | null>; origin: TemplateParameterValue | undefined} {
        const templateInput = this.getTemplateInput(evaluator)
        if (templateInput === undefined) return {value: evaluator.evaluateTemplate(scope, this.parameters.default ?? null), origin: undefined}
        else return templateInput
    }
}

export type TemplateInputFwd = TemplateNode<TemplateInputParameters> & EvaluableTemplateNode<TemplateGraph | null>

////////////////////////////////////////////////////////////////////

export interface ImageInputParameters extends InputParameters<ImageLike> {} // workaround for recursive type

@registerNode
export class ImageInput
    extends generateInput<ImageLike, TemplateImageDataNew>(
        imageLike,
        "image",
        {
            onVisited: {
                onCompile: function (this: ImageInput, {context, parameters}) {
                    const {id, name} = parameters
                    const {evaluator, currentTemplate} = context
                    const scope = evaluator.getScope(this)
                    const {descriptorList} = currentTemplate
                    const {value, origin} = this.evaluateImpl(scope, evaluator)

                    descriptorList.push(scope.lambda(value, (image) => new ImageInfo({id, name, value: image, type: "input", origin}), "imageInfo"))

                    return visitNone(parameters)
                },
            },
        },
        {nodeClass: "ImageInput"},
    )
    implements EvaluableTemplateNode<TemplateImageDataNew | null>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator): BuilderInlet<TemplateImageDataNew | null> {
        return this.evaluateImpl(scope, evaluator).value
    }

    private evaluateImpl(
        scope: GraphBuilderScope,
        evaluator: NodeEvaluator,
    ): {value: BuilderInlet<TemplateImageDataNew | null>; origin: TemplateParameterValue | undefined} {
        const templateInput = this.getTemplateInput(evaluator)
        if (templateInput === undefined) return {value: evaluator.evaluateImage(scope, this.parameters.default ?? null), origin: undefined}
        else return templateInput
    }
}

export type ImageInputFwd = TemplateNode<ImageInputParameters> & EvaluableTemplateNode<TemplateImageDataNew | null>

////////////////////////////////////////////////////////////////////

const stringInputParameters = z.union([z.string(), nodeInstance(StringValue), nodeInstance(StringResolve)])
export type StringInputParameters = InputParameters<string | StringValue | StringResolve>

@registerNode
export class StringInput
    extends generateInput<string | StringValue | StringResolve, string>(
        stringInputParameters,
        "string",
        {
            onVisited: {
                onCompile: function (this: StringInput, {context, parameters}) {
                    const {id, name} = parameters
                    const {evaluator, currentTemplate} = context
                    const scope = evaluator.getScope(this)
                    const {descriptorList} = currentTemplate
                    const {value, origin} = this.evaluateImpl(scope, evaluator)

                    descriptorList.push(scope.lambda(value, (string) => new StringInfo({id, name, value: string, type: "input", origin}), "stringInfo"))

                    return visitNone(parameters)
                },
            },
        },
        {nodeClass: "StringInput"},
    )
    implements EvaluableTemplateNode<string | null>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator): BuilderInlet<string | null> {
        return this.evaluateImpl(scope, evaluator).value
    }

    private evaluateImpl(scope: GraphBuilderScope, evaluator: NodeEvaluator): {value: BuilderInlet<string | null>; origin: TemplateParameterValue | undefined} {
        const templateInput = this.getTemplateInput(evaluator)
        if (templateInput === undefined) return {value: evaluator.evaluateString(scope, this.parameters.default ?? null), origin: undefined}
        else return templateInput
    }
}

export type StringInputFwd = TemplateNode<StringInputParameters> & EvaluableTemplateNode<string | null>

////////////////////////////////////////////////////////////////////

const numberInputParameters = z.union([z.number(), nodeInstance(NumberValue)])
export type NumberInputParameters = InputParameters<number | NumberValue>

@registerNode
export class NumberInput
    extends generateInput<number | NumberValue, number>(
        numberInputParameters,
        "number",
        {
            onVisited: {
                onCompile: function (this: NumberInput, {context, parameters}) {
                    const {id, name} = parameters
                    const {evaluator, currentTemplate} = context
                    const scope = evaluator.getScope(this)
                    const {descriptorList} = currentTemplate
                    const {value, origin} = this.evaluateImpl(scope, evaluator)

                    descriptorList.push(scope.lambda(value, (number) => new NumberInfo({id, name, value: number, type: "input", origin}), "numberInfo"))

                    return visitNone(parameters)
                },
            },
        },
        {nodeClass: "NumberInput"},
    )
    implements EvaluableTemplateNode<number | null>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator): BuilderInlet<number | null> {
        return this.evaluateImpl(scope, evaluator).value
    }

    private evaluateImpl(scope: GraphBuilderScope, evaluator: NodeEvaluator): {value: BuilderInlet<number | null>; origin: TemplateParameterValue | undefined} {
        const templateInput = this.getTemplateInput(evaluator)
        if (templateInput === undefined) return {value: evaluator.evaluateNumber(scope, this.parameters.default ?? null), origin: undefined}
        else return templateInput
    }
}

export type NumberInputFwd = TemplateNode<NumberInputParameters> & EvaluableTemplateNode<number | null>

////////////////////////////////////////////////////////////////////

const booleanInputParameters = z.union([z.boolean(), nodeInstance(BooleanValue)])
export type BooleanInputParameters = InputParameters<boolean | BooleanValue>

@registerNode
export class BooleanInput
    extends generateInput<boolean | BooleanValue, boolean>(
        booleanInputParameters,
        "boolean",
        {
            onVisited: {
                onCompile: function (this: BooleanInput, {context, parameters}) {
                    const {id, name} = parameters
                    const {evaluator, currentTemplate} = context
                    const scope = evaluator.getScope(this)
                    const {descriptorList} = currentTemplate
                    const {value, origin} = this.evaluateImpl(scope, evaluator)

                    descriptorList.push(scope.lambda(value, (boolean) => new BooleanInfo({id, name, value: boolean, type: "input", origin}), "booleanInfo"))

                    return visitNone(parameters)
                },
            },
        },
        {nodeClass: "BooleanInput"},
    )
    implements EvaluableTemplateNode<boolean | null>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator): BuilderInlet<boolean | null> {
        return this.evaluateImpl(scope, evaluator).value
    }

    private evaluateImpl(
        scope: GraphBuilderScope,
        evaluator: NodeEvaluator,
    ): {value: BuilderInlet<boolean | null>; origin: TemplateParameterValue | undefined} {
        const templateInput = this.getTemplateInput(evaluator)
        if (templateInput === undefined) return {value: evaluator.evaluateBoolean(scope, this.parameters.default ?? null), origin: undefined}
        else return templateInput
    }
}

export type BooleanInputFwd = TemplateNode<BooleanInputParameters> & EvaluableTemplateNode<boolean | null>

////////////////////////////////////////////////////////////////////

const jsonInputParameters = z.union([anyJsonValue, nodeInstance(JSONValue)])
export type JSONInputParameters = InputParameters<AnyJSONValue | JSONValue>

@registerNode
export class JSONInput
    extends generateInput<AnyJSONValue | JSONValue, AnyJSONValue>(
        jsonInputParameters,
        "json",
        {
            onVisited: {
                onCompile: function (this: JSONInput, {context, parameters}) {
                    const {id, name} = parameters
                    const {evaluator, currentTemplate} = context
                    const scope = evaluator.getScope(this)
                    const {descriptorList} = currentTemplate
                    const {value, origin} = this.evaluateImpl(scope, evaluator)

                    descriptorList.push(scope.lambda(value, (json) => new JSONInfo({id, name, value: json, type: "input", origin}), "jsonInfo"))

                    return visitNone(parameters)
                },
            },
        },
        {nodeClass: "JSONInput"},
    )
    implements EvaluableTemplateNode<AnyJSONValue | null>
{
    evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator): BuilderInlet<AnyJSONValue | null> {
        return this.evaluateImpl(scope, evaluator).value
    }

    private evaluateImpl(
        scope: GraphBuilderScope,
        evaluator: NodeEvaluator,
    ): {value: BuilderInlet<AnyJSONValue | null>; origin: TemplateParameterValue | undefined} {
        const templateInput = this.getTemplateInput(evaluator)
        if (templateInput === undefined) return {value: evaluator.evaluateJSON(scope, this.parameters.default ?? null), origin: undefined}
        else return templateInput
    }
}

export type JSONInputFwd = TemplateNode<JSONInputParameters> & EvaluableTemplateNode<AnyJSONValue | null>
