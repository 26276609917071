import {ImageOpType} from "app/textures/texture-editor/operator-stack/image-op-system/detail/types"
import {ImagePtr} from "app/textures/texture-editor/operator-stack/image-op-system/image-ref"
import {AddressMode} from "app/textures/texture-editor/operator-stack/image-op-system/detail/common-types"
import {Vector2Like} from "@cm/lib/math/vector2"
import {ImageProcessingNodes as Nodes, ImageProcessingNodes} from "@cm/lib/image-processing/image-processing-nodes"
import {ColorLike} from "@cm/lib/math"
import {ImageDescriptor} from "@app/textures/texture-editor/operator-stack/image-op-system/image-ops/image-op-get-image-desc"
import {isImageDescriptor} from "@common/helpers/hal"
import {SmartPtr} from "@app/textures/texture-editor/operator-stack/image-op-system/smart-ptr"
import {getHalAddressMode} from "@app/textures/texture-editor/operator-stack/image-op-system/detail/utils-webgl2"

export type Vertex = {
    position: Vector2Like // in pixel space
    uv?: Vector2Like // in pixel space; default: {x: 0, y: 0}
    color?: ColorLike // default: {r: 1, g: 1, b: 1, a: 1}
}

export type ParameterType = {
    topology: "triangleList"
    vertices: Vertex[]
    indices?: number[] // default: [0, 1, 2, 3, 4, 5, ...]
    textureImage?: ImagePtr
    addressMode?: AddressMode // default: "wrap"
    resultImageOrDescriptor: ImagePtr | ImageDescriptor
}

export type ReturnType = ImagePtr

export const imageOpRasterizeGeometry: ImageOpType<ParameterType, ReturnType> = {
    WebGL2: async ({context, parameters: {topology, vertices, indices, textureImage, addressMode, resultImageOrDescriptor}}) => {
        addressMode ??= "wrap"
        if (topology !== "triangleList") {
            throw new Error("Only triangleList topology is supported in WebGL2")
        }
        const halPrimitivePainter = await context.getOrCreatePrimitivePainter(`
            vec4 computeColor(vec2 worldPosition, vec2 uv, vec4 color) {
                ${textureImage ? `color *= texelFetchInterpolated0(uv, ${getHalAddressMode(addressMode)});` : ""}
                return color;
            }
        `)
        using textureImageWebGl2 = textureImage ? await context.getImage(textureImage) : undefined
        const resultImage = isImageDescriptor(resultImageOrDescriptor)
            ? await context.createImage(resultImageOrDescriptor)
            : new SmartPtr(resultImageOrDescriptor)
        const vertexPositions = vertices.map((vertex) => vertex.position)
        const vertexUVs = vertices.map((vertex) => vertex.uv ?? {x: 0, y: 0})
        const vertexColors = vertices.map((vertex) => vertex.color ?? {r: 1, g: 1, b: 1, a: 1})
        indices ??= new Array(vertices.length).fill(0).map((_, index) => index)
        using resultImageWebGl2 = await context.getImage(resultImage)
        halPrimitivePainter.clearGeometry()
        halPrimitivePainter.addVertices(vertexPositions, vertexUVs, vertexColors)
        halPrimitivePainter.addIndices(indices)
        halPrimitivePainter.setSourceImage(0, textureImageWebGl2?.ref.halImage)
        await halPrimitivePainter.paint(resultImageWebGl2.ref.halImage)
        return resultImage
    },

    name: "RasterizeGeometry",

    ImgProc: async ({context, parameters: {topology, vertices, indices, textureImage, addressMode, resultImageOrDescriptor}}) => {
        addressMode ??= "wrap"
        if (topology !== "triangleList") {
            throw new Error("Only triangleList topology is supported in ImgProc")
        }
        if (addressMode !== "wrap") {
            throw new Error("Only wrap address mode is supported in ImgProc")
        }
        const imgProcVertices: ImageProcessingNodes.Vertex[] = vertices.map((vertex) => ({
            position: [vertex.position.x, vertex.position.y],
            uv: vertex.uv ? [vertex.uv.x, vertex.uv.y] : undefined,
            color: vertex.color ? [vertex.color.r, vertex.color.g, vertex.color.b, vertex.color.a ?? 1] : undefined,
        }))
        using textureImageImgProc = textureImage ? await context.getImage(textureImage) : undefined
        const resultImage = isImageDescriptor(resultImageOrDescriptor)
            ? await context.createImage(resultImageOrDescriptor)
            : new SmartPtr(resultImageOrDescriptor)
        using resultImageImgProc = await context.getImage(resultImage)
        const resultNode = Nodes.rasterizeGeometry(resultImageImgProc.ref.node, "triangleList", imgProcVertices, indices, textureImageImgProc?.ref.node)
        return await context.createImage(resultImageImgProc.ref.descriptor, resultNode)
    },
}
