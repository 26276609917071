import {Matrix4, Vector3, Quaternion} from "@cm/lib/math"
import {SceneNodes} from "@cm/lib/templates/interfaces/scene-object"
import {getIOSVersion, getSafariVersion, isIoS, isIpadOS} from "@app/common/helpers/device-browser-detection/device-browser-detection"
import * as THREE from "three"

export type ThreeFloatTextureType = typeof THREE.HalfFloatType | typeof THREE.FloatType
export let DEFAULT_FLOAT_TEXTURE_TYPE: ThreeFloatTextureType = THREE.HalfFloatType

if (isIpadOS) {
    const versionFields = getSafariVersion()
    if (versionFields) {
        const [major, minor, rev] = versionFields
        if (major == 14) {
            // THREE.FloatType textures render all black on iPadOS Safari 14...
            DEFAULT_FLOAT_TEXTURE_TYPE = THREE.HalfFloatType
        } else if (major <= 13) {
            console.warn(`Enabling workaround for half-float textures on iPadOS Safari ${major}.${minor}.${rev}`)
            DEFAULT_FLOAT_TEXTURE_TYPE = THREE.FloatType
        }
    } else {
        console.warn("Can't detect iPadOS Safari version! No WebGL workarounds applied.")
    }
} else if (isIoS) {
    const versionFields = getIOSVersion()
    if (versionFields) {
        const [major, minor, rev] = versionFields
        if (major == 14) {
            // THREE.FloatType textures render all black on iOS 14...
            DEFAULT_FLOAT_TEXTURE_TYPE = THREE.HalfFloatType
        } else if (major <= 13) {
            console.warn(`Enabling workaround for half-float textures on iOS ${major}.${minor}.${rev}`)
            DEFAULT_FLOAT_TEXTURE_TYPE = THREE.FloatType
        }
    } else {
        console.warn("Can't detect iOS version! No WebGL workarounds applied.")
    }
}

export function toThreeMatrix(matrix: Matrix4): THREE.Matrix4 {
    return new THREE.Matrix4().fromArray(matrix.toArray())
}

export function fromThreeMatrix(matrix: THREE.Matrix4): Matrix4 {
    return Matrix4.fromArray(matrix.elements)
}

export function toThreeVector(vector: Vector3): THREE.Vector3 {
    return new THREE.Vector3(vector.x, vector.y, vector.z)
}

export function fromThreeVector(vector: THREE.Vector3): Vector3 {
    return new Vector3(vector.x, vector.y, vector.z)
}

export const MIN_NEAR_CLIP = 1
export const MAX_FAR_CLIP = 20000
export type CameraParameters = Pick<SceneNodes.Camera, "nearClip" | "farClip" | "filmGauge" | "focalLength" | "shiftX" | "shiftY" | "aspectRatio">

export const updateThreeCamera = (threeCamera: THREE.PerspectiveCamera, parameters: CameraParameters, transform?: Matrix4 | THREE.Matrix4) => {
    const {nearClip, farClip, filmGauge, focalLength, shiftX, shiftY, aspectRatio} = parameters

    threeCamera.near = Math.max(MIN_NEAR_CLIP, nearClip ?? MIN_NEAR_CLIP)
    threeCamera.far = Math.min(MAX_FAR_CLIP, farClip ?? MAX_FAR_CLIP)
    threeCamera.clearViewOffset()
    threeCamera.aspect = aspectRatio
    threeCamera.filmGauge = filmGauge
    threeCamera.setFocalLength(focalLength)
    if (aspectRatio > 1.0) threeCamera.setViewOffset(1, 1 / aspectRatio, shiftX, -shiftY / aspectRatio, 1, 1 / aspectRatio)
    else threeCamera.setViewOffset(aspectRatio, 1, shiftX * aspectRatio, -shiftY, aspectRatio, 1)
    if (transform) {
        const threeMatrix = transform instanceof THREE.Matrix4 ? transform : toThreeMatrix(transform)
        if (!(threeMatrix instanceof THREE.Matrix4)) throw new Error("Expected a THREE.Matrix4")

        threeCamera.matrix = threeMatrix.clone()
        threeCamera.matrix.decompose(threeCamera.position, threeCamera.quaternion, threeCamera.scale)

        threeCamera.updateMatrixWorld() // needed if camera has no parent
    }

    threeCamera.updateProjectionMatrix()

    return threeCamera
}

export class Float16ArrayBuilder {
    private tmpFloat32 = new Float32Array(1)
    private tmpUint32View = new Uint32Array(this.tmpFloat32.buffer)
    readonly array: Uint16Array
    constructor(length: number) {
        this.array = new Uint16Array(length)
    }

    set(idx: number, value: number): void {
        this.array[idx] = THREE.DataUtils.toHalfFloat(value)
    }
}

export class Float32ArrayBuilder {
    readonly array: Float32Array
    constructor(length: number) {
        this.array = new Float32Array(length)
    }
    set(idx: number, value: number): void {
        this.array[idx] = value
    }
}
