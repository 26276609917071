import {Component, computed, output, signal} from "@angular/core"
import {Matrix4} from "@cm/lib/math"
import {getNodeIconClass} from "@app/template-editor/helpers/template-icons"
import {Camera} from "@cm/lib/templates/nodes/camera"
import {Node} from "@cm/lib/templates/node-types"
import {AreaLight} from "@cm/lib/templates/nodes/area-light"
import {LightPortal} from "@cm/lib/templates/nodes/light-portal"
import {Render} from "@cm/lib/templates/nodes/render"
import {PostProcessRender, defaultsForToneMapping} from "@cm/lib/templates/nodes/post-process-render"
import {SceneProperties} from "@cm/lib/templates/nodes/scene-properties"
import {SearchComponent} from "../../../common/components/inputs/search/search.component"
import {InfiniteListComponent} from "../../../common/components/lists/infinite-list/infinite-list.component"
import {CardErrorComponent} from "../../../common/components/cards/card-error/card-error.component"
import {CardPlaceholderComponent} from "../../../common/components/cards/card-placeholder/card-placeholder.component"
import {HdriFilterInput, SelectHdriItemFragment} from "@api"
import {cardAnimations} from "@app/common/components/cards/animations"
import {BaseSelectComponent} from "@app/platform/components/base/base-select/base-select.component"
import {TemplateAddCardComponent} from "../template-add-card/template-add-card.component"
import {HDRILight} from "@cm/lib/templates/nodes/hdri-light"
import {OwnOrganizationsFilterComponent} from "../../../common/components/filters/own-organizations-filter/own-organizations-filter.component"

type SceneItem = {
    name: string
    node: Node
    iconClass: string
}

@Component({
    selector: "cm-template-add-scene-item",
    standalone: true,
    templateUrl: "./template-add-scene-item.component.html",
    styleUrl: "./template-add-scene-item.component.scss",
    animations: [...cardAnimations],
    imports: [SearchComponent, InfiniteListComponent, TemplateAddCardComponent, CardPlaceholderComponent, CardErrorComponent, OwnOrganizationsFilterComponent],
})
export class TemplateAddSceneItemComponent extends BaseSelectComponent<SelectHdriItemFragment, HdriFilterInput> {
    private rawData = this.implementDataLoader(this.sdk.gql.selectHdriItems)
    data = computed(() => this.rawData().items.map((item) => ({...item, node: item.data ? this.getHdriLight(item.data) : undefined})))
    onItemClicked = output()

    currentSection = signal<"camera" | "light" | "hdri">("camera")

    cameraSceneItems = computed<SceneItem[]>(() => {
        return [
            {
                name: "Camera",
                node: new Camera({
                    name: "Camera",
                    target: [0, 0, 0],
                    targeted: true,
                    lockedTransform: Matrix4.translation(0, 0, 100).toArray(),
                    resolutionX: 1920,
                    resolutionY: 1080,
                    filmGauge: 36,
                    fStop: 128,
                    focalLength: 50,
                    focalDistance: 100,
                    autoFocus: true,
                    ev: 0.0,
                    shiftX: 0,
                    shiftY: 0,
                    automaticVerticalTilt: false,
                    enablePanning: true,
                    screenSpacePanning: true,
                    toneMapping: defaultsForToneMapping("pbr-neutral"),
                }),
                iconClass: getNodeIconClass(Camera.getNodeClass()),
            },
            {
                name: "Render",
                node: new Render({
                    width: 1920,
                    height: 1080,
                    samples: 100,
                }),
                iconClass: getNodeIconClass(Render.getNodeClass()),
            },
            {
                name: "Post Process Render",
                node: new PostProcessRender({
                    mode: "whiteBackground",
                    render: null,
                    ev: 0,
                    whiteBalance: 0,
                    transparent: false,
                    composite: false,
                    backgroundColor: [1, 1, 1],
                    processShadows: false,
                    shadowInner: 0,
                    shadowOuter: 0,
                    shadowFalloff: 1,
                    autoCrop: false,
                    autoCropMargin: 50,
                    toneMapping: defaultsForToneMapping("pbr-neutral"),
                }),
                iconClass: getNodeIconClass(PostProcessRender.getNodeClass()),
            },
            {
                name: "Scene Properties",
                node: new SceneProperties({
                    maxSubdivisionLevel: 2,
                    backgroundColor: [1, 1, 1],
                    uiColor: [0, 0, 0],
                    uiStyle: "default",
                    iconSize: 24,
                    enableAr: false,
                    enableSalesEnquiry: false,
                    textureResolution: "2000px",
                    textureFiltering: false,
                    enableRealtimeShadows: true,
                    enableRealtimeLights: true,
                    enableRealtimeMaterials: true,
                    enableOnboardingHint: false,
                    enableGltfDownload: false,
                    enableStlDownload: false,
                    enablePdfGeneration: false,
                    enableSnapshot: true,
                    enableFullscreen: true,
                    environmentMapMode: "full",
                    showAnnotations: true,
                    enableAdaptiveSubdivision: false,
                }),
                iconClass: getNodeIconClass(SceneProperties.getNodeClass()),
            },
        ]
    })

    lightSceneItems = computed<SceneItem[]>(() => {
        return [
            {
                name: "Area Light",
                node: new AreaLight({
                    name: "Area Light",
                    width: 100,
                    height: 100,
                    lockedTransform: Matrix4.identity().toArray(),
                    target: [0, 0, -100],
                    targeted: true,
                    intensity: 1,
                    color: [1, 1, 1],
                    on: true,
                    directionality: 0,
                    visibleDirectly: true,
                    visibleInReflections: true,
                    visibleInRefractions: true,
                    transparent: false,
                    lightType: "Corona",
                }),
                iconClass: getNodeIconClass(AreaLight.getNodeClass()),
            },
            {
                name: "Light Portal",
                node: new LightPortal({
                    name: "Light Portal",
                    width: 100,
                    height: 100,
                    lockedTransform: Matrix4.identity().toArray(),
                }),
                iconClass: getNodeIconClass(LightPortal.getNodeClass()),
            },
        ]
    })

    sceneItems = computed<SceneItem[] | undefined>(() => {
        const currentSection = this.currentSection()
        if (currentSection === "camera") return this.cameraSceneItems()
        else if (currentSection === "light") return this.lightSceneItems()
        else return undefined
    })

    private getHdriLight(data: SelectHdriItemFragment) {
        const {name, legacyId} = data
        return new HDRILight({
            name: name ?? "HDRI Light",
            hdriId: legacyId,
            intensity: 1,
            rotation: [0, 0, 0],
            mirror: false,
        })
    }
}
