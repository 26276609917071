import {Component, DestroyRef, inject, OnInit, signal} from "@angular/core"
import {takeUntilDestroyed, toSignal} from "@angular/core/rxjs-interop"
import {ActivatedRoute, Router} from "@angular/router"
import {ContentTypeModel} from "@api"
import {exhaustMapWithTrailing} from "@common/helpers/utils/exhaust-map-with-trailing"
import {capitalizeFirstLetter} from "@common/helpers/utils/string"
import {ColormassEntity} from "@common/models/abilities"
import {AuthService} from "@common/services/auth/auth.service"
import {DialogService} from "@common/services/dialog/dialog.service"
import {GalleryImageService} from "@common/services/gallery-image/gallery-image.service"
import {NotificationsService} from "@common/services/notifications/notifications.service"
import {PermissionsService} from "@common/services/permissions/permissions.service"
import {RefreshService} from "@common/services/refresh/refresh.service"
import {SdkService} from "@common/services/sdk/sdk.service"
import {Enums} from "@enums"
import {Labels} from "@labels"
import {GraphQLError} from "graphql"
import {BehaviorSubject, catchError, debounceTime, distinctUntilChanged, EMPTY, from, map, share, Subject, switchMap, tap} from "rxjs"
import {z} from "zod"

@Component({
    selector: "cm-base-details",
    standalone: true,
    imports: [],
    templateUrl: "./base-details.component.html",
    styleUrl: "./base-details.component.scss",
})
export abstract class BaseDetailsComponent<
    EntityType extends {
        id: string
        legacyId: number
        name?: string | null
        galleryImage?: {id: string} | null
    } & ColormassEntity,
    UpdateType extends Record<string, unknown>,
> implements OnInit
{
    destroyRef = inject(DestroyRef)
    dialog = inject(DialogService)
    galleryImage = inject(GalleryImageService)
    route = inject(ActivatedRoute)
    router = inject(Router)
    protected sdk = inject(SdkService)
    public auth = inject(AuthService)
    protected notifications = inject(NotificationsService)
    protected permission = inject(PermissionsService)
    protected refresh = inject(RefreshService)

    $can = this.permission.$to

    protected readonly Labels = Labels
    protected readonly Enums = Enums

    public $loadError = signal<Error | null>(null)
    public item$ = new BehaviorSubject<EntityType | null | undefined>(undefined)
    public $item = toSignal(this.item$)
    public canRemoveGalleryImage = false

    abstract _contentTypeModel: ContentTypeModel

    abstract _fetchItem({id}: {id: string}): Promise<{item: EntityType}>

    abstract _updateItem({input}: {input: Omit<UpdateType, "id"> & {id: string}}): Promise<{item: EntityType}>

    _deleteItem?: (input: {id: string}) => Promise<{errors?: GraphQLError[]}>

    paramName = "itemId"
    displayName = "Item"

    // use both paramMap and the url in order to refresh the data when the url changes (e.g. a details dialog is closed)
    protected itemId$ = this.route.paramMap.pipe(
        map((params) => params.get(this.paramName) ?? ""),
        tap((itemId) => {
            if (!z.string().uuid().safeParse(itemId).success) {
                throw new Error("Invalid ID")
            }
        }),
        distinctUntilChanged(),
        share(),
        takeUntilDestroyed(this.destroyRef),
    )

    ngOnInit() {
        this.itemId$
            .pipe(
                switchMap((id) => this.refresh.keepFetched$(id, this._contentTypeModel, this._fetchItem)),
                catchError((error) => {
                    this.item$.next(null)
                    this.$loadError.set(error)
                    return EMPTY
                }),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe((item) => {
                this.item$.next(item)
                this.$loadError.set(null)

                if (item && this.$can().delete.item(item, "galleryImage")) {
                    this.galleryImage.available(item).then((available) => {
                        this.canRemoveGalleryImage = available
                    })
                } else {
                    this.canRemoveGalleryImage = false
                }
            })
        this.debounceUpdates()
    }

    debounceUpdates() {
        this.updateItemSubject
            .pipe(
                debounceTime(500),
                exhaustMapWithTrailing((data) => {
                    const id = this.$item()?.id
                    if (!id) {
                        throw new Error("Cannot update item without id.")
                    }
                    return from(
                        this._updateItem({
                            input: {
                                ...data,
                                id,
                            },
                        }).then(({item}) => item),
                    ).pipe(
                        tap((item) => this.refresh.item(item)),
                        tap(() => this.notifications.showInfo("Item updated.")),
                        catchError(() => {
                            this.notifications.showError("Cannot update item")
                            return EMPTY
                        }),
                        takeUntilDestroyed(this.destroyRef),
                    )
                }),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe()
    }

    // use a subject to debounce updates
    updateItemSubject = new Subject<UpdateType>()

    async updateItem(data: UpdateType) {
        this.updateItemSubject.next(data)
    }

    get title() {
        const item = this.$item()
        return item?.name || (item ? `${capitalizeFirstLetter(this.displayName)} ${item?.legacyId}` : null)
    }

    async requestDeleteConfirmation() {
        const itemId = this.$item()?.id
        if (!itemId) {
            throw new Error("Cannot delete item without id.")
        }
        const deleteMutation = this._deleteItem
        if (!deleteMutation) {
            throw new Error("Delete mutation is not defined.")
        }
        const confirmed = await this.notifications.confirmationDialog({
            title: `Delete ${this.displayName}`,
            message: `This action <strong>cannot be undone</strong>. Are you sure you want to delete this ${this.displayName}?`,
            isDestructive: true,
        })
        if (confirmed) {
            const {errors} = await deleteMutation({
                id: itemId,
            })
            if (errors?.length) {
                this.notifications.showError(errors[0])
            } else {
                this.refresh.contentTypeModel(this._contentTypeModel)
                this.notifications.showInfo(`${capitalizeFirstLetter(this.displayName)} deleted`)
                await this.closeModal()
            }
        }
    }

    async closeModal() {
        await this.router.navigate([this.route.snapshot.data.closeNavigationPath ?? "../"], {
            relativeTo: this.route,
            queryParamsHandling: "preserve",
        })
    }

    async removeGalleryImage() {
        const item = this.$item()
        if (item) {
            await this.notifications.withUserFeedback(
                async () => {
                    await this.galleryImage.remove(item)
                    this.refresh.item(item)
                },
                {
                    success: `Successfully removed ${this.galleryImage.labelForItem(item)}`,
                    error: `Failed to remove ${this.galleryImage.labelForItem(item)}`,
                },
            )
        }
    }
}
