import {registerNode} from "@src/graph-system/register-node"
import {colorValue, positionValue} from "@src/templates/types"
import {namedNodeParameters} from "@src/templates/nodes/named-node"
import {DeclareObjectNode, ObjectNode, TemplateObjectNode} from "@src/templates/declare-object-node"
import {z} from "zod"
import {VisitorNodeVersion, visitNone} from "@src/graph-system/declare-visitor-node"
import {GlobalRenderConstants, SceneNodes} from "@src/templates/interfaces/scene-object"
import {versionChain} from "@src/graph-system/node-graph"
import {Vector3, Matrix4} from "@src/math"
import {cameraLookAt} from "@src/templates/utils/camera-utils"

const areaLightParameters = namedNodeParameters.merge(
    z.object({
        intensity: z.number(),
        width: z.number(),
        height: z.number(),
        color: colorValue,
        target: positionValue,
        targeted: z.boolean(),
        on: z.boolean(),
        directionality: z.number(),
        visibleDirectly: z.boolean(),
        visibleInReflections: z.boolean(),
        visibleInRefractions: z.boolean(),
        transparent: z.boolean(),
        lightType: z.enum(["Corona", "Blender"]),
    }),
)
export type AreaLightParameters = z.infer<typeof areaLightParameters>

type V0 = ObjectNode & {
    name: string
    intensity: number
    width: number
    height: number
    color: [number, number, number]
    target: [number, number, number]
    on: boolean
    directionality: number
    visibleDirectly: boolean
    visibleInReflections: boolean
    visibleInRefractions: boolean
    transparent: boolean
}

type V1 = V0 & {lightType: "Corona" | "Blender"}
const v0: VisitorNodeVersion<V0, V1> = {
    toNextVersion: (parameters) => {
        return {...parameters, lightType: "Corona"}
    },
}

type V2 = V1 & {targeted: boolean}
const v1: VisitorNodeVersion<V1, V2> = {
    toNextVersion: (parameters) => {
        return {...parameters, targeted: true}
    },
}

@registerNode
export class AreaLight extends DeclareObjectNode(
    {parameters: areaLightParameters},
    {
        onVisited: {
            onCompile: function (this: AreaLight, {context, parameters}) {
                const {evaluator, currentTemplate} = context
                const {preDisplayList} = currentTemplate
                const {
                    width,
                    height,
                    color,
                    target,
                    targeted,
                    on,
                    intensity,
                    directionality,
                    visibleDirectly,
                    visibleInReflections,
                    visibleInRefractions,
                    transparent,
                    lightType,
                } = parameters

                const scope = evaluator.getScope(this)

                //Our platform has light intensity values x, they are transformed as
                //x * lightIntensityScale * width * height * 4
                const widthM = width / 100
                const heightM = height / 100
                const mappedIntensity = lightType === "Corona" ? intensity : intensity / 4.0 / GlobalRenderConstants.lightIntensityScale / widthM / heightM

                const {transform: objectTransform, ...objectProps} = this.setupObject(scope, context, "AreaLight", undefined, undefined)
                const transform = targeted
                    ? scope.lambda(
                          objectTransform,
                          (transform) => {
                              return cameraLookAt(transform.getTranslation(), Vector3.fromArray(target))
                          },
                          "transform",
                      )
                    : objectTransform

                const light = scope.struct<SceneNodes.AreaLight>("AreaLight", {
                    type: "AreaLight",
                    ...objectProps,
                    transform,
                    width,
                    height,
                    color,
                    on,
                    intensity: mappedIntensity,
                    directionality,
                    visibleDirectly,
                    visibleInReflections,
                    visibleInRefractions,
                    target: Vector3.fromArray(target),
                    targeted,
                    transparent: transparent,
                })
                preDisplayList.push(light)

                return visitNone(parameters)
            },
        },
    },
    {nodeClass: "AreaLight", versionChain: versionChain([v0, v1])},
) {}

export type AreaLightFwd = TemplateObjectNode<AreaLightParameters>
