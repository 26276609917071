import {ImageProcessingNodes as Nodes} from "@cm/lib/image-processing/image-processing-nodes"
import {ImageOpType} from "app/textures/texture-editor/operator-stack/image-op-system/detail/types"
import {ImagePtr} from "app/textures/texture-editor/operator-stack/image-op-system/image-ref"
import {toImgProcResultImage} from "app/textures/texture-editor/operator-stack/image-op-system/detail/utils-img-proc"

export type ParameterType = {
    sourceImage: ImagePtr
    kernel: {
        width: number
        height: number
        values: number[] // row-major
    }
    borderMode: "wrap" | "clamp" | "wrap-mirrored" | "zero" // TODO switch to AddressMode
    resultImage?: ImagePtr
}

export type ReturnType = ImagePtr

export const imageOpConvolve: ImageOpType<ParameterType, ReturnType> = {
    name: "Convolve",

    WebGL2: async ({context, parameters: {sourceImage, kernel, borderMode, resultImage}}) => {
        if (kernel.values.length != kernel.width * kernel.height) {
            throw new Error("Kernel values array length doesn't match the kernel size")
        }
        const halConvolve = await context.getOrCreateImageCompositor(`
            uniform float u_kernel[${kernel.width} * ${kernel.height}];

            vec4 computeColor(ivec2 targetPixel) {
                vec4 color = vec4(0);
                ivec2 kernelOfs = (ivec2(${kernel.width}, ${kernel.height}) - 1) / 2;
                for (int ky = 0; ky < ${kernel.height}; ky++) {
                    for (int kx = 0; kx < ${kernel.width}; kx++) {
                        float k = u_kernel[kx + ky * ${kernel.width}];
                        ivec2 sourcePixelIndex = targetPixel + ivec2(kx, ky) - kernelOfs;
                        vec4 i = texelFetch0(sourcePixelIndex, ${borderMode === "wrap" ? "ADDRESS_MODE_REPEAT" : borderMode === "clamp" ? "ADDRESS_MODE_CLAMP_TO_EDGE" : borderMode === "wrap-mirrored" ? "ADDRESS_MODE_MIRRORED_REPEAT" : "ADDRESS_MODE_BORDER"});
                        color += i * k;
                    }
                }
                return color;
            }
        `)
        using sourceImageWebGl2 = await context.getImage(sourceImage)
        resultImage = await context.prepareResultImage(resultImage, sourceImageWebGl2.ref.descriptor)
        using resultImageWebGl2 = await context.getImage(resultImage)
        halConvolve.setParameter("u_kernel", {type: "float[]", value: kernel.values})
        await halConvolve.paint(resultImageWebGl2.ref.halImage, sourceImageWebGl2.ref.halImage)
        return resultImage
    },

    ImgProc: async ({context, parameters: {sourceImage, kernel, borderMode, resultImage}}) => {
        if (kernel.values.length != kernel.width * kernel.height) {
            throw new Error("Kernel values array length doesn't match the kernel size")
        }
        using sourceImageImgProc = await context.getImage(sourceImage)
        const resultNode = Nodes.convolve(sourceImageImgProc.ref.node, Nodes.constKernel(kernel.width, kernel.height, kernel.values), borderMode, undefined)
        using result = await context.createImage(sourceImageImgProc.ref.descriptor, resultNode)
        return await toImgProcResultImage(context, result, resultImage)
    },
}
