import {registerNode} from "@src/graph-system/register-node"
import {AnyJSONValue, EvaluatedTemplateOutputs, externalId} from "@src/templates/types"
import {MaterialLike, TemplateLike, ObjectLike, ImageLike} from "@src/templates/node-types"
import {TemplateInstance} from "@src/templates/nodes/template-instance"
import {DeclareTemplateNodeTS, TemplateNodeImplementation, TemplateNodeMeta} from "@src/templates/declare-template-node"
import {TemplateNode} from "@src/templates/types"
import {EvaluatedTemplateValueType} from "@src/templates/types"
import {IMaterialGraph} from "@src/templates/interfaces/material-data"
import {ObjectData} from "@src/templates/interfaces/object-data"
import {TemplateImageDataNew} from "@src/templates/runtime-graph/type-descriptors"
import {TemplateGraph} from "@src/templates/nodes/template-graph"
import {EvaluableTemplateNode} from "@src/templates/evaluable-template-node"
import {GraphBuilderScope} from "@src/templates/runtime-graph/graph-builder-scope"
import {NodeEvaluator} from "@src/templates/node-evaluator"
import {BuilderOutlet} from "@src/templates/runtime-graph/graph-builder"
import {z} from "zod"
import {namedNodeParameters, NamedNodeParameters} from "@src/templates/nodes/named-node"
import {nodeInstance} from "@src/graph-system/instance"
import {NodeGraphClass} from "@src/graph-system/node-graph"

const outputParameters = namedNodeParameters.merge(
    z.object({
        template: nodeInstance(TemplateInstance),
        outputId: externalId,
    }),
)
type OutputParameters<T> = NamedNodeParameters & {template: TemplateInstance; outputId: string}

const generateOutput = <T, E>(
    type: EvaluatedTemplateValueType,
    implementation: TemplateNodeImplementation<OutputParameters<T>>,
    meta: TemplateNodeMeta<OutputParameters<T>>,
): NodeGraphClass<TemplateNode<OutputParameters<T>> & EvaluableTemplateNode<E>> => {
    const retClass = class
        extends DeclareTemplateNodeTS<OutputParameters<T>>({validation: {paramsSchema: outputParameters}, ...implementation}, meta)
        implements EvaluableTemplateNode<E>
    {
        evaluate(scope: GraphBuilderScope, evaluator: NodeEvaluator) {
            const outputs = evaluator.templateScope.resolve<EvaluatedTemplateOutputs>(`templateOutputs-${evaluator.getLocalId(this.parameters.template)}`)
            return scope.get(outputs, `output-${type}-${this.parameters.outputId}`) as BuilderOutlet<E>
        }
    }
    return retClass
}

////////////////////////////////////////////////////////////////////

export interface ObjectOutputParameters extends OutputParameters<ObjectLike> {} // workaround for recursive type

@registerNode
export class ObjectOutput extends generateOutput<ObjectLike, ObjectData>("object", {}, {nodeClass: "ObjectOutput"}) {}

export type ObjectOutputFwd = TemplateNode<ObjectOutputParameters> & EvaluableTemplateNode<ObjectData>

////////////////////////////////////////////////////////////////////

export interface MaterialOutputParameters extends OutputParameters<MaterialLike> {} // workaround for recursive type

@registerNode
export class MaterialOutput extends generateOutput<MaterialLike, IMaterialGraph>("material", {}, {nodeClass: "MaterialOutput"}) {}

export type MaterialOutputFwd = TemplateNode<MaterialOutputParameters> & EvaluableTemplateNode<IMaterialGraph>

////////////////////////////////////////////////////////////////////

export interface TemplateOutputParameters extends OutputParameters<TemplateLike> {} // workaround for recursive type

@registerNode
export class TemplateOutput extends generateOutput<TemplateLike, TemplateGraph>("template", {}, {nodeClass: "TemplateOutput"}) {}

export type TemplateOutputFwd = TemplateNode<TemplateOutputParameters> & EvaluableTemplateNode<TemplateGraph>

////////////////////////////////////////////////////////////////////

export interface ImageOutputParameters extends OutputParameters<ImageLike> {} // workaround for recursive type

@registerNode
export class ImageOutput extends generateOutput<ImageLike, TemplateImageDataNew>("image", {}, {nodeClass: "ImageOutput"}) {}

export type ImageOutputFwd = TemplateNode<ImageOutputParameters> & EvaluableTemplateNode<TemplateImageDataNew>

////////////////////////////////////////////////////////////////////

export type StringOutputParameters = OutputParameters<string>

@registerNode
export class StringOutput extends generateOutput<string, string>("string", {}, {nodeClass: "StringOutput"}) {}

export type StringOutputFwd = TemplateNode<StringOutputParameters> & EvaluableTemplateNode<string>

////////////////////////////////////////////////////////////////////

export type NumberOutputParameters = OutputParameters<number>

@registerNode
export class NumberOutput extends generateOutput<number, number>("number", {}, {nodeClass: "NumberOutput"}) {}

export type NumberOutputFwd = TemplateNode<NumberOutputParameters> & EvaluableTemplateNode<number>

////////////////////////////////////////////////////////////////////

export type BooleanOutputParameters = OutputParameters<boolean>

@registerNode
export class BooleanOutput extends generateOutput<boolean, boolean>("boolean", {}, {nodeClass: "BooleanOutput"}) {}

export type BooleanOutputFwd = TemplateNode<BooleanOutputParameters> & EvaluableTemplateNode<boolean>

////////////////////////////////////////////////////////////////////

export type JSONOutputParameters = OutputParameters<AnyJSONValue>

@registerNode
export class JSONOutput extends generateOutput<AnyJSONValue, AnyJSONValue>("json", {}, {nodeClass: "JSONOutput"}) {}

export type JSONOutputFwd = TemplateNode<JSONOutputParameters> & EvaluableTemplateNode<AnyJSONValue>
